import { makeStyles } from '@material-ui/core';
import { customGap } from 'helpers/device_helper';

interface Props {
    imgSrc?: string;
    align?: 'center' | 'start' | 'end';
}

export default makeStyles((theme) => ({
    wrapper: (props: Props) => ({
        transition: 'box-shadow 200ms linear',
        borderRadius: '0.8rem',
        border: `0.1rem solid ${theme.palette.action.disabledBackground}`,
        minWidth: '28rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: props.align,
        textAlign: props.align,
        justifyContent: 'space-between',
        padding: `${theme.spacing(3)}rem`,
        ...customGap(theme.spacing(4), 'vertical'),
        '&:hover': {
            boxShadow: '0 0 2.0rem 0 rgba(0, 0, 0, 0.2)',
        },
    }),
    content: {
        ...customGap(theme.spacing(4), 'vertical'),
        width: '100%',
        color: theme.palette.text.default,
    },
    disabled: {
        opacity: 0.5,
    },
    image: (props: Props) => ({
        borderRadius: '0.8rem',
        width: '100%',
        height: '14rem',
        backgroundImage: `url(${props.imgSrc})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }),
    iframe: {
        borderRadius: '0.8rem',
        width: '100%',
        height: '20rem',
    },
}));
