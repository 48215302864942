import { EKYCTypes } from './actionsTypes';
import { KycReducerState } from './interface';

const initialState: KycReducerState = {
    updateLoading: false,
};

const KycReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EKYCTypes.UPDATE_USER_INFO_START: {
            return {
                ...state,
                updateLoading: true,
            };
        }
        case EKYCTypes.UPDATE_USER_INFO_SUCCESS:
        case EKYCTypes.UPDATE_USER_INFO_FAILED: {
            return {
                ...state,
                updateLoading: false,
            };
        }
        default:
            return state;
    }
};

export default KycReducer;
