import { makeStyles } from '@material-ui/core';
import { cssEnd, customGap, isDesktop, isRtl, isTablet, oppositeCss } from 'helpers/device_helper';

interface StylesProps {
    sideMenuState: boolean;
}

export default makeStyles((theme) => ({
    overlay: (props: StylesProps) => ({
        height: '100%',
        width: 'auto',
        zIndex: 'initial',
        overflow: 'hidden',
        background: 'unset',
        position: 'fixed',
        ...isTablet({
            position: 'initial',
        }),
        ...isDesktop({
            height: '100%',
            width: 'auto',
            zIndex: 'initial',
            overflow: 'auto',
            background: 'unset',
            position: 'initial',
        }),
    }),
    overlay__open: (props: StylesProps) => ({
        height: '100vh',
        width: '100vw',
        zIndex: theme.zIndex.drawer,
        overflow: 'hidden',
        background: 'rgba(52, 64, 84, 0.60)',
        position: 'fixed',
        ...isTablet({
            position: 'fixed',
        }),
        ...isDesktop({
            height: '100%',
            width: 'auto',
            zIndex: 'initial',
            overflow: 'auto',
            background: 'unset',
            position: 'initial',
        }),
    }),
    menu_container: (props: StylesProps) => ({
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.background.default,
        width: 'unset',
        overflowX: 'hidden',
        overflowY: 'overlay',
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'repeat(3, auto) 1fr',
        gap: `${theme.spacing(5)}rem`,
        ...oppositeCss(
            theme.direction,
            'padding',
            `${theme.spacing(4)}rem ${theme.spacing(2)}rem ${theme.spacing(6)}rem ${theme.spacing(2)}rem`
        ),
        ...oppositeCss(theme.direction, 'margin', '0 0 0 -27.5rem'),
        ...cssEnd(theme.direction, 'border', `0.1rem solid ${theme.palette.action.disabledBackground}`),
        ...isTablet({
            width: '6.4rem',
            margin: 'unset',
        }),
    }),
    menu_container__open: () => ({
        margin: 'unset',
        width: '311px',
        ...isTablet({
            width: '27.5rem',
            margin: 'unset',
        }),
        position: 'relative',
    }),
    menu_list_wrapper: (props: StylesProps) => ({
        position: 'relative',
        display: 'grid',
        width: '100%',
        gap: `${theme.spacing(1)}rem`,

        '&:not(:last-child):not(:nth-child(2n)):after': {
            content: "''",
            position: 'absolute',
            height: '0.1rem',
            bottom: `-${theme.spacing(2.8)}rem`,
            ...oppositeCss(theme.direction, 'margin', `0 0 0 -${theme.spacing(2)}rem`),
            width: `calc(100% + ${theme.spacing(4)}rem)`,
            backgroundColor:
                theme.palette.type === 'dark'
                    ? theme.palette.grayVariants[300]
                    : theme.palette.action.disabledBackground,
        },
        '&:first-child:after': {
            display: props.sideMenuState ? 'block' : 'none',
        },
        '&:last-child': {
            gap: 'unset',
            ...customGap(theme.spacing(1), 'vertical'),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    }),
    menu_list_wrapper_last: { marginBottom: '180px' },
    seperator: {
        width: '100%',
        height: '0.1rem',
        backgroundColor: 'grey',
    },
    shareIconsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        ...customGap(1.6, 'vertical'),
    },
    shareIconsContainer__openMenu: () => ({
        bottom: '0px',
        left: '0px',
        padding: '20px 0px',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.background.default,
        position: 'fixed',
        width: '310px',
        zIndex: 2,
        ...isRtl({
            left: 'unset',
            right: '0',
        }),
        ...isTablet({
            width: '274px',
        }),
    }),
    shareIconsContainer__closedMenu: { display: 'none' },
    closeButton: () => ({
        position: 'fixed',
        right: 0,
        top: 0,
        width: '40px',
        height: '40px',
        margin: '12px 8px',
        color: theme.palette.common.white,
        ...isRtl({ right: 'unset', left: 0 }),
        ...isTablet({
            display: 'none',
        }),
    }),
    collapseWrapper: {},
    collapsedItemMain: { display: 'flex', alignItems: 'center', height: '40px', color: theme.palette.text.primary },
    collapsedItem: {
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        padding: '8px 8px 8px 35px',
        color: theme.palette.text.primary,
    },
    collapsedIconMain: { margin: '0 15px 0 15px' },
    collapsedIcon: { margin: '0 15px' },
    root: { padding: '0' },
    emptySpace: { height: '106px' },
    shareButtonStyle: {
        padding: '5px',
        '&:hover': { backgroundColor: theme.palette.disabled.background, borderRadius: '8px' },

        collapsedItem__active: {
            backgroundColor: theme.palette.grayVariants[100],
            borderRadius: '0.8rem',
            color: theme.palette.grayVariants[900],
            fontWeight: 600,
        },
        collapseButton: () => ({
            '&:hover,&:focus,&:active': { backgroundColor: 'transparent' },
            marginRight: '12px',
            ...isRtl({ marginLeft: '12px' }),
        }),
        flagsAndText: {
            display: 'flex',
            alignItems: 'center',
        },
    },
    menuItemWrapper: {
        display: 'inline-block',
        width: '100%',
    },
}));
