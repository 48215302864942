import React from 'react';
import { NetworkConnectionMessageProps } from './networkConnectionMessage.scheme';
import { useTranslation } from 'react-i18next';
import useStyles from './networkConnectionMessage.styles';
import Typography from 'components/Typography';
import LoaderFullScreen from 'components/LoaderFullScreen';
import Icon, { IconsType } from 'components/Icon';

const NetworkConnectionMessage: React.FunctionComponent<NetworkConnectionMessageProps> = () => {
    const classes = useStyles();
    const { t } = useTranslation('common');

    return (
        <div className={classes.networkConnection}>
            <LoaderFullScreen withoutLoader />
            <div className={classes.innerContainerWrapper}>
                <div className={classes.iconContainer}>
                    <Icon name={IconsType.WifiOf} />
                </div>
                <Typography>
                    {t('common:networkConnection.connection_lost')} {t('common:networkConnection.try_reconnect')}
                </Typography>
            </div>
        </div>
    );
};

export default NetworkConnectionMessage;
