import { CircularProgress, Collapse } from '@material-ui/core';
import { Alert, AlertProps, Color } from '@material-ui/lab';
import Stack from 'components/Stack';

import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

export interface FileAlertListProps extends AlertProps {
    renderItem: (item: any) => React.ReactNode;
    item: any[];
    renderAction?: (handleClose: () => void, severity?: Color) => React.ReactNode;
    loading?: boolean;
    handleRemoveItem?: (item: any) => void;
}

const style = {
    transform: 'rotate(-90deg)',
    webkitTransform: 'rotate(-89deg)', // vendor prefix, cause it's still experimental in Safari. Also multiples of 90 degrees did not work for me, don't know why
};

export const CustomAlert: FunctionComponent<FileAlertListProps> = ({
    renderAction,
    iconMapping,
    icon,
    renderItem,
    item,
    variant,
    severity,
    className,
    loading,
    handleRemoveItem,
}) => {
    const [openAlert, setOpenAlert] = useState<boolean>(true);
    const [progress, setProgress] = React.useState(10);

    useEffect(() => {
        let timer;
        if (progress < 100 && loading) {
            timer = setTimeout(() => {
                setProgress((prevProgress) => prevProgress + 10);
            }, 800);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [loading, progress]);

    const handleClose = useCallback(() => {
        setOpenAlert(false);
    }, []);

    const actionComponent = useMemo(() => {
        if (loading && progress < 100) {
            return (
                <Stack fullHeight justifyContent="center">
                    <CircularProgress style={style} size={`32px`} variant="determinate" value={progress} />
                </Stack>
            );
        }
        return typeof renderAction === 'function' && renderAction(handleClose, severity);
    }, [handleClose, loading, progress, renderAction, severity]);

    const handleExist = useCallback(() => {
        if (typeof handleRemoveItem === 'function') {
            handleRemoveItem(item);
        }
    }, [handleRemoveItem, item]);

    return (
        <>
            <Collapse timeout={500} onExited={handleExist} unmountOnExit in={openAlert}>
                <Alert
                    className={className}
                    iconMapping={iconMapping}
                    action={actionComponent}
                    icon={icon}
                    variant={variant}
                    severity={severity}
                >
                    {renderItem(item)}
                </Alert>
            </Collapse>
        </>
    );
};
