import { getTokenFromLocalStorage } from 'helpers/token';
import { ESystemTypes } from 'store/system/actionsTypes';
import { EAuthTypes } from './actionsTypes';
import { AuthReducerState } from './interfaces';

const initialState: AuthReducerState = {
    loader: false,
    loginType: null,
    hasJwtToken: !!getTokenFromLocalStorage(),
    errors: {},
    ipData: undefined,
    emailVerified: null,
};

const loginReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // Register Cases
        case EAuthTypes.REGISTER_START: {
            return {
                ...state,
                loader: true,
                loginType: payload,
            };
        }
        case EAuthTypes.REGISTER_SUCCESS: {
            return {
                ...state,
                loader: false,
                hasJwtToken: true,
                loginType: null,
            };
        }
        case EAuthTypes.REGISTER_FAILED: {
            return {
                ...state,
                loader: false,
                loginType: null,
                errors: {
                    ...state.errors,
                    ...payload.errors,
                },
            };
        }
        //Login Cases
        case EAuthTypes.LOGIN_START: {
            return {
                ...state,
                loader: true,
                loginType: payload.type,
            };
        }
        case EAuthTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                loader: false,
                loginType: null,
                hasJwtToken: true,
            };
        }
        case EAuthTypes.LOGIN_FAILED: {
            return {
                ...state,
                loader: false,
                loginType: null,
                errors: {
                    ...state.errors,
                    ...payload.errors,
                },
            };
        }
        //Common Cases
        case EAuthTypes.SOCIAL_REQUEST: {
            return {
                ...state,
                loader: true,
                loginType: payload.type,
            };
        }
        case EAuthTypes.RESET_FORM_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [payload.field]: {
                        error: false,
                        error_label: '',
                    },
                },
            };
        case EAuthTypes.SET_FORM_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [payload.field]: {
                        error: payload.error,
                        error_label: payload.error_label,
                    },
                },
            };
        case EAuthTypes.START_LOADER:
            return {
                ...state,
                loader: true,
            };
        case EAuthTypes.STOP_LOADER:
            return {
                ...state,
                loader: false,
            };
        case EAuthTypes.IP_DATA_START:
            return {
                ...state,
                loader: true,
            };
        case EAuthTypes.IP_DATA_SUCCESS:
            return {
                ...state,
                ipData: payload,
                loader: false,
            };
        case EAuthTypes.IP_DATA_FAILED:
            return {
                ...state,
                loader: false,
            };
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                loginType: null,
                hasJwtToken: false,
                errors: {},
            };
        }
        default:
            return state;
    }
};

export default loginReducer;
