import { Notification } from 'services/generatedClientFromSwagger';
import { ENotificationsTypes } from './actionsTypes';
import { notificationGetAmount, NotificationReducerState } from './interface';

const initialState: NotificationReducerState = {
    loading: false,
    notifications: [],

    moreLoading: false,
    hasMoreData: true,
    offset: 0,

    deleteNotificationLoading: false,

    notFoundNotifications: [],
};

const sortAndFilterNotifications = (notificatins: Array<Notification>) => {
    const asObject = notificatins.reduce<{ [id: string]: Notification }>(
        (acc, row) => ({ ...acc, [row._id]: row }),
        {}
    );
    return Object.values(asObject).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
};

const NotificationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ENotificationsTypes.GET_SPECIFIC_NOTIFICATION_START:
        case ENotificationsTypes.GET_ALL_NOTIFICATIONS_START: {
            return {
                ...state,
                loading: true,
            };
        }
        case ENotificationsTypes.GET_SPECIFIC_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                notifications: sortAndFilterNotifications([...state.notifications, payload]),
            };
        }
        case ENotificationsTypes.GET_ALL_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                notifications: payload,
            };
        }
        case ENotificationsTypes.GET_SPECIFIC_NOTIFICATION_FAILED: {
            return {
                ...state,
                loading: false,
                notFoundNotifications: [...state.notFoundNotifications, payload],
            };
        }
        case ENotificationsTypes.GET_ALL_NOTIFICATIONS_FAILED: {
            return {
                ...state,
                loading: false,
                notifications: [],
            };
        }
        case ENotificationsTypes.GET_MORE_NOTIFICATIONS_START: {
            return {
                ...state,
                moreLoading: true,
            };
        }
        case ENotificationsTypes.GET_MORE_NOTIFICATIONS_FAILED: {
            return {
                ...state,
                moreLoading: false,
                hasMoreData: false,
            };
        }
        case ENotificationsTypes.GET_MORE_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                moreLoading: false,
                notifications: sortAndFilterNotifications([...state.notifications, ...payload]),
                offset: state.offset + notificationGetAmount,
                hasMoreData: payload.length > 0,
            };
        }
        case ENotificationsTypes.NEW_MESSAGE: {
            return {
                ...state,
                notifications: sortAndFilterNotifications([payload, ...state.notifications]),
            };
        }
        case ENotificationsTypes.MARK_NOTIFICATIONS: {
            return {
                ...state,
                notifications: payload,
            };
        }
        case ENotificationsTypes.DELETE_NOTIFICATION_START: {
            return {
                ...state,
                deleteNotificationLoading: true,
            };
        }
        case ENotificationsTypes.DELETE_NOTIFICATION_FAILED: {
            return {
                ...state,
                deleteNotificationLoading: false,
            };
        }
        case ENotificationsTypes.DELETE_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                deleteNotificationLoading: false,
                notifications: payload.notifications,
                notFoundNotifications: [...state.notFoundNotifications, ...payload.deletedNotifications],
                moreLoading: false,
                hasMoreData: true,
                offset: 0,
            };
        }
        default:
            return state;
    }
};

export default NotificationReducer;
