import React from 'react';
import { WtTradeBtnProps } from './wtTradeBtn.scheme';
import useStyles from './wtTradeBtn.styles';
import Button from 'components/Button';
import { Pages } from 'interfaces/main';
import { useTranslation } from 'react-i18next';
import Icon, { IconsType } from 'components/Icon';
import Typography from 'components/Typography';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from 'features/prop/components/WtTradeBtn/wtTradeBtn.scheme';
import { useHistory } from 'react-router-dom';
import { PropChallengeChallengeStatusEnum, PropChallengeInternalStatusEnum } from 'services/generatedClientFromSwagger';

const WtTradeBtn: React.FunctionComponent<WtTradeBtnProps> = (props) => {
    const { selectedChallenge, setSelectedChallenge } = props;
    const { t } = useTranslation('prop');
    const history = useHistory();

    const handleButtonClick = React.useCallback(() => {
        setSelectedChallenge && setSelectedChallenge(selectedChallenge?.tpName || '');
        history.push(`/${Pages.PLATFORMS}/home`);
    }, [history, selectedChallenge?.tpName, setSelectedChallenge]);

    const enabled =
        selectedChallenge &&
        selectedChallenge?.internalStatus === PropChallengeInternalStatusEnum.Success &&
        selectedChallenge?.challengeStatus === PropChallengeChallengeStatusEnum.Active;

    const classes = useStyles();

    return (
        <div className={classes.btn_wrapper}>
            <Button
                className={classes.propTradeNowBtn}
                variant="outlined"
                onClick={handleButtonClick}
                disabled={!enabled}
            >
                <Icon name={IconsType.nav_bar_line_chart} className={classes.line_chart_icon} />
                <Typography variant="tMdSemibold" className={classes.trade_now}>
                    {t('trade_now_btn')}
                </Typography>
            </Button>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(WtTradeBtn));
