import { uploadD_uploadFiles, uploadD_cleanUploadFiles, uploadD_cleanUploadFile } from './store/actions';
import { uploadD_processLoading, uploadD_filesUploadingState } from './store/selector';
import { FileUpload } from './store/interface';
import { UploadedFileWithType } from './upload-documents.interface';

export interface UploadDocumentsProps {
    uploadD_processLoading: boolean;
    uploadD_filesUploadingState: {
        [file: string]: FileUpload;
    };
    uploadD_uploadFiles: (files: Array<UploadedFileWithType>) => void;
    uploadD_cleanUploadFiles: () => void;
    uploadD_cleanUploadFile: (fileName: string) => void;
}

export enum FileTypes {
    'proofOfId' = 'proofOfId',
    'proofOfResidence' = 'proofOfResidence',
    'proofOfAttorney' = 'proofOfAttorney',
    'creditCard' = 'creditCard',
    'swift' = 'swift',
    'declarationOfDeposit' = 'declarationOfDeposit',
    'declarationOfAddress' = 'declarationOfAddress',
    'other' = 'other',
}

export const mapStateToProps = (state) => ({
    uploadD_processLoading: uploadD_processLoading(state),
    uploadD_filesUploadingState: uploadD_filesUploadingState(state),
});

export const mapDispatchToProps = {
    uploadD_uploadFiles,
    uploadD_cleanUploadFiles,
    uploadD_cleanUploadFile,
};
