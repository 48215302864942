import Button from 'components/Button';
import Icon, { IconsType } from 'components/Icon';
import { isTablet } from 'helpers/device_helper';
import React from 'react';
import useStyles from './backButton.styles';

export interface MenuButtonProps {
    toggleSideMenu: () => void;
    accountName?: string;
}

const MenuButton: React.FunctionComponent<MenuButtonProps> = (props) => {
    const { toggleSideMenu, accountName } = props;
    const classes = useStyles();

    return (
        <>
            {isTablet() ? (
                <Button variant="text" onClick={toggleSideMenu}>
                    <Icon name={IconsType.menu} />
                </Button>
            ) : (
                <Button onClick={toggleSideMenu} className={classes.menuBtn}>
                    {accountName?.charAt(0).toUpperCase()}
                </Button>
            )}
        </>
    );
};

export default MenuButton;
