export enum EPropMyChallengesTypes {
    GET_PLANS_START = '[prop-myChallenges] get plans start',
    GET_PLANS_SUCCESS = '[prop-myChallenges] get plans success',
    GET_PLANS_FAIL = '[prop-myChallenges] get plans fail',

    GET_CHALLENGES_START = '[prop-myChallenges] get challenges start',
    GET_CHALLENGES_SUCCESS = '[prop-myChallenges] get challenges success',
    GET_CHALLENGES_FAIL = '[prop-myChallenges] get challenges fail',

    GET_CHALLENGE_IFRAME_START = '[prop-myChallenges] get challenge iframe start',
    GET_CHALLENGE_IFRAME_SUCCESS = '[prop-myChallenges] get challenge iframe success',
    GET_CHALLENGE_IFRAME_FAIL = '[prop-myChallenges] get challenge iframe fail',

    SET_SELECTED_CHALLENGE = '[prop-myChallenges] set selected challenge',

    SET_MAIN_ACCOUNT_TP = '[prop-myChallenges] set main account tp',

    GET_CHALLENGE_IFRAME_POPUP_START = '[prop-myChallenges] get challenge iframe popup start',
    GET_CHALLENGE_IFRAME_POPUP_SUCCESS = '[prop-myChallenges] get challenge iframe popup success',
    GET_CHALLENGE_IFRAME_POPUP_FAIL = '[prop-myChallenges] get challenge iframe popup fail',

    ACCEPT_CHALLENGE_TERMS_START = '[prop-myChallenges] accept challenge terms start',
    ACCEPT_CHALLENGE_TERMS_SUCCESS = '[prop-myChallenges] accept challenge terms success',
    ACCEPT_CHALLENGE_TERMS_FAIL = '[prop-myChallenges] accept challenge terms fail',

    SET_FUNDED_CHALLENGES_START = '[prop-myChallenges] set funded challenges start',
    SET_FUNDED_CHALLENGES_SUCCESS = '[prop-myChallenges] set funded challenges success',
    SET_FUNDED_CHALLENGES_FAIL = '[prop-myChallenges] set funded challenges fail',

    VALIDATE_WITHDRAWAL_SUCCESS = '[prop-myChallenges] validate withdrawal success',

    SET_KYC_DIALOG_STATE = '[prop-myChallenges] set kyc dialog state',

    STATUS_MANUAL_KYC_START = '[prop-myChallenges] status manual kyc start',
    STATUS_MANUAL_KYC_SUCCESS = '[prop-myChallenges] status manual kyc success',
    STATUS_MANUAL_KYC_FAIL = '[prop-myChallenges] status manual kyc fail',

    SET_FUNDED_ALERT_TOGGLE = '[prop-myChallenges] set funded alert toggle',
}
