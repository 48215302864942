import { makeStyles } from '@material-ui/core';
import { isTablet } from 'helpers/device_helper';

interface Props {
    imgSrc: string;
    mobileImgSrc: string;
}

export default makeStyles((theme) => ({
    title: {
        color: theme.palette.text.default,
        paddingTop: '2.0rem',
    },
    img: (props: Props) => ({
        backgroundColor: theme.palette.default.main,
        backgroundImage: `url(${props.mobileImgSrc})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '0.8rem',
        height: '12rem',
        ...isTablet({
            backgroundImage: `url(${props.imgSrc})`,
            height: '18rem',
        }),
    }),
    btn: {
        width: '100%',
        maxWidth: '34rem',
    },
}));
