import { EPropPayoutTypes } from './actionsTypes';
import { PropPayoutReducerState } from './interfaces';

const initialState: PropPayoutReducerState = {
    payoutPageLoading: false,
    challengeMonetaryTransactionsDetails: {},
};

const PropPayoutReducer = (state = initialState, { type, payload }: { type: EPropPayoutTypes; payload: any }) => {
    switch (type) {
        case EPropPayoutTypes.GET_PAGE_DATA_START:
            return {
                ...state,
                payoutPageLoading: true,
            };
        case EPropPayoutTypes.GET_PAGE_DATA_SUCCESS:
            return {
                ...state,
                payoutPageLoading: false,
            };
        case EPropPayoutTypes.GET_PAGE_DATA_FAIL:
            return {
                ...state,
                payoutPageLoading: false,
            };

        case EPropPayoutTypes.GET_MONETARY_TRANSACTIONS_HISTORY_START:
            return {
                ...state,
                payoutPageLoading: true,
            };
        case EPropPayoutTypes.GET_MONETARY_TRANSACTIONS_HISTORY_SUCCESS:
            return {
                ...state,
                challengeMonetaryTransactionsDetails: {
                    ...state.challengeMonetaryTransactionsDetails,
                    [payload.challengeId]: {
                        monetaryTransactions: payload.monetaryTransactions,
                        paidAmount: payload.paidAmount,
                        pendingAmount: payload.pendingAmount,
                    },
                },
                payoutPageLoading: false,
            };
        case EPropPayoutTypes.GET_MONETARY_TRANSACTIONS_HISTORY_FAIL:
            return { ...state, payoutPageLoading: false };

        default:
            return state;
    }
};

export default PropPayoutReducer;
