import React from 'react';
import Typography from 'components/Typography';
import useStyles from '../fundedInvestingManualKycDialog.styles';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';

interface StepFundedProps {
    handleNextStep: () => void;
    loading: boolean;
}

const FundedStep: React.FunctionComponent<StepFundedProps> = ({ handleNextStep, loading }) => {
    const classes = useStyles();
    const { t } = useTranslation('prop');

    return (
        <>
            <Typography className={classes.header} variant="tLgSemibold">
                {t('investingManualKyc.NotStarted.header')}
            </Typography>
            <Typography className={classes.id} variant="tSmallMedium">
                {t('investingManualKyc.NotStarted.challengeId')}
            </Typography>
            <Typography className={classes.subTxt2} variant="tSmallRegular">
                {t('investingManualKyc.NotStarted.subTxt')}
            </Typography>
            <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={handleNextStep}
                loading={loading}
            >
                {t(`investingManualKyc.NotStarted.btn`)}
            </Button>
        </>
    );
};

export default FundedStep;
