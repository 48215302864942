import { ESystemTypes } from 'store/system/actionsTypes';
import { EPaymentTypes } from './actionsTypes';
import { PaymentReducerState } from './interfaces';

const initialState: PaymentReducerState = {
    depositIframeLoading: false,
    depositIframe: null,
    depositIframeError: undefined,

    depositAmount: undefined,
    depositPhone: undefined,
    depositType: undefined,

    depositResult: undefined,

    withdrawLoading: false,
    withdrawResult: undefined,

    cancelWithdraw: {},

    cryptoWalletsLoading: false,
    cryptoWallets: undefined,

    depositTabs: {},
    depositTabsLoading: false,

    transferBetweenAccountsLoading: false,
    transferBetweenAccountsResult: undefined,

    referenceId: '',
    referenceIdLoading: false,
    challengeUpdated: false,

    createPendingPaymentLoading: false,
    createPendingPaymentResult: undefined,

    freeTrialLoader: false,
    fullDiscountPlanLoader: false,
};

const PaymentReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EPaymentTypes.SET_DEPOSIT_TYPE: {
            return {
                ...state,
                depositType: payload,
                depositAmount: undefined,
                depositPhone: undefined,
                depositIframe: null,
            };
        }
        case EPaymentTypes.SET_DEPOSIT_AMOUNT: {
            return {
                ...state,
                depositAmount: payload.amount,
                depositPhone: payload?.phone || undefined,
            };
        }
        case EPaymentTypes.RESET_DEPOSIT_IFRAME_ERROR: {
            return {
                ...state,
                depositIframeError: undefined,
            };
        }
        case EPaymentTypes.GET_DEPOSIT_IFRAME: {
            return {
                ...state,
                depositIframeLoading: true,
                depositIframe: null,
                depositIframeError: undefined,
            };
        }
        case EPaymentTypes.GET_DEPOSIT_IFRAME_SUCCESS: {
            return {
                ...state,
                depositIframeLoading: false,
                depositIframe: payload,
            };
        }
        case EPaymentTypes.GET_DEPOSIT_IFRAME_FAILED: {
            return {
                ...state,
                depositIframeLoading: false,
                depositIframe: null,
                depositIframeError: payload,
            };
        }
        case EPaymentTypes.DEPOSIT_DONE: {
            return {
                ...state,
                depositResult: payload,
            };
        }
        case EPaymentTypes.RESET_DEPOSIT_RESULT: {
            return {
                ...state,
                depositResult: undefined,
                depositIframe: null,
            };
        }
        case EPaymentTypes.WITHDRAW_START: {
            return {
                ...state,
                withdrawLoading: true,
                withdrawResult: undefined,
            };
        }
        case EPaymentTypes.WITHDRAW_SUCCESS: {
            return {
                ...state,
                withdrawLoading: false,
                withdrawResult: 'success',
            };
        }
        case EPaymentTypes.WITHDRAW_FAILED: {
            return {
                ...state,
                withdrawLoading: false,
                withdrawResult: 'failed',
            };
        }
        case EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_START: {
            return {
                ...state,
                transferBetweenAccountsLoading: true,
                transferBetweenAccountsResult: undefined,
            };
        }
        case EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                transferBetweenAccountsLoading: false,
                transferBetweenAccountsResult: 'success',
            };
        }
        case EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_FAILED: {
            return {
                ...state,
                transferBetweenAccountsLoading: false,
                transferBetweenAccountsResult: 'failed',
            };
        }
        case EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_RESET: {
            return {
                ...state,
                transferBetweenAccountsLoading: false,
                transferBetweenAccountsResult: undefined,
            };
        }
        case EPaymentTypes.RESET_WITHDRAW_RESULT: {
            return {
                ...state,
                withdrawResult: undefined,
            };
        }
        case EPaymentTypes.WITHDRAW_CANCEL_START: {
            return {
                ...state,
                cancelWithdraw: {
                    ...state.cancelWithdraw,
                    [payload]: true,
                },
            };
        }
        case EPaymentTypes.WITHDRAW_CANCEL_SUCCESS:
        case EPaymentTypes.WITHDRAW_CANCEL_FAILED: {
            return {
                ...state,
                cancelWithdraw: {
                    ...state.cancelWithdraw,
                    [payload]: false,
                },
            };
        }
        case EPaymentTypes.GET_CRYPTO_WALLETS_START: {
            return {
                ...state,
                cryptoWalletsLoading: true,
            };
        }
        case EPaymentTypes.GET_CRYPTO_WALLETS_FAILED: {
            return {
                ...state,
                cryptoWalletsLoading: false,
            };
        }
        case EPaymentTypes.GET_CRYPTO_WALLETS_SUCCESS: {
            return {
                ...state,
                cryptoWalletsLoading: false,
                cryptoWallets: payload,
            };
        }
        case EPaymentTypes.GET_PAYMENT_TYPES_START: {
            return {
                ...state,
                depositTabsLoading: true,
            };
        }
        case EPaymentTypes.GET_PAYMENT_TYPES_FAILED: {
            return {
                ...state,
                depositTabsLoading: false,
            };
        }
        case EPaymentTypes.GET_PAYMENT_TYPES_SUCCESS: {
            return {
                ...state,
                depositTabsLoading: false,
                depositTabs: payload,
            };
        }

        case EPaymentTypes.GET_REFERENCE_NUMBER_START: {
            return {
                ...state,
                referenceIdLoading: true,
            };
        }
        case EPaymentTypes.GET_REFERENCE_NUMBER_FAILED: {
            return {
                ...state,
                referenceIdLoading: false,
            };
        }
        case EPaymentTypes.GET_REFERENCE_NUMBER_SUCCESS: {
            return {
                ...state,
                referenceIdLoading: false,
                referenceId: payload,
            };
        }

        case EPaymentTypes.CHALLENGE_UPDATED: {
            return {
                ...state,
                challengeUpdated: true,
            };
        }
        case EPaymentTypes.RESET_CHALLENGE_UPDATED: {
            return {
                ...state,
                challengeUpdated: false,
            };
        }

        case EPaymentTypes.CREATE_PENDING_PAYMENT_START: {
            return {
                ...state,
                createPendingPaymentLoading: true,
            };
        }

        case EPaymentTypes.CREATE_PENDING_PAYMENT_SUCCESS: {
            return {
                ...state,
                createPendingPaymentLoading: false,
                createPendingPaymentResult: payload,
            };
        }

        case EPaymentTypes.CREATE_PENDING_PAYMENT_FAILED: {
            return {
                ...state,
                createPendingPaymentLoading: false,
                createPendingPaymentResult: 'failed',
            };
        }
        case EPaymentTypes.CREATE_FREE_TRIAL_PLAN_START:
            return {
                ...state,
                freeTrialLoader: true,
            };
        case EPaymentTypes.CREATE_FREE_TRIAL_PLAN_FAILED:
            return {
                ...state,
                depositResult: payload,
                freeTrialLoader: false,
            };
        case EPaymentTypes.CREATE_FREE_TRIAL_PLAN_SUCCESS:
            return {
                ...state,
                depositResult: payload,
                freeTrialLoader: false,
            };
        case EPaymentTypes.CREATE_FULL_DISCOUNT_PLAN_START:
            return {
                ...state,
                fullDiscountPlanLoader: true,
            };
        case EPaymentTypes.CREATE_FULL_DISCOUNT_PLAN_SUCCESS:
            return {
                ...state,
                fullDiscountPlanLoader: false,
            };
        case EPaymentTypes.CREATE_FULL_DISCOUNT_PLAN_FAILED:
            return {
                ...state,
                fullDiscountPlanLoader: false,
            };
        case EPaymentTypes.RESET_STATE:
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                depositIframeLoading: false,
                depositIframe: null,
                depositAmount: undefined,
                depositPhone: undefined,
                depositType: undefined,
                withdrawResult: undefined,
                withdrawLoading: false,
                referenceId: '',
            };
        }

        default:
            return state;
    }
};

export default PaymentReducer;
