import { createStyles } from '@material-ui/core';
import { typographyVariantObj } from 'components/Typography/typography.styles';
import palette from 'muiTheme/config/palette';

export default createStyles({
    root: {
        marginTop: '2.4rem',
        '&$error': {
            color: palette().error.main,
        },
        '& > svg': {
            // opacity: 0.42,
            flexShrink: 0,
        },
        '&:hover': {
            '& > svg': {
                opacity: 1,
            },
        },
        '&$focused': {
            '& > svg': {
                opacity: 1,
            },
        },
    },
    input: typographyVariantObj.tMdRegular,
    inputAdornedStart: {
        paddingInlineStart: '1.0rem',
    },
});
