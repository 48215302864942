/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateKycFragmentsRequest } from '../models';
// @ts-ignore
import { CreateKycFragmentsResponse } from '../models';
// @ts-ignore
import { GetAccountKycDataResponse } from '../models';
// @ts-ignore
import { UpdateCompleteKycRequest } from '../models';
// @ts-ignore
import { UpdateCompleteKycResponse } from '../models';
// @ts-ignore
import { UpdateKycAcceptRiskRequest } from '../models';
// @ts-ignore
import { UpdateKycAcceptRiskResponse } from '../models';
// @ts-ignore
import { UploadKycSignatureRequest } from '../models';
/**
 * KycApi - axios parameter creator
 * @export
 */
export const KycApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateKycFragmentsRequest} createKycFragmentsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApiControllerCreateKycFragments: async (
            createKycFragmentsRequest: CreateKycFragmentsRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createKycFragmentsRequest' is not null or undefined
            assertParamExists(
                'kycApiControllerCreateKycFragments',
                'createKycFragmentsRequest',
                createKycFragmentsRequest
            );
            const localVarPath = `/api/kyc/createKycFragment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createKycFragmentsRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApiControllerGetAccountKycData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kyc/getAccountKycData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateCompleteKycRequest} updateCompleteKycRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApiControllerUpdateCompleteKyc: async (
            updateCompleteKycRequest: UpdateCompleteKycRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateCompleteKycRequest' is not null or undefined
            assertParamExists(
                'kycApiControllerUpdateCompleteKyc',
                'updateCompleteKycRequest',
                updateCompleteKycRequest
            );
            const localVarPath = `/api/kyc/updateCompleteKyc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateCompleteKycRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateKycAcceptRiskRequest} updateKycAcceptRiskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApiControllerUpdateKycAcceptRisk: async (
            updateKycAcceptRiskRequest: UpdateKycAcceptRiskRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateKycAcceptRiskRequest' is not null or undefined
            assertParamExists(
                'kycApiControllerUpdateKycAcceptRisk',
                'updateKycAcceptRiskRequest',
                updateKycAcceptRiskRequest
            );
            const localVarPath = `/api/kyc/updateKycAcceptRisk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateKycAcceptRiskRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UploadKycSignatureRequest} uploadKycSignatureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApiControllerUploadKycSignature: async (
            uploadKycSignatureRequest: UploadKycSignatureRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'uploadKycSignatureRequest' is not null or undefined
            assertParamExists(
                'kycApiControllerUploadKycSignature',
                'uploadKycSignatureRequest',
                uploadKycSignatureRequest
            );
            const localVarPath = `/api/kyc/uploadKycSignature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                uploadKycSignatureRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * KycApi - functional programming interface
 * @export
 */
export const KycApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = KycApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CreateKycFragmentsRequest} createKycFragmentsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kycApiControllerCreateKycFragments(
            createKycFragmentsRequest: CreateKycFragmentsRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateKycFragmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kycApiControllerCreateKycFragments(
                createKycFragmentsRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kycApiControllerGetAccountKycData(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountKycDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kycApiControllerGetAccountKycData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateCompleteKycRequest} updateCompleteKycRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kycApiControllerUpdateCompleteKyc(
            updateCompleteKycRequest: UpdateCompleteKycRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCompleteKycResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kycApiControllerUpdateCompleteKyc(
                updateCompleteKycRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateKycAcceptRiskRequest} updateKycAcceptRiskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kycApiControllerUpdateKycAcceptRisk(
            updateKycAcceptRiskRequest: UpdateKycAcceptRiskRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateKycAcceptRiskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kycApiControllerUpdateKycAcceptRisk(
                updateKycAcceptRiskRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UploadKycSignatureRequest} uploadKycSignatureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kycApiControllerUploadKycSignature(
            uploadKycSignatureRequest: UploadKycSignatureRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kycApiControllerUploadKycSignature(
                uploadKycSignatureRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * KycApi - factory interface
 * @export
 */
export const KycApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KycApiFp(configuration);
    return {
        /**
         *
         * @param {CreateKycFragmentsRequest} createKycFragmentsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApiControllerCreateKycFragments(
            createKycFragmentsRequest: CreateKycFragmentsRequest,
            options?: any
        ): AxiosPromise<CreateKycFragmentsResponse> {
            return localVarFp
                .kycApiControllerCreateKycFragments(createKycFragmentsRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApiControllerGetAccountKycData(options?: any): AxiosPromise<GetAccountKycDataResponse> {
            return localVarFp.kycApiControllerGetAccountKycData(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateCompleteKycRequest} updateCompleteKycRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApiControllerUpdateCompleteKyc(
            updateCompleteKycRequest: UpdateCompleteKycRequest,
            options?: any
        ): AxiosPromise<UpdateCompleteKycResponse> {
            return localVarFp
                .kycApiControllerUpdateCompleteKyc(updateCompleteKycRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateKycAcceptRiskRequest} updateKycAcceptRiskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApiControllerUpdateKycAcceptRisk(
            updateKycAcceptRiskRequest: UpdateKycAcceptRiskRequest,
            options?: any
        ): AxiosPromise<UpdateKycAcceptRiskResponse> {
            return localVarFp
                .kycApiControllerUpdateKycAcceptRisk(updateKycAcceptRiskRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UploadKycSignatureRequest} uploadKycSignatureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycApiControllerUploadKycSignature(
            uploadKycSignatureRequest: UploadKycSignatureRequest,
            options?: any
        ): AxiosPromise<boolean> {
            return localVarFp
                .kycApiControllerUploadKycSignature(uploadKycSignatureRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * KycApi - object-oriented interface
 * @export
 * @class KycApi
 * @extends {BaseAPI}
 */
export class KycApi extends BaseAPI {
    /**
     *
     * @param {CreateKycFragmentsRequest} createKycFragmentsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public kycApiControllerCreateKycFragments(createKycFragmentsRequest: CreateKycFragmentsRequest, options?: any) {
        return KycApiFp(this.configuration)
            .kycApiControllerCreateKycFragments(createKycFragmentsRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public kycApiControllerGetAccountKycData(options?: any) {
        return KycApiFp(this.configuration)
            .kycApiControllerGetAccountKycData(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateCompleteKycRequest} updateCompleteKycRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public kycApiControllerUpdateCompleteKyc(updateCompleteKycRequest: UpdateCompleteKycRequest, options?: any) {
        return KycApiFp(this.configuration)
            .kycApiControllerUpdateCompleteKyc(updateCompleteKycRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateKycAcceptRiskRequest} updateKycAcceptRiskRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public kycApiControllerUpdateKycAcceptRisk(updateKycAcceptRiskRequest: UpdateKycAcceptRiskRequest, options?: any) {
        return KycApiFp(this.configuration)
            .kycApiControllerUpdateKycAcceptRisk(updateKycAcceptRiskRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UploadKycSignatureRequest} uploadKycSignatureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public kycApiControllerUploadKycSignature(uploadKycSignatureRequest: UploadKycSignatureRequest, options?: any) {
        return KycApiFp(this.configuration)
            .kycApiControllerUploadKycSignature(uploadKycSignatureRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
