import { IconsType } from 'components/Icon';

export interface FileUploadPayload {
    fileName: string;
    fileBase64: string;
}

export enum FileUploadStatus {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed',
}

export interface FileUpload {
    loading: boolean;
    status: FileUploadStatus;
}

export interface UploadDReducerState {
    uploadProcessLoader: boolean;
    filesUploading: {
        [file: string]: FileUpload;
    };
}

export interface UploadDButtonProps {
    possibleLinks: Array<string>;
    link: string;
    buttonType: string;
    icon: IconsType;
    stepsPercent?: number;
}
