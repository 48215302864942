import { makeStyles } from '@material-ui/core';
import { customGap, isRtl } from 'helpers/device_helper';

interface Props {
    fullWidth?: boolean;
    disableMarginTop?: boolean;
    slim?: boolean;
    disabled?: boolean;
    removeArrow?: boolean;
    disableBorder?: boolean;
    disablePadding?: boolean;
    isRtlReverseDirection?: boolean;
}

export default makeStyles((theme) => ({
    placeholder: {
        opacity: 0.42,
    },
    formControlRoot: (props: Props) => ({
        width: props.fullWidth ? '100%' : 'auto',
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.background.default,
            minHeight: props.slim ? '2.4rem' : '4.4rem',
            marginTop: props.disableMarginTop ? 'unset' : '2.4rem',
            ...(props.disabled && {
                backgroundColor: theme.palette.default.main,
            }),
            '& fieldset': {
                borderColor: theme.palette.grayVariants[300],
                ...(props.disableBorder && {
                    border: 'unset',
                }),
            },
            ...(isRtl() && {
                '& svg.MuiSvgIcon-root': {
                    right: 'auto',
                    left: '7px',
                },
                '& .MuiSelect-select.MuiSelect-select': {
                    paddingRight: '1.25rem',
                    ...(isRtl() &&
                        props?.isRtlReverseDirection && {
                            direction: 'ltr',
                            textAlign: 'right',
                            paddingRight: '1.25rem',
                            paddingLeft: '2.25rem',
                        }),
                },
            }),
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.action.disabledBackground,
        },
        '& .MuiSelect-icon': {
            stroke: theme.palette.grayVariants[500],
        },
    }),
    menuPaper: {
        marginTop: `${theme.spacing(2)}rem`,
        borderRadius: '0.8rem',
        maxHeight: '21.2rem',
        backgroundColor: theme.palette.background.default,
    },
    menuList: {
        padding: `${theme.spacing(2)}rem`,
        ...customGap(theme.spacing(1), 'vertical'),
        '& .MuiMenuItem-root': {
            borderRadius: '0.8rem',
            padding: '0.8rem 0.6rem',
            fontWeight: theme.palette.featuresFlags['prop'] ? '500' : 'bold',
            justifyContent: 'space-between',
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
            },
        },
        '& .Mui-selected': {
            backgroundColor: theme.palette.featuresFlags['prop']
                ? theme.palette.grayVariants[50]
                : theme.palette.primary.main,
            color: theme.palette.featuresFlags['prop']
                ? theme.palette.grayVariants[900]
                : theme.palette.primary.contrastText,
        },
    },
    select: (props: Props) => ({
        height: 'auto',
        padding: props.slim ? '0.438rem 1.2rem' : '1.1rem 1.2rem',
        fontSize: props.slim ? '1.3rem' : '1.6rem',
        ...(props.removeArrow && {
            padding: props.slim ? '0.4rem 1.2rem !important' : '1.1rem 1.2rem !important',
        }),
        ...(props.disablePadding && {
            padding: '0 !important',
        }),
        display: 'block',
        alignItems: 'center',
    }),
    selectDisabled: {
        // backgroundColor: theme.palette.default.main,
    },
    tooltip: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    selectItemIconLabelWrapper: {
        display: 'flex',
        alignItems: 'center',
        ...customGap(theme.spacing(2), 'horizontal'),
        '& svg': {
            color: theme.palette.grayVariants[500],
        },
    },
    selectItemIcon: {
        width: '2.4rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectIcon: {
        right: '9px',
        ...isRtl({
            right: 'auto',
            left: '9px',
        }),
    },
    selectedIcon: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.8rem',
        '& svg': {
            color: theme.palette.primary.main,
        },
    },
}));
