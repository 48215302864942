import { connect } from 'react-redux';
import React, { forwardRef, useCallback } from 'react';
import {
    MyWalletPreConditionProps,
    mapDispatchToProps,
    mapStateToProps,
    preDepositFormInputsScheme,
    preDepositFormValidationsScheme,
} from './my-wallet_pre-condition.scheme';
import useStyles from './my-wallet_pre-condition.styles';
import { useTranslation } from 'react-i18next';
import FormGenerator from 'components/FormGenerator';
import { AccountInfoParsed } from 'services/generatedClientFromSwagger';
import config from 'config/common';
import { FormGeneratorHandle } from 'components/FormGenerator/form-generator.render';
import Typography from 'components/Typography';

const MyWalletPreCondition = forwardRef<FormGeneratorHandle, MyWalletPreConditionProps>((props, ref) => {
    const {
        userFullAddress,
        userAddressObject,
        userDateOfBirth,
        countriesList,
        countriesListDialCodes,
        phoneNumber,
        ipData,
        user_UpdateUserInfo,
        disabled,
        dataLoader,
        authLoader,
        callToPaymentIframe,
    } = props;

    const classes = useStyles();
    const { t, i18n } = useTranslation(['myWallet', 'prop']);
    const { language } = i18n;

    const handleOnSubmit = useCallback(
        async (values: any) => {
            const { phoneNumber: newPhoneNumber, phoneCountryCode, custom_field_dateOfBirth, location } = values;
            const { country, country_short, city, address, addressNumber, postalCode, state } = location;

            const newObject: AccountInfoParsed = {};

            if (config.preDepositFlags['phoneNumber'] && !phoneNumber) {
                newObject.phoneNumber = newPhoneNumber;
                newObject.phoneCountryCode = phoneCountryCode;
            }

            if (config.preDepositFlags['dateOfBirth']) {
                newObject.custom_field_dateOfBirth = custom_field_dateOfBirth;
            }

            if (config.preDepositFlags['address']) {
                newObject.country = country;
                newObject.city = city;
                newObject.address1 = `${address} ${addressNumber || ''}`.trim();
                newObject.zipCode = postalCode;
                newObject.state = state;
                newObject.country_short = country_short;
            }

            await user_UpdateUserInfo(newObject);
            if (typeof callToPaymentIframe === 'function') {
                callToPaymentIframe();
            }
        },
        [callToPaymentIframe, phoneNumber, user_UpdateUserInfo]
    );

    return (
        <div className={classes.wrapper}>
            <Typography variant={'tLgSemibold'} className={classes.challengesTitle} color="primary">
                {t('prop:checkout.customer_details')}
            </Typography>
            {!authLoader && !dataLoader && (
                <FormGenerator
                    disabled={disabled}
                    ref={ref}
                    onSubmitClick={handleOnSubmit}
                    cancelBtnProps={{ disabled: true }}
                    validationsScheme={preDepositFormValidationsScheme(t, phoneNumber)}
                    inputs={preDepositFormInputsScheme({
                        date: userDateOfBirth,
                        location: userFullAddress,
                        userAddressObject,
                        countriesList,
                        countriesListDialCodes,
                        dataLoader,
                        phoneNumber: phoneNumber,
                        lang: language,
                        ipData,
                        t,
                    })}
                />
            )}
        </div>
    );
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    React.memo(MyWalletPreCondition)
);
