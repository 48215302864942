import React from 'react';
import Typography from 'components/Typography';
import Checkbox from 'components/Inputs/Checkbox';
import { useTranslation } from 'react-i18next';
import useStyles from '../fundedInvestingManualKycDialog.styles';
import { AccountInfoParsedKycVerificationStatusEnum } from 'services/generatedClientFromSwagger';
import Button from 'components/Button';
import config from 'config/common';

interface StepYouAreVerifiedProps {
    isCheckboxChecked: boolean;
    handleCheckboxChange: () => void;
    handleNextStep: (optional?: AccountInfoParsedKycVerificationStatusEnum) => void;
    disabled: boolean;
}

const YouAreVerifiedStep: React.FunctionComponent<StepYouAreVerifiedProps> = ({
    isCheckboxChecked,
    handleNextStep,
    handleCheckboxChange,
}) => {
    const classes = useStyles();
    const { t } = useTranslation('prop');

    return (
        <>
            <Typography className={classes.header} variant="tLgSemibold">
                {t('investingManualKyc.youAreVerified.header')}
            </Typography>
            <Typography className={classes.subTxtFundedInvestingManualKycDialog} variant="tSmallRegular">
                {t('investingManualKyc.youAreVerified.subTxt')}
            </Typography>
            <Checkbox
                checked={isCheckboxChecked}
                onChange={handleCheckboxChange}
                disableSidesMargin
                labelPlacement={'end'}
                label={
                    <>
                        {t('prop:investingManualKyc.youAreVerified.checkbox')}
                        <a
                            className={classes.trader_agreement_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={config.traderAgreementLink}
                        >
                            {t('prop:investingManualKyc.youAreVerified.checkbox1')}
                        </a>
                    </>
                }
                className={classes.checkbox}
            />
            <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={handleNextStep.bind(null, AccountInfoParsedKycVerificationStatusEnum.Approved)}
                disabled={!isCheckboxChecked}
            >
                {t(`investingManualKyc.youAreVerified.btn`)}
            </Button>
        </>
    );
};

export default YouAreVerifiedStep;
