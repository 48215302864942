import { TradingPlatformAccountInfo } from 'services/generatedClientFromSwagger/models';
import { user_changeSelectedTP } from 'store/user/actions';
import { user_demoTPAccounts, user_fullName, user_realTPAccounts, user_selectedTpAccount } from 'store/user/selectors';
import { PickListInfo } from 'services/generatedClientFromSwagger/models/pick-list-info';

export interface TpSelectProps {
    selectedTp: TradingPlatformAccountInfo | undefined;
    realAccounts: Array<TradingPlatformAccountInfo>;
    demoAccounts: Array<TradingPlatformAccountInfo>;
    user_changeSelectedTP: (tp: TradingPlatformAccountInfo) => void;
    accountType?: PickListInfo | null;
    fullName: string;
}

export const mapStateToProps = (state) => ({
    selectedTp: user_selectedTpAccount(state),
    realAccounts: user_realTPAccounts(state),
    demoAccounts: user_demoTPAccounts(state),
    fullName: user_fullName(state),
});

export const mapDispatchToProps = {
    user_changeSelectedTP,
};
