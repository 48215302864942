import { makeStyles } from '@material-ui/core';
import { customGap, isTablet } from 'helpers/device_helper';

interface Props {
    dashboardView?: boolean;
}

export default makeStyles((theme) => ({
    wrapper: (props: Props) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: `${theme.spacing(6)}rem`,
        ...(props.dashboardView && {
            ...isTablet({
                gridTemplateColumns: '1fr 1fr',
                '& > div:first-child': {
                    gridRow: '1 / 4',
                },
            }),
        }),
    }),
    linksWrapper: {
        ...customGap(theme.spacing(2), 'vertical'),
    },
    sideImageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sideImage: {
        width: '100%',
        maxWidth: '42rem',
        maxHeight: '42rem',
    },
}));
