import React from 'react';
import { connect } from 'react-redux';
import { HeaderProps, mapDispatchToProps, mapStateToProps } from './header.scheme';
import PrivateHeader from './PrivateHeader';
import PublicHeader from './PublicHeader';
import useDisableHeaderOnMobile from 'features/prop/hooks/useDisableHeaderOnMobile';

const Header: React.FunctionComponent<HeaderProps> = (props) => {
    const { hasJwtToken, user_verified } = props;
    const disableHeaderOnMobile = useDisableHeaderOnMobile();

    if (hasJwtToken && user_verified) {
        if (disableHeaderOnMobile) {
            return null;
        }
        return <PrivateHeader />;
    }
    return <PublicHeader />;
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Header));
