import React from 'react';
import { ThemeSwitchProps, mapDispatchToProps, mapStateToProps } from './theme_switch.scheme';
import { connect } from 'react-redux';
import useStyles from './theme_switch.styles';
import DarkMode from 'assets/icons/dark_mode.svg?react';
import LightMode from 'assets/icons/light_mode.svg?react';
import { ThemeOptions } from 'interfaces/main';

const ThemeSwitch: React.FunctionComponent<ThemeSwitchProps> = (props) => {
    const { theme, toggleTheme } = props;
    const classes = useStyles({ theme });
    return (
        <button className={classes.theme_switch_wrapper} onClick={toggleTheme}>
            <div className={classes.theme_switch_circle}>
                {theme === ThemeOptions.DARK ? <DarkMode /> : <LightMode />}
            </div>
        </button>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ThemeSwitch));
