import { createStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default createStyles({
    root: {
        padding: '0.8rem 2.8rem',
        textTransform: 'none',
        borderRadius: '0.8rem',
        fontSize: '1.6rem',
        fontWeight: 600,
        '&$disabled': {
            color: '#unknown',
            opacity: '0.5',
        },
    },
    sizeSmall: {
        borderRadius: '0.8rem',
        fontSize: '1.3rem',
        padding: '0.2rem 1.2rem',
    },
    contained: {
        '&$disabled': {
            color: '#unknown',
            backgroundColor: '#unknown',
        },
    },
    outlined: {
        '&$disabled': {
            border: '#unknown',
        },
    },
    text: {
        padding: 0,
        minWidth: 'unset',
    },
    startIcon: {
        marginRight: '0.8rem',
        marginLeft: '-0.4rem',
        ...isRtl({
            marginRight: '-0.4rem',
            marginLeft: '0.8rem',
        }),
    },
    endIcon: {
        marginLeft: '0.8rem',
        marginRight: '-0.4rem',
        ...isRtl({
            marginLeft: '-0.4rem',
            marginRight: '0.8rem',
        }),
    },
});
