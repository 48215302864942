import { AccountInfoParsed, Notification } from 'services/generatedClientFromSwagger';
import { user_getUserPushNotificationPermission } from 'store/user/actions';
import { pushNotificationPermissionStatusSelector, user_userInfo } from 'store/user/selectors';
import { notification_getAllNotifications, notification_markNotifications } from '../../store/actions';
import { notification_loading, notification_notifications } from '../../store/selector';

export interface MainNotificationBtnProps {
    notification_loading: boolean;
    notification_notifications: Array<Notification>;
    notification_getAllNotifications: () => void;
    user_userInfo: AccountInfoParsed;
    user_getUserPushNotificationPermission: () => void;
    pushNotificationPermissionStatus: 'default' | 'denied' | 'granted';
    notification_markNotifications: (notifications: Array<string>) => void;
}

export const mapStateToProps = (state) => ({
    notification_notifications: notification_notifications(state),
    notification_loading: notification_loading(state),
    user_userInfo: user_userInfo(state),
    pushNotificationPermissionStatus: pushNotificationPermissionStatusSelector(state),
});

export const mapDispatchToProps = {
    notification_getAllNotifications,
    user_getUserPushNotificationPermission,
    notification_markNotifications,
};
