import { Pages } from 'interfaces/main';
import { connect } from 'react-redux';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { user_loggedIn } from 'store/user/selectors';
import config, { FeatureFlag } from 'config/common';

export const mapStateToProps = (state) => ({
    loggedIn: user_loggedIn(state),
});

interface ToHomePageProps {
    loggedIn: boolean;
}

const ToHomePage: React.FunctionComponent<ToHomePageProps> = (props) => {
    const { loggedIn } = props;
    // if feature flag prop then redirect to prop
    if (!loggedIn) {
        return <Redirect to={`/${Pages.LOGIN}`} />;
    }

    if (config.featuresFlags[FeatureFlag.PROP]) {
        return <Redirect to={`/${Pages.PROP}`} />;
    }

    return <Redirect to={`/${Pages.PLATFORMS}/home`} />;
};

export default connect(mapStateToProps)(React.memo(ToHomePage));
