import React, { useMemo } from 'react';
import { DatePickerProps } from './date-picker.scheme';
import { KeyboardDatePicker as DatePickerMat } from '@material-ui/pickers';
import useStyles from './date-picker.styles';
import { FormControl, InputLabel } from '@material-ui/core';
import Icon, { IconsType } from 'components/Icon';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { isMobileDevice, isRtl } from 'helpers/device_helper';
import moment from 'moment';

const DatePicker: React.FunctionComponent<DatePickerProps> = (props) => {
    const {
        id,
        label,
        format = 'DD.MM.YYYY',
        placeholder,
        defaultValue,
        error,
        helperText,
        disableFuture,
        openTo,
        views,
        initialFocusedDate,
        minDate,
        maxDate,
        disabled = false,
        onChange,
    } = props;
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = React.useState<ParsableDate>(defaultValue || null);

    const handleOnChange = (date: ParsableDate) => {
        setSelectedDate(date);
        onChange && onChange(date);
    };

    const dateValue: ParsableDate = useMemo(() => {
        const date = moment(selectedDate);
        const validMin = minDate ? date.isAfter(minDate) : true;
        const validMax = maxDate ? date.isBefore(maxDate) : true;
        if (!date.isValid() || !validMin || !validMax) {
            return null;
        }
        return selectedDate;
    }, [maxDate, minDate, selectedDate]);

    return (
        <FormControl classes={{ root: classes.formControlRoot }} variant={'outlined'} error={error} disabled={disabled}>
            {label && (
                <InputLabel shrink error={false} htmlFor={`${id}-date-picker` || 'date-picker-input-label'}>
                    {label}
                </InputLabel>
            )}
            <DatePickerMat
                id={id}
                disableFuture={disableFuture}
                openTo={openTo}
                initialFocusedDate={initialFocusedDate}
                views={views}
                name={id}
                error={error}
                orientation="portrait"
                helperText={helperText}
                SelectProps={{
                    MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: isRtl() ? 'right' : 'left',
                        },
                    },
                }}
                minDate={minDate}
                maxDate={maxDate}
                placeholder={placeholder}
                InputProps={{ inputProps: { inputMode: 'numeric' }, endAdornment: <Icon name={IconsType.date} /> }}
                autoOk
                PopoverProps={{ classes: { paper: classes.paper } }}
                format={format}
                variant={isMobileDevice() ? 'dialog' : 'inline'}
                inputVariant="outlined"
                value={dateValue}
                onChange={handleOnChange}
                disabled={disabled}
            />
        </FormControl>
    );
};

export default DatePicker;
