import { createSelector } from 'reselect';
import { RootState } from 'store';

import { Pages } from 'interfaces/main';
import { IconsType } from 'components/Icon';
import { SumsubButtonProps } from './interface';
import { kyc_amlKycFailed, kyc_completed } from 'features/kyc/store/selector';
import config from 'config/common';

export const uploadDocumentsActive = config.featuresFlags['uploadDocuments'];
export const sumsubActive = config.featuresFlags['sumsub'];

const rootReducerSelector = (state: RootState) => state;
const userReducerSelector = (state: RootState) => state.user;
const uploadDReducerSelector = (state: RootState) => state.sumsub;

export const sumsub_loadingState = createSelector(uploadDReducerSelector, (uploadD) => uploadD.sumsubLoading);

export const sumsub_subsubData = createSelector(uploadDReducerSelector, (uploadD) => uploadD.sumsubData);

export const sumsub_completed = createSelector(
    userReducerSelector,
    (user) => !sumsubActive || Boolean(user.userInfo.suppliedNecessaryDocuments)
);

export const sumsub_isVisible = createSelector(rootReducerSelector, (root) => {
    if (kyc_amlKycFailed(root)) {
        return false;
    }

    if (kyc_completed(root) && !root.user.userInfo.suppliedNecessaryDocuments && !root.sumsub.requestApproved)
        return true;

    return false;
});

export const sumsub_status = createSelector(rootReducerSelector, (root): SumsubButtonProps | undefined => {
    const sumsubIsCompleted = sumsub_completed(root);
    const isVisible = sumsub_isVisible(root);
    if (!sumsubActive || !isVisible || sumsubIsCompleted) return undefined;
    return {
        possibleLinks: [`/${Pages.SUMSUB_UPLOAD}`],
        link: `/${Pages.SUMSUB_UPLOAD}`,
        buttonType: 'kyc_upload_documents',
        icon: IconsType.upload_documents,
    };
});

export const upload_documents_active = createSelector(userReducerSelector, (user) => {
    if ((!sumsubActive && !uploadDocumentsActive) || Boolean(user.userInfo.suppliedNecessaryDocuments)) {
        return Pages.PLATFORMS;
    }
    if (sumsubActive) {
        return Pages.SUMSUB_UPLOAD;
    }

    if (uploadDocumentsActive) {
        return Pages.UPLOAD_DOCUMENTS;
    }
});
