import { TradingSuggestion } from 'services/generatedClientFromSwagger';
import { analytics_getTradingSuggestions } from 'store/analytics/actions';
import { analytics_tradingSuggetions, analytics_tradingSuggetionsLoading } from 'store/analytics/selectors';

export interface TradingSuggestionsProps {
    analytics_tradingSuggetions: Array<TradingSuggestion>;
    analytics_tradingSuggetionsLoading: boolean;
    analytics_getTradingSuggestions: (language: string) => void;
}

export const mapStateToProps = (state) => ({
    analytics_tradingSuggetions: analytics_tradingSuggetions(state),
    analytics_tradingSuggetionsLoading: analytics_tradingSuggetionsLoading(state),
});

export const mapDispatchToProps = {
    analytics_getTradingSuggestions,
};
