import { Button, makeStyles, withStyles } from '@material-ui/core';
import { fadeColor } from 'helpers/fadeColor';

export default withStyles((theme) => ({
    contained: {
        backgroundColor: theme.palette.default.main,
        color: theme.palette.default.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.default.light,
        },
        '&:active': {
            backgroundColor: theme.palette.default.dark,
        },
    },
    outlined: {
        borderColor: theme.palette.default.main,
        '&:hover': {
            borderColor: theme.palette.default.light,
        },
        '&:active': {
            borderColor: theme.palette.default.dark,
        },
    },
    containedPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primaryVariants[700],
        },
        '&:active': {
            backgroundColor: theme.palette.primaryVariants[700],
        },
    },
    outlinedPrimary: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primaryVariants[700],
            borderColor: theme.palette.primaryVariants[700],
        },
        '&:active': {
            color: theme.palette.primaryVariants[700],
            borderColor: theme.palette.primaryVariants[700],
        },
    },
    containedSecondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
        '&:active': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    outlinedSecondary: {
        borderColor: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.light,
        },
        '&:active': {
            borderColor: theme.palette.secondary.dark,
        },
    },
}))(Button);

interface Props {
    horizontalDuration: number;
    verticalDuration: number;
    status?: 'success' | 'failed' | 'redirect';
    specialButtons?: 'buy' | 'sell' | 'square';
    variant?: 'text' | 'outlined' | 'contained';
}

export const useStyles = makeStyles((theme) => ({
    customContained: (props: Props) => ({
        ...(props.specialButtons === 'buy' && {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.success.light,
            },
            '&:active': {
                backgroundColor: theme.palette.success.dark,
            },
        }),
        ...(props.specialButtons === 'sell' && {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.error.light,
            },
            '&:active': {
                backgroundColor: theme.palette.error.dark,
            },
        }),
        ...(props.specialButtons === 'square' && {
            padding: '0.4rem',
            minWidth: 'unset',
        }),
    }),
    customOutlined: (props: Props) => ({
        ...(props.specialButtons === 'buy' && {
            border: 'unset',
            backgroundColor: fadeColor(theme.palette.success.main, 10),
            color: theme.palette.success.main,
            '&:hover': {
                backgroundColor: fadeColor(theme.palette.success.main, 20),
            },
            '&:active': {
                backgroundColor: fadeColor(theme.palette.success.main, 30),
            },
        }),
        ...(props.specialButtons === 'sell' && {
            border: 'unset',
            backgroundColor: fadeColor(theme.palette.error.main, 10),
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fadeColor(theme.palette.error.main, 20),
            },
            '&:active': {
                backgroundColor: fadeColor(theme.palette.error.main, 30),
            },
        }),
    }),
    btn_success: {
        '&&': {
            backgroundColor: `${theme.palette.success.main} !important`,
            color: theme.palette.success.contrastText,
            opacity: 1,
        },
    },
    btn_error: {
        '&&': {
            backgroundColor: `${theme.palette.error.main} !important`,
            color: theme.palette.error.contrastText,
            opacity: 1,
        },
    },
    btn_redirect: {
        '&&': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: theme.palette.primary.contrastText,
            opacity: 1,
        },
    },
    outerLoaderWrapper: {
        zIndex: -100,
        height: 'calc(100% + 0.6rem)',
        width: 'calc(100% + 0.6rem)',
        position: 'absolute',
        top: '-0.3rem',
        left: '-0.3rem',
        borderRadius: '1rem',
        overflow: 'hidden',
    },
    outerLoaderSide: ({ status }: Props) => ({
        position: 'absolute',
        background:
            status === 'success'
                ? theme.palette.success.dark
                : status === 'failed'
                ? theme.palette.error.dark
                : theme.palette.primary.dark,
        animationFillMode: 'forwards',
        animationTimingFunction: 'linear',
    }),
    outerLoaderSide__top: ({ horizontalDuration }: Props) => ({
        top: 0,
        left: 0,
        height: '0.6rem',
        animationName: `top-animation`,
        animationDelay: `0s`,
        animationDuration: `${horizontalDuration}s`,
    }),
    outerLoaderSide__right: ({ verticalDuration, horizontalDuration }: Props) => ({
        top: 0,
        right: 0,
        width: '0.6rem',
        animationName: `right-animation`,
        animationDuration: `${verticalDuration}s`,
        animationDelay: `${horizontalDuration}s`,
    }),
    outerLoaderSide__bottom: ({ verticalDuration, horizontalDuration }: Props) => ({
        bottom: 0,
        right: 0,
        height: '0.6rem',
        animationName: `bottom-animation`,
        animationDuration: `${horizontalDuration}s`,
        animationDelay: `${horizontalDuration + verticalDuration}s`,
    }),
    outerLoaderSide__left: ({ verticalDuration, horizontalDuration }: Props) => ({
        bottom: 0,
        left: 0,
        width: '0.6rem',
        animationName: `left-animation`,
        animationDuration: `${verticalDuration}s`,
        animationDelay: `${horizontalDuration * 2 + verticalDuration}s`,
    }),
    '@global': {
        '@keyframes top-animation': {
            '0%': { right: '100%' },
            '100%': { right: '0%' },
        },
        '@keyframes right-animation': {
            '0%': { bottom: '100%' },
            '100%': { bottom: '0%' },
        },
        '@keyframes bottom-animation': {
            '0%': { left: '100%' },
            '100%': { left: '0%' },
        },
        '@keyframes left-animation': {
            '0%': { top: '100%' },
            '100%': { top: '0%' },
        },
    },
}));
