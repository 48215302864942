import { makeStyles } from '@material-ui/core';
import { isMobile, isRtl } from 'helpers/device_helper';
import { newShadows } from 'muiTheme/config/palette';

export default makeStyles((theme) => {
    return {
        select_wrapper: {
            backgroundColor:
                theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.background.default,
        },
        chip: {
            borderRadius: 16,
            border: `1px solid ${theme.palette.grayVariants[200]}`,
            background: theme.palette.background.paper,
            display: 'flex',
            alignItems: 'center',
            padding: '4px 8px',
        },
        chip_mobile: { marginLeft: 'auto' },
        mainSelectFrame: {
            display: 'flex',
            padding: '0',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '8px',
            '& .MuiInputBase-root': {
                border:
                    theme.palette.type === 'dark'
                        ? `solid 1px ${theme.palette.grayVariants[300]}`
                        : `solid 1px ${theme.palette.grayVariants[200]}`,
                borderRadius: '8px',
                marginTop: '0',
                boxShadow: newShadows.xs,
                '&:before': {
                    borderBottom: theme.palette.grayVariants[200],
                },
                '&:after': {
                    borderBottom: theme.palette.grayVariants[200],
                },
                '&:hover': {},
            },
            '& .MuiInputBase-input': {},
            '& .MuiSelect-select.MuiSelect-select': {
                padding: '8px 12px',
                paddingInlineEnd: '40px',
                ...isRtl({
                    paddingInlineEnd: '40px',
                    paddingInlineStart: '12px',
                }),
                ...isMobile({
                    padding: '8px 40px 8px 12px',
                }),
            },
        },
        menuItemContent_platform: {
            width: '100% !important',
        },
        menuItemContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            ...isMobile({
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'center',
            }),
        },
        statusActive: {
            color: theme.palette.primary.main,
        },
        statusEnded: {
            color: theme.palette.error.main,
        },
        statusPending: {
            color: theme.palette.warning.main,
        },
        statusPassed: {
            color: theme.palette.success.main,
        },
        shrinkItemContainer: {
            minWidth: 190,
            textAlign: 'left',
        },
        tpNameSection: {
            ...isRtl({
                alignItems: 'flex-start',
            }),
        },
        menuItem_tpNameSection: {
            gap: 24,
            width: '100%',
            minWidth: 190,
            textAlign: 'left',
        },

        menuIdSelect: {
            color: theme.palette.grayVariants[900],
            display: 'flex',
            width: '100%',
        },
        id: {
            fontWeight: 500,
            paddingRight: '2px',
            ...isRtl({
                paddingRight: '0px',
                paddingLeft: '2px',
            }),
        },
        tpNameWeight: {
            fontWeight: 500,
        },
        idDrawer: {
            color: theme.palette.type === 'dark' ? theme.palette.grayVariants[500] : theme.palette.grayVariants[900],
            paddingRight: '2px',
            ...isRtl({
                paddingRight: '0px',
                paddingLeft: '2px',
            }),
        },
        tpName: {
            color: theme.palette.type === 'dark' ? theme.palette.grayVariants[500] : theme.palette.grayVariants[900],
        },
        idSelect: {
            color: theme.palette.grayVariants[600],
            display: 'flex',
        },
        status: {
            marginLeft: '8px',
            ...isRtl({
                marginRight: '8px',
                marginLeft: '0px',
            }),
            ...isMobile({
                marginLeft: '4px',
            }),
        },
        idStatusContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        wrapperNameStatus: {
            display: 'flex',
            alignItems: 'center',
        },
        link_statistics: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'underline',
            color: `${theme.palette.blueDarkVariants[600]} !important`,
        },
        link: {
            color: theme.palette.blueDarkVariants[600],
        },
        packageName: {
            color: theme.palette.grayVariants[700],
            textTransform: 'lowercase',
            '&::first-letter': {
                textTransform: 'uppercase',
            },
            ...isMobile({
                fontSize: '1.6rem',
                fontWeight: 500,
                lineHeight: '2.4rem',
            }),
        },
        packageNameShrink: {
            textTransform: 'lowercase',
            width: '100%',
            '&::first-letter': {
                textTransform: 'uppercase',
            },
            ...isRtl({
                width: 'unset',
            }),
        },
        icon: {
            position: 'absolute',
            marginLeft: '16px',
            right: '0',
            marginRight: '16px',
            marginTop: '16px',
            color: theme.palette.grayVariants[500],
            ...isRtl({
                left: '0',
                right: 'unset',
            }),
        },
        iconCheck: {
            marginLeft: 'auto',
            ...isRtl({
                marginRight: 'auto',
                marginLeft: 'unset',
            }),
        },
        chevronDownIcon: {
            display: 'flex',
            right: '1.2rem',
            transition: '100ms linear all',
            color: theme.palette.type === 'dark' ? theme.palette.grayVariants[500] : theme.palette.grayVariants[500],
            ...isRtl({
                display: 'flex',
                right: 'unset',
                left: '1.2rem',
                transition: '100ms linear all',
            }),
        },
        chevronRotate: {
            display: 'flex',
            right: '1.2rem',
            transform: 'rotate(180deg)',
            transition: '100ms linear all',
            ...isRtl({
                display: 'flex',
                right: 'unset',
                left: '1.2rem',
            }),
        },
        chevronDownIcon2: {
            transition: '100ms linear all',
            ...isRtl({
                transition: '100ms linear all',
            }),
        },
        chevronRotate2: {
            transform: 'rotate(180deg)',
            transition: '100ms linear all',
            ...isRtl({}),
        },

        menuItemContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: '8px',
            ...isRtl({}),
        },
        menuItemSelect: {
            padding: '10px 10px 10px 8px',
            marginRight: '6px',
            marginLeft: '6px',
            borderRadius: '8px',
            marginTop: '2px',
            gap: '8px',

            '&:hover': {
                backgroundColor:
                    theme.palette.type === 'dark' ? theme.palette.grayVariants[50] : theme.palette.grayVariants[50],
                border:
                    theme.palette.type === 'dark'
                        ? `solid 1px ${theme.palette.grayVariants[300]}`
                        : `solid 1px ${theme.palette.grayVariants[50]}`,
                marginRight: '6px',
                marginLeft: '6px',
                borderRadius: '6px',
            },
            '&.Mui-selected': {
                backgroundColor:
                    theme.palette.type === 'dark' ? theme.palette.grayVariants[50] : theme.palette.grayVariants[50],
                border:
                    theme.palette.type === 'dark'
                        ? `solid 1px ${theme.palette.grayVariants[300]}`
                        : `solid 1px ${theme.palette.grayVariants[50]}`,
                marginRight: '6px',
                marginLeft: '6px',
                borderRadius: '6px',

                '&:hover': {
                    backgroundColor:
                        theme.palette.type === 'dark' ? theme.palette.grayVariants[50] : theme.palette.grayVariants[50],
                    border:
                        theme.palette.type === 'dark'
                            ? `solid 1px ${theme.palette.grayVariants[300]}`
                            : `solid 1px ${theme.palette.grayVariants[50]}`,
                    marginRight: '6px',
                    marginLeft: '6px',
                    borderRadius: '6px',
                },
            },
            ...isMobile({
                marginTop: '8px',
            }),
        },

        menuSelectPaperRoot: {
            maxHeight: '200px',
            marginTop: '5px',
            backgroundColor:
                theme.palette.type === 'dark' ? theme.palette.grayVariants[100] : theme.palette.background.default,
        },
        drawerContent: {
            padding: '24px 8px 24px 24px',
            borderRadius: '32px 32px 0px 0px',
            backgroundColor:
                theme.palette.type === 'dark' ? theme.palette.grayVariants[100] : theme.palette.background.default,
            ...isRtl({
                padding: '24px 24px 24px 8px',
            }),
        },
        drawerPaper: {
            height: 'auto',
            width: '100%',
            bottom: '0',
            position: 'fixed',
            borderTopLeftRadius: '32px',
            borderTopRightRadius: '32px',
        },
        mobileBtn: {
            display: 'flex',
            direction: 'ltr',
            border: `0.1rem solid ${theme.palette.grayVariants[200]}`,
            padding: '8px 12px',
            borderRadius: '8px',
            boxShadow: newShadows.xs,
            width: '100%',
            ...isRtl({
                direction: 'rtl',
            }),
        },
        select_drawer: {
            color: theme.palette.grayVariants[700],
        },
        drawerContentWrapper: {
            display: 'flex',
            marginBottom: '18px',
        },
        drawerListContainer: {
            maxHeight: '212px',
            overflowY: 'auto',
            marginTop: '20px',
        },
        skeleton: {
            borderRadius: '1rem',
        },
        vIconPlaceholder: {
            width: '20px',
        },
    };
});
