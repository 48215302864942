import React from 'react';
import Typography from 'components/Typography';
import useStyles from '../fundedInvestingManualKycDialog.styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

interface VerificationInProgressStepProps {
    onClose: () => void;
}

const VerificationInProgressStep: React.FunctionComponent<VerificationInProgressStepProps> = ({ onClose }) => {
    const classes = useStyles();

    const { t } = useTranslation('prop');

    const handleOnClose = React.useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <>
            <Typography className={classes.header} variant="tLgSemibold">
                {t('investingManualKyc.verificationInProgress.header')}
            </Typography>
            <Typography className={classes.subTxt} variant="tSmallRegular">
                {t('investingManualKyc.verificationInProgress.subTxt')}
            </Typography>
            <Button className={classes.btn} variant="contained" color="primary" onClick={handleOnClose}>
                {t(`investingManualKyc.verificationInProgress.btn`)}
            </Button>
        </>
    );
};

export default VerificationInProgressStep;
