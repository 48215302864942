import { createStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default createStyles({
    root: {
        fontSize: '1.4rem',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '2.0rem',
    },
    shrink: {
        '&&&': {
            transform: 'translate(0, 0.15rem)',
        },
    },
    formControl: {
        left: 0,
        right: 'unset',
        ...isRtl({
            left: 'unset',
            right: 0,
        }),
    },
});
