import { makeStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    paper: {
        borderRadius: '0.8rem',
        marginTop: '0.8rem',
        padding: '0.8rem',
    },
    formControlRoot: {
        '& .MuiOutlinedInput-root': {
            height: '44px',
            backgroundColor: theme.palette.background.default,
            ...isRtl({
                paddingLeft: '0',
            }),
        },
    },
}));
