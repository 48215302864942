import React from 'react';
import Typography from 'components/Typography';
import useStyles from '../fundedInvestingManualKycDialog.styles';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { AccountInfoParsedKycVerificationStatusEnum } from 'services/generatedClientFromSwagger';
import IconBox from 'components/IconBox/iconBox.render';
import { IconsType } from 'components/Icon';

interface DocumentResubmissionProps {
    handleNextStep: (status: AccountInfoParsedKycVerificationStatusEnum) => void;
    renderChildren: () => React.ReactNode;
}

const DocumentResubmissionStep: React.FunctionComponent<DocumentResubmissionProps> = ({
    renderChildren,
    handleNextStep,
}) => {
    const classes = useStyles();
    const { t } = useTranslation('prop');
    const [toggle, setToggle] = React.useState(false);

    return (
        <>
            {!toggle && (
                <>
                    <div className={classes.iconsContainer}>
                        <IconBox variant="border" color="gray" size={48} iconType={IconsType.shield_plus} />
                    </div>
                    <Typography className={classes.header} variant="tLgSemibold">
                        {t('investingManualKyc.documentResubmission.header')}
                    </Typography>
                    <Typography className={classes.subTxt} variant="tSmallRegular">
                        {t('investingManualKyc.documentResubmission.subTxt')}
                    </Typography>
                    <Button
                        className={classes.btn}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setToggle(true);
                            handleNextStep.bind(null, AccountInfoParsedKycVerificationStatusEnum.NotStarted);
                        }}
                    >
                        {t(`investingManualKyc.documentResubmission.btn`)}
                    </Button>
                </>
            )}
            {toggle && renderChildren()}
        </>
    );
};

export default DocumentResubmissionStep;
