import { makeStyles, Theme } from '@material-ui/core';
import { customGap, isRtl, isLaptop, isTablet, isDesktop } from 'helpers/device_helper';

interface Props {
    accountTypeActive: boolean;
}

export default makeStyles<Theme, Props>((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'flex-end',
        ...isTablet({
            flexGrow: 'unset',
            justifyContent: 'initial',
        }),
        paddingRight: '10px',
        ...isRtl({
            paddingLeft: '10px',
        }),
        position: 'relative',
    },
    detailsWrapper: (props) => ({
        height: '4.2rem',
        border: `0.1rem solid ${theme.palette.action.disabledBackground}`,
        borderRadius: '0.8rem',
        display: 'flex',
        ...isTablet({
            width: props.accountTypeActive ? 'auto' : '32rem',
            height: '4.8rem',
        }),
        ...isLaptop({
            width: props.accountTypeActive ? 'auto' : '40rem',
        }),
        ...isDesktop({
            width: props.accountTypeActive ? '40rem' : '47rem',
        }),
        minWidth: '109px',
        width: 'fit-content',
    }),
    detailsItem: {
        flex: 1,
        width: '33.33333%',
        padding: '0 0.8rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: theme.palette.text.default,
        '&:not(:first-child)': {
            borderLeft: `0.1rem solid ${theme.palette.action.disabledBackground}`,
            borderRight: 'unset',
            ...isRtl({
                borderRight: `0.1rem solid ${theme.palette.action.disabledBackground}`,
                borderLeft: 'unset',
            }),
        },
        ...isTablet({
            padding: '0 0.6rem 0 1.6rem',
            ...isRtl({
                padding: '0 1.6rem 0 0.6rem',
            }),
        }),
    },
    detailsItemTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    coloredDot: {
        display: 'inline-block',
        minHeight: '0.8rem',
        minWidth: '0.8rem',
        borderRadius: '50%',
        background: theme.palette.primary.main,
        marginRight: `${theme.spacing(1)}rem`,
        marginLeft: 'unset',
        ...isRtl({
            marginLeft: `${theme.spacing(1)}rem`,
            marginRight: 'unset',
        }),
    },
    coloredDot__secondary: {
        background: theme.palette.secondary.main,
    },
    iconBtn: {
        height: '2.5rem',
        width: '2.5rem',
        borderRadius: '0.8rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& > svg': {
            transition: 'all 200ms linear',
            width: '15px',
        },
        position: 'relative',
        right: '25px',
        ...isRtl({ left: '25px', right: 'unset' }),
    },
    iconBtn__active: {
        '& > svg': {
            transform: 'rotate(-180deg)',
        },
    },
    toolTipPopper: {
        pointerEvents: 'initial',
    },
    toolTipPaper: {
        borderRadius: '0.8rem',
        padding: 'unset',
        backgroundColor: theme.palette.background.paper,
        boxShadow:
            '0 0.5rem 0.5rem -0.3rem rgba(0,0,0,0.2), 0 0.8rem 1.0rem 0.1rem rgba(0,0,0,0.14), 0 0.3rem 1.4rem 0.2rem rgba(0,0,0,0.12)',
        display: 'flex',
        flexDirection: 'column',
        margin: '2.4rem 0',
    },
    toolTipArrow: {
        color: theme.palette.background.paper,
        height: '1rem',
        width: '1.5rem',
    },
    toolTipTop: {
        '&& .MuiTooltip-arrow': {
            marginTop: '-1rem',
        },
    },
    btn: {
        color: theme.palette.side_menu.text,
        padding: '0.8rem',
        width: '100%',
        alignItems: 'center',
        lineHeight: 1,
        justifyContent: 'flex-start',
        '&:hover,&:focus': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },

    seperator: {
        height: '0.1rem',
        width: '100%',
        background: theme.palette.action.disabledBackground,
    },
    titleWrapper: {
        color: theme.palette.text.default,
        padding: `${theme.spacing(6)}rem ${theme.spacing(5)}rem ${theme.spacing(4)}rem`,
    },
    rowsWrapper: {
        padding: `${theme.spacing(2)}rem`,
        ...customGap(theme.spacing(1), 'vertical'),
        maxHeight: '28.0rem',
        overflow: 'auto',
    },
    row: {
        cursor: 'pointer',
        padding: `${theme.spacing(3)}rem ${theme.spacing(12)}rem ${theme.spacing(3)}rem ${theme.spacing(3)}rem`,
        ...isRtl({
            padding: `${theme.spacing(3)}rem ${theme.spacing(3)}rem ${theme.spacing(3)}rem ${theme.spacing(12)}rem`,
        }),
        display: 'flex',
        borderRadius: '0.8rem',
        ...customGap(theme.spacing(3), 'horizontal'),
        '& > div > p:first-child': {
            color: theme.palette.text.default,
        },
        '& > div > p:nth-child(2)': {
            color: theme.palette.grayVariants[500],
        },
        '&:hover': {
            background: theme.palette.primary.main,
            '& > div > p:first-child': {
                color: theme.palette.primary.contrastText,
            },
            '& > div > p:nth-child(2)': {
                color: theme.palette.primary.contrastText,
            },
        },
    },
    row__active: {
        background: theme.palette.primary.main,
        '& > div > p:first-child': {
            color: theme.palette.primary.contrastText,
        },
        '& > div > p:nth-child(2)': {
            color: theme.palette.primary.contrastText,
        },
    },
    rowContect: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    boldText: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    lightText: {
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '16px',
        color: theme.palette.grayVariants[700],
        ...isRtl({ paddingLeft: '16px', paddingRight: 'unset' }),
    },
    divider: {
        position: 'relative',
        height: '3rem',
        alignSelf: 'center',
        right: '6px',
        ...isRtl({
            right: '-6px',
        }),
    },
    accountTypeWrapper: {
        display: 'flex',
        paddingLeft: '1.2rem',
        paddingRight: '1.2rem',
        ...isRtl({
            paddingLeft: '1.2rem',
            paddingRight: '1.2rem',
        }),
    },
    accountTypeIconWrapper: (props: any) => ({
        borderRadius: '0.8rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '4.4rem',
        minWidth: '4.2rem',
        background: theme.palette.default.main,
        color: theme.palette.default.contrastText,
        ...(props.iconBackground === 'primary' && {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }),
        ...(props.iconBackground === 'secondary' && {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        }),
    }),
    accountTypeTitle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: '1.2rem',
        marginRight: 'unset',
        ...isRtl({
            marginLeft: 'unset',
            marginRight: '1.2rem',
        }),
    },
}));
