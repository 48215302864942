export enum EPropPayoutTypes {
    GET_PAGE_DATA_START = '[prop-payout] get payout page data start',
    GET_PAGE_DATA_SUCCESS = '[prop-payout] get payout page data success',
    GET_PAGE_DATA_FAIL = '[prop-payout] get payout page data fail',

    REQUEST_PAYOUT_START = '[prop-payout] request payout start',
    REQUEST_PAYOUT_SUCCESS = '[prop-payout] request payout success',
    REQUEST_PAYOUT_FAIL = '[prop-payout] request payout fail',

    GET_MONETARY_TRANSACTIONS_HISTORY_START = '[prop-payout] get monetary transactions history start',
    GET_MONETARY_TRANSACTIONS_HISTORY_SUCCESS = '[prop-payout] get monetary transactions history success',
    GET_MONETARY_TRANSACTIONS_HISTORY_FAIL = '[prop-payout] get monetary transactions history fail',
}
