import { createStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default createStyles({
    root: {
        marginLeft: '-1.1rem',
        marginRight: '1.6rem',
        ...isRtl({
            marginLeft: '1.6rem',
            marginRight: '-1.1rem',
        }),
    },
});
