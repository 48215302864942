import { makeStyles } from '@material-ui/core';
import { newShadows } from 'muiTheme/config/palette';

interface Props {
    reverseColors?: boolean;
    display?: 'flex' | 'inline';
    tooltipPosition?: { top?: number; left?: number };
}

export default makeStyles((theme) => ({
    root: ({ display }: Props) => ({
        display: display || 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    }),
    tooltip: ({ reverseColors, tooltipPosition }: Props) => ({
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
        padding: '0.8rem 1.2rem',
        backgroundColor: theme.palette.tooltip.background,
        color: theme.palette.tooltip.text,
        boxShadow: newShadows.lg,
        ...(reverseColors && {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.grayVariants[700],
            boxShadow: theme.shadows['8'],
        }),
        [theme.breakpoints.up('md')]: {
            maxWidth: 500,
        },
        top: `${tooltipPosition?.top}px`,
        left: `${tooltipPosition?.left}px`,
    }),
    arrow: ({ reverseColors }: Props) => ({
        color: theme.palette.tooltip.background,
        ...(reverseColors && {
            color: theme.palette.background.default,
        }),
    }),
}));
