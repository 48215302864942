import React from 'react';
import { TpSelectProps, mapDispatchToProps, mapStateToProps } from './tp-select.scheme';
import { ClickAwayListener, Divider, Hidden, Tooltip } from '@material-ui/core';
import Icon, { IconsType } from 'components/Icon';
import { connect } from 'react-redux';
import classNames from 'classnames';
import useStyles from './tp-select.styles';
import { useTranslation } from 'react-i18next';
import Typography from 'components/Typography';
import { TradingPlatformAccountInfo } from 'services/generatedClientFromSwagger/models';
import config from 'config/common';
import ToolTip from 'components/ToolTip';
import { getImages } from 'helpers/getAssets';
const images = getImages();

const TpSelect: React.FunctionComponent<TpSelectProps> = (props) => {
    const { selectedTp, realAccounts, demoAccounts, user_changeSelectedTP, accountType, fullName } = props;
    const [menuState, setMenuState] = React.useState<boolean>(false);
    const { t } = useTranslation();

    const accountTypeData = React.useMemo<{ label: string; iconSrc: string } | undefined>(() => {
        if (!Boolean(config.featuresFlags['accountType']) || !accountType) return undefined;
        let iconSrc;
        try {
            iconSrc = images[`account_type_${accountType?.value}`];
        } catch (error) {
            iconSrc = images['account_type_1.png'];
        }
        return {
            label: accountType?.name || 'Basic',
            iconSrc,
        };
    }, [accountType]);

    const classes = useStyles({ accountTypeActive: Boolean(accountTypeData) });

    const toggleMenuState = () => setMenuState(!menuState);

    const onClickAway = () => {
        if (!menuState) return;
        setMenuState(false);
    };

    const handleOnRowClick = (tp: TradingPlatformAccountInfo) => {
        user_changeSelectedTP(tp);
    };

    if (!selectedTp) {
        return null;
    }

    return (
        <div className={classes.wrapper}>
            <Hidden xsDown>
                <div className={classes.detailsWrapper}>
                    <div className={classes.detailsItem}>
                        <div className={classes.detailsItemTitle}>
                            <div className={classes.coloredDot} />
                            <Typography
                                variant="body2"
                                display="inline"
                                classes={{ root: classes.lightText }}
                                color="textSecondary"
                            >
                                {t('tp_menu.details.accountId')}
                            </Typography>
                        </div>
                        <Typography variant="body1" classes={{ root: classes.boldText }}>
                            {selectedTp?.name}
                        </Typography>
                    </div>
                    <div className={classes.detailsItem}>
                        <div className={classes.detailsItemTitle}>
                            <div className={`${classes.coloredDot} ${classes.coloredDot__secondary}`} />
                            <Typography
                                variant="body2"
                                display="inline"
                                classes={{ root: classes.lightText }}
                                color="textSecondary"
                            >
                                {t('tp_menu.details.currency')}
                            </Typography>
                        </div>
                        <Typography variant="body1" classes={{ root: classes.boldText }}>
                            {selectedTp?.baseCurrency?.code}
                        </Typography>
                    </div>
                    <div className={classes.detailsItem}>
                        <div className={classes.detailsItemTitle}>
                            <div className={classes.coloredDot} />
                            <Typography
                                variant="body2"
                                display="inline"
                                classes={{ root: classes.lightText }}
                                color="textSecondary"
                            >
                                {t('tp_menu.details.type')}
                            </Typography>
                        </div>
                        <Typography variant="body1" classes={{ root: classes.boldText }}>
                            {selectedTp?.tradingPlatform?.type}
                        </Typography>
                    </div>
                </div>
            </Hidden>
            <Hidden smUp>
                <div className={classes.detailsWrapper}>
                    <div className={classes.detailsItem}>
                        <Typography variant="tXsMedium" classes={{ root: classes.lightText }}>
                            {`${selectedTp?.baseCurrency?.code} / ${selectedTp?.tradingPlatform?.type}`}
                        </Typography>
                    </div>
                </div>
            </Hidden>

            <ClickAwayListener onClickAway={onClickAway}>
                <Tooltip
                    arrow
                    open={menuState}
                    placement="bottom-end"
                    classes={{
                        tooltip: classes.toolTipPaper,
                        arrow: classes.toolTipArrow,
                        popper: classes.toolTipPopper,
                        tooltipPlacementBottom: classes.toolTipTop,
                    }}
                    title={
                        <React.Fragment>
                            <div className={classes.titleWrapper}>
                                <Typography variant="body1" classes={{ root: classes.boldText }}>
                                    {t('tp_menu.paper.title')}
                                </Typography>
                                {config.featuresFlags['showTpMenuAccountName'] && (
                                    <Typography
                                        variant="body2"
                                        display="inline"
                                        classes={{ root: classes.lightText }}
                                        color="textSecondary"
                                    >
                                        {`${fullName}`}
                                    </Typography>
                                )}
                            </div>
                            <div className={classes.seperator} />
                            <div className={classes.rowsWrapper}>
                                {[...realAccounts, ...demoAccounts].map((account, index) => (
                                    <div
                                        id={`account_type_${account.tradingPlatform?.type}`}
                                        key={account.name}
                                        className={classNames({
                                            [classes.row]: true,
                                            [classes.row__active]: account.id === selectedTp?.id,
                                        })}
                                        onClick={() => handleOnRowClick(account)}
                                    >
                                        <div className={classes.rowContect}>
                                            <Typography variant="body1" classes={{ root: classes.boldText }}>
                                                {t('tp_menu.paper.accountId', { id: account.name })}
                                            </Typography>
                                            <Typography variant="body2" classes={{ root: classes.lightText }}>
                                                {t('tp_menu.paper.accountInfo', {
                                                    currency: account?.baseCurrency?.code,
                                                    type: account?.tradingPlatform?.type,
                                                })}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    }
                >
                    <div
                        className={classNames({ [classes.iconBtn]: true, [classes.iconBtn__active]: menuState })}
                        onClick={toggleMenuState}
                        id={'tpSelect_button'}
                    >
                        <Icon name={IconsType.chevron_down} />
                    </div>
                </Tooltip>
            </ClickAwayListener>

            {accountTypeData && (
                <>
                    <Divider orientation="vertical" flexItem className={classes.divider} />
                    <div className={classes.accountTypeWrapper}>
                        <ToolTip
                            title={`${t('account-type.membership')}: ${t(`account-type.${accountTypeData.label}`, {
                                defaultValue: accountTypeData.label,
                            })}`}
                        >
                            <div className={classes.accountTypeIconWrapper}>
                                <img src={accountTypeData.iconSrc} alt="account-type" />
                            </div>
                        </ToolTip>
                        <Hidden smDown>
                            <div className={classes.accountTypeTitle}>
                                <Typography variant="body2" color="textSecondary">
                                    {t('account-type.membership')}
                                </Typography>
                                <Typography variant="body1" style={{ fontWeight: 700 }}>
                                    {t(`account-type.${accountTypeData.label}`, {
                                        defaultValue: accountTypeData.label,
                                    })}
                                </Typography>
                            </div>
                        </Hidden>
                    </div>
                </>
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TpSelect));
