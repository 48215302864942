import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { isRtl } from 'helpers/device_helper';
import { getInstruments } from 'helpers/getAssets';
import React from 'react';
const instruments = getInstruments();
export interface InstrumentImageProps {
    instrument: string;
    iconSize?: string;
    pair?: boolean;
    quote?: string;
    base?: string;
    disableImageScale?: boolean;
}

const useStyles = makeStyles((theme) => ({
    wrapper: (props: any) => ({
        height: '4rem',
        width: '4rem',
        position: 'relative',
        transform: props.disableImageScale ? 'none' : `scale(${props?.iconSize && props.iconSize})`,
    }),
    signle_image: {
        height: '4rem',
        width: '4rem',
    },
    half_image: {
        height: '2.4rem',
        width: '2.4rem',
    },
    left_image: {
        zIndex: 1,
        position: 'absolute',
        left: 0,
        right: 'unset',
        top: 0,
        ...isRtl({
            left: 'unset',
            right: 0,
        }),
    },
    right_image: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 'unset',
        ...isRtl({
            left: 0,
            right: 'unset',
        }),
    },
}));

const getInstrument = (name: string) => {
    return (
        instruments[name.toLowerCase()] ||
        instruments[name.toUpperCase()] ||
        instruments[name] ||
        instruments['no_icon']
    );
};

const InstrumentImage: React.FunctionComponent<InstrumentImageProps> = (props) => {
    const { instrument, iconSize, pair, quote, base, disableImageScale } = props;
    const classes = useStyles({ iconSize, disableImageScale });
    if (pair) {
        return (
            <div className={classes.wrapper}>
                <div
                    className={classNames(classes.half_image, classes.left_image)}
                    style={{ backgroundImage: `url(${getInstrument(base || '')})` }}
                />
                <div
                    className={classNames(classes.half_image, classes.right_image)}
                    style={{ backgroundImage: `url(${getInstrument(quote || '')})` }}
                />
            </div>
        );
    }
    return (
        <div className={classes.wrapper}>
            <div
                className={classes.signle_image}
                style={{ backgroundImage: `url(${getInstrument(instrument || 'no_icon')})` }}
            />
        </div>
    );
};

export default InstrumentImage;
