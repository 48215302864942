import React from 'react';
import { Typography as TypographyMat } from '@material-ui/core';
import { TypographyProps } from './typography.scheme';
import useStyles from './typography.styles';
import classNames from 'classnames';
import { Variant } from '@material-ui/core/styles/createTypography';

const Typography: React.FunctionComponent<TypographyProps> = (props) => {
    const { variant, className, ...rest } = props;
    const classes = useStyles();

    let currentClasses = className;
    let isCustom = false;
    if (variant) {
        isCustom = Boolean(classes[variant]);
        if (isCustom) {
            currentClasses = classNames(classes[variant], className);
        }
    }
    const muiVariant = variant as Variant;
    return <TypographyMat className={currentClasses} variant={isCustom ? undefined : muiVariant} {...rest} />;
};

export default Typography;
