import config from 'config/common';
import { CryptoAddresses } from 'services/generatedClientFromSwagger';
import { IconsType } from 'components/Icon';

export enum DepositTypes {
    'payotop' = 'payotop',
    'safecharge' = 'safecharge',
    'praxis' = 'praxis',
    'bank-transfer' = 'bank-transfer',
    'links' = 'links',
    'crypto' = 'crypto',
    'realCrypto' = 'realCrypto',
    'bridgerpay' = 'bridgerpay',
    'outsource' = 'outsource',
    'payretailers' = 'payretailers',
    'bpwallet' = 'bpwallet',
    'gr8pay' = 'gr8pay',
    'neobanq' = 'neobanq',
    'maldopay' = 'maldopay',
    'pagsmile' = 'pagsmile',
    'paymentechnologies' = 'paymentechnologies',
    'ngpayment' = 'ngpayment',
    'knoxsecure' = 'knoxsecure',
    'unknown' = 'unknown',
    'quikipay' = 'quikipay',
    'chargemoney' = 'chargemoney',
    'rafinita' = 'rafinita',
    'gcg' = 'gcg',
    'neonpay' = 'neonpay',
    'dikeshield' = 'dikeshield',
    'nonstopay' = 'nonstopay',
    'korapay' = 'korapay',
    'kasha' = 'kasha',
    'pelecard' = 'pelecard',
    'payport' = 'payport',
    'bitolo' = 'bitolo',
    'nowpayments' = 'nowpayments',
    'payneteasy' = 'payneteasy',
    'vepara' = 'vepara',
    'match2pay' = 'match2pay',
    'paytechno' = 'paytechno',
    'payok' = 'payok',
    'paragon' = 'paragon',
    'epsilon' = 'epsilon',
    'payku' = 'payku',
    'toptechpay' = 'toptechpay',
    'cricpayz' = 'cricpayz',
    'fullDiscountPlan' = 'fullDiscountPlan',
}
export interface DepositResult {
    status: boolean;
    transactionId: string;
    amount: number;
    currency: string;
    type?: DepositTypes;
}
export interface PaymentReducerState {
    depositType?: { tabName: string; type: DepositTypes; subType?: string };
    depositAmount: number | undefined;
    depositPhone?: string | undefined;
    depositIframeLoading: boolean;
    depositIframe: string | null;
    depositIframeError: string | undefined;

    depositResult?: DepositResult;

    withdrawLoading: boolean;
    withdrawResult?: 'success' | 'failed';

    cancelWithdraw: {
        [id: string]: boolean;
    };

    cryptoWallets?: CryptoAddresses;
    cryptoWalletsLoading: boolean;

    depositTabs: { [type: string]: DepositTab };
    depositTabsLoading: boolean;

    transferBetweenAccountsLoading: boolean;
    transferBetweenAccountsResult?: 'success' | 'failed';

    referenceId: string;
    referenceIdLoading: boolean;

    challengeUpdated: boolean;

    createPendingPaymentLoading: boolean;
    createPendingPaymentResult?: 'success' | 'failed';

    freeTrialLoader?: boolean;

    fullDiscountPlanLoader?: boolean;
}

export interface DepositTab {
    tabT: string;
    titleT: string;
    btnT?: string;
    tKey: string;
    icon: IconsType;
    isIframe: boolean;
    isActive: boolean;
    order: number;
    iframeUrl?: string;
    link?: string;
    iframeNewTab?: boolean;
    linkTargetBlank?: boolean;
    outsideLink?: boolean;
    links?: Array<{
        label: string;
        link?: string;
        wallet?: string;
        currency?: string;
        text?: string;
    }>;
    phoneField?: boolean;
    overrideName?: string;
    type: DepositTypes;
    subType?: string;
    subTitle?: string;
    hidden?: boolean;
}

export const depositTabsMetaData: { [type: string]: DepositTab } = {
    [DepositTypes.payotop]: {
        type: DepositTypes.payotop,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        subTitle: '',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'credit_card',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        isActive: false,
        order: 0,
    },
    [DepositTypes.safecharge]: {
        type: DepositTypes.safecharge,
        tabT: 'myWallet:deposit.safe_charge.tab_name',
        titleT: 'myWallet:deposit.safe_charge.title',
        subTitle: '',
        btnT: 'myWallet:deposit.safe_charge.make_deposit_btn',
        tKey: 'safe_charge',
        icon: IconsType.electronic_pay,
        isIframe: true,
        isActive: false,
        order: 1,
    },
    [DepositTypes.praxis]: {
        type: DepositTypes.praxis,
        tabT: 'myWallet:deposit.praxis.tab_name',
        titleT: 'myWallet:deposit.praxis.title',
        subTitle: '',
        btnT: 'myWallet:deposit.praxis.make_deposit_btn',
        tKey: 'praxis',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        isActive: false,
        order: 2,
    },
    [DepositTypes.bridgerpay]: {
        type: DepositTypes.bridgerpay,
        tabT: 'myWallet:deposit.bridgerpay.tab_name',
        titleT: 'myWallet:deposit.bridgerpay.title',
        subTitle: '',
        btnT: 'myWallet:deposit.bridgerpay.make_deposit_btn',
        tKey: 'bridgerpay',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        isActive: false,
        order: 3,
    },
    [DepositTypes['bank-transfer']]: {
        type: DepositTypes['bank-transfer'],
        tabT: 'myWallet:deposit.bank_transfer.tab_name',
        titleT: '',
        subTitle: '',
        btnT: 'myWallet:deposit.bank_transfer.make_deposit_btn',
        tKey: 'bank_transfer',
        icon: IconsType.bank_wire_piqk,
        isIframe: false,
        isActive: false,
        order: 4,
    },
    [DepositTypes.links]: {
        type: DepositTypes.links,
        tabT: 'myWallet:deposit.link.tab_name',
        titleT: 'myWallet:deposit.link.title',
        subTitle: '',
        btnT: 'myWallet:deposit.link.make_deposit_btn',
        tKey: 'link',
        icon: IconsType.deposit_links,
        isIframe: false,
        links: config.depositLinks,
        isActive: false,
        order: 5,
    },
    [DepositTypes.crypto]: {
        type: DepositTypes.crypto,
        tabT: 'myWallet:deposit.crypto.tab_name',
        titleT: 'myWallet:deposit.crypto.title',
        btnT: 'myWallet:deposit.crypto.make_deposit_btn',
        subTitle: config?.cryptoSubtitle ? config.cryptoSubtitle : 'myWallet:deposit.crypto.sub_title',
        tKey: 'crypto',
        icon: IconsType.bitcoin_crypto_piqk,
        isIframe: false,
        links: config.depositCrypto,
        isActive: false,
        order: 6,
    },
    [DepositTypes.realCrypto]: {
        type: DepositTypes.realCrypto,
        tabT: 'myWallet:deposit.crypto.tab_name',
        titleT: 'myWallet:deposit.crypto.title',
        subTitle: 'myWallet:deposit.crypto.sub_title',
        btnT: 'myWallet:deposit.crypto.make_deposit_btn',
        tKey: 'crypto',
        icon: IconsType.bitcoin_crypto_piqk,
        isIframe: false,
        isActive: false,
        order: 7,
    },
    [DepositTypes.outsource]: {
        type: DepositTypes.outsource,
        tabT: 'myWallet:deposit.outsource.tab_name',
        titleT: 'myWallet:deposit.outsource.title',
        subTitle: '',
        tKey: 'outsource',
        icon: IconsType.ellipse,
        isIframe: false,
        iframeUrl:
            config.depositOutsource.url && !config.depositOutsource.blank ? config.depositOutsource.url : undefined,
        link: config.depositOutsource.url && config.depositOutsource.blank ? config.depositOutsource.url : undefined,
        linkTargetBlank: !!config.depositOutsource.blank,
        outsideLink: true,
        isActive: false,
        order: 99,
        hidden: !Boolean(config?.depositOutsource.url),
    },
    [DepositTypes.payretailers]: {
        type: DepositTypes.payretailers,
        tabT: 'myWallet:deposit.payretailers.tab_name',
        titleT: 'myWallet:deposit.payretailers.title',
        subTitle: '',
        btnT: 'myWallet:deposit.payretailers.make_deposit_btn',
        tKey: 'payretailers',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        iframeNewTab: true,
        isActive: false,
        order: 9,
    },
    [DepositTypes.bpwallet]: {
        type: DepositTypes.bpwallet,
        tabT: 'myWallet:deposit.bpwallet.tab_name',
        titleT: 'myWallet:deposit.bpwallet.title',
        subTitle: '',
        btnT: 'myWallet:deposit.bpwallet.make_deposit_btn',
        tKey: 'bpwallet',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        iframeNewTab: true,
        isActive: false,
        order: 10,
    },
    [DepositTypes.gr8pay]: {
        type: DepositTypes.gr8pay,
        tabT: 'myWallet:deposit.gr8pay.tab_name',
        titleT: 'myWallet:deposit.gr8pay.title',
        subTitle: '',

        btnT: 'myWallet:deposit.gr8pay.make_deposit_btn',
        tKey: 'gr8pay',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        isActive: false,
        order: 11,
    },
    [DepositTypes.neobanq]: {
        type: DepositTypes.neobanq,
        tabT: 'myWallet:deposit.neobanq.tab_name',
        titleT: 'myWallet:deposit.neobanq.title',
        subTitle: '',

        btnT: 'myWallet:deposit.neobanq.make_deposit_btn',
        tKey: 'neobanq',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        isActive: false,
        order: 12,
    },
    [DepositTypes.maldopay]: {
        type: DepositTypes.maldopay,
        tabT: 'myWallet:deposit.maldopay.tab_name',
        titleT: 'myWallet:deposit.maldopay.title',
        subTitle: '',

        btnT: 'myWallet:deposit.maldopay.make_deposit_btn',
        tKey: 'maldopay',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        isActive: false,
        order: 13,
    },
    [DepositTypes.pagsmile]: {
        type: DepositTypes.pagsmile,
        tabT: 'myWallet:deposit.pagsmile.tab_name',
        titleT: 'myWallet:deposit.pagsmile.title',
        subTitle: '',
        btnT: 'myWallet:deposit.pagsmile.make_deposit_btn',
        tKey: 'pagsmile',
        icon: IconsType.electronic_pay,
        isIframe: true,
        isActive: false,
        order: 14,
    },
    [DepositTypes.paymentechnologies]: {
        type: DepositTypes.paymentechnologies,
        tabT: 'myWallet:deposit.paymentechnologies.tab_name',
        titleT: 'myWallet:deposit.paymentechnologies.title',
        subTitle: '',
        btnT: 'myWallet:deposit.paymentechnologies.make_deposit_btn',
        tKey: 'paymentechnologies',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        iframeNewTab: true,
        isActive: false,
        order: 15,
    },
    [DepositTypes.ngpayment]: {
        type: DepositTypes.ngpayment,
        tabT: 'myWallet:deposit.ngpayment.tab_name',
        titleT: 'myWallet:deposit.ngpayment.title',
        subTitle: '',

        btnT: 'myWallet:deposit.ngpayment.make_deposit_btn',
        tKey: 'ngpayment',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        iframeNewTab: true,
        isActive: false,
        order: 16,
    },
    [DepositTypes.knoxsecure]: {
        type: DepositTypes.knoxsecure,
        tabT: 'myWallet:deposit.knoxsecure.tab_name',
        titleT: 'myWallet:deposit.knoxsecure.title',
        subTitle: '',

        btnT: 'myWallet:deposit.knoxsecure.make_deposit_btn',
        tKey: 'knoxsecure',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        isActive: false,
        order: 17,
    },
    [DepositTypes.quikipay]: {
        type: DepositTypes.quikipay,
        tabT: 'myWallet:deposit.quikipay.tab_name',
        titleT: 'myWallet:deposit.quikipay.title',
        subTitle: '',
        btnT: 'myWallet:deposit.quikipay.make_deposit_btn',
        tKey: 'quikipay',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        isActive: false,
        order: 18,
    },
    [DepositTypes.rafinita]: {
        type: DepositTypes.rafinita,
        tabT: 'myWallet:deposit.rafinita.tab_name',
        titleT: 'myWallet:deposit.rafinita.title',
        subTitle: '',

        btnT: 'myWallet:deposit.rafinita.make_deposit_btn',
        tKey: 'rafinita',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        isActive: false,
        order: 19,
    },
    [DepositTypes.chargemoney]: {
        type: DepositTypes.chargemoney,
        tabT: 'myWallet:deposit.chargemoney.tab_name',
        titleT: 'myWallet:deposit.chargemoney.title',
        subTitle: '',
        btnT: 'myWallet:deposit.chargemoney.make_deposit_btn',
        tKey: 'chargemoney',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        isActive: false,
        order: 20,
    },
    [DepositTypes.gcg]: {
        type: DepositTypes.gcg,
        tabT: 'myWallet:deposit.gcg.tab_name',
        titleT: 'myWallet:deposit.gcg.title',
        subTitle: '',
        btnT: 'myWallet:deposit.gcg.make_deposit_btn',
        tKey: 'gcg',
        icon: IconsType.credit_card_piqk,
        isIframe: true,
        isActive: false,
        order: 21,
    },
    [DepositTypes.neonpay]: {
        type: DepositTypes.neonpay,
        tabT: 'myWallet:deposit.neonpay.tab_name',
        titleT: 'myWallet:deposit.neonpay.title',
        btnT: 'myWallet:deposit.neonpay.make_deposit_btn',
        tKey: 'neonpay',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 22,
    },
    [DepositTypes.dikeshield]: {
        type: DepositTypes.dikeshield,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'dikeshield',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 23,
    },
    [DepositTypes.nonstopay]: {
        type: DepositTypes.nonstopay,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'nonstopay',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 24,
    },
    [DepositTypes.korapay]: {
        type: DepositTypes.korapay,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'korapay',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 25,
    },
    [DepositTypes.kasha]: {
        type: DepositTypes.kasha,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'kasha',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 26,
    },
    [DepositTypes.payport]: {
        type: DepositTypes.payport,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'payport',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 26,
    },
    [DepositTypes.pelecard]: {
        type: DepositTypes.pelecard,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'pelecard',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 27,
    },
    [DepositTypes.bitolo]: {
        type: DepositTypes.bitolo,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'bitolo',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 29,
    },
    [DepositTypes.payneteasy]: {
        type: DepositTypes.payneteasy,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'payneteasy',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 30,
    },
    [DepositTypes.vepara]: {
        type: DepositTypes.vepara,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'vepara',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 31,
    },
    [DepositTypes.nowpayments]: {
        type: DepositTypes.nowpayments,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'nowpayments',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 32,
    },
    [DepositTypes.match2pay]: {
        type: DepositTypes.match2pay,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'match2pay',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 34,
    },
    [DepositTypes.paytechno]: {
        type: DepositTypes.paytechno,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'paytechno',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 35,
    },
    [DepositTypes.paragon]: {
        type: DepositTypes.paragon,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'paragon',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 36,
    },
    [DepositTypes.epsilon]: {
        type: DepositTypes.epsilon,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'epsilon',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 37,
    },
    [DepositTypes.payku]: {
        type: DepositTypes.payku,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'payku',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 38,
    },
    [DepositTypes.toptechpay]: {
        type: DepositTypes.toptechpay,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'toptechpay',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 39,
    },
    [DepositTypes.cricpayz]: {
        type: DepositTypes.cricpayz,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'cricpayz',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 39,
    },
    [DepositTypes.payok]: {
        type: DepositTypes.payok,
        tabT: 'myWallet:deposit.credit_card.tab_name',
        titleT: 'myWallet:deposit.credit_card.title',
        btnT: 'myWallet:deposit.credit_card.make_deposit_btn',
        tKey: 'payok',
        icon: IconsType.credit_card,
        isIframe: true,
        isActive: false,
        order: 40,
    },
};
