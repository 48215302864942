import { makeStyles } from '@material-ui/core';
import { isMobileDevice, isRtl, isTablet } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    chatDrawer: {
        position: 'fixed',
        height: '100%',
        bottom: 0,
        width: '450px',
        transition: 'all 0.3s ease-in-out',
        zIndex: 99999,
        boxShadow: '0px 5px 20px 0 rgba(0,0,0,0.3)',
        display: 'flex',
        maxWidth: '95%',
        overflow: 'hidden',
        right: 0,
        ...isRtl({
            right: 'auto',
            left: 0,
        }),
        ...isTablet({
            height: '60vh',
            bottom: 166,
            borderRadius: 16,
            right: 12,
            ...isRtl({
                right: 'auto',
                left: 12,
            }),
        }),
    },
    chatDrawerOpen: {
        transform: 'translateX(0)',
        opacity: 1,
        zIndex: 999999,
    },
    chatDrawerClose: {
        transform: 'translateX(-100px)',
        opacity: 0,
        zIndex: -999999,
        visibility: 'hidden',
        ...isRtl({
            transform: 'translateX(100px)',
        }),
    },
    closeIcon: {
        position: 'absolute',
        top: 15,
        right: 60,
        color: '#000',
        cursor: 'pointer',
        strokeWidth: 1,
    },
    chatTrigger: {
        position: 'fixed',
        bottom: 75,
        backgroundColor: theme.palette.grayVariants[600],
        width: 50,
        height: 50,
        right: 12,
        zIndex: 999999,
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 0 10px 3px rgba(0,0,0,0.25)',
        cursor: 'pointer',
        color: theme.palette.grayVariants[50],
        ...isRtl({
            right: 'auto',
            left: 12,
        }),
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grayVariants[800],
        },
    },
    chatTriggerActive: {
        backgroundColor: theme.palette.grayVariants[800],
    },
}));
