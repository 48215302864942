import { jwtDecode as decode } from 'jwt-decode';
import { LoginType } from './loginType';

export class TokenPayload {
    type: LoginType;
    user_id: string;
    phoneVerified: boolean;
    emailVerified: boolean;
    isVerified: boolean;
    token?: string;
    delegateUser?: string;
    deviceVerified?: boolean;
    exp: number;
}

export const parseToken = (token: string | undefined) => {
    try {
        if (!token) return undefined;
        const parsedToken: TokenPayload = decode(token);
        return parsedToken;
    } catch (error) {
        return undefined;
    }
};

export const tokenExpireIn = (token: string | undefined) => {
    try {
        const parsedToken = parseToken(token);
        if (!parsedToken) return -1;
        const nowTimeInMs = new Date().getTime();
        return parsedToken.exp * 1000 - nowTimeInMs;
    } catch (error) {
        return -1;
    }
};
