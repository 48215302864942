import { makeStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    select_wrapper: {
        '& .MuiInputBase-root': {
            marginTop: 'unset',
        },
        '& .MuiOutlinedInput-root': {
            width: 'auto',
            minHeight: 'unset',
            height: '4.4rem',
        },
        '& .MuiOutlinedInput-input': {
            padding: '8px 12px',
            color: theme.palette.primary.main,
            gap: '4px',
            width: '49px',
            height: '30px',
            lineHeight: '3rem',
            fontSize: '2rem',
            fontWeight: 500,
            ...isRtl({ padding: '0.9rem 1.6rem 0.9rem 3.2rem' }),
        },
        '& .MuiSelect-iconOutlined': {
            color: theme.palette.primary.main,
        },
    },
}));
