import { makeStyles } from '@material-ui/core';
import { isTablet } from 'helpers/device_helper';

interface Props {
    contactUsImage: string;
}

export default makeStyles((theme) => ({
    contactUsImage: (props: Props) => ({
        backgroundImage: `url(${props.contactUsImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '0.8rem',
        height: '12rem',
        ...isTablet({
            height: '23rem',
        }),
    }),
}));
