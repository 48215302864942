import { makeStyles, Theme } from '@material-ui/core';
import { customGap, isLaptop, isRtl } from 'helpers/device_helper';

interface Props {
    fullWidth: boolean;
    fullHeight?: boolean;
    disableMaxWidth: boolean;
    maxWidthStr?: string;
    disablePadding?: boolean;
}

export default makeStyles<Theme, Props>((theme) => ({
    settingPaper: (props) => ({
        background: theme.palette.background.default,
        padding: props.disablePadding ? '0' : `${theme.spacing(7)}rem ${theme.spacing(4)}rem`,
        margin: `${theme.spacing(8)}rem ${theme.spacing(3)}rem`,
        maxHeight: '90%',
        height: props.fullHeight ? '90%' : 'auto',
        width: props.disableMaxWidth ? 'auto' : '90%',
        maxWidth:
            props.fullWidth || props.disableMaxWidth
                ? props.maxWidthStr
                    ? props.maxWidthStr
                    : 'unset'
                : `calc(48rem + ${theme.spacing(16)}rem)`,
        display: 'flex',
        ...isLaptop({
            margin: `${theme.spacing(8)}rem`,
            padding: props.disablePadding ? '0' : `${theme.spacing(7)}rem ${theme.spacing(8)}rem`,
        }),
    }),
    wrapper: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        ...customGap(theme.spacing(4), 'vertical'),
    },
    content: {
        flexGrow: 1,
        ...customGap(theme.spacing(2), 'vertical'),
    },
    closeButton: {
        zIndex: 1,
        position: 'absolute',
        right: `${theme.spacing(6)}rem`,

        left: 'unset',
        color: theme.palette.grayVariants[500],
        ...isRtl({
            left: `${theme.spacing(4)}rem`,
            right: 'unset',
        }),
    },
    bottomSection: {},
    helperTextWrapper: {
        paddingBottom: `${theme.spacing(2)}rem`,
    },
    errorMsgWrapper: {
        '& svg': {
            minWidth: '2rem',
        },
        paddingBottom: `${theme.spacing(2)}rem`,
        display: 'flex',
        color: theme.palette.error.main,
        justifyContent: 'center',
        alignItems: 'center',
        ...customGap(theme.spacing(1), 'horizontal'),
    },
    btnsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        ...customGap(theme.spacing(3), 'horizontal'),
    },
    btn: {
        flex: 1,
    },
}));
