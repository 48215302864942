import { createSelector } from 'reselect';
import { PropChallengeChallengeStatusEnum, PropChallengeChallengeTypeEnum } from 'services/generatedClientFromSwagger';
import { RootState } from 'store/index';

const prop_myChallengesReducerSelector = (state: RootState) => state.prop_myChallenges;

export const prop_getPlans = createSelector(prop_myChallengesReducerSelector, (store) => {
    return store.plans;
});

export const prop_myChallenges_getChallengesLoader = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.challengesLoader
);

export const prop_myChallenges_getAllChallenges = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.challenges
);
export const prop_myChallenges_validateWithdrawalData = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.validateWithdrawalData
);
export const prop_myChallenges_isStartFundedAlertOpen = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.isStartFundedAlertOpen
);

export const prop_myChallenges_getFilteredChallenges = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.filteredChallenges
);
export const prop_myChallenges_getNotStartedFundedChallenges = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.notStartedfundedPropChallenge
);

export const prop_myChallenges_getChallengeIframeLoading = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.challengeIframeLoading
);

export const prop_myChallenges_setFundedChallengeLoader = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.setFundedChallengeLoader
);

export const prop_myChallenges_getChallengeIframe = createSelector([prop_myChallengesReducerSelector], (challenges) => {
    if (challenges.selectedChallenge?.tpAccount) {
        return challenges.challengeIframe?.[challenges.selectedChallenge?.tpAccount];
    }
});

export const prop_myChallenges_getSelectedChallenge = createSelector(prop_myChallengesReducerSelector, (challenges) => {
    return challenges.selectedChallenge;
});

export const prop_myChallenges_getMainAccountTp = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.mainAccountTp
);

export const prop_myChallenges_iframePopUp = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.challengeIframePopup
);

export const prop_myChallenges_getFundedChallenges = createSelector(prop_myChallengesReducerSelector, (challenges) => {
    return challenges.fundedPropChallenge;
});

export const prop_myChallenges_fundsFilterOptions = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.fundsFilterOptions
);
export const prop_myChallenges_isKycDialogOpen = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.isKycDialogOpen
);
//need to change
export const prop_myChallenges_freeTrialNumbers = createSelector(prop_myChallengesReducerSelector, (store) => {
    const { limitAccountsNum, maxFreeTrialLimit, loading } = store.challenges.reduce(
        (acc: { limitAccountsNum: number; maxFreeTrialLimit: number; loading: boolean }, curr, index) => {
            if (curr.challengeType === PropChallengeChallengeTypeEnum.FreeTrial) {
                if (curr.challengeStatus === PropChallengeChallengeStatusEnum.Active) {
                    acc.limitAccountsNum += 1;
                } else {
                    acc.maxFreeTrialLimit += 1;
                }
            }
            const isLast = index === store.challenges.length - 1;
            acc.loading = isLast ? false : true;
            return acc;
        },
        { limitAccountsNum: 0, maxFreeTrialLimit: 0, loading: store?.challenges?.length > 0 }
    );
    return { limitAccountsNum, maxFreeTrialLimit, loading };
});

//remove
export const prop_myChallenges_getSelectedChallengeAddon = createSelector(prop_myChallengesReducerSelector, (store) => {
    const selectedChallenge = store.selectedChallenge;
    const plans = store.plans;
    if (!selectedChallenge || !plans) {
        return undefined;
    }
    return plans.find((plan) => plan.pPlan?.planId === selectedChallenge.plan)?.addOns;
});

export const prop_myChallenges_manualKycLoading = createSelector(
    prop_myChallengesReducerSelector,
    (challenges) => challenges.manualKycLoading
);
