import { EAnalyticsTypes } from './actionsTypes';
import { batch } from 'react-redux';
import axios from 'services/axios';
import { GenerateTradingAnalyticsIframeRequestPageIdEnum } from 'services/generatedClientFromSwagger/models';
import { RootState } from 'store';

export const analytics_getIframeUrl = (pageId: GenerateTradingAnalyticsIframeRequestPageIdEnum, language: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EAnalyticsTypes.GET_IFRAME_URL, payload: pageId });
            const getIframeRes = await axios.TradingAnalyticsApi.tradingAnalysisControllerGenerateWebTraderIframe({
                language,
                pageId,
            });
            dispatch({
                type: EAnalyticsTypes.GET_IFRAME_URL_SUCCESS,
                payload: { pageId, iframeUrl: getIframeRes.data.iframeUrl },
            });
        } catch (error) {
            return batch(() => {
                dispatch({ type: EAnalyticsTypes.GET_IFRAME_URL_FAILED, payload: pageId });
            });
        }
    };
};

export const analytics_getWebTVLinks = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: EAnalyticsTypes.GET_WEB_TV_LINKS });
            const getWebTvRes = await axios.TradingAnalyticsApi.tradingAnalysisControllerWebTVLinks();
            dispatch({
                type: EAnalyticsTypes.GET_WEB_TV_LINKS_SUCCESS,
                payload: getWebTvRes.data.links,
            });
        } catch (error) {
            return batch(() => {
                dispatch({ type: EAnalyticsTypes.GET_WEB_TV_LINKS_FAILED });
            });
        }
    };
};

export const analytics_getTradingSuggestions = (language: string) => {
    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const owningTradingAnalysis = state.user?.userInfo?.accountFeatures?.t4pTradingAnalysis?.enabled;
            if (!owningTradingAnalysis) throw new Error('Trading Analysis Not Enabled');
            dispatch({ type: EAnalyticsTypes.GET_TRADING_SUGGESTIONS });
            const suggestions = await axios.TradingAnalyticsApi.tradingAnalysisControllerGetT4PSignals({ language });
            dispatch({
                type: EAnalyticsTypes.GET_TRADING_SUGGESTIONS_SUCCESS,
                payload: suggestions.data.signals,
            });
        } catch (error) {
            return batch(() => {
                dispatch({ type: EAnalyticsTypes.GET_TRADING_SUGGESTIONS_FAILED });
            });
        }
    };
};
