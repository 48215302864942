import { makeStyles, useTheme } from '@material-ui/core';
import { SideMenuItem } from 'interfaces/main';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Icon, { IconsType } from 'components/Icon';
import Tooltip from 'components/ToolTip';
import { useTranslation } from 'react-i18next';
import Typography from 'components/Typography';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Button from 'components/Button';
import ConditionalWrapper from 'components/ConditionalWrapper';
import { isRtl } from 'helpers/device_helper';

export interface MenuItemProps extends SideMenuItem {
    active?: boolean;
    percent?: string | number;
    menuState: boolean;
    iconBackground?: boolean;
    t: (key: string) => string;
    id?: string;
    isCollapsible?: boolean;
    subMenuLinks?: Array<{ icon: IconsType; link: string; t_name: string }>;
    withTooltip?: boolean;
    additionalTooltipContent?: string | undefined;
}

interface StyleProps {
    menuState: boolean;
    percentState: boolean;
    percent?: string | number;
    iconBackground?: boolean;
}

const useStyles = makeStyles((theme) => ({
    menu_list_item: (props: StyleProps) => ({
        flex: '1 1 auto',
        minHeight: '0',
        fontSize: '1.4rem',
        fontWeight: 600,
        height: '4rem',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'transparent',
        borderRadius: '0.8rem',
        color: theme.palette.grayVariants[700],
        justifyContent: props.menuState ? 'unset' : 'center',
        '& > div:first-child': {
            color: theme.palette.grayVariants[500],
        },
        '&:hover': {
            backgroundColor: theme.palette.grayVariants[100],
            color: theme.palette.text.default,
            '& p': {
                color: theme.palette.text.default,
            },
        },
        ...((props.percentState || props.iconBackground) && {
            height: '5.6rem',
            border: `0.1rem solid ${theme.palette.action.disabledBackground}`,
        }),
        ...(!props.menuState && {}),
    }),
    menu_list_item__active: (props: StyleProps) => ({
        color: theme.palette.text.default,
        position: 'relative',
        backgroundColor: theme.palette.grayVariants[100],

        '& p': {
            color: theme.palette.text.default,
        },
    }),
    menu_list_item__menu_close__kyc: (props: StyleProps) => ({
        height: 'auto',
        padding: `${theme.spacing(1)}rem`,
        flexDirection: 'column',
        backgroundColor: theme.palette.secondary.main,
        border: 'unset',

        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            '& > div:first-child': {
                backgroundColor: theme.palette.secondary.light,
            },
        },
    }),
    endText: {
        color: theme.palette.secondary.main,
    },
    endText__close: {
        width: '100%',
        height: '2.6rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.main,
        borderRadius: '0.8rem',
    },
    content_wrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    icon_wrapper: (props: StyleProps) => ({
        width: 'calc(4.7rem - 2.4rem)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0px 15px',
        flexShrink: 0,
        ...((props.percentState || props.iconBackground) && {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            borderRadius: '0.8rem',
        }),
    }),
    text_wrapper: (props: StyleProps) => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        ...((props.percentState || props.iconBackground) && {
            fontWeight: 600,
            color: theme.palette.text.default,
        }),
    }),
    progressBar: (props: StyleProps) => ({
        width: '100%',
        height: '0.5rem',
        background: theme.palette.default.dark,
        overflow: 'hidden',
        borderRadius: '0.8rem',
        marginTop: '0.4rem',
        position: 'relative',
        '&:after': {
            content: "''",
            position: 'absolute',
            left: 0,
            height: '0.5rem',
            width: `${props.percent}%`,
            background: theme.palette.secondary.main,
        },
    }),
    collapsedItemMain: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        color: theme.palette.grayVariants[700],
        '& svg': {
            color: theme.palette.grayVariants[500],
            stroke: 'currentColor',
            '& path': {
                stroke: 'currentColor',
            },
        },
    },

    collapsedItem: () => ({
        marginTop: '4px',
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        padding: '8px 8px 8px 35px',
        color: theme.palette.text.primary,
        '& svg': {
            color: theme.palette.grayVariants[500],
            stroke: 'currentColor',
            '& path': {
                stroke: 'currentColor',
            },
        },
        ...isRtl({ padding: '8px 35px 8px 8px' }),
    }),
    collapsedIcon: { margin: '0 15px' },
    root: { padding: '0', alignItems: 'stretch', '&.MuiListItem-button': { borderRadius: '8px' } },
    menu_list_item__collapsible: (props: StyleProps) => ({
        width: '100%',
        ...(!props.menuState && {}),
    }),
    collapsedItem__active: {
        backgroundColor: theme.palette.grayVariants[100],
        borderRadius: '0.8rem',
        color: theme.palette.grayVariants[900],
        fontWeight: 600,
    },
    collapseButton: {
        padding: '0 10px',
        '&:hover,&:focus,&:active': { backgroundColor: 'transparent' },
    },

    menu_item_typography: {
        whiteSpace: 'nowrap',
    },
}));

const MenuItem: React.FunctionComponent<MenuItemProps> = (props) => {
    const {
        additionalTooltipContent,
        icon,
        active,
        t_name,
        link,
        outsideLink,
        percent,
        menuState,
        t,
        onClick,
        iconBackground,
        id,
        isCollapsible,
        subMenuLinks,
        withTooltip,
    } = props;
    const classes = useStyles({ menuState, percentState: Boolean(percent), percent: percent, iconBackground });
    const theme = useTheme();
    const location = useLocation();

    const {
        i18n: { language },
    } = useTranslation();

    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

    const handleOnClick = (e) => {
        if (onClick) onClick(e);
    };
    const handleClick = () => {
        setIsCollapsed((e) => !e);
    };

    React.useEffect(() => {
        if (subMenuLinks) {
            const areYouInSubMenu: any = subMenuLinks?.find((menu) => {
                return location.pathname === menu.link;
            });

            if (!areYouInSubMenu) {
                setIsCollapsed(false);
            } else {
                setIsCollapsed(true);
            }
        }
    }, [location, subMenuLinks]);

    const checkIfIsActive = (link: string | Array<string> | undefined) => {
        if (!link) return false;
        if (Array.isArray(link)) {
            return link.some((url) => location.pathname.includes(url));
        }
        return location.pathname.includes(link);
    };

    const onCollapseClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsCollapsed((state) => !state);
    };

    const InnerComponent = () => (
        <ConditionalWrapper
            condition={!!withTooltip && !menuState}
            wrapper={(wrappedChildren) => (
                <Tooltip
                    title={`${t(t_name)}${additionalTooltipContent ? `\n${additionalTooltipContent}` : ''}`}
                    placement={theme.direction === 'rtl' ? 'left' : 'right'}
                >
                    {wrappedChildren}
                </Tooltip>
            )}
        >
            {isCollapsible ? (
                <div
                    key={`${id}_innerButton_key`}
                    id={`${id}_innerButton`}
                    className={classNames({
                        [classes.menu_list_item__collapsible]: true,
                    })}
                >
                    <ListItem button onClick={handleClick} className={classes.root}>
                        <div className={classNames(classes.collapsedItemMain, {})}>
                            <div className={classes.icon_wrapper}>
                                <Icon name={icon} />
                            </div>
                            {menuState && (
                                <Typography variant="tMdMedium" className={classes.menu_item_typography}>
                                    {' '}
                                    {t(t_name)}
                                </Typography>
                            )}
                        </div>
                        {menuState && (
                            <Button className={classes.collapseButton} onClick={onCollapseClick}>
                                {isCollapsed ? <ExpandLess /> : <ExpandMore />}
                            </Button>
                        )}
                    </ListItem>
                    {menuState && (
                        <Collapse in={isCollapsed} timeout={10000} unmountOnExit>
                            {subMenuLinks?.map((subMenu, index) => (
                                <Link to={subMenu.link} key={`${id}_link_${index}`}>
                                    <div
                                        onClick={handleOnClick}
                                        className={classNames({
                                            [classes.collapsedItem]: true,
                                            [classes.collapsedItem__active]: checkIfIsActive(subMenu.link),
                                        })}
                                    >
                                        <Icon name={subMenu.icon} className={classes.collapsedIcon} />
                                        <Typography> {t(subMenu.t_name)}</Typography>
                                    </div>
                                </Link>
                            ))}
                        </Collapse>
                    )}
                </div>
            ) : (
                <div
                    className={classNames({
                        [classes.menu_list_item]: true,
                        [classes.menu_list_item__active]: active,
                        [classes.menu_list_item__menu_close__kyc]: !menuState && iconBackground,
                    })}
                    onClick={handleOnClick}
                    id={`${id}_innerButton`}
                >
                    <div className={classes.icon_wrapper}>
                        <Icon name={icon} />
                    </div>
                    {menuState && (
                        <div className={classes.content_wrapper}>
                            <div className={classes.text_wrapper}>
                                <Typography className={classes.menu_item_typography} component="p" variant="tMdMedium">
                                    {t(t_name)}
                                </Typography>
                                {Boolean(percent) && <div className={classes.endText}>{percent}%</div>}
                            </div>
                            {Boolean(percent) && <div className={classes.progressBar} />}
                        </div>
                    )}
                    {!menuState && Boolean(percent) && (
                        <div className={classNames({ [classes.endText]: true, [classes.endText__close]: true })}>
                            {percent}%
                        </div>
                    )}
                </div>
            )}
        </ConditionalWrapper>
    );

    if (isCollapsed) {
        return <InnerComponent />;
    }

    if (link && outsideLink) {
        let modLink: string = link;
        try {
            modLink = link.replace('{{lng}}', language);
        } catch (error) {}
        return (
            <a href={modLink} target="_blank" rel="noopener noreferrer" id={`${id}_link`}>
                <InnerComponent />
            </a>
        );
    }

    if (link) {
        return (
            <Link to={link} id={`${id}_link`}>
                <InnerComponent />
            </Link>
        );
    }

    return <InnerComponent />;
};

export default MenuItem;
//
