import { GridSize } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { ButtonProps } from 'components/Button';
import { ButtonRadioGroupProps } from 'components/Inputs/ButtonRadioGroup';
import { CheckboxProps } from 'components/Inputs/Checkbox';
import { DatePickerProps } from 'components/Inputs/DatePicker';
import {
    GoogleLocationAutocompletePayload,
    GoogleLocationAutocompletePropsWrapper,
} from 'components/Inputs/GoogleLocationAutocomplete';
import { RadioButtonsProps } from 'components/Inputs/RadioButtons';
import { SelectProps } from 'components/Inputs/Select';
import { SwitchProps } from 'components/Inputs/Switch/switch.render';
import { TextFieldProps } from 'components/Inputs/TextField';
import { TypographyProps } from 'components/Typography';
import { ErrorsScheme } from 'interfaces/main';

export enum FormComponents {
    textfield = 'textfield',
    select = 'select',
    checkbox = 'checkbox',
    switch = 'switch',
    datepicker = 'datepicker',
    googleLocationAutocomplete = 'googleLocationAutocomplete',
    typography = 'typography',
    radioButtons = 'radioButtons',
    buttonRadioGroup = 'buttonRadioGroup',
    link = 'link',
    // phoneNumber = 'phoneNumber',
}

export interface FormInput {
    hidden?: boolean;
    type: FormComponents;
    id: string;
    placeholderT?: string | { t: string; values: { [value: string]: string | number } };
    labelT?: string | { t: string; values: { [value: string]: string | number } };
    labelTrans?: {
        components?: { [tagName: string]: React.ReactElement };
        values: { [value: string]: string | number };
        i18nKey: string;
    };
    textfieldProps?: TextFieldProps;
    selectProps?: SelectProps;
    checkboxProps?: CheckboxProps;
    switchProps?: SwitchProps;
    datepickerProps?: DatePickerProps;
    googleLocationAutocompleteProps?: GoogleLocationAutocompletePropsWrapper;
    googleLocationAutocompleteDefaultObject?: GoogleLocationAutocompletePayload;
    typographyTextT?: string | { t: string; values: { [value: string]: string | number } };
    typographyProps?: TypographyProps;
    radioButtonsProps?: RadioButtonsProps;
    buttonRadioGroupProps?: ButtonRadioGroupProps;
    disableOptionsT?: boolean;
    order?: number;
    breakpointsSizes?: {
        xs?: GridSize;
        sm?: GridSize;
        md?: GridSize;
        lg?: GridSize;
        xl?: GridSize;
    };
    formInputClasses?: any;
    group?: string;
    linkProps?: {
        to: string;
        text: string;
        alignRight?: boolean;
        typographyProps?: TypographyProps;
    };
    // phoneNumberProps?: {
    //     selectProps?: SelectProps;
    //     textfieldProps?: TextFieldProps;
    // };
}

export interface ValidationScheme {
    [field: string]: any;
}

export interface ValuesInterface {
    [field: string]: any;
}

export interface FormGeneratorProps {
    submitBtnT?: string;
    submitBtnProps?: ButtonProps;
    submitBtnBreakPoints?: {
        xs?: GridSize;
        sm?: GridSize;
        md?: GridSize;
        lg?: GridSize;
        xl?: GridSize;
    };
    submitBtnCenter?: boolean;
    cancelBtnT?: string;
    cancelBtnProps?: ButtonProps;
    cancelBtnBreakPoints?: {
        xs?: GridSize;
        sm?: GridSize;
        md?: GridSize;
        lg?: GridSize;
        xl?: GridSize;
    };
    errors?: ErrorsScheme;
    validationsScheme?: ValidationScheme;
    inputs: Array<FormInput>;
    color?: 'primary' | 'secondary';
    variant?: 'standard' | 'outlined' | 'filled';
    error?: boolean;
    helperText?: string;
    loading?: boolean;
    isMaxWidthForm?: boolean;
    fullWidth?: boolean;
    customWrapperCss?: CSSProperties;
    disabled?: boolean;
    reverseSubmitCancelButtons?: boolean;
    onFieldChange?: (field: string, value: any) => void;
    onSubmitClick?: (values: ValuesInterface) => void;
    onCancelClick?: () => void;
    submitted?: boolean;
    onErrors?: (errors: object | null) => any;
    onBlurTextField?: boolean;
    gap?: number;
}
