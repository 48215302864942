import config from 'config/common';
import { tokenExpireIn } from 'helpers/jwtParser';

let tokenExpiredTimeout;

const storageChanged = ({ key }) => {
    if (key === config.tokenKey) {
        const token = getTokenFromLocalStorage();
        if (token) {
            resetTokenExpiredTimeout(token);
        }
    }
};
window.addEventListener('storage', storageChanged);

const resetTokenExpiredTimeout = (token: string | undefined) => {
    const timeLeftForToken = tokenExpireIn(token);
    clearTimeout(tokenExpiredTimeout);
    tokenExpiredTimeout = setTimeout(() => {
        deleteTokenFromLocalStorage();
        window.location.reload();
    }, timeLeftForToken);
};

export const saveTokenToLocalStorage: (token: string) => void = (token: string) => {
    localStorage.setItem(config.tokenKey, token);
    resetTokenExpiredTimeout(token);
};

export const getTokenFromLocalStorage: () => string | undefined = () => {
    return localStorage.getItem(config.tokenKey) || undefined;
};

export const deleteTokenFromLocalStorage: () => void = () => {
    localStorage.removeItem(config.tokenKey);
};

const initialCheck = () => {
    const token = getTokenFromLocalStorage();
    if (token) {
        resetTokenExpiredTimeout(token);
    }
};
initialCheck();
