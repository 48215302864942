import React from 'react';
import useStyles from './chat-drawer.style';
import classNames from 'classnames';
import Icon, { IconsType } from 'components/Icon';
import config from 'config/common';
import Button from 'components/Button';
import mixPanel, { mixPanelEventsTypes } from 'helpers/mixPanel';

type Props = {};

const ChatDrawer: React.FC<Props> = ({}) => {
    const classes = useStyles();
    const [isChatDrawerOpen, setIsChatDrawerOpen] = React.useState<boolean>(false);
    const toggleChatFrame = () => {
        mixPanel.track(mixPanelEventsTypes.CHAT_BASE_DRAWER_TOGGLE, isChatDrawerOpen ? 'close' : 'open');
        setIsChatDrawerOpen(!isChatDrawerOpen);
    };
    React.useEffect(() => {
        // this will disable the scroll if our back page was scrollable
        if (isChatDrawerOpen) document.body.style.overflow = 'hidden';
        // when you close the modal, remove this class
        return () => {
            document.body.style.overflow = '';
        };
    }, [isChatDrawerOpen]);
    return (
        <React.Fragment>
            <Button
                className={classNames(classes.chatTrigger, {
                    [classes.chatTriggerActive]: isChatDrawerOpen,
                })}
                onClick={toggleChatFrame}
            >
                <Icon name={IconsType.message_chat_square} />
            </Button>
            <div
                className={classNames({
                    [classes.chatDrawer]: true,
                    [classes.chatDrawerOpen]: isChatDrawerOpen,
                    [classes.chatDrawerClose]: !isChatDrawerOpen,
                })}
            >
                <Icon name={IconsType.close} className={classes.closeIcon} onClick={toggleChatFrame} />
                <iframe
                    title="chatBase"
                    src={`https://www.chatbase.co/chatbot-iframe/${config['reactAppChatBaseKey']}`}
                    width="100%"
                    style={{
                        height: ' 100%',
                    }}
                ></iframe>
            </div>
        </React.Fragment>
    );
};

export default ChatDrawer;
