import { makeStyles } from '@material-ui/core';
import Button from 'components/Button';
import Icon, { IconsType } from 'components/Icon';
import Typography from 'components/Typography';
import config from 'config/common';
import { isTablet } from 'helpers/device_helper';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatLinkLng } from 'helpers/formatLinkLng';

const useStyles = makeStyles((theme) => ({
    fixed_bottom_text: () => ({
        flex: '0 0 auto',
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: `2rem`,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        '& > div': {
            maxWidth: '102.4rem',
            '& > p': {
                marginBottom: '1rem',
                '&:last-child': {
                    marginBottom: '0',
                },
            },
        },
        ...isTablet({
            padding: `2rem 4rem`,
        }),
    }),
    page_link: {
        '&:link,&:visited': {
            textDecoration: 'underline',
        },
    },
    fixed_bottom_text_btn: (props: { disclaimerState: boolean }) => ({
        '&&': {
            position: 'absolute',
            top: 0,
            transform: `translateY(-50%) rotate(${props.disclaimerState ? 0 : 180}deg)`,
            backgroundColor: theme.palette.primary.main,
            transition: 'all 200ms linear',
            borderRadius: '50%',
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
    }),
    risk_warning_notice: {
        color: theme.palette.secondary.main,
    },
}));

export interface DisclaimerProps {}

const Disclaimer: React.FunctionComponent<DisclaimerProps> = () => {
    const [disclaimerState, setDisclaimerState] = React.useState<boolean>(true);
    const [cfd_percentage, set_cfd_percentage] = React.useState<string>('');
    const classes = useStyles({ disclaimerState });

    const {
        i18n: { language },
    } = useTranslation();

    React.useEffect(() => {
        (async () => {
            const config_cfd_percentage = await config.cfd_percentage;
            set_cfd_percentage(config_cfd_percentage);
        })();
    }, []);

    return (
        <div className={classes.fixed_bottom_text}>
            <Button
                classes={{ root: classes.fixed_bottom_text_btn }}
                variant="text"
                onClick={() => setDisclaimerState(!disclaimerState)}
            >
                <Icon name={IconsType.chevron_down} />
            </Button>
            <div>
                <Typography variant="body2">
                    <Trans
                        i18nKey={`common:${disclaimerState ? 'fixed_bottom_text' : 'fixed_button_text_short'}`}
                        values={{ cfd_percentage }}
                        components={{
                            c_link: (
                                <a
                                    className={classes.page_link}
                                    href={formatLinkLng(config.links.warnings_and_disclosures, language)}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    a
                                </a>
                            ),
                        }}
                    />
                </Typography>
                {disclaimerState && config.featuresFlags['showUKDisclaimerNotice'] && (
                    <Typography variant="body2" className={classes.risk_warning_notice}>
                        <Trans i18nKey={`common:fixed_bottom_text_notice`} />
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default Disclaimer;
