import axios from 'services/axios';
import { EPropNewChallengeTypes } from './actionsTypes';

const getNewGoal = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPropNewChallengeTypes.GET_NEW_GOAL_OPTIONS_START });
            const result = await axios.PropApi.propApiControllerNewChallengeOptions();
            dispatch({
                type: EPropNewChallengeTypes.GET_NEW_GOAL_OPTIONS_SUCCESS,
                payload: result.data.goals,
            });
        } catch (error) {
            dispatch({ type: EPropNewChallengeTypes.GET_NEW_GOAL_OPTIONS_FAIL });
        }
    };
};

export const prop_newChallege_actions = {
    getNewGoal,
};

export const prop_newChallege_saveChallengeData = (challengeId: string, packageType: string) => {
    return { type: EPropNewChallengeTypes.SET_CHALLENGE_DATA, payload: { challengeId, packageType } };
};
