import { createSelector } from 'reselect';
import { RootState } from 'store';

const notificationReducerSelector = (state: RootState) => state.notification;

export const notification_loading = createSelector(notificationReducerSelector, (notification) => notification.loading);

export const notification_notifications = createSelector(
    notificationReducerSelector,
    (notification) => notification.notifications
);

export const notification_deleteLoading = createSelector(
    notificationReducerSelector,
    (notification) => notification.deleteNotificationLoading
);

export const notification_notFoundNotifications = createSelector(
    notificationReducerSelector,
    (notification) => notification.notFoundNotifications
);

export const notification_moreLoading = createSelector(
    notificationReducerSelector,
    (notification) => notification.moreLoading
);
