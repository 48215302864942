interface BookMessage {
    as?: [[string, string, string]];
    bs?: [[string, string, string]];
    a?: [[string, string, string]];
    b?: [[string, string, string]];
}

export interface BookListener {
    pair: string;
    depth: number;
}

export interface BookData {
    as: {
        [price: string]: string;
    };
    bs: {
        [price: string]: string;
    };
}

export interface BookDataAll {
    [pair: string]: BookData;
}

let messageDelayTimeout;
let reloadSocket;

class KrakenWebsocketConnection {
    private webSocket;
    private active: boolean = false;
    private connectionStatus: boolean = false;

    private connectionHeartbeatTimeout;

    private bookListener: BookListener | undefined = undefined;
    private bookData: BookDataAll = {};

    constructor() {
        this.publishBook();
    }

    public init() {
        if (!this.active) {
            this.active = true;
            this.start();
            this.startInactivityListener();
        }
    }

    public destroy() {
        this.active = false;
        this.stop();
        this.stopInactivityListener();
        clearTimeout(messageDelayTimeout);
        clearTimeout(reloadSocket);
    }

    private startInactivityListener() {
        document.addEventListener('visibilitychange', () => this.visibilitychange());
    }

    private stopInactivityListener() {
        document.removeEventListener('visibilitychange', () => this.visibilitychange());
    }

    private visibilitychange() {
        switch (document.hidden) {
            case false:
                this.reloadSocketConnection();
                break;
        }
    }

    private reloadSocketConnection() {
        this.stop();
        clearTimeout(reloadSocket);
        reloadSocket = setTimeout(() => {
            this.start();
        }, 1000);
    }

    private stop() {
        if (this.webSocket) {
            this.webSocket.close();
        }
    }

    private start() {
        this.webSocket = new WebSocket(`wss://ws.kraken.com`);
        this.webSocket.onmessage = (d) => {
            const parsedMessage = JSON.parse(d.data);
            this.onMessage(parsedMessage);
        };
    }

    private onMessage(parsedMessage: any) {
        if (Array.isArray(parsedMessage)) {
            //eslint-disable-next-line
            const parsedMessageLength = parsedMessage.length;
            const [event, asset] = parsedMessage.splice(parsedMessageLength - 2, 2);
            // const [num] = parsedMessage.splice(0, 1); //TODO Eden what is this?
            const data = parsedMessage;
            // const num = parsedMessage[]
            if (event.includes('book')) {
                if (data.length > 1) {
                    // debugger;
                }
                for (const bookDataItem of data) {
                    const bookData: BookMessage = { ...bookDataItem };

                    if (!this.bookData[asset]) {
                        this.bookData[asset] = {
                            as: {},
                            bs: {},
                        };
                    }

                    if (bookData.as || bookData.bs) {
                        this.bookData[asset].as = {};
                        this.bookData[asset].bs = {};
                    }

                    const bookAsk = bookData.as || bookData.a || [];
                    const bookBid = bookData.bs || bookData.b || [];

                    for (let i = 0; i < Math.max(bookAsk.length, bookBid.length); i++) {
                        if (bookAsk[i]) {
                            const [price, amount] = bookAsk[i];
                            if (Number(amount) === 0) {
                                delete this.bookData[asset].as[price];
                            } else {
                                this.bookData[asset].as[price] = amount;
                            }
                        }

                        if (bookBid[i]) {
                            const [price, amount] = bookBid[i];
                            if (Number(amount) === 0) {
                                delete this.bookData[asset].bs[price];
                            } else {
                                this.bookData[asset].bs[price] = amount;
                            }
                        }
                    }
                }
            }
        } else {
            if (parsedMessage?.event) {
                switch (parsedMessage.event) {
                    case 'systemStatus':
                        if (parsedMessage.status === 'online') {
                            this.sendMessageToWS({
                                event: 'subscribe',
                                pair: ['XDG/USD'],
                                subscription: { name: 'ohlc' },
                            });
                            this.sendMessageToWS({
                                event: 'unsubscribe',
                                pair: ['XDG/USD'],
                                subscription: { name: 'ohlc' },
                            });
                            this.connectionStatus = true;
                            if (this.bookListener) {
                                this.updateBookListener(this.bookListener, 'subscribe');
                            }
                            break;
                        }
                        console.error(`Failed to connect to Public Kraken WS. Reason: ${parsedMessage.status}`);
                        break;
                    case 'subscriptionStatus':
                        const {
                            errorMessage,
                            pair,
                            status,
                            subscription: { name },
                        } = parsedMessage;
                        switch (name) {
                            case 'ticker':
                                if (status === 'error') {
                                    console.error(`Failed to subscribe to pair: (${pair}). Reason: ${errorMessage}`);
                                }
                                break;
                        }
                        break;
                    case 'heartbeat':
                        this.connectionHeartbeat();
                        break;
                    default:
                        break;
                }
            }
        }
    }

    public updateBookListener = (bookListener: BookListener, event: 'subscribe' | 'unsubscribe') => {
        this.bookListener = event === 'subscribe' ? bookListener : undefined;
        if (!this.connectionStatus) return false;

        this.sendMessageToWS({
            event,
            pair: [bookListener.pair],
            subscription: {
                name: 'book',
                depth: bookListener.depth,
            },
        });
        return true;
    };

    private sendMessageToWS = (data: any) => {
        try {
            let dataToSend = data;
            if (typeof dataToSend !== 'string') {
                dataToSend = JSON.stringify(dataToSend);
            }
            this.webSocket.send(dataToSend);
        } catch (error) {
            console.error(error);
        }
    };

    private publishBook = () => {
        setInterval(() => {
            Object.keys(this.bookData).forEach((pair) => {
                const newEvent = new CustomEvent(`newBook_${pair}`, { detail: JSON.stringify(this.bookData[pair]) });
                window.dispatchEvent(newEvent);
            });
        }, 500);
    };

    private connectionHeartbeat() {
        clearTimeout(this.connectionHeartbeatTimeout);
        this.connectionHeartbeatTimeout = setTimeout(() => {
            console.log(`Connection heartbeat timeout reconnect...`);

            this.reloadSocketConnection();
        }, 5000);
    }
}

export default new KrakenWebsocketConnection();
