export enum EUserTypes {
    START_LOADER = '[user] start loader',
    STOP_LOADER = '[user] stop loader',

    GET_USER_DATA_START = '[user] get user data start',
    GET_USER_DATA_SUCCESS = '[user] get user data success',
    GET_USER_DATA_FAILED = '[user] get user data failed',

    CREATE_TP_ACCOUNT_START = '[user] create tp account start',
    CREATE_TP_ACCOUNT_SUCCESS = '[user] create tp account success',
    CREATE_TP_ACCOUNT_FAILED = '[user] create tp account failed',

    UPDATE_USER_INFO_START = '[user] update user info start',
    UPDATE_USER_INFO_SUCCESS = '[user] update user info success',
    UPDATE_USER_INFO_FAILED = '[user] update user info failed',
    UPDATE_USER_INFO_RESET = '[user] update user info reset',

    CHANGE_SELECTED_TP = '[user] change selected tp',

    REFRESH_USER_DATA_START = '[user] refresh user data start',
    REFRESH_USER_DATA_SUCCESS = '[user] refresh user data success',
    REFRESH_USER_DATA_FAILED = '[user] refresh user data failed',

    IS_REACHED_CREDIT_VOLUME_START = '[user] is reached credit volume start',
    IS_REACHED_CREDIT_VOLUME_SUCCESS = '[user] is reached credit volume success',
    IS_REACHED_CREDIT_VOLUME_FAILED = '[user] is reached credit volume failed',

    UPDATE_CRYPTO_WALLET = '[user] update crypto wallet from feed',
    REGISTER_VERIFIED = '[user] user verified',
    SEND_EMAIL_VERIFICATION_TO_ACCOUNT_START = '[user] send email verification to account start',
    SEND_EMAIL_VERIFICATION_TO_ACCOUNT_SUCCESS = '[user] send email verification to account success',
    SEND_EMAIL_VERIFICATION_TO_ACCOUNT_FAILED = '[user] send email verification to account failed',

    SEND_PHONE_VERIFICATION_TO_ACCOUNT_START = '[user] send phone verification to account start',
    SEND_PHONE_VERIFICATION_TO_ACCOUNT_SUCCESS = '[user] send phone verification to account success',
    SEND_PHONE_VERIFICATION_TO_ACCOUNT_FAILED = '[user] send phone verification to account failed',

    VERIFY_PHONE_START = '[user] verify phone start',
    VERIFY_PHONE_SUCCESS = '[user] verify phone success',
    VERIFY_PHONE_FAILED = '[user] verify phone failed',

    VERIFY_EMAIL_START = '[user] verify email start',
    VERIFY_EMAIL_FINISHED = '[user] verify email finished',
    VERIFY_EMAIL_FAILED = '[user] verify email failed',

    UPDATE_ACCOUNT_KYC_DATA_START = '[user] update account kyc data start',
    UPDATE_ACCOUNT_KYC_DATA_SUCCESS = '[user] update account kyc data success',
    UPDATE_ACCOUNT_KYC_DATA_FAILED = '[user] update account kyc data failed',

    UPDATE_ACCOUNT_KYC_ACCEPT_RISK_START = '[user] update account kyc accept risk start',
    UPDATE_ACCOUNT_KYC_ACCEPT_RISK_SUCCESS = '[user] update account kyc accept risk success',
    UPDATE_ACCOUNT_KYC_ACCEPT_RISK_FAILED = '[user] update account kyc accept risk failed',

    UPDATE_ACCOUNT_PUSH_NOTIFICATION_PERMISSION_START = '[user] update account push notification permission start',
    UPDATE_ACCOUNT_PUSH_NOTIFICATION_PERMISSION_SUCCESS = '[user] update account push notification permission success',
    UPDATE_ACCOUNT_PUSH_NOTIFICATION_PERMISSION_FAILED = '[user] update account push notification permission failed',

    GET_REFERRAL_CODE_START = '[user] get referral code start',
    GET_REFERRAL_CODE_SUCCESS = '[user] get referral code success',
    GET_REFERRAL_CODE_FAILED = '[user] get referral code failed',
}
