import Button from 'components/Button';
import Typography from 'components/Typography';
import config from 'config/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { user_country, user_loader, user_msgPopupVisibleLogic } from 'store/user/selectors';
import useStyles from './msgPopup.styles';
import { connect } from 'react-redux';
import { user_UpdateUserInfo } from 'store/user/actions';
import { AccountInfoParsed } from 'services/generatedClientFromSwagger';
import TransAsHtml from 'components/TransAsHtml';
import { useStorageState } from 'hooks/useStorageState';
import Icon, { IconsType } from 'components/Icon';

export interface MsgPopupProps {
    loading: boolean;
    user_msgPopupVisibleLogic: boolean;
    user_country: string;
    user_UpdateUserInfo: (payload: AccountInfoParsed) => Promise<boolean>;
    imageOnly?: any;
}

export const mapStateToProps = (state) => ({
    loading: user_loader(state),
    user_msgPopupVisibleLogic: user_msgPopupVisibleLogic(state),
    user_country: user_country(state),
});

export const mapDispatchToProps = {
    user_UpdateUserInfo,
};

const MsgPopup: React.FunctionComponent<MsgPopupProps> = (props) => {
    const { user_msgPopupVisibleLogic, user_country, imageOnly } = props;
    const classes = useStyles();
    const { t } = useTranslation('msgPopup');

    const [popupSeen, setPopupSeen] = useStorageState('msgPopupSeen', false, 'sessionStorage');

    const handleOnPopupClose = async () => {
        setPopupSeen(true);
    };

    const termNumber = React.useMemo(() => {
        return user_country === 'United Kingdom' ? '1' : '2';
    }, [user_country]);

    if (!config.featuresFlags['msgPopup'] || !user_msgPopupVisibleLogic || popupSeen) return null;

    return (
        <div className={classes.wrapper}>
            {imageOnly ? (
                <Icon onClick={handleOnPopupClose} name={IconsType.netdania_trade_trade_smart} />
            ) : (
                <div className={classes.popupWrapper}>
                    <Typography variant="h1">
                        <TransAsHtml i18FileAndKey={`msgPopup:type${termNumber}.title`} />
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        <TransAsHtml i18FileAndKey={`msgPopup:type${termNumber}.content`} />
                    </Typography>
                    <div>
                        <Button variant="contained" color="primary" onClick={handleOnPopupClose}>
                            {t('btn')}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MsgPopup));
