import { FormComponents, FormInput, ValidationScheme } from 'components/FormGenerator';
import {
    user_userInfo,
    // user_loader,
    user_fullAddress,
    user_fullName,
    user_locationAddressObject,
    user_dateOfBirth,
    user_phoneNumber,
    user_refreshingUser,
} from 'store/user/selectors';
import Yup from 'yupSetup';
import moment from 'moment';
import { user_UpdateUserInfo } from 'store/user/actions';
import { GoogleLocationAutocompletePayload } from 'components/Inputs/GoogleLocationAutocomplete';
import { AccountInfoParsed, GetIpDataResponse } from 'services/generatedClientFromSwagger/models';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { system_countriesList, system_countriesListDialCodes, system_dataLoader } from 'store/system/selectors';
import { RouteComponentProps } from 'react-router-dom';
import { TFunction } from 'i18next';
import config from 'config/common';
import { isMobileDevice } from 'helpers/device_helper';
import countryCodeToDialCode from 'helpers/countryCodeToDialCode';
import { auth_ipData, auth_loader } from 'store/auth/selectors';

export interface MyWalletPreConditionProps extends RouteComponentProps {
    // redux props
    userInfo: AccountInfoParsed;
    loading: boolean;
    userFullAddress: string;
    userFullName: string;
    userDateOfBirth: ParsableDate;
    userAddressObject: GoogleLocationAutocompletePayload;
    countriesList: Array<{ value: string; label: string; shortName: string }>;
    countriesListDialCodes: Array<{ value: string; label: string; shortName: string }>;
    dataLoader: boolean;
    authLoader: boolean;
    phoneNumber?: string;
    ipData: GetIpDataResponse | undefined;
    user_UpdateUserInfo: (payload: AccountInfoParsed) => Promise<boolean>;
    user_refreshingUser: boolean;

    // props
    customRedirectUrl?: string;
    disabled?: boolean;
    callToPaymentIframe?: () => void;
}

export const mapStateToProps = (state) => ({
    // loading: user_loader(state),
    userInfo: user_userInfo(state),
    userFullAddress: user_fullAddress(state),
    userAddressObject: user_locationAddressObject(state),
    userFullName: user_fullName(state),
    userDateOfBirth: user_dateOfBirth(state),
    countriesList: system_countriesList(state),
    countriesListDialCodes: system_countriesListDialCodes(state),
    dataLoader: system_dataLoader(state),
    authLoader: auth_loader(state),
    phoneNumber: user_phoneNumber(state),
    ipData: auth_ipData(state),
    user_refreshingUser: user_refreshingUser(state),
});

export const mapDispatchToProps = {
    user_UpdateUserInfo,
};

export const preDepositFormInputsScheme: (data) => Array<FormInput> = (data: {
    date: ParsableDate;
    location: string;
    userAddressObject: GoogleLocationAutocompletePayload;
    isCheck: boolean;
    countriesList: Array<{ value: string; label: string; shortName: string }>;
    countriesListDialCodes: Array<{ value: string; label: string; shortName: string }>;
    dataLoader: boolean;
    custom_field_citizenship?: string;
    custom_field_accepttermsandconditions?: boolean;
    phoneNumber?: string;
    ipData: GetIpDataResponse | undefined;
    t: TFunction;
    classes?: any;
    lang: string;
}) => {
    return [
        {
            id: 'phoneCountryCode',
            type: FormComponents.select,
            labelT: 'prop:customer_details_form.country_code.label',
            selectProps: {
                options: isMobileDevice()
                    ? data.countriesListDialCodes.map((cn) => ({ value: cn.value, label: cn.value }))
                    : data.countriesListDialCodes,
                loading: data.dataLoader,
                value: countryCodeToDialCode(data.ipData?.countryCode),
            },
            breakpointsSizes: {
                xs: 4,
            },
            hidden: !config.preDepositFlags['phoneNumber'] || Boolean(data.phoneNumber),
        },
        {
            id: 'phoneNumber',
            type: FormComponents.textfield,
            placeholderT: 'prop:customer_details_form.phone.placeholder',
            labelT: 'prop:customer_details_form.phone.label',
            textfieldProps: {
                defaultValue: '',
                type: 'tel',
                autoComplete: 'phone',
            },
            breakpointsSizes: {
                xs: 8,
            },
            hidden: !config.preDepositFlags['phoneNumber'] || Boolean(data.phoneNumber),
        },
        {
            id: 'custom_field_dateOfBirth',
            type: FormComponents.datepicker,
            placeholderT: 'prop:customer_details_form.dateOfBirth.placeholder',
            labelT: 'prop:customer_details_form.dateOfBirth.label',
            datepickerProps: {
                id: 'custom_field_dateOfBirth',
                defaultValue: data.date,
                initialFocusedDate: moment().subtract(21, 'years'),
                minDate: moment().year(1900),
                maxDate: moment().subtract(18, 'years'),
                openTo: 'year',
                disableFuture: true,
                views: ['year', 'month', 'date'],
            },
            hidden: !config.preDepositFlags['dateOfBirth'],
        },
        {
            id: 'location',
            type: FormComponents.googleLocationAutocomplete,
            placeholderT: 'prop:customer_details_form.location.placeholder',
            labelT: 'prop:customer_details_form.location.label',
            googleLocationAutocompleteDefaultObject: data.userAddressObject,
            googleLocationAutocompleteProps: {
                defaultValue: data.location,
                countriesList: data.countriesList,
            },
            hidden:
                !config.preDepositFlags['address'] || (config.preDepositFlags['acceptTerms'] && data?.location !== ''),
        },
    ];
};

export const preDepositFormValidationsScheme: (
    t: TFunction,
    phoneNumber?: string,
    userFullAddress?: string
) => ValidationScheme = (t: TFunction, phoneNumber?: string) => ({
    phoneCountryCode: config.preDepositFlags['phoneNumber'] && !phoneNumber && Yup.string().trim().required(),
    phoneNumber: config.preDepositFlags['phoneNumber'] && !phoneNumber && Yup.string().trim().required().phone(),
    custom_field_dateOfBirth: config.preDepositFlags['dateOfBirth'] && Yup.date().required().date18Plus(),
    location: config.preDepositFlags['address'] && Yup.object().required('invalid address').fullAddress(),
});
