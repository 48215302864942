import { IconsType } from 'components/Icon';

export interface GoogleLocationAutocompletePayload {
    country?: string;
    country_short?: string;
    city?: string;
    address?: string;
    addressNumber?: string;
    postalCode?: string;
    state?: string;
}

export interface GoogleLocationAutocompleteProps {
    variant?: 'standard' | 'outlined' | 'filled';
    label?: React.ReactNode | string;
    id?: string;
    disabled?: boolean;
    placeholder?: string;
    loading?: boolean;
    error?: boolean;
    helperText?: string;
    startIcon?: IconsType;
    endIcon?: IconsType;
    defaultValue?: string;
    defaultValueObject?: GoogleLocationAutocompletePayload;
    countryRestrictions?: Array<string>;
    onChange?: (payload: GoogleLocationAutocompletePayload) => void;
}

export enum GoogleComponentsTypes {
    street_number = 'street_number',
    route = 'route',
    locality = 'locality',
    postal_town = 'postal_town',
    administrative_area_level_1 = 'administrative_area_level_1',
    administrative_area_level_2 = 'administrative_area_level_2',
    administrative_area_level_3 = 'administrative_area_level_3',
    administrative_area_level_4 = 'administrative_area_level_4',
    administrative_area_level_5 = 'administrative_area_level_5',
    country = 'country',
    postal_code = 'postal_code',
    premise = 'premise',
}

export interface GoogleResult {
    address_components?: Array<{
        long_name: string;
        short_name: string;
        types: Array<GoogleComponentsTypes>;
    }>;
    formatted_address?: string;
}
