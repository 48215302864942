import { makeStyles } from '@material-ui/core';

export const propStatusStyles = makeStyles((theme) => ({
    statusChipActive: {
        color: theme.palette.type === 'dark' ? theme.palette.blueVariants[500] : theme.palette.primary.main,
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.blueVariants[50],
        border:
            theme.palette.type === 'dark'
                ? `solid 1px ${theme.palette.blueVariants[500]}`
                : `solid 1px ${theme.palette.blueVariants[50]}`,
    },
    statusChipEnded: {
        color: theme.palette.type === 'dark' ? theme.palette.errorVariants[500] : theme.palette.error.main,
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.errorVariants[50],
        border:
            theme.palette.type === 'dark'
                ? `solid 1px ${theme.palette.errorVariants[500]}`
                : `solid 1px ${theme.palette.errorVariants[50]}`,
    },
    statusChipPending: {
        color: theme.palette.type === 'dark' ? theme.palette.warning[500] : theme.palette.warning.main,
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.warning[50],
        border:
            theme.palette.type === 'dark'
                ? `solid 1px ${theme.palette.warning[500]}`
                : `solid 1px ${theme.palette.warning[50]}`,
    },
    statusChipPassed: {
        color: theme.palette.type === 'dark' ? theme.palette.success[500] : theme.palette.success.main,
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.success[50],
        border:
            theme.palette.type === 'dark'
                ? `solid 1px ${theme.palette.success[500]}`
                : `solid 1px ${theme.palette.success[50]}`,
    },
    statusChipExpired: {
        color: theme.palette.type === 'dark' ? theme.palette.error[500] : theme.palette.error.main,
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.error[50],
        border:
            theme.palette.type === 'dark'
                ? `solid 1px ${theme.palette.error[500]}`
                : `solid 1px ${theme.palette.error[50]}`,
    },
    statusActive: {
        color: theme.palette.primary.main,
    },
    statusEnded: {
        color: theme.palette.error.main,
    },
    statusPending: {
        color: theme.palette.warning.main,
    },
    statusPassed: {
        color: theme.palette.success.main,
    },
    statusExpired: {
        color: theme.palette.error[700],
    },
}));
