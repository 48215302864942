/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CreditCardInfo } from './credit-card-info';
import { CurrencyInfo } from './currency-info';
import { PickListInfo } from './pick-list-info';
import { WebMoneyInfo } from './web-money-info';
import { WireTransferInfo } from './wire-transfer-info';

/**
 *
 * @export
 * @interface MonetaryTransaction
 */
export interface MonetaryTransaction {
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    creationTime?: string;
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    type?: MonetaryTransactionTypeEnum;
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    internalType?: MonetaryTransactionInternalTypeEnum;
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    paymentMethod?: MonetaryTransactionPaymentMethodEnum;
    /**
     *
     * @type {number}
     * @memberof MonetaryTransaction
     */
    amount?: number;
    /**
     *
     * @type {CurrencyInfo}
     * @memberof MonetaryTransaction
     */
    currency?: CurrencyInfo;
    /**
     *
     * @type {number}
     * @memberof MonetaryTransaction
     */
    originalAmount?: number;
    /**
     *
     * @type {PickListInfo}
     * @memberof MonetaryTransaction
     */
    originalCurrency?: PickListInfo;
    /**
     *
     * @type {number}
     * @memberof MonetaryTransaction
     */
    baseCurrencyAmount?: number;
    /**
     *
     * @type {number}
     * @memberof MonetaryTransaction
     */
    exchangeRate?: number;
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    tradingPlatformAccountName?: string;
    /**
     *
     * @type {number}
     * @memberof MonetaryTransaction
     */
    usdValue?: number;
    /**
     *
     * @type {CurrencyInfo}
     * @memberof MonetaryTransaction
     */
    baseCurrency?: CurrencyInfo;
    /**
     *
     * @type {PickListInfo}
     * @memberof MonetaryTransaction
     */
    creaditCardType?: PickListInfo;
    /**
     *
     * @type {boolean}
     * @memberof MonetaryTransaction
     */
    approved?: boolean;
    /**
     *
     * @type {CreditCardInfo}
     * @memberof MonetaryTransaction
     */
    creditCardDetails?: CreditCardInfo;
    /**
     *
     * @type {WireTransferInfo}
     * @memberof MonetaryTransaction
     */
    wireTransferDetails?: WireTransferInfo;
    /**
     *
     * @type {WebMoneyInfo}
     * @memberof MonetaryTransaction
     */
    webMoneyDetails?: WebMoneyInfo;
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    relatedTransactionId?: string;
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    affiliateTransactionId?: string;
    /**
     *
     * @type {PickListInfo}
     * @memberof MonetaryTransaction
     */
    internalTransactionStatus?: PickListInfo;
    /**
     *
     * @type {number}
     * @memberof MonetaryTransaction
     */
    convertedAmount?: number;
    /**
     *
     * @type {number}
     * @memberof MonetaryTransaction
     */
    conversionRate?: number;
    /**
     *
     * @type {number}
     * @memberof MonetaryTransaction
     */
    commission?: number;
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    oppositeAccountId?: string;
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    approvedOn?: string;
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    internalComment?: string;
    /**
     *
     * @type {PickListInfo}
     * @memberof MonetaryTransaction
     */
    withdrawalReason?: PickListInfo;
    /**
     *
     * @type {string}
     * @memberof MonetaryTransaction
     */
    typeOfFee?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MonetaryTransactionTypeEnum {
    Deposit = 'Deposit',
    DepositCancelled = 'DepositCancelled',
    CreditLine = 'CreditLine',
    CreditLineCancelled = 'CreditLineCancelled',
    Bonus = 'Bonus',
    BonusCancelled = 'BonusCancelled',
    ChargeBack = 'ChargeBack',
    ChargeBackCancelled = 'ChargeBackCancelled',
    Withdrawal = 'Withdrawal',
    WithdrawalCancelled = 'WithdrawalCancelled',
    AffiliatePaymentToTradingPlatform = 'AffiliatePaymentToTradingPlatform',
    AffiliatePaymentToTradingPlatformCancelled = 'AffiliatePaymentToTradingPlatformCancelled',
    TransferBetweenTradingPlatformAccounts = 'TransferBetweenTradingPlatformAccounts',
    TransferBetweenTradingPlatformAccountsCancelled = 'TransferBetweenTradingPlatformAccountsCancelled',
    Credit = 'Credit',
    CreditCancelled = 'CreditCancelled',
    Debit = 'Debit',
    DebitCancelled = 'DebitCancelled',
    InactivityFee = 'InactivityFee',
    InactivityFeeCancelled = 'InactivityFeeCancelled',
    OnRamp = 'OnRamp',
    OffRamp = 'OffRamp',
}
/**
 * @export
 * @enum {string}
 */
export enum MonetaryTransactionInternalTypeEnum {
    TransferToAccount = 'TransferToAccount',
    NewAccount = 'NewAccount',
    Retention = 'Retention',
    SpecialPromotion = 'SpecialPromotion',
    SetBalanceToZero = 'SetBalanceToZero',
    DueToTechIssues = 'DueToTechIssues',
    ManagedAccountFees = 'ManagedAccountFees',
    ReferAFriend = 'ReferAFriend',
    Other = 'Other',
}
/**
 * @export
 * @enum {string}
 */
export enum MonetaryTransactionPaymentMethodEnum {
    Internal = 'Internal',
    CreditCard = 'CreditCard',
    WireTransfer = 'WireTransfer',
    Cash = 'Cash',
    Cheque = 'Cheque',
    MoneyBookers = 'MoneyBookers',
    Neteller = 'Neteller',
    Paypal = 'Paypal',
    WebMoney = 'WebMoney',
    WesternUnion = 'WesternUnion',
    EWallet = 'EWallet',
    KashU = 'KashU',
    Crypto = 'Crypto',
}
