/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ResultInfo
 */
export interface ResultInfo {
    /**
     *
     * @type {string}
     * @memberof ResultInfo
     */
    requestId?: string;
    /**
     *
     * @type {string}
     * @memberof ResultInfo
     */
    code?: ResultInfoCodeEnum;
    /**
     *
     * @type {string}
     * @memberof ResultInfo
     */
    message?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ResultInfoCodeEnum {
    Success = 'Success',
    UserAlreadyExist = 'UserAlreadyExist',
    AccountNotExist = 'AccountNotExist',
    TradingPlatformAccountNotExist = 'TradingPlatformAccountNotExist',
    TradingPlatformNotExist = 'TradingPlatformNotExist',
    ArgumentsValidationFailed = 'ArgumentsValidationFailed',
    NotEnoughMoney = 'NotEnoughMoney',
    DepositForDemoAccountNotAllowed = 'DepositForDemoAccountNotAllowed',
    WithdrawalForDemoAccountNotAllowed = 'WithdrawalForDemoAccountNotAllowed',
    MonetaryTransactionAlreadyApproved = 'MonetaryTransactionAlreadyApproved',
    RequiredFieldIsEmpty = 'RequiredFieldIsEmpty',
    InvalidCrmConfiguration = 'InvalidCRMConfiguration',
    DeletionFailed = 'DeletionFailed',
    SuccessWithDuplicates = 'SuccessWithDuplicates',
    FailedToCreateTransaction = 'FailedToCreateTransaction',
    SuccessWithIssues = 'SuccessWithIssues',
    ProblemWithTransactionApproval = 'ProblemWithTransactionApproval',
    FailedCreatingUserOnTradingPlatform = 'FailedCreatingUserOnTradingPlatform',
    AccountRegistrationFailed = 'AccountRegistrationFailed',
    SuccessWithClientVerificationIssues = 'SuccessWithClientVerificationIssues',
    SuccessWithDuplicatesAndIssues = 'SuccessWithDuplicatesAndIssues',
    FailedGettingAccountDetails = 'FailedGettingAccountDetails',
    Failed = 'Failed',
    MultipleResultFound = 'MultipleResultFound',
}
