/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CrmSSOLoginRequest } from '../models';
// @ts-ignore
import { CrmSSOLoginResponse } from '../models';
// @ts-ignore
import { ForgotPasswordRequest } from '../models';
// @ts-ignore
import { GetIpDataResponse } from '../models';
// @ts-ignore
import { LocalRegisterRequest } from '../models';
// @ts-ignore
import { LoginRequest } from '../models';
// @ts-ignore
import { LoginResponse } from '../models';
// @ts-ignore
import { RegisterResponse } from '../models';
// @ts-ignore
import { ResetPasswordRequest } from '../models';
// @ts-ignore
import { RestPasswordResponse } from '../models';
// @ts-ignore
import { SocialRegisterRequest } from '../models';
// @ts-ignore
import { TryRegisterLeadAccountRequest } from '../models';
// @ts-ignore
import { TryRegisterLeadAccountResponse } from '../models';
// @ts-ignore
import { WtSSOLoginRequest } from '../models';
// @ts-ignore
import { WtSSOLoginResponse } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CrmSSOLoginRequest} crmSSOLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCrmSSOLogin: async (
            crmSSOLoginRequest: CrmSSOLoginRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'crmSSOLoginRequest' is not null or undefined
            assertParamExists('authControllerCrmSSOLogin', 'crmSSOLoginRequest', crmSSOLoginRequest);
            const localVarPath = `/api/auth/crmSSOlogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                crmSSOLoginRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ForgotPasswordRequest} forgotPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword: async (
            forgotPasswordRequest: ForgotPasswordRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordRequest' is not null or undefined
            assertParamExists('authControllerForgotPassword', 'forgotPasswordRequest', forgotPasswordRequest);
            const localVarPath = `/api/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                forgotPasswordRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetIp: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/ip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LocalRegisterRequest} localRegisterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLocalRegister: async (
            localRegisterRequest: LocalRegisterRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'localRegisterRequest' is not null or undefined
            assertParamExists('authControllerLocalRegister', 'localRegisterRequest', localRegisterRequest);
            const localVarPath = `/api/auth/local-register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                localRegisterRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginRequest} loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginRequest: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('authControllerLogin', 'loginRequest', loginRequest);
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ResetPasswordRequest} resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRestPassword: async (
            resetPasswordRequest: ResetPasswordRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('authControllerRestPassword', 'resetPasswordRequest', resetPasswordRequest);
            const localVarPath = `/api/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                resetPasswordRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SocialRegisterRequest} socialRegisterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSocialRegister: async (
            socialRegisterRequest: SocialRegisterRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'socialRegisterRequest' is not null or undefined
            assertParamExists('authControllerSocialRegister', 'socialRegisterRequest', socialRegisterRequest);
            const localVarPath = `/api/auth/social-register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                socialRegisterRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {TryRegisterLeadAccountRequest} tryRegisterLeadAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTryRegisterLeadAccount: async (
            tryRegisterLeadAccountRequest: TryRegisterLeadAccountRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'tryRegisterLeadAccountRequest' is not null or undefined
            assertParamExists(
                'authControllerTryRegisterLeadAccount',
                'tryRegisterLeadAccountRequest',
                tryRegisterLeadAccountRequest
            );
            const localVarPath = `/api/auth/tryRegisterLeadAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                tryRegisterLeadAccountRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {WtSSOLoginRequest} wtSSOLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerWtSSOLogin: async (
            wtSSOLoginRequest: WtSSOLoginRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'wtSSOLoginRequest' is not null or undefined
            assertParamExists('authControllerWtSSOLogin', 'wtSSOLoginRequest', wtSSOLoginRequest);
            const localVarPath = `/api/auth/wtSSOlogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                wtSSOLoginRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CrmSSOLoginRequest} crmSSOLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCrmSSOLogin(
            crmSSOLoginRequest: CrmSSOLoginRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmSSOLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCrmSSOLogin(
                crmSSOLoginRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ForgotPasswordRequest} forgotPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerForgotPassword(
            forgotPasswordRequest: ForgotPasswordRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerForgotPassword(
                forgotPasswordRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetIp(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIpDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGetIp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {LocalRegisterRequest} localRegisterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLocalRegister(
            localRegisterRequest: LocalRegisterRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLocalRegister(
                localRegisterRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {LoginRequest} loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(
            loginRequest: LoginRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ResetPasswordRequest} resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRestPassword(
            resetPasswordRequest: ResetPasswordRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRestPassword(
                resetPasswordRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SocialRegisterRequest} socialRegisterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSocialRegister(
            socialRegisterRequest: SocialRegisterRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSocialRegister(
                socialRegisterRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {TryRegisterLeadAccountRequest} tryRegisterLeadAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerTryRegisterLeadAccount(
            tryRegisterLeadAccountRequest: TryRegisterLeadAccountRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TryRegisterLeadAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerTryRegisterLeadAccount(
                tryRegisterLeadAccountRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {WtSSOLoginRequest} wtSSOLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerWtSSOLogin(
            wtSSOLoginRequest: WtSSOLoginRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WtSSOLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerWtSSOLogin(
                wtSSOLoginRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration);
    return {
        /**
         *
         * @param {CrmSSOLoginRequest} crmSSOLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCrmSSOLogin(
            crmSSOLoginRequest: CrmSSOLoginRequest,
            options?: any
        ): AxiosPromise<CrmSSOLoginResponse> {
            return localVarFp
                .authControllerCrmSSOLogin(crmSSOLoginRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ForgotPasswordRequest} forgotPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword(
            forgotPasswordRequest: ForgotPasswordRequest,
            options?: any
        ): AxiosPromise<boolean> {
            return localVarFp
                .authControllerForgotPassword(forgotPasswordRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetIp(options?: any): AxiosPromise<GetIpDataResponse> {
            return localVarFp.authControllerGetIp(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LocalRegisterRequest} localRegisterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLocalRegister(
            localRegisterRequest: LocalRegisterRequest,
            options?: any
        ): AxiosPromise<RegisterResponse> {
            return localVarFp
                .authControllerLocalRegister(localRegisterRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LoginRequest} loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.authControllerLogin(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ResetPasswordRequest} resetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRestPassword(
            resetPasswordRequest: ResetPasswordRequest,
            options?: any
        ): AxiosPromise<RestPasswordResponse> {
            return localVarFp
                .authControllerRestPassword(resetPasswordRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SocialRegisterRequest} socialRegisterRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSocialRegister(
            socialRegisterRequest: SocialRegisterRequest,
            options?: any
        ): AxiosPromise<RegisterResponse> {
            return localVarFp
                .authControllerSocialRegister(socialRegisterRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {TryRegisterLeadAccountRequest} tryRegisterLeadAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTryRegisterLeadAccount(
            tryRegisterLeadAccountRequest: TryRegisterLeadAccountRequest,
            options?: any
        ): AxiosPromise<TryRegisterLeadAccountResponse> {
            return localVarFp
                .authControllerTryRegisterLeadAccount(tryRegisterLeadAccountRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {WtSSOLoginRequest} wtSSOLoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerWtSSOLogin(
            wtSSOLoginRequest: WtSSOLoginRequest,
            options?: any
        ): AxiosPromise<WtSSOLoginResponse> {
            return localVarFp
                .authControllerWtSSOLogin(wtSSOLoginRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     *
     * @param {CrmSSOLoginRequest} crmSSOLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCrmSSOLogin(crmSSOLoginRequest: CrmSSOLoginRequest, options?: any) {
        return AuthApiFp(this.configuration)
            .authControllerCrmSSOLogin(crmSSOLoginRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ForgotPasswordRequest} forgotPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerForgotPassword(forgotPasswordRequest: ForgotPasswordRequest, options?: any) {
        return AuthApiFp(this.configuration)
            .authControllerForgotPassword(forgotPasswordRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerGetIp(options?: any) {
        return AuthApiFp(this.configuration)
            .authControllerGetIp(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LocalRegisterRequest} localRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLocalRegister(localRegisterRequest: LocalRegisterRequest, options?: any) {
        return AuthApiFp(this.configuration)
            .authControllerLocalRegister(localRegisterRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(loginRequest: LoginRequest, options?: any) {
        return AuthApiFp(this.configuration)
            .authControllerLogin(loginRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRestPassword(resetPasswordRequest: ResetPasswordRequest, options?: any) {
        return AuthApiFp(this.configuration)
            .authControllerRestPassword(resetPasswordRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SocialRegisterRequest} socialRegisterRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSocialRegister(socialRegisterRequest: SocialRegisterRequest, options?: any) {
        return AuthApiFp(this.configuration)
            .authControllerSocialRegister(socialRegisterRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {TryRegisterLeadAccountRequest} tryRegisterLeadAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerTryRegisterLeadAccount(
        tryRegisterLeadAccountRequest: TryRegisterLeadAccountRequest,
        options?: any
    ) {
        return AuthApiFp(this.configuration)
            .authControllerTryRegisterLeadAccount(tryRegisterLeadAccountRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {WtSSOLoginRequest} wtSSOLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerWtSSOLogin(wtSSOLoginRequest: WtSSOLoginRequest, options?: any) {
        return AuthApiFp(this.configuration)
            .authControllerWtSSOLogin(wtSSOLoginRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
