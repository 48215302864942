import { makeStyles } from '@material-ui/core';
import { customGap } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    wrapper: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        zIndex: 10000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    popupWrapper: {
        width: '70rem',
        maxWidth: '90%',
        maxHeight: '90%',
        borderRadius: `${theme.spacing(2)}rem`,
        background: theme.palette.background.paper,
        padding: `${theme.spacing(6)}rem ${theme.spacing(6)}rem`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        ...customGap(theme.spacing(6), 'vertical'),
    },
}));
