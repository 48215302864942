import { ESystemTypes } from 'store/system/actionsTypes';
import { EREVERSESOLICITATIONTypes } from './actionsTypes';
import { ReverseSolicatationState } from './interface';

const initialState: ReverseSolicatationState = {
    updateLoading: false,
};

const ReverseSolicitationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EREVERSESOLICITATIONTypes.UPDATE_DATA: {
            return {
                ...state,
                updateLoading: true,
            };
        }
        case ESystemTypes.RESET_APP:
        case EREVERSESOLICITATIONTypes.UPDATE_DATA_FAILED:
        case EREVERSESOLICITATIONTypes.UPDATE_DATA_SUCCESS: {
            return {
                ...state,
                updateLoading: false,
            };
        }
        default:
            return state;
    }
};

export default ReverseSolicitationReducer;
