import React from 'react';
import { auth_hasJwtToken, auth_loader } from 'store/auth/selectors';
import { user_emailVerified, user_phoneVerified, user_verified } from 'store/user/selectors';
import { connect } from 'react-redux';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { Pages } from '../../interfaces/main';
import { deleteTokenFromLocalStorage } from '../../helpers/token';
import { deleteLastSelectedTp } from '../../helpers/lastSelectedTP';

export interface RegisterVerifyRouteProps {
    component: any;
    hasJwtToken: boolean;
    userVerified: boolean;
    phoneVerified: boolean;
    emailVerified: boolean;
    loginLoading: boolean;
}

export const mapStateToProps = (state) => ({
    hasJwtToken: auth_hasJwtToken(state),
    userVerified: user_verified(state),
    phoneVerified: user_phoneVerified(state),
    emailVerified: user_emailVerified(state),
    loginLoading: auth_loader(state),
});

export const mapDispatchToProps = {};

const RegisterVerifyRoute: React.FunctionComponent<RegisterVerifyRouteProps> = (props) => {
    const {
        component: Component,
        hasJwtToken,
        userVerified,
        phoneVerified,
        emailVerified,
        loginLoading,
        ...restProps
    } = props;

    const { pathname } = useLocation();
    let history = useHistory();

    // if is phone - requiered jwt
    if (!hasJwtToken && !loginLoading) {
        deleteTokenFromLocalStorage();
        deleteLastSelectedTp();
        history.push(`/${Pages.LOGIN}`);
        return null;
    }

    // const redirect = config.redirectAfterRegister || Pages.DASHBOARD;
    if (userVerified) {
        history.push('/');
        return null;
    }

    if (phoneVerified && pathname === `/${Pages.PHONE_VERIFICATION}`) {
        console.log('phoneVerified guard!!!');
        history.push(`/${Pages.EMAIL_VERIFICATION}`);
        return null;
    }

    if (emailVerified && pathname === `/${Pages.EMAIL_VERIFICATION}`) {
        console.log('emailVerified guard!!!');
        history.push(`/${Pages.PHONE_VERIFICATION}`);
        return null;
    }

    return <Route {...restProps} render={(props) => <Component {...props} />} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(RegisterVerifyRoute));
