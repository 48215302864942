import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    container: { width: '100%', display: 'flex', flexDirection: 'column', gap: 16 },
    select: { maxWidth: '100%' },
    wrapper: {
        background: theme.palette.grayVariants[200],
        border: `1px solid ${theme.palette.grayVariants[300]}`,
        borderRadius: '0.8rem',
        padding: '1.2rem 0.4rem',
    },
    textfieldWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        color: 'red !important',
    },
}));
