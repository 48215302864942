export enum ESystemTypes {
    START_LOADER = '[system] start loader',
    STOP_LOADER = '[system] stop loader',
    TOGGLE_THEME = '[system] toggle theme',
    TOGGLE_SIDE_MENU = '[system] toogle side menu',
    GET_COUNTRIES_START = '[system] get countries start',
    GET_COUNTRIES_SUCCESS = '[system] get countries success',
    GET_COUNTRIES_FAIL = '[system] get countries fail',
    GET_CURRENCIES_START = '[system] get currencies start',
    GET_CURRENCIES_SUCCESS = '[system] get currencies success',
    GET_CURRENCIES_FAIL = '[system] get currencies fail',
    RESET_APP = '[system] reset app',
    OPEN_SNACKBAR = '[system] open message snackbar',
    CLOSE_SNACKBAR = '[system] close message snackbar',
    DOWNLOAD_CSV = '[system] download csv',
    TOGGLE_FILTER_STATE = '[system] toggle filter state',
    UPDATE_FILTERS = '[system] update filters',
    SCROLL_TO_TABLE = '[system] scroll to table',
    SET_SELECTED_TIME_FRAME = '[system] set selected timeframe',
    SET_CHART_TYPE = '[system] set chart type',
    COMPLETED_WALK_THROUGH = '[system] completed walk through',
    TOGGLE_BOTTOM_NAV_BAR = '[system] toggle bottom nav bar',
    DEVICE_SIZE = '[system] device size',
    TOGGLE_EDUCATION_POPUP = '[system] toggle education popup',
}
