import config from 'config/common';
import { ThemeOptions } from 'interfaces/main';

export const getThemeFromLocalStorage: () => any = () =>
    localStorage.getItem(config.theme.local_storage_key) || config.theme.default_theme;

export const setThemeToLocalStorage = (theme: ThemeOptions) =>
    localStorage.setItem(config.theme.local_storage_key, theme);

export const getRenewPlatformUser = () => {
    return Boolean(sessionStorage.getItem(config.platformRenewUserKey));
};

export const setRenewPlatformUserToSS = () => {
    return sessionStorage.setItem(config.platformRenewUserKey, 'true');
};

export const removeRenewPlatformUserToSS = () => {
    return sessionStorage.removeItem(config.platformRenewUserKey);
};
