import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { Pages } from 'interfaces/main';

export interface NotificationsRouterProps {
    relativePath?: string;
    path?: string;
    exact?: boolean;
    component: any;
}

const routes: Array<NotificationsRouterProps> = [
    {
        component: React.lazy(() => import('features/Notifications/scenes/Notifications')),
        relativePath: `/:messageId?`,
        exact: true,
    },
    {
        component: () => <Redirect to={`/${Pages.NOTIFICATIONS}`} />,
    },
];

const NotificationsRouter = (props) => {
    return (
        <Switch>
            {routes.map((route, index) => {
                route.path = props.match.path + (route.relativePath || '');
                let tempRoute = Object.assign({}, route);
                return (
                    <Route
                        key={`Notification-Router-${index}`}
                        {...tempRoute}
                        render={(props) => <Component {...props} />}
                    />
                );
            })}
        </Switch>
    );
};

export default withRouter(NotificationsRouter);
