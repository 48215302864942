import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Pages } from 'interfaces/main';

const pagesNames = new Set<Pages>([Pages.PROP__CHECKOUT, Pages.PROP__NEW_CHALLENGES]);

export const useHideStartChallengeButton = () => {
    const location = useLocation();

    return useMemo(() => {
        const { pathname } = location;
        const lastPathname = pathname.replace(/\/$/, '').split('/').pop();
        return pagesNames.has(lastPathname as Pages);
    }, [location]);
};
