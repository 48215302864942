import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    dialogPaper: {
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.background.default,

        boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
        borderRadius: '24px',
        width: '350px',
        marginLeft: '16px',
        marginRight: '16px',
    },
    imgRocket: {
        width: '100%',
    },
    dialogTitle: {
        color: theme.palette.grayVariants[700],
        fontSize: '1.6rem',
        textAlign: 'center',
        paddingTop: '15px',
        fontWeight: 800,
    },
    dialogSubTitle: {
        color: theme.palette.grayVariants[500],
        fontSize: '1.4rem',
        textAlign: 'center',
        fontWeight: 400,
    },
    closeButton: {
        // backgroundColor: theme.palette.grayVariants[300],
        // backgroundColor: theme.palette.grayVariants[200],
        // color: theme.palette.grayVariants[900],
        color: theme.palette.grayVariants[600],
        backgroundColor: theme.palette.type === 'dark' ? '#101828' : '#EAECF0',
        fontSize: '1,6rem',
        fontWeight: 700,
        marginBottom: '28px',
        marginTop: '10px',
        marginRight: '68px',
        marginLeft: '68px',
        borderRadius: '8px',
        // border: `solid 1px ${theme.palette.grayVariants[300]}`,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grayVariants[300],
        },
    },
}));
