export enum ENotificationsTypes {
    GET_ALL_NOTIFICATIONS_START = '[notifications] get all notifications start',
    GET_ALL_NOTIFICATIONS_SUCCESS = '[notifications] get all notifications success',
    GET_ALL_NOTIFICATIONS_FAILED = '[notifications] get all notifications failed',

    GET_MORE_NOTIFICATIONS_START = '[notifications] get more notifications start',
    GET_MORE_NOTIFICATIONS_SUCCESS = '[notifications] get more notifications success',
    GET_MORE_NOTIFICATIONS_FAILED = '[notifications] get more notifications failed',

    GET_SPECIFIC_NOTIFICATION_START = '[notifications] get specific notification start',
    GET_SPECIFIC_NOTIFICATION_SUCCESS = '[notifications] get specific notification success',
    GET_SPECIFIC_NOTIFICATION_FAILED = '[notifications] get specific notification failed',

    DELETE_NOTIFICATION_START = '[notifications] delete notification start',
    DELETE_NOTIFICATION_SUCCESS = '[notifications] delete notification succcess',
    DELETE_NOTIFICATION_FAILED = '[notifications] delete notification failed',

    MARK_NOTIFICATIONS = '[notifications] mark notifications',

    NEW_MESSAGE = '[notifications] new message',
}
