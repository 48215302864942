import * as Yup from 'yup';
import { latinOnlyRegex, phoneRegex, emailRegex, emailRegexRegister, cardLast4DigitsRegex } from 'helpers/regex';
import i18n from '../i18n';
import { dateFilter } from 'helpers/dateFilter';
import moment from 'moment';
import config from 'config/common';

Yup.setLocale({
    mixed: {
        default: (args) =>
            i18n.t('validations.mixed.default', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        required: (args) =>
            i18n.t('validations.mixed.required', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        oneOf: (args) =>
            i18n.t('validations.mixed.oneOf', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        notOneOf: (args) =>
            i18n.t('validations.mixed.notOneOf', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
    },
    string: {
        length: (args) =>
            i18n.t('validations.string.length', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        min: (args) => i18n.t('validations.string.min', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        max: (args) => i18n.t('validations.string.max', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        matches: (args) =>
            i18n.t('validations.string.matches', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        email: (args) =>
            i18n.t('validations.string.email', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        url: (args) => i18n.t('validations.string.url', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        uuid: (args) => i18n.t('validations.string.uuid', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        trim: (args) => i18n.t('validations.string.trim', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        lowercase: (args) =>
            i18n.t('validations.string.lowercase', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        uppercase: (args) =>
            i18n.t('validations.string.uppercase', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
    },
    number: {
        min: (args) => i18n.t('validations.number.min', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        max: (args) => i18n.t('validations.number.max', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        lessThan: (args) =>
            i18n.t('validations.number.lessThan', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        moreThan: (args) =>
            i18n.t('validations.number.moreThan', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        positive: (args) =>
            i18n.t('validations.number.positive', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        negative: (args) =>
            i18n.t('validations.number.negative', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        integer: (args) =>
            i18n.t('validations.number.integer', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
    },
    date: {
        min: (args) =>
            i18n.t('validations.date.min', {
                ...args,
                min: dateFilter(args.min),
                path: i18n.t(`validations.fields.${args.path}`),
            }),
        max: (args) =>
            i18n.t('validations.date.max', {
                ...args,
                max: dateFilter(args.max),
                path: i18n.t(`validations.fields.${args.path}`),
            }),
    },
    object: {
        noUnknown: (args) =>
            i18n.t('validations.object.noUnknown', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
    },
    array: {
        min: (args) => i18n.t('validations.array.min', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
        max: (args) => i18n.t('validations.array.max', { ...args, path: i18n.t(`validations.fields.${args.path}`) }),
    },
});

export enum PasswordErrors {
    LEN = 'length',
    DIGIT = 'digit',
    ULC = 'ulc',
    SYMBOL = 'symbol',
}

Yup.addMethod(Yup.string, 'password', function () {
    return Yup.string().test(`password`, '', function (value) {
        const { path, createError } = this;
        const testPasswordLength = value && value.length >= 8 && value.length <= 15;
        const testPasswordHaveDigits = value?.match(/[0-9]/);
        const testPasswordUpperLower = value?.match(/(?=.*[A-Z])(?=.*[a-z]).+/);
        const testPasswordHaveSymbols = value?.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
        let error = '';

        if (!testPasswordLength) {
            error += `,${PasswordErrors.LEN}`;
        }
        if (!testPasswordHaveDigits) {
            error += `,${PasswordErrors.DIGIT}`;
        }
        if (!testPasswordUpperLower) {
            error += `,${PasswordErrors.ULC}`;
        }
        if (!testPasswordHaveSymbols && config.featuresFlags['passwordWithSpecialCharacters']) {
            error += `,${PasswordErrors.SYMBOL}`;
        }

        return (
            (value && !error) ||
            createError({
                path,
                message: error,
            })
        );
    });
});

Yup.addMethod(Yup.string, 'cardLast4Digits', function () {
    return Yup.string()
        .nullable(true)
        .transform((value) => (value === '' ? null : value))
        .test(`cardLast4Digits`, '', function (value) {
            const { path, createError } = this;
            return (
                (value && cardLast4DigitsRegex.test(value)) ||
                value === null ||
                createError({
                    path,
                    message: i18n.t('validations.string.cardLast4Digits', {
                        path: i18n.t(`validations.fields.${path}`),
                    }),
                })
            );
        });
});

Yup.addMethod(Yup.string, 'phone', function (args: { disallowLeadingZero: false }) {
    const { disallowLeadingZero } = args || '';
    const disallowLeadingZeroMsg = disallowLeadingZero ? 'disableLeadingZeroMsg' : '';

    return Yup.string()
        .max(30)
        .test(`phone`, disallowLeadingZeroMsg, function (value) {
            const { path, createError } = this;

            if (disallowLeadingZeroMsg === 'disableLeadingZeroMsg' && value && value.startsWith('0')) {
                return createError({ path, message: i18n.t('validations.string.phoneLeadingZero') });
            }

            return (
                (value && phoneRegex.test(value) && value.length > 3) ||
                createError({ path, message: i18n.t('validations.string.phone') })
            );
        });
});

Yup.addMethod(Yup.string, 'latinOnly', function (disableValidation: boolean) {
    return Yup.string().test(`latinOnly`, '', function (value) {
        if (disableValidation) return true;
        const { path, createError } = this;
        return (
            (value && latinOnlyRegex.test(value)) ||
            createError({
                path,
                message: i18n.t('validations.string.latinOnly', { path: i18n.t(`validations.fields.${path}`) }),
            })
        );
    });
});

Yup.addMethod(Yup.string, 'fixedEmail', function (args: { type: 'register' }) {
    const { type } = args || '';

    return Yup.string()
        .max(90)
        .test(`fixedEmail`, type, function (value) {
            const { path, createError } = this;
            let emailRegexPattern = type === 'register' ? emailRegexRegister : emailRegex;
            return (
                (value && emailRegexPattern.test(value)) ||
                createError({
                    path,
                    message: i18n.t('validations.string.email', { path: i18n.t(`validations.fields.${path}`) }),
                })
            );
        });
});

Yup.addMethod(Yup.object, 'fullAddress', function () {
    return Yup.mixed().test(`fullAddress`, '', function (value: any) {
        const { path, createError } = this;
        const { address, city, country, postalCode } = value || {};
        const isValid =
            !!address &&
            !!city &&
            !!country &&
            postalCode.length > 2 &&
            postalCode.length < 21 &&
            address.length < 251 &&
            city.length < 81;
        return isValid || createError({ path, message: i18n.t('validations.object.fullAddress') });
    });
});

Yup.addMethod(Yup.date, 'date18Plus', function () {
    return Yup.mixed().test(`date18Plus`, '', function (value: any) {
        const { path, createError } = this;
        const year = new Date(value).getFullYear();
        const currentYear = new Date().getFullYear();

        if (!value || isNaN(year)) {
            return createError({ path, message: i18n.t('validations.date.not_valid') });
        }

        if (currentYear - 18 >= year) {
            return true;
        }
        return createError({ path, message: i18n.t('validations.date.18plus') });
    });
});

declare module 'yup' {
    interface StringSchema {
        password(): StringSchema;

        phone(args?: object): StringSchema;

        latinOnly(disableValidation?: boolean): StringSchema;

        fixedEmail(args?: object): StringSchema;

        cardLast4Digits(args?: object): StringSchema;
    }

    interface ObjectSchema {
        fullAddress(): Yup.ObjectSchema;
    }

    interface DateSchema {
        date18Plus(): Yup.DateSchema;
    }
}

export default Yup;
