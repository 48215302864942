/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccessTokenRequest } from '../models';
// @ts-ignore
import { AccessTokenResponse } from '../models';
/**
 * SumsubApi - axios parameter creator
 * @export
 */
export const SumsubApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {AccessTokenRequest} accessTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sumsubControllerGetAccessTokenAndFlow: async (
            accessTokenRequest: AccessTokenRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'accessTokenRequest' is not null or undefined
            assertParamExists('sumsubControllerGetAccessTokenAndFlow', 'accessTokenRequest', accessTokenRequest);
            const localVarPath = `/api/sumsub/getAccessTokenAndFlow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                accessTokenRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SumsubApi - functional programming interface
 * @export
 */
export const SumsubApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = SumsubApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {AccessTokenRequest} accessTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sumsubControllerGetAccessTokenAndFlow(
            accessTokenRequest: AccessTokenRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sumsubControllerGetAccessTokenAndFlow(
                accessTokenRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * SumsubApi - factory interface
 * @export
 */
export const SumsubApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SumsubApiFp(configuration);
    return {
        /**
         *
         * @param {AccessTokenRequest} accessTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sumsubControllerGetAccessTokenAndFlow(
            accessTokenRequest: AccessTokenRequest,
            options?: any
        ): AxiosPromise<AccessTokenResponse> {
            return localVarFp
                .sumsubControllerGetAccessTokenAndFlow(accessTokenRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * SumsubApi - object-oriented interface
 * @export
 * @class SumsubApi
 * @extends {BaseAPI}
 */
export class SumsubApi extends BaseAPI {
    /**
     *
     * @param {AccessTokenRequest} accessTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SumsubApi
     */
    public sumsubControllerGetAccessTokenAndFlow(accessTokenRequest: AccessTokenRequest, options?: any) {
        return SumsubApiFp(this.configuration)
            .sumsubControllerGetAccessTokenAndFlow(accessTokenRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
