import React, { useMemo } from 'react';
import Typography from 'components/Typography';
import UploadDocumentsDropZone from 'features/prop/components/UploadDocumentsDropZone/uploadDocumentsDropZone.render';
import useStyles from '../fundedInvestingManualKycDialog.styles';
import { UploadedFileWithType } from '../../FundedSelect';
import DialogFileTypeSelect from '../../DialogFundedSelect/dialogFundedSelect.render';
import {
    AccountInfoParsedKycVerificationStatusEnum,
    UploadFileWithDocumentTypeRequestDocumentTypeEnum,
} from 'services/generatedClientFromSwagger';
import Button from 'components/Button';

interface VerifyIdentityStepProps {
    selectedFileType: UploadFileWithDocumentTypeRequestDocumentTypeEnum | undefined;
    setSelectedFileType: React.Dispatch<
        React.SetStateAction<UploadFileWithDocumentTypeRequestDocumentTypeEnum | undefined>
    >;
    files: UploadedFileWithType[];
    setFiles: React.Dispatch<React.SetStateAction<UploadedFileWithType[]>>;
    t: (key: string) => string;
    uploadD_processLoading: boolean;
    handleNextStep: (status?: AccountInfoParsedKycVerificationStatusEnum) => void;
    disabled: boolean;
}

const VerifyIdentityStep: React.FunctionComponent<VerifyIdentityStepProps> = ({
    selectedFileType,
    setSelectedFileType,
    files,
    setFiles,
    t,
    handleNextStep,
    disabled,
}) => {
    const classes = useStyles();

    const disabledButton = useMemo(() => {
        const errorFileExists = files.some((file) => file.errorMessage !== undefined);
        return disabled || errorFileExists || !selectedFileType || files.length === 0;
    }, [disabled, files, selectedFileType]);

    return (
        <>
            <Typography className={classes.header} variant="tLgSemibold">
                {t('investingManualKyc.verifyIdentity.header')}
            </Typography>
            <Typography variant="tSmallRegular" className={classes.subTxt2}>
                {t('investingManualKyc.verifyIdentity.documentType')}
            </Typography>
            <DialogFileTypeSelect selectedFileType={selectedFileType} setSelectedFileType={setSelectedFileType} />
            <Typography variant="tSmallRegular" className={classes.subTxt2}>
                {t('investingManualKyc.verifyIdentity.uploadYourDocument')}
            </Typography>
            <UploadDocumentsDropZone files={files} setFiles={setFiles} maxSize={10} t={t} />
            <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={handleNextStep.bind(null, AccountInfoParsedKycVerificationStatusEnum.DocumentUploaded)}
                disabled={disabledButton}
                loading={disabled}
            >
                {t(`investingManualKyc.verifyIdentity.btn`)}
            </Button>
        </>
    );
};

export default VerifyIdentityStep;
