import React, { FunctionComponent } from 'react';
import { CustomAlert } from './CustomAlert.render';

export interface AlertListProps {
    items: any[];
    handleRemoveItem: (item: any) => void;
}

export const AlertList: FunctionComponent<AlertListProps> = ({ items, handleRemoveItem }) => {
    return (
        <>
            {items?.map((item) => (
                <CustomAlert {...item} key={item?.elementKey} handleRemoveItem={handleRemoveItem} />
            ))}
        </>
    );
};
