import { Theme, makeStyles } from '@material-ui/core';
import { isTablet } from 'helpers/device_helper';

interface Props {
    disabled?: boolean;
}

export default makeStyles((theme: Theme) => ({
    dropzone: (props: Props) => ({
        padding: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: props.disabled ? 'default' : 'pointer',
        border: `0.2rem solid ${theme.palette.grayVariants[200]}`,
        background: theme.palette.background.default,
        borderRadius: '0.8rem',
        opacity: props.disabled ? 0.4 : 0.8,
        ...isTablet({
            padding: '1.6rem 2.4rem',
        }),
        gap: 12,
    }),
    browseWord: {
        color: theme.palette.primary.main,
    },
}));
