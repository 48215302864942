import React from 'react';
import {
    ClickAwayListener as ClickAwayListenerMat,
    ClickAwayListenerProps as ClickAwayListenerPropsMat,
} from '@material-ui/core';

export interface ClickAwayListenerProps extends ClickAwayListenerPropsMat {
    disable?: boolean;
}

const ClickAwayListener: React.FunctionComponent<ClickAwayListenerProps> = (props) => {
    const { disable, children, ...rest } = props;
    if (disable) return <span>{children}</span>;
    return <ClickAwayListenerMat {...rest}>{children}</ClickAwayListenerMat>;
};

export default ClickAwayListener;
