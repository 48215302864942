import React from 'react';
import { connect } from 'react-redux';
import { TradingSuggestionsProps, mapDispatchToProps, mapStateToProps } from './trading-suggestions.scheme';
import useStyles from './trading-suggestions.styles';
import classNames from 'classnames';
import Icon, { IconsType } from 'components/Icon';
import moment, { Moment } from 'moment';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { numberPipe } from 'helpers/numberPipe';
import { Link } from 'react-router-dom';
import { Pages } from 'interfaces/main';
import config from 'config/common';
import mixPanel, { mixPanelEventsTypes } from 'helpers/mixPanel';
import { Trading4ProLevelEnum } from 'store/analytics/interfaces';

const TradingSuggestions: React.FunctionComponent<TradingSuggestionsProps> = (props) => {
    const { analytics_tradingSuggetions, analytics_getTradingSuggestions } = props;
    const {
        t,
        i18n: { language },
    } = useTranslation('trading-analysis');
    const wrapperRef = React.useRef<any>(null);
    const [wrapperWithScroll, setWrapperWithScroll] = React.useState(true);
    const classes = useStyles();

    React.useEffect(() => {
        if (wrapperRef && wrapperRef.current) {
            let hasScroll = false;
            try {
                hasScroll = wrapperRef.current.scrollHeight > wrapperRef.current.clientHeight;
            } catch (error) {}
            setWrapperWithScroll(hasScroll);
        }
    }, [wrapperRef, analytics_tradingSuggetions]);

    React.useEffect(() => {
        analytics_getTradingSuggestions(language);
        //eslint-disable-next-line
    }, [analytics_getTradingSuggestions, language]);

    const getDiffInDDHHMM = (from: Moment, to: Moment) => {
        let days = to.diff(from, 'days');
        from.days(from.days() + days);
        let hours = to.diff(from, 'hours');
        from.hours(from.hours() + hours);
        let minutes = to.diff(from, 'minutes');
        switch (true) {
            case days > 0:
                return t('suggestions.timeDiff.days', { days, hours, minutes });
            case hours > 0:
                return t('suggestions.timeDiff.hours', { hours, minutes });
            default:
                return t('suggestions.timeDiff.minutes', { minutes });
        }
    };

    const isBuy = (direction: number) => {
        return direction === 1;
    };

    if (analytics_tradingSuggetions.length === 0) {
        return null;
    }

    return (
        <div className={classes.wrapper}>
            <Typography variant="h4">{t('suggestions.title')}</Typography>
            <div
                className={classNames({
                    [classes.itemsWrapper]: true,
                    [classes.itemsWrapper__withScroll]: wrapperWithScroll,
                })}
                ref={wrapperRef}
            >
                {analytics_tradingSuggetions.map((item, index) => (
                    <Link
                        onClick={() => mixPanel.track(mixPanelEventsTypes.CLICK_ON_T4P_SIGNAL, item)}
                        to={`/${Pages.PLATFORMS}/graph&${config.platformT4PSignalKey}=${item.id}`}
                        key={`suggetion-${index}`}
                        className={classes.item}
                    >
                        <div
                            className={classNames({
                                [classes.itemImage]: true,
                                [classes.itemImage__buy]: isBuy(item.direction),
                                [classes.itemImage__sell]: !isBuy(item.direction),
                            })}
                        >
                            <Icon name={isBuy(item.direction) ? IconsType.suggestion_buy : IconsType.suggestion_sell} />
                        </div>
                        <div className={classes.content}>
                            <div className={classes.spaceBetweenTexts}>
                                <Typography variant="body1">{`${item.symbol} ${
                                    isBuy(item.direction) ? t('suggestions.buy') : t('suggestions.sell')
                                }`}</Typography>
                                <div className={classes.stars_wrapper}>
                                    <div
                                        className={classNames({
                                            [classes.star]: true,
                                            [classes.star__buy]: isBuy(item.direction) && item.rating > 0,
                                            [classes.star__sell]: !isBuy(item.direction) && item.rating > 0,
                                        })}
                                    >
                                        <Icon name={IconsType.star} />
                                    </div>
                                    <div
                                        className={classNames({
                                            [classes.star]: true,
                                            [classes.star__buy]: isBuy(item.direction) && item.rating > 1,
                                            [classes.star__sell]: !isBuy(item.direction) && item.rating > 1,
                                        })}
                                    >
                                        <Icon name={IconsType.star} />
                                    </div>
                                    <div
                                        className={classNames({
                                            [classes.star]: true,
                                            [classes.star__buy]: isBuy(item.direction) && item.rating > 2,
                                            [classes.star__sell]: !isBuy(item.direction) && item.rating > 2,
                                        })}
                                    >
                                        <Icon name={IconsType.star} />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.spaceBetweenTexts}>
                                <Typography variant="body2">{t('suggestions.intraday')}</Typography>
                                <Typography variant="body2">
                                    {t('suggestions.ago', {
                                        time: getDiffInDDHHMM(moment(item.published * 1000), moment()),
                                    })}
                                </Typography>
                            </div>
                            <div className={classes.spaceBetweenTexts}>
                                <Typography variant="body2">{`TARGET: ${numberPipe(item.target_price, 10)
                                    .toString()
                                    .substring(0, 7)}`}</Typography>
                                <Typography variant="body2">
                                    {t('suggestions.in', {
                                        time: getDiffInDDHHMM(moment(), moment(item.target_timestamp * 1000)),
                                    })}
                                </Typography>
                            </div>
                            <Typography variant="body2" color="textSecondary">
                                {item.description}
                            </Typography>
                        </div>
                    </Link>
                ))}
            </div>
            <div>
                <Typography variant="caption" color="textSecondary">
                    {t('suggestions.disclaimer')}
                </Typography>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TradingSuggestions));
