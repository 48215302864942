import React, { forwardRef } from 'react';
import { IStackProps } from './Stack.types';
import useStyles from './Stack.styles';
import classNames from 'classnames';

const Stack: React.FunctionComponent<IStackProps> = forwardRef<HTMLDivElement, IStackProps>((props, ref) => {
    const {
        direction = 'column',
        alignItems = 'stretch',
        spacing = 0,
        justifyContent = 'flex-start',
        children,
        padding = 0,
        margin = 0,
        flexWrap = false,
        fullHeight = false,
        fullWidth = false,
        className,
        ...rest
    } = props;

    const classes = useStyles({
        alignItems,
        direction,
        justifyContent,
        margin,
        padding,
        spacing,
        flexWrap,
        fullHeight,
        fullWidth,
    });

    return (
        <div ref={ref} className={classNames(className, classes.stackComponent)} {...rest}>
            {children}
        </div>
    );
});

Stack.displayName = 'Stack';
export default Stack;
