/**
 *
 * @param color hex color
 * @param opacity number of opacity (0-100)
 */
export const fadeColor = (color: string, opacity: number) => {
    return hexToRgbA(color[0] !== '#' ? '#' : '' + color, opacity);
};

function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity / 100})`;
    }
    return '#ffffff';
}
