export enum EPropNewChallengeTypes {
    GET_NEW_CHALLENGE_OPTIONS_START = '[prop-newChallenge] get new challenge options start',
    GET_NEW_CHALLENGE_OPTIONS_SUCCESS = '[prop-newChallenge] get new challenge options success',
    GET_NEW_CHALLENGE_OPTIONS_FAIL = '[prop-newChallenge] get new challenge options fail',

    GET_NEW_GOAL_OPTIONS_START = '[prop-newChallenge] get new goal options start',
    GET_NEW_GOAL_OPTIONS_SUCCESS = '[prop-newChallenge] get new goal options success',
    GET_NEW_GOAL_OPTIONS_FAIL = '[prop-newChallenge] get new goal options fail',

    SET_CHALLENGE_DATA = '[prop-newChallenge] set challenge data',
}
