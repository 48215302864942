import { makeStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    theme_switch_wrapper: {
        position: 'relative',
        cursor: 'pointer',
        height: '4.0rem',
        width: '7.2rem',
        borderRadius: '2.0rem',
        border: `0.1rem solid ${theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)'}`,
        backgroundColor: 'transparent',
        outline: 0,
    },
    theme_switch_circle: {
        position: 'absolute',
        left: theme.palette.type === 'dark' ? '100%' : '0.5rem',
        right: theme.palette.type === 'dark' ? '0.5rem' : '100%',
        transform: `translateX(${
            theme.palette.type === 'dark' ? 'calc(-100% - 0.5rem)' : '0'
        }) translateY(-50%) rotate(${theme.palette.type === 'dark' ? '0deg' : '-360deg'})`,
        ...isRtl({
            transform: `translateX(${
                theme.palette.type === 'dark' ? '0' : 'calc(100% + 0.5rem)'
            }) translateY(-50%) rotate(${theme.palette.type === 'dark' ? '0deg' : '-360deg'})`,
        }),
        transition: 'all 500ms linear',
        top: '50%',
        borderRadius: '50%',
        height: '3.0rem',
        width: '3.0rem',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.4rem',
        overflow: 'hidden',
    },
}));
