import React, { useCallback } from 'react';
import Dialog from 'components/Dialog';
import useStyles from './tradersEducationDialog.styles';
import { useTranslation } from 'react-i18next';
import Typography from 'components/Typography';
import IframeResizer from 'iframe-resizer-react';
import config from 'config/common';
import { IconsType } from 'components/Icon';

interface CheckoutOutsourceDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const TradersEducationDialog: React.FunctionComponent<CheckoutOutsourceDialogProps> = (props) => {
    const { isOpen, onClose } = props;

    const classes = useStyles({ isOpen });
    const { t } = useTranslation('prop');

    const handleCloseAndAccept = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <div className={classes.iframe}>
            <Dialog
                open={isOpen}
                onClose={handleCloseAndAccept}
                classes={{ paper: classes.dialogPaper, root: classes.backDrop }}
                closeButtonIcon={{ icon: IconsType.x_close, className: classes.closeIcon }}
            >
                {config.tradersEducation.showTitleArea && (
                    <div>
                        <Typography className={classes.title} variant="tLgSemibold" component="h2">
                            {t('checkout.tradersEducationDialog.title')}
                        </Typography>
                        <Typography className={classes.description} variant="tSmallRegular">
                            {t('checkout.tradersEducationDialog.description')}
                        </Typography>
                    </div>
                )}

                <div className={classes.iframe}>
                    <IframeResizer
                        src={config.tradersEducation.url}
                        title="traders-education"
                        height="100%"
                        width="100%"
                        minHeight={500}
                        scrolling={true}
                        checkOrigin={false}
                    />
                </div>
            </Dialog>
        </div>
    );
};

export default TradersEducationDialog;
