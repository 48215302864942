import React from 'react';

export function useStorageState<T>(
    key: string,
    initialValue: T,
    type: 'localStorage' | 'sessionStorage' = 'localStorage'
) {
    const [storedValue, setStoredValue] = React.useState<T>(() => {
        try {
            let item;
            if (type === 'localStorage') {
                item = window.localStorage.getItem(key);
            } else {
                item = window.sessionStorage.getItem(key);
            }
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = (value: T | ((val: T) => T)) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if (type === 'localStorage') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            } else {
                window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {}
    };
    return [storedValue, setValue] as const;
}
