import { createSelector } from 'reselect';
import { RootState } from '../index';

const loginReducerSelector = (state: RootState) => state.auth;

export const auth_loader = createSelector(loginReducerSelector, (login) => login.loader);

export const auth_hasJwtToken = createSelector(loginReducerSelector, (login) => login.hasJwtToken);

export const auth_authErrors = createSelector(loginReducerSelector, (login) => login.errors);

export const auth_loginType = createSelector(loginReducerSelector, (login) => login.loginType);

export const auth_ipData = createSelector(loginReducerSelector, (login) => login.ipData);

export const auth_countryLanguages = createSelector(loginReducerSelector, (login) => login.ipData?.languages);

export const auth_emailVerified = createSelector(loginReducerSelector, (login) => login.emailVerified);
