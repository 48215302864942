import { isLaptop, isTablet } from 'helpers/device_helper';

export default {
    fontFamily: 'Inter',
    h1: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '3rem',
        lineHeight: 1,
        letterSpacing: 'normal',
        ...isTablet({
            fontSize: '3.6rem',
        }),
    },
    h2: {
        fontFamily: 'Inter',
        fontWeight: 300,
        fontSize: '2.5rem',
        lineHeight: 1,
        letterSpacing: '-0.00833em',
    },
    h3: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '2.2rem',
        lineHeight: 1.04,
        letterSpacing: '0em',
    },
    h4: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '2rem',
        lineHeight: 1.17,
        letterSpacing: '0.00735em',
    },
    h5: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '1.6rem',
        lineHeight: 1,
        letterSpacing: 'normal',
    },
    h6: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
    },
    subtitle1: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '1.6rem',
        lineHeight: 1.75,
        letterSpacing: '0.00938em',
    },
    subtitle2: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '1.2rem',
        lineHeight: 1.57,
        letterSpacing: '0.00714em',
    },
    body1: {
        fontFamily: 'Inter',
        fontWeight: 600,
        letterSpacing: 'initial',
        lineHeight: 'initial',
        fontSize: '1.4rem',
        ...isLaptop({
            fontSize: '1.6rem',
        }),
    },
    body2: {
        fontFamily: 'Inter',
        fontWeight: 600,
        letterSpacing: 'initial',
        lineHeight: 'initial',
        fontSize: '1rem',
        ...isLaptop({
            fontSize: '1.2rem',
        }),
    },
    button: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.75,
        letterSpacing: '0.02857em',
    },
    caption: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '0.9rem',
        lineHeight: 1.33,
        letterSpacing: 'normal',
    },
    overline: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 2.66,
        letterSpacing: '0.08333em',
    },
};
