import Typography from 'components/Typography';
import config from 'config/common';
import React from 'react';
import { Trans } from 'react-i18next';
import useStyles from './transAsHtml.styles';

export interface TransAsHtmlProps {
    i18FileAndKey: string;
    term?: string;
}

const TransAsHtml: React.FunctionComponent<TransAsHtmlProps> = ({ i18FileAndKey, term }) => {
    const classes = useStyles();
    return (
        <Trans
            i18nKey={i18FileAndKey}
            components={{
                h1Comp: <Typography variant="h1" color="inherit" />,
                h2Comp: <Typography variant="h2" color="inherit" />,
                h3Comp: <Typography variant="h3" color="inherit" />,
                h4Comp: <Typography variant="h4" color="inherit" />,
                h5Comp: <Typography variant="h5" color="inherit" />,
                body1Comp: <Typography variant="body1" color="inherit" />,
                body2Comp: <Typography variant="body2" color="inherit" />,
                bold: <b />,
                red: <span className={classes.redText} />,
                primary: <span className={classes.primaryText} />,
                secondary: <span className={classes.secondaryText} />,
                under_line: <span className={classes.underLine} />,
                inner_line: <span className={classes.innerLine} />,
                ul: <ul />,
                ol: <ol />,
                li: <li />,
                seperator: <span className={classes.seperator} />,
                //eslint-disable-next-line
                a: <a className={classes.link} />,
            }}
            values={{
                supportEmail: config.supportEmail,
                siteName: config.siteName,
            }}
        >
            {term}
        </Trans>
    );
};

export default TransAsHtml;
