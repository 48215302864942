import { makeStyles } from '@material-ui/core';
import { isRtl, isTablet } from 'helpers/device_helper';

interface Props {
    error: boolean;
    withLabel: boolean;
}

export default makeStyles((theme) => ({
    formControlRoot: (props: Props) => ({
        color: theme.palette.text.lightDefault,
        fontSize: '1.6rem',
        fontWeight: 600,
        lineHeight: '1.2',
        ...isTablet({
            fontSize: '2rem',
        }),
    }),
    formControlRoot__active: {
        color: theme.palette.primary.main,
    },
    label: {
        fontSize: '1.3rem',
        fontWeight: 'normal',
        lineHeight: '2rem',
    },
    checkboxRoot: (props: Props) => ({
        padding: '0 0.8rem',
        '& svg': {
            fill: props.error ? theme.palette.error.main : 'currentColor',
        },

        display: 'none',
    }),
    radioGroupRoot: (props: Props) => ({
        marginTop: props.withLabel ? '1.6rem' : 'unset',
        flexDirection: 'row',
    }),
    formControlLabelRoot: (props: Props) => ({
        alignItems: 'center',
        minHeight: '6rem',
        minWidth: '6.4rem',
        border: `0.2rem solid ${theme.palette.default.main}`,
        borderRadius: `${theme.spacing(2)}rem`,
        justifyContent: 'center',
        padding: `${theme.spacing(5)}rem`,
        marginBottom: `${theme.spacing(4)}rem`,
        marginRight: `${theme.spacing(4)}rem`,
        marginLeft: '0',
        '&:last-child': {
            marginRight: '0',
        },
        ...isRtl({
            marginLeft: `${theme.spacing(4)}rem`,
            marginRight: '0',
            '&:last-child': {
                marginLeft: '0',
            },
        }),
        ...isTablet({
            minHeight: '8rem',
            minWidth: '8.6rem',
        }),
    }),
    formControlLabelRoot__active: (props: Props) => ({
        border: `0.2rem solid ${theme.palette.primary.main}`,
    }),
    formHelperTextError: {
        '&&': {
            color: theme.palette.error.main,
            backgroundColor: 'transparent',
            padding: 'unset',
            margin: '0',
            marginTop: `${theme.spacing(4)}rem`,
        },
    },
}));
