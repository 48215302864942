import { IconsType } from 'components/Icon';
import Typography from 'components/Typography';
import { fileToBase64 } from 'helpers/fileToBase64';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import useStyles from './dropzone.styles';
import IconBox from 'components/IconBox/iconBox.render';

export class UploadedFile {
    name?: string;
    mimeType?: string;
    size?: number;
    base64?: string;
    errorMessage?: string;
}
export class DropZoneProps {
    disabled?: boolean;
    accept?: Array<string>;
    dragActiveText?: string;
    dragHereText?: string;
    browseText?: string;
    onDrop: (files: Array<UploadedFile>) => void;
}

const DropZone: React.FunctionComponent<DropZoneProps> = (props) => {
    const { t } = useTranslation('components');
    const {
        disabled,
        accept,
        dragActiveText = t('dropzone.dragActiveText'),
        dragHereText = t('dropzone.dragHereText'),
        browseText = t('dropzone.browseText'),
        onDrop,
        children,
    } = props;
    const classes = useStyles({ disabled });

    const handleOnDrop = React.useCallback(
        async (acceptedFiles: Array<File>) => {
            if (disabled) return;
            const filesAsUploadedFile: Array<UploadedFile> = [];
            try {
                for (let index = 0; index < acceptedFiles.length; index++) {
                    const file = acceptedFiles[index];
                    const fileAsBase64 = await fileToBase64(file);
                    if (fileAsBase64) {
                        filesAsUploadedFile.push({
                            base64: fileAsBase64,
                            name: file.name,
                            mimeType: file.type,
                            size: file.size,
                        });
                    }
                }
            } catch (error) {}
            onDrop(filesAsUploadedFile);
        },
        [disabled, onDrop]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleOnDrop, accept });

    const renderSelfComponent =
        isDragActive && !disabled ? (
            <Typography variant="body1">{dragActiveText}</Typography>
        ) : (
            <Typography variant="body1">
                {dragHereText} <span className={classes.browseWord}>{browseText}</span>
            </Typography>
        );
    return (
        <div {...getRootProps()} className={classes.dropzone}>
            <input {...getInputProps()} disabled={disabled} />
            <IconBox size={36} variant={'border'} color={'gray'} iconType={IconsType.backup} />
            {children && <>{children}</>}

            {!children && renderSelfComponent}
        </div>
    );
};

export default DropZone;
