import Button from 'components/Button';
import Typography from 'components/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { FloatingPaperProps } from './floating-paper.scheme';
import useStyles from './floating-paper.styles';
import classNames from 'classnames';

const FloatingPaper: React.FunctionComponent<FloatingPaperProps> = (props) => {
    const {
        imgSrc,
        title,
        description,
        link,
        btnText,
        iframeSrc,
        btnColor = 'primary',
        align = 'center',
        btnProps = {},
        disabled,
    } = props;

    const classes = useStyles({ imgSrc, align });
    return (
        <div className={classes.wrapper}>
            <div className={classNames(classes.content, { [classes.disabled]: disabled })}>
                {imgSrc && <div className={classes.image} />}
                {iframeSrc && (
                    <iframe
                        className={classes.iframe}
                        src={iframeSrc}
                        allowFullScreen
                        allowTransparency
                        title={title}
                    />
                )}
                <Typography variant="h4">{title}</Typography>
                {description && (
                    <Typography variant="body2" color="textPrimary">
                        {description}
                    </Typography>
                )}
            </div>
            {link && btnText && (
                <Link to={link}>
                    <Button variant="contained" size="small" color={btnColor} {...btnProps}>
                        {btnText}
                    </Button>
                </Link>
            )}
        </div>
    );
};

export default FloatingPaper;
