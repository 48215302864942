import { makeStyles } from '@material-ui/core';
import { newShadows } from 'muiTheme/config/palette';

interface Props {
    disableMarginTop: boolean;
    disabled?: boolean;
    alignCenter?: boolean;
}

export default makeStyles((theme) => ({
    root: (props: Props) => ({
        '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.background.default,
            width: '100%',
            height: '44px',
            boxShadow: newShadows.xs,
            borderRadius: '8px',
            // padding: '8px 12px',
            // border: ' 1px solid #D0D5DD',
            '&.MuiOutlinedInput-multiline': {
                height: 'auto',
            },
            '& svg': {
                color: theme.palette.grayVariants[500],
            },
        },
        ...(props.disableMarginTop && {
            '& .MuiInputBase-root': {
                marginTop: '0',
            },
        }),
        '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grayVariants[300],
        },
        '& .MuiInputBase-input::placeholder': {
            color: theme.palette.grayVariants[500],
        },
    }),
    input: (props: Props) => ({
        fontWeight: 400,
        '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100rem ${theme.palette.background.default} inset`,
            borderRadius: '8px',
            ...(props.disabled && {
                WebkitBoxShadow: `0 0 0 100rem ${theme.palette.default.main} inset`,
            }),
        },
        ...(props.alignCenter && {
            textAlign: 'center',
        }),
    }),
}));
