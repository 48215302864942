import { makeStyles } from '@material-ui/core';
import { isDesktop, isIOS, isLaptop, isRtl, isTablet } from 'helpers/device_helper';
import palette from 'muiTheme/config/palette';

interface Props {
    loggedIn: boolean;
    theme: 'light' | 'dark';
    fixedDisclaimerRow: boolean;
    system_side_menu_state: boolean;
}

export default makeStyles((them) => ({
    app_container: {
        minHeight: '100vh',
        display: 'flex',
        ...(isIOS() && {
            minHeight: 'calc(100vh - calc(100vh - 100%))',
        }),
    },
    header: {
        display: 'block',
        height: '7rem',
        position: 'relative',
    },
    body: (props: Props) => ({
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        position: 'relative',
        width: '100vw',
        // height: props.fixedDisclaimerRow ? '100vh' : '100%',
        minHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxWidth: '100vw',
        ...(isIOS() && {
            minHeight: 'calc(100vh - calc(100vh - 100%))',
            height: props.fixedDisclaimerRow ? 'calc(100vh - calc(100vh - 100%))' : '100%',
        }),
        filter: props.system_side_menu_state ? 'blur(8px)' : 'none',
        ...isLaptop({ filter: 'none' }),
    }),
    side_menu: (props: Props) => ({
        '@media print': {
            display: 'none',
        },
        backgroundColor: palette(props.theme).side_menu.background,
        ...isTablet({
            minWidth: '6.4rem',
        }),
        ...isDesktop({
            minWidth: 'auto',
        }),
    }),
    main: ({ loggedIn }: Props) => ({
        marginBottom: loggedIn ? '75px' : 'unset',
        ...isLaptop({
            marginBottom: 'unset',
        }),
    }),
    footer: {
        display: 'block',
        position: 'relative',
    },
    page_link: {
        '&:link,&:visited': {
            textDecoration: 'underline',
        },
    },
    bottomNavigationWrapper: {
        position: 'fixed',
        bottom: 0,
        zIndex: 2,
        width: '100%',
    },
}));
