import { makeStyles } from '@material-ui/core';
import { customGap } from 'helpers/device_helper';

interface Props {
    shiningInterval: number;
}

export default makeStyles((theme) => ({
    shareIconsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        ...customGap(1.6, 'vertical'),
    },
    shareButtonStyle: {
        padding: '5px',
        display: 'flex',
        '&:hover': {
            backgroundColor: theme.palette.disabled.background,
            borderRadius: '8px',
            transition: 'background-color 200ms ease-in-out',
        },
    },
    iconsContainer: { width: '100%', display: 'flex', justifyContent: 'space-evenly !important', alignItems: 'center' },
}));
