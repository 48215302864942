import { ESystemTypes } from 'store/system/actionsTypes';
import { EUPLOADDTypes } from './actionsTypes';
import { FileUploadStatus, UploadDReducerState } from './interface';

const initialState: UploadDReducerState = {
    uploadProcessLoader: false,
    filesUploading: {},
};

const SumsubReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EUPLOADDTypes.UPLOAD_FILES_PROCESS_START: {
            return {
                ...state,
                uploadProcessLoader: true,
            };
        }
        case EUPLOADDTypes.UPLOAD_FILES_PROCESS_FINISH: {
            return {
                ...state,
                uploadProcessLoader: false,
            };
        }
        case EUPLOADDTypes.UPLOAD_FILE_START: {
            const { fileName } = payload;
            return {
                ...state,
                filesUploading: {
                    ...state.filesUploading,
                    [fileName]: {
                        loading: true,
                        status: FileUploadStatus.PENDING,
                    },
                },
            };
        }
        case EUPLOADDTypes.UPLOAD_FILE_SUCCESS: {
            const { fileName } = payload;
            return {
                ...state,
                filesUploading: {
                    ...state.filesUploading,
                    [fileName]: {
                        loading: false,
                        status: FileUploadStatus.SUCCESS,
                    },
                },
            };
        }
        case EUPLOADDTypes.UPLOAD_FILE_FAILED: {
            const { fileName } = payload;
            return {
                ...state,
                filesUploading: {
                    ...state.filesUploading,
                    [fileName]: {
                        loading: false,
                        status: FileUploadStatus.FAILED,
                    },
                },
            };
        }
        case EUPLOADDTypes.CLEAN_UPLOAD_FILE: {
            const { fileName } = payload;
            const newFilesUploading = { ...state.filesUploading };
            delete newFilesUploading[fileName];
            return {
                ...state,
                filesUploading: newFilesUploading,
            };
        }
        case EUPLOADDTypes.CLEAN_UPLOAD_FILES:
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                filesUploading: {},
                uploadProcessLoader: false,
            };
        }
        default:
            return state;
    }
};

export default SumsubReducer;
