import React from 'react';
import { ButtonProps as ButtonMatProps } from '@material-ui/core';
import Icon, { IconsType } from 'components/Icon';
import Loader from 'components/Loader';
import { useStyles } from './styles';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useClientRect } from 'hooks/useClientRect';
import { Button as ButtonMat } from '@material-ui/core';

export interface ButtonProps extends ButtonMatProps {
    loading?: boolean;
    startIcon?: IconsType;
    endIcon?: IconsType;
    status?: 'success' | 'failed' | 'redirect';
    statusDuration?: number;
    statusDurationOverCallback?: () => void;
    statusCustomTexts?: {
        success?: string;
        failed?: string;
        redirect?: string;
    };
    statusCustomIcons?: {
        success?: IconsType;
        failed?: IconsType;
        redirect?: string;
    };
    specialButtons?: 'buy' | 'sell' | 'square';
    target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
}

let timeout;

const Button: React.FunctionComponent<ButtonProps> = (props) => {
    const {
        loading,
        startIcon,
        endIcon,
        disabled,
        disableRipple = true,
        statusDuration = 4,
        statusDurationOverCallback,
        status,
        onClick,
        children,
        statusCustomTexts,
        statusCustomIcons,
        specialButtons,
        ...rest
    } = props;

    const { t } = useTranslation();

    const [statusDurationOver, setStatusDurationOver] = React.useState(false);
    const [{ width: refWidth, height: refHeight }, myRef] = useClientRect();
    let [tempStatus, setTempStatus] = React.useState(status);

    React.useEffect(() => {
        if (tempStatus !== status) {
            setStatusDurationOver(false);
            clearTimeout(timeout);
            setTempStatus(status);
        }
    }, [status, tempStatus]);

    React.useEffect(() => {
        if (statusDuration && status) {
            timeout = setTimeout(() => {
                setStatusDurationOver(true);
                statusDurationOverCallback && statusDurationOverCallback();
            }, statusDuration * 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [statusDuration, status, statusDurationOverCallback]);

    const classes = useStyles({
        horizontalDuration: statusDuration * (0.5 - refHeight / refWidth / 2),
        verticalDuration: statusDuration * (refHeight / refWidth / 2),
        status,
        specialButtons,
        variant: props.variant,
    });

    const getStartIcon = () => {
        if (!status || statusDurationOver) return startIcon ? <Icon name={startIcon} color="inherit" /> : null;
        switch (status) {
            case 'success':
                return <Icon name={statusCustomIcons?.success || IconsType.success} />;
            case 'failed':
                return <Icon name={statusCustomIcons?.failed || IconsType.failed} />;
            case 'redirect':
                return null;
        }
    };

    const getEndIcon = () => {
        if (loading) return <Loader size={15} color="inherit" />;
        return endIcon ? <Icon name={endIcon} color="inherit" /> : null;
    };

    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick && onClick(event);
    };

    const getBtnClassName = () => {
        if (!status || statusDurationOver) return undefined;
        switch (status) {
            case 'success':
                return classes.btn_success;
            case 'failed':
                return classes.btn_error;
            case 'redirect':
                return classes.btn_redirect;
        }
    };

    const getBtnContent = () => {
        if (!status || statusDurationOver) return children;
        switch (status) {
            case 'success':
                return statusCustomTexts?.success || t('success');
            case 'failed':
                return statusCustomTexts?.failed || t('failed');
            case 'redirect':
                return statusCustomTexts?.redirect || children;
        }
    };

    const getBtnState = () => {
        if (disabled || loading) return true;
        if (status === 'redirect') return false;
        return status && !statusDurationOver;
    };

    return (
        <ButtonMat
            key={status}
            ref={myRef}
            disabled={getBtnState()}
            disableRipple={disableRipple}
            disableElevation
            onClick={handleOnClick}
            {...rest}
            startIcon={getStartIcon()}
            endIcon={getEndIcon()}
            classes={{ ...props.classes, contained: classes.customContained, outlined: classes.customOutlined }}
            className={`${props.className} ${getBtnClassName()}`}
        >
            <React.Fragment>
                {getBtnContent()}
                {status && !statusDurationOver && (
                    <div className={classes.outerLoaderWrapper}>
                        <div
                            className={classNames({
                                [classes.outerLoaderSide]: true,
                                [classes.outerLoaderSide__top]: true,
                            })}
                        />
                        <div
                            className={classNames({
                                [classes.outerLoaderSide]: true,
                                [classes.outerLoaderSide__right]: true,
                            })}
                        />
                        <div
                            className={classNames({
                                [classes.outerLoaderSide]: true,
                                [classes.outerLoaderSide__bottom]: true,
                            })}
                        />
                        <div
                            className={classNames({
                                [classes.outerLoaderSide]: true,
                                [classes.outerLoaderSide__left]: true,
                            })}
                        />
                    </div>
                )}
            </React.Fragment>
        </ButtonMat>
    );
};

export default Button;
