/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeTpPasswordRequest } from '../models';
// @ts-ignore
import { ChangeTpPasswordResponse } from '../models';
// @ts-ignore
import { CreateTPAccountRequest } from '../models';
// @ts-ignore
import { CreateTPAccountResponse } from '../models';
// @ts-ignore
import { GetMonetaryTransactionHistoryResponse } from '../models';
// @ts-ignore
import { GetTPAccountsDataRequest } from '../models';
// @ts-ignore
import { GetTPAccountsDataResponse } from '../models';
// @ts-ignore
import { GetTPTypesRequest } from '../models';
// @ts-ignore
import { GetTPTypesResponse } from '../models';
// @ts-ignore
import { GetTradingHistoryRequest } from '../models';
// @ts-ignore
import { GetTradingHistoryResponse } from '../models';
// @ts-ignore
import { IsReachedCreditVolumeRequest } from '../models';
// @ts-ignore
import { IsReachedCreditVolumeResponse } from '../models';
/**
 * TPApi - axios parameter creator
 * @export
 */
export const TPApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {ChangeTpPasswordRequest} changeTpPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerChangeTpPassword: async (
            changeTpPasswordRequest: ChangeTpPasswordRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'changeTpPasswordRequest' is not null or undefined
            assertParamExists('tPControllerChangeTpPassword', 'changeTpPasswordRequest', changeTpPasswordRequest);
            const localVarPath = `/api/tp/changeTpPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                changeTpPasswordRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateTPAccountRequest} createTPAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerCreateTPAccount: async (
            createTPAccountRequest: CreateTPAccountRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createTPAccountRequest' is not null or undefined
            assertParamExists('tPControllerCreateTPAccount', 'createTPAccountRequest', createTPAccountRequest);
            const localVarPath = `/api/tp/createTPAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createTPAccountRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerGetMonetaryTransactionHistory: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tp/getMonetaryTransactionHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetTPAccountsDataRequest} getTPAccountsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerGetTPData: async (
            getTPAccountsDataRequest: GetTPAccountsDataRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getTPAccountsDataRequest' is not null or undefined
            assertParamExists('tPControllerGetTPData', 'getTPAccountsDataRequest', getTPAccountsDataRequest);
            const localVarPath = `/api/tp/getTPData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getTPAccountsDataRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetTPTypesRequest} getTPTypesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerGetTpTypes: async (
            getTPTypesRequest: GetTPTypesRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getTPTypesRequest' is not null or undefined
            assertParamExists('tPControllerGetTpTypes', 'getTPTypesRequest', getTPTypesRequest);
            const localVarPath = `/api/tp/getTpTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getTPTypesRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetTradingHistoryRequest} getTradingHistoryRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerGetTradingHistory: async (
            getTradingHistoryRequest: GetTradingHistoryRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getTradingHistoryRequest' is not null or undefined
            assertParamExists('tPControllerGetTradingHistory', 'getTradingHistoryRequest', getTradingHistoryRequest);
            const localVarPath = `/api/tp/getTradingHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getTradingHistoryRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {IsReachedCreditVolumeRequest} isReachedCreditVolumeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerIsReachedCreditVolume: async (
            isReachedCreditVolumeRequest: IsReachedCreditVolumeRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'isReachedCreditVolumeRequest' is not null or undefined
            assertParamExists(
                'tPControllerIsReachedCreditVolume',
                'isReachedCreditVolumeRequest',
                isReachedCreditVolumeRequest
            );
            const localVarPath = `/api/tp/isReachedCreditVolume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                isReachedCreditVolumeRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TPApi - functional programming interface
 * @export
 */
export const TPApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TPApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {ChangeTpPasswordRequest} changeTpPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tPControllerChangeTpPassword(
            changeTpPasswordRequest: ChangeTpPasswordRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeTpPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tPControllerChangeTpPassword(
                changeTpPasswordRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreateTPAccountRequest} createTPAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tPControllerCreateTPAccount(
            createTPAccountRequest: CreateTPAccountRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTPAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tPControllerCreateTPAccount(
                createTPAccountRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tPControllerGetMonetaryTransactionHistory(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMonetaryTransactionHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tPControllerGetMonetaryTransactionHistory(
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetTPAccountsDataRequest} getTPAccountsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tPControllerGetTPData(
            getTPAccountsDataRequest: GetTPAccountsDataRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTPAccountsDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tPControllerGetTPData(
                getTPAccountsDataRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetTPTypesRequest} getTPTypesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tPControllerGetTpTypes(
            getTPTypesRequest: GetTPTypesRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTPTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tPControllerGetTpTypes(
                getTPTypesRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetTradingHistoryRequest} getTradingHistoryRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tPControllerGetTradingHistory(
            getTradingHistoryRequest: GetTradingHistoryRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTradingHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tPControllerGetTradingHistory(
                getTradingHistoryRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {IsReachedCreditVolumeRequest} isReachedCreditVolumeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tPControllerIsReachedCreditVolume(
            isReachedCreditVolumeRequest: IsReachedCreditVolumeRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IsReachedCreditVolumeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tPControllerIsReachedCreditVolume(
                isReachedCreditVolumeRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TPApi - factory interface
 * @export
 */
export const TPApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TPApiFp(configuration);
    return {
        /**
         *
         * @param {ChangeTpPasswordRequest} changeTpPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerChangeTpPassword(
            changeTpPasswordRequest: ChangeTpPasswordRequest,
            options?: any
        ): AxiosPromise<ChangeTpPasswordResponse> {
            return localVarFp
                .tPControllerChangeTpPassword(changeTpPasswordRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateTPAccountRequest} createTPAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerCreateTPAccount(
            createTPAccountRequest: CreateTPAccountRequest,
            options?: any
        ): AxiosPromise<CreateTPAccountResponse> {
            return localVarFp
                .tPControllerCreateTPAccount(createTPAccountRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerGetMonetaryTransactionHistory(options?: any): AxiosPromise<GetMonetaryTransactionHistoryResponse> {
            return localVarFp
                .tPControllerGetMonetaryTransactionHistory(options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetTPAccountsDataRequest} getTPAccountsDataRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerGetTPData(
            getTPAccountsDataRequest: GetTPAccountsDataRequest,
            options?: any
        ): AxiosPromise<GetTPAccountsDataResponse> {
            return localVarFp
                .tPControllerGetTPData(getTPAccountsDataRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetTPTypesRequest} getTPTypesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerGetTpTypes(getTPTypesRequest: GetTPTypesRequest, options?: any): AxiosPromise<GetTPTypesResponse> {
            return localVarFp
                .tPControllerGetTpTypes(getTPTypesRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetTradingHistoryRequest} getTradingHistoryRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerGetTradingHistory(
            getTradingHistoryRequest: GetTradingHistoryRequest,
            options?: any
        ): AxiosPromise<GetTradingHistoryResponse> {
            return localVarFp
                .tPControllerGetTradingHistory(getTradingHistoryRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IsReachedCreditVolumeRequest} isReachedCreditVolumeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tPControllerIsReachedCreditVolume(
            isReachedCreditVolumeRequest: IsReachedCreditVolumeRequest,
            options?: any
        ): AxiosPromise<IsReachedCreditVolumeResponse> {
            return localVarFp
                .tPControllerIsReachedCreditVolume(isReachedCreditVolumeRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * TPApi - object-oriented interface
 * @export
 * @class TPApi
 * @extends {BaseAPI}
 */
export class TPApi extends BaseAPI {
    /**
     *
     * @param {ChangeTpPasswordRequest} changeTpPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TPApi
     */
    public tPControllerChangeTpPassword(changeTpPasswordRequest: ChangeTpPasswordRequest, options?: any) {
        return TPApiFp(this.configuration)
            .tPControllerChangeTpPassword(changeTpPasswordRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CreateTPAccountRequest} createTPAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TPApi
     */
    public tPControllerCreateTPAccount(createTPAccountRequest: CreateTPAccountRequest, options?: any) {
        return TPApiFp(this.configuration)
            .tPControllerCreateTPAccount(createTPAccountRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TPApi
     */
    public tPControllerGetMonetaryTransactionHistory(options?: any) {
        return TPApiFp(this.configuration)
            .tPControllerGetMonetaryTransactionHistory(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetTPAccountsDataRequest} getTPAccountsDataRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TPApi
     */
    public tPControllerGetTPData(getTPAccountsDataRequest: GetTPAccountsDataRequest, options?: any) {
        return TPApiFp(this.configuration)
            .tPControllerGetTPData(getTPAccountsDataRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetTPTypesRequest} getTPTypesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TPApi
     */
    public tPControllerGetTpTypes(getTPTypesRequest: GetTPTypesRequest, options?: any) {
        return TPApiFp(this.configuration)
            .tPControllerGetTpTypes(getTPTypesRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetTradingHistoryRequest} getTradingHistoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TPApi
     */
    public tPControllerGetTradingHistory(getTradingHistoryRequest: GetTradingHistoryRequest, options?: any) {
        return TPApiFp(this.configuration)
            .tPControllerGetTradingHistory(getTradingHistoryRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {IsReachedCreditVolumeRequest} isReachedCreditVolumeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TPApi
     */
    public tPControllerIsReachedCreditVolume(
        isReachedCreditVolumeRequest: IsReachedCreditVolumeRequest,
        options?: any
    ) {
        return TPApiFp(this.configuration)
            .tPControllerIsReachedCreditVolume(isReachedCreditVolumeRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
