import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    prop_myChallenges_getAllChallenges,
    prop_myChallenges_getChallengesLoader,
    prop_myChallenges_getFilteredChallenges,
    prop_myChallenges_getNotStartedFundedChallenges,
} from '../store/my-challenges/selectors';
import { PropChallenge } from 'services/generatedClientFromSwagger';
import { Pages } from 'interfaces/main';
import LoaderFullScreen from 'components/LoaderFullScreen';

interface PropGuardProps {
    component: any;
    challenges: Array<PropChallenge>;
    filteredChallenges: Array<PropChallenge>;
    hasFilteredChallengesGuard?: boolean;
    hasChallengesGuard?: boolean;
    loading: boolean;
    notStartedFundedChallenges?: Array<PropChallenge>;
    prop_myChallenges_getChallengesLoader: boolean;
}

const PropGuard: React.FunctionComponent<PropGuardProps> = (props) => {
    const {
        component: Component,
        challenges,
        filteredChallenges,
        hasChallengesGuard,
        hasFilteredChallengesGuard,
        loading,
        notStartedFundedChallenges,
        prop_myChallenges_getChallengesLoader,
        ...restProps
    } = props;

    if (
        hasChallengesGuard &&
        (prop_myChallenges_getChallengesLoader === undefined || prop_myChallenges_getChallengesLoader)
    ) {
        return <LoaderFullScreen />;
    }

    if (hasChallengesGuard && challenges.length < 1) {
        return <Redirect to={`/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`} />;
    }

    if (hasFilteredChallengesGuard && filteredChallenges.length === 0 && notStartedFundedChallenges?.length === 0) {
        return <Redirect to={`/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`} />;
    }

    return <Route {...restProps} render={(props) => <Component {...props} />} />;
};

export const mapStateToProps = (state) => ({
    challenges: prop_myChallenges_getAllChallenges(state),
    filteredChallenges: prop_myChallenges_getFilteredChallenges(state),

    notStartedFundedChallenges: prop_myChallenges_getNotStartedFundedChallenges(state),
    prop_myChallenges_getChallengesLoader: prop_myChallenges_getChallengesLoader(state),
});

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PropGuard));
