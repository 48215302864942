import { makeStyles } from '@material-ui/core';
import { isMobileDevice } from 'helpers/device_helper';
import { newShadows } from 'muiTheme/config/palette';

export default makeStyles((theme) => ({
    btn_wrapper: {},
    propBtn: {
        padding: '10px 16px',
        boxShadow: newShadows.xs,
        lineHeight: '2.4rem',
        width: 'max-content',
        ...isMobileDevice({
            width: '100%',
            padding: '12px 18px',
        }),
    },
}));
