import React from 'react';
import { StartNewChallengeBtnProps } from './startNewChallengeBtn.scheme';
import useStyles from './startNewChallengeBtn.styles';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import { Pages } from 'interfaces/main';

const StartNewChallengeBtn: React.FunctionComponent<StartNewChallengeBtnProps> = (props) => {
    const classes = useStyles();

    const buttonUrl = `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`;

    return (
        <div className={classes.btn_wrapper}>
            <Link to={`${buttonUrl}`}>
                <Button
                    data-testid={'startNewChallengeBtn'}
                    className={classes.propBtn}
                    variant="contained"
                    color="primary"
                >
                    {props.label}
                </Button>
            </Link>
        </div>
    );
};

export default StartNewChallengeBtn;
