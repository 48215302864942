import React, { useMemo } from 'react';
import Select, { SelectProps } from 'components/Inputs/Select';
import { useTranslation } from 'react-i18next';
import { UploadFileWithDocumentTypeRequestDocumentTypeEnum } from 'services/generatedClientFromSwagger';

interface FileTypeSelectProps extends Omit<SelectProps, 'options'> {
    selectedFileType: UploadFileWithDocumentTypeRequestDocumentTypeEnum | undefined;
    setSelectedFileType: (value: UploadFileWithDocumentTypeRequestDocumentTypeEnum | undefined) => void;
}

const DialogFileTypeSelect: React.FunctionComponent<FileTypeSelectProps> = ({
    selectedFileType,
    setSelectedFileType,
    ...rest
}) => {
    const { t } = useTranslation('upload-documents');

    const options = useMemo(
        () =>
            Object.keys(UploadFileWithDocumentTypeRequestDocumentTypeEnum).map((type) => ({
                label: t(`manualProcess.typesDialog.${type}`),
                value: type,
            })),
        [t]
    );

    return (
        <Select
            options={options}
            value={selectedFileType}
            onChange={setSelectedFileType}
            placeholder={t('manualProcess.selectFileType')}
            disableMarginTop={true}
            fullWidth
        />
    );
};

export default DialogFileTypeSelect;
