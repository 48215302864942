// @ts-nocheck
class Site24x7 {
    isInit: boolean = false;
    isActive: boolean = false;

    public init = () => {
        this.isInit = true;
        const rumKey = '71b98fc2975e659e5c0d37e8d23aa256';
        const self = this;
        (function (w, d, s, r, k, h, m) {
            if (w.performance && w.performance.timing && w.performance.navigation) {
                w[r] =
                    w[r] ||
                    function () {
                        (w[r].q = w[r].q || []).push(arguments);
                    };
                h = d.createElement('script');
                h.async = true;
                h.setAttribute('src', s + k);
                d.getElementsByTagName('head')[0].appendChild(h);
                h.onload = self.onLoad;
                m = window.onerror;
                window.onerror = function (b, c, d, f, g) {
                    if (m) {
                        m(b, c, d, f, g);
                        g = g || new Error(b);
                        (w[r].q = w[r].q || []).push(['captureException', g]);
                    }
                };
            }
        })(window, document, '//static.site24x7rum.com/beacon/site24x7rum-min.js?appKey=', 's247r', rumKey);
    };

    private onLoad = () => {
        this.isActive = true;
        this.setEnvironment();
    };

    private waitForActive = async () => {
        return new Promise((resolve, reject) => {
            const totalTimes = 5;
            let times = 0;
            if (!this.isInit) {
                reject('Site24x7 not initiated');
            }
            const interval = setInterval(() => {
                if (times > totalTimes) {
                    reject('site24x7 reach total retry times');
                    clearInterval(interval);
                }
                if (this.isActive) {
                    resolve('Site24x7 is active');
                    clearInterval(interval);
                }
                times++;
            }, 300);
        });
    };

    public setUser = async (accountId: string) => {
        try {
            await this.waitForActive();
            const anyWindow = window as any;
            anyWindow.s247r('userId', accountId);
        } catch (e) {
            console.log(e);
        }
    };

    public setEnvironment = () => {
        if (!this.isActive) return;
        const environment = localStorage.getItem('app');
        const anyWindow = window as any;
        anyWindow.s247r('environment', environment || 'default');
    };

    public destroy = () => {
        this.isActive = false;
    };
}

export default new Site24x7();
