import config from 'config/common';
import { AccountInfoParsed } from './generatedClientFromSwagger';

class LuckyOrange {
    isActive: boolean = false;

    private initLuckyOrange = (luckyOrangeSiteId: string, luckyOrangeSrc: string) => {
        this.isActive = true;
        (window as any).__lo_site_id = luckyOrangeSiteId;
        const head = document.getElementsByTagName('head')[0];
        const wa = document.createElement('script');
        wa.type = 'text/javascript';
        wa.async = true;
        wa.src = luckyOrangeSrc;
        head.appendChild(wa);
    };

    private setUserConst = (account: AccountInfoParsed) => {
        if (!this.isActive) return;
        const anyWindow = window as any;
        anyWindow._loq = anyWindow._loq || [];
        anyWindow._loq.push([
            'custom',
            {
                tpNames: account.tradingPlatformAccounts?.reduce((tps, tp) => `${tps}, ${tp.name}`, ''),
                userId: account.id,
                businessUnit: account.owningBusinessUnit,
                name: `${account.firstName} ${account.lastName}`,
            },
        ]);
    };

    public init = (userDetails: AccountInfoParsed) => {
        if (config.featuresFlags['disableLuckyOrange']) return;
        if (this.isActive) return;
        if (config.luckyOrange.siteId && config.luckyOrange.src) {
            this.initLuckyOrange(config.luckyOrange.siteId, config.luckyOrange.src);
            this.setUserConst(userDetails);
        }
    };

    public destroy = () => {
        this.isActive = false;
    };
}

export default new LuckyOrange();
