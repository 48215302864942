import { makeStyles } from '@material-ui/core';
import { customGap, isLaptop } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    networkConnection: {
        backgroundColor: theme.palette.default.main,
        position: 'fixed',
        width: '-webkit-fill-available',
        height: '5.7rem',
        zIndex: 99999,
        display: 'flex',
        borderRadius: '.8rem',
        justifyContent: 'center',
        margin: '0 1rem',
        top: 144,
        ...isLaptop({ top: 70 }),
    },
    innerContainerWrapper: { display: 'flex', alignItems: 'center', ...customGap(theme.spacing(3), 'horizontal') },
    iconContainer: { marginLeft: '1rem', '& > *': { fontSize: '4.5rem' } },
}));
