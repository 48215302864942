/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CancelWithdrawalRequest } from '../models';
// @ts-ignore
import { CancelWithdrawalResponse } from '../models';
// @ts-ignore
import { CreateBankAccountRequest } from '../models';
// @ts-ignore
import { CreateBankAccountResponse } from '../models';
// @ts-ignore
import { CreateCcsAccountProcessResponse } from '../models';
// @ts-ignore
import { CreateCcsWalletTransactionRequest } from '../models';
// @ts-ignore
import { CreateWithdrawalDto } from '../models';
// @ts-ignore
import { CryptoWalletRequest } from '../models';
// @ts-ignore
import { CryptoWalletResponse } from '../models';
// @ts-ignore
import { DeleteBankAccountRequest } from '../models';
// @ts-ignore
import { DeleteBankAccountResponse } from '../models';
// @ts-ignore
import { DeletePaymentCardRequest } from '../models';
// @ts-ignore
import { DeletePaymentCardResponse } from '../models';
// @ts-ignore
import { DepositWireDto } from '../models';
// @ts-ignore
import { DepositWireResponse } from '../models';
// @ts-ignore
import { DepositWithTokenRequest } from '../models';
// @ts-ignore
import { DepositWithTokenResponse } from '../models';
// @ts-ignore
import { GetBankAccountsResponse } from '../models';
// @ts-ignore
import { GetMonetaryTransactionsByAccountIdRequest } from '../models';
// @ts-ignore
import { GetPaymentTypesDto } from '../models';
// @ts-ignore
import { GetRateResponse } from '../models';
// @ts-ignore
import { MonetaryTransactionsTCBonusApproveRequest } from '../models';
// @ts-ignore
import { MonetaryTransactionsTCBonusApproveResponse } from '../models';
// @ts-ignore
import { OffRampDto } from '../models';
// @ts-ignore
import { OnRampDto } from '../models';
// @ts-ignore
import { PaymentIframeRequest } from '../models';
// @ts-ignore
import { PaymentIframeResponse } from '../models';
// @ts-ignore
import { PaymentTypesFlagsNewEntity } from '../models';
// @ts-ignore
import { SaveCardTokenIframeURLRequest } from '../models';
// @ts-ignore
import { SetDefaultBankAccountRequest } from '../models';
// @ts-ignore
import { SetDefaultBankAccountResponse } from '../models';
// @ts-ignore
import { SetDefaultPaymentCardRequest } from '../models';
// @ts-ignore
import { SetDefaultPaymentCardResponse } from '../models';
// @ts-ignore
import { ShouldGetVerifyBonusResponse } from '../models';
// @ts-ignore
import { TransferBetweenTPAccountsDto } from '../models';
// @ts-ignore
import { TransferBetweenTPAccountsResponse } from '../models';
// @ts-ignore
import { TransferBetweenTPAccountsValidationDto } from '../models';
// @ts-ignore
import { TransferBetweenTPAccountsValidationResponse } from '../models';
/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CancelWithdrawalRequest} cancelWithdrawalRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerCancelWithdrawal: async (
            cancelWithdrawalRequest: CancelWithdrawalRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'cancelWithdrawalRequest' is not null or undefined
            assertParamExists(
                'paymentApiControllerCancelWithdrawal',
                'cancelWithdrawalRequest',
                cancelWithdrawalRequest
            );
            const localVarPath = `/api/payment/cancelWithdrawal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                cancelWithdrawalRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateBankAccountRequest} createBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerCreateBankAccount: async (
            createBankAccountRequest: CreateBankAccountRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createBankAccountRequest' is not null or undefined
            assertParamExists(
                'paymentApiControllerCreateBankAccount',
                'createBankAccountRequest',
                createBankAccountRequest
            );
            const localVarPath = `/api/payment/createBankAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createBankAccountRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CryptoWalletRequest} cryptoWalletRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerCreateCryptoWallet: async (
            cryptoWalletRequest: CryptoWalletRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'cryptoWalletRequest' is not null or undefined
            assertParamExists('paymentApiControllerCreateCryptoWallet', 'cryptoWalletRequest', cryptoWalletRequest);
            const localVarPath = `/api/payment/getCryptoWallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                cryptoWalletRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateCcsWalletTransactionRequest} createCcsWalletTransactionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerCreateWalletTransaction: async (
            createCcsWalletTransactionRequest: CreateCcsWalletTransactionRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createCcsWalletTransactionRequest' is not null or undefined
            assertParamExists(
                'paymentApiControllerCreateWalletTransaction',
                'createCcsWalletTransactionRequest',
                createCcsWalletTransactionRequest
            );
            const localVarPath = `/api/payment/createWalletTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createCcsWalletTransactionRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateWithdrawalDto} createWithdrawalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerCreateWithdrawal: async (
            createWithdrawalDto: CreateWithdrawalDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createWithdrawalDto' is not null or undefined
            assertParamExists('paymentApiControllerCreateWithdrawal', 'createWithdrawalDto', createWithdrawalDto);
            const localVarPath = `/api/payment/createWithdrawal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createWithdrawalDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DeleteBankAccountRequest} deleteBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerDeleteBankAccount: async (
            deleteBankAccountRequest: DeleteBankAccountRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'deleteBankAccountRequest' is not null or undefined
            assertParamExists(
                'paymentApiControllerDeleteBankAccount',
                'deleteBankAccountRequest',
                deleteBankAccountRequest
            );
            const localVarPath = `/api/payment/deleteBankAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                deleteBankAccountRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DeletePaymentCardRequest} deletePaymentCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerDeletePaymentCard: async (
            deletePaymentCardRequest: DeletePaymentCardRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'deletePaymentCardRequest' is not null or undefined
            assertParamExists(
                'paymentApiControllerDeletePaymentCard',
                'deletePaymentCardRequest',
                deletePaymentCardRequest
            );
            const localVarPath = `/api/payment/deletePaymentCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                deletePaymentCardRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PaymentIframeRequest} paymentIframeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerDepositIframe: async (
            paymentIframeRequest: PaymentIframeRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'paymentIframeRequest' is not null or undefined
            assertParamExists('paymentApiControllerDepositIframe', 'paymentIframeRequest', paymentIframeRequest);
            const localVarPath = `/api/payment/depositIframe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                paymentIframeRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DepositWireDto} depositWireDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerDepositWire: async (
            depositWireDto: DepositWireDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'depositWireDto' is not null or undefined
            assertParamExists('paymentApiControllerDepositWire', 'depositWireDto', depositWireDto);
            const localVarPath = `/api/payment/depositWire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(depositWireDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DepositWithTokenRequest} depositWithTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerDepositWithToken: async (
            depositWithTokenRequest: DepositWithTokenRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'depositWithTokenRequest' is not null or undefined
            assertParamExists(
                'paymentApiControllerDepositWithToken',
                'depositWithTokenRequest',
                depositWithTokenRequest
            );
            const localVarPath = `/api/payment/depositWithToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                depositWithTokenRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerGetBankAccounts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/getBankAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetMonetaryTransactionsByAccountIdRequest} getMonetaryTransactionsByAccountIdRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerGetMonetaryTransactionsPerAccountId: async (
            getMonetaryTransactionsByAccountIdRequest: GetMonetaryTransactionsByAccountIdRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getMonetaryTransactionsByAccountIdRequest' is not null or undefined
            assertParamExists(
                'paymentApiControllerGetMonetaryTransactionsPerAccountId',
                'getMonetaryTransactionsByAccountIdRequest',
                getMonetaryTransactionsByAccountIdRequest
            );
            const localVarPath = `/api/payment/getMonetaryTransactionsPerAccountId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getMonetaryTransactionsByAccountIdRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetPaymentTypesDto} getPaymentTypesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerGetPaymentTypes: async (
            getPaymentTypesDto: GetPaymentTypesDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getPaymentTypesDto' is not null or undefined
            assertParamExists('paymentApiControllerGetPaymentTypes', 'getPaymentTypesDto', getPaymentTypesDto);
            const localVarPath = `/api/payment/getPaymentTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getPaymentTypesDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} from
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerGetRate: async (from: string, to: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('paymentApiControllerGetRate', 'from', from);
            // verify required parameter 'to' is not null or undefined
            assertParamExists('paymentApiControllerGetRate', 'to', to);
            const localVarPath = `/api/payment/getRate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {OffRampDto} offRampDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerOffRamp: async (offRampDto: OffRampDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offRampDto' is not null or undefined
            assertParamExists('paymentApiControllerOffRamp', 'offRampDto', offRampDto);
            const localVarPath = `/api/payment/offRamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(offRampDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {OnRampDto} onRampDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerOnRamp: async (onRampDto: OnRampDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'onRampDto' is not null or undefined
            assertParamExists('paymentApiControllerOnRamp', 'onRampDto', onRampDto);
            const localVarPath = `/api/payment/onRamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(onRampDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SaveCardTokenIframeURLRequest} saveCardTokenIframeURLRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerSaveCardTokenIframeURL: async (
            saveCardTokenIframeURLRequest: SaveCardTokenIframeURLRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'saveCardTokenIframeURLRequest' is not null or undefined
            assertParamExists(
                'paymentApiControllerSaveCardTokenIframeURL',
                'saveCardTokenIframeURLRequest',
                saveCardTokenIframeURLRequest
            );
            const localVarPath = `/api/payment/saveCardTokenIframeURL`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                saveCardTokenIframeURLRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SetDefaultPaymentCardRequest} setDefaultPaymentCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerSetDefaultPaymentCard: async (
            setDefaultPaymentCardRequest: SetDefaultPaymentCardRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'setDefaultPaymentCardRequest' is not null or undefined
            assertParamExists(
                'paymentApiControllerSetDefaultPaymentCard',
                'setDefaultPaymentCardRequest',
                setDefaultPaymentCardRequest
            );
            const localVarPath = `/api/payment/setDefaultPaymentCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                setDefaultPaymentCardRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SetDefaultBankAccountRequest} setDefaultBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerSetPrimaryBankAccount: async (
            setDefaultBankAccountRequest: SetDefaultBankAccountRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'setDefaultBankAccountRequest' is not null or undefined
            assertParamExists(
                'paymentApiControllerSetPrimaryBankAccount',
                'setDefaultBankAccountRequest',
                setDefaultBankAccountRequest
            );
            const localVarPath = `/api/payment/setPrimaryBankAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                setDefaultBankAccountRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerShouldGetVerifyBonus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/shouldGetVerifyBonus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {TransferBetweenTPAccountsDto} transferBetweenTPAccountsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerTransferBetweenTPAccounts: async (
            transferBetweenTPAccountsDto: TransferBetweenTPAccountsDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'transferBetweenTPAccountsDto' is not null or undefined
            assertParamExists(
                'paymentApiControllerTransferBetweenTPAccounts',
                'transferBetweenTPAccountsDto',
                transferBetweenTPAccountsDto
            );
            const localVarPath = `/api/payment/transferBetweenTPAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                transferBetweenTPAccountsDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {TransferBetweenTPAccountsValidationDto} transferBetweenTPAccountsValidationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerTransferBetweenTPAccountsValidation: async (
            transferBetweenTPAccountsValidationDto: TransferBetweenTPAccountsValidationDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'transferBetweenTPAccountsValidationDto' is not null or undefined
            assertParamExists(
                'paymentApiControllerTransferBetweenTPAccountsValidation',
                'transferBetweenTPAccountsValidationDto',
                transferBetweenTPAccountsValidationDto
            );
            const localVarPath = `/api/payment/transferBetweenTPAccountsValidation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                transferBetweenTPAccountsValidationDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MonetaryTransactionsTCBonusApproveRequest} monetaryTransactionsTCBonusApproveRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerUpdateMonetaryTransactionsTCBonusApprove: async (
            monetaryTransactionsTCBonusApproveRequest: MonetaryTransactionsTCBonusApproveRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'monetaryTransactionsTCBonusApproveRequest' is not null or undefined
            assertParamExists(
                'paymentApiControllerUpdateMonetaryTransactionsTCBonusApprove',
                'monetaryTransactionsTCBonusApproveRequest',
                monetaryTransactionsTCBonusApproveRequest
            );
            const localVarPath = `/api/payment/updateMonetaryTransactionsTCBonusApprove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                monetaryTransactionsTCBonusApproveRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CancelWithdrawalRequest} cancelWithdrawalRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerCancelWithdrawal(
            cancelWithdrawalRequest: CancelWithdrawalRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelWithdrawalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerCancelWithdrawal(
                cancelWithdrawalRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreateBankAccountRequest} createBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerCreateBankAccount(
            createBankAccountRequest: CreateBankAccountRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBankAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerCreateBankAccount(
                createBankAccountRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CryptoWalletRequest} cryptoWalletRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerCreateCryptoWallet(
            cryptoWalletRequest: CryptoWalletRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CryptoWalletResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerCreateCryptoWallet(
                cryptoWalletRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreateCcsWalletTransactionRequest} createCcsWalletTransactionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerCreateWalletTransaction(
            createCcsWalletTransactionRequest: CreateCcsWalletTransactionRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCcsAccountProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerCreateWalletTransaction(
                createCcsWalletTransactionRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreateWithdrawalDto} createWithdrawalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerCreateWithdrawal(
            createWithdrawalDto: CreateWithdrawalDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerCreateWithdrawal(
                createWithdrawalDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DeleteBankAccountRequest} deleteBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerDeleteBankAccount(
            deleteBankAccountRequest: DeleteBankAccountRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteBankAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerDeleteBankAccount(
                deleteBankAccountRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DeletePaymentCardRequest} deletePaymentCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerDeletePaymentCard(
            deletePaymentCardRequest: DeletePaymentCardRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeletePaymentCardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerDeletePaymentCard(
                deletePaymentCardRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PaymentIframeRequest} paymentIframeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerDepositIframe(
            paymentIframeRequest: PaymentIframeRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIframeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerDepositIframe(
                paymentIframeRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DepositWireDto} depositWireDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerDepositWire(
            depositWireDto: DepositWireDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepositWireResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerDepositWire(
                depositWireDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DepositWithTokenRequest} depositWithTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerDepositWithToken(
            depositWithTokenRequest: DepositWithTokenRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepositWithTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerDepositWithToken(
                depositWithTokenRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerGetBankAccounts(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBankAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerGetBankAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetMonetaryTransactionsByAccountIdRequest} getMonetaryTransactionsByAccountIdRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerGetMonetaryTransactionsPerAccountId(
            getMonetaryTransactionsByAccountIdRequest: GetMonetaryTransactionsByAccountIdRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.paymentApiControllerGetMonetaryTransactionsPerAccountId(
                    getMonetaryTransactionsByAccountIdRequest,
                    options
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetPaymentTypesDto} getPaymentTypesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerGetPaymentTypes(
            getPaymentTypesDto: GetPaymentTypesDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentTypesFlagsNewEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerGetPaymentTypes(
                getPaymentTypesDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} from
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerGetRate(
            from: string,
            to: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerGetRate(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {OffRampDto} offRampDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerOffRamp(
            offRampDto: OffRampDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerOffRamp(offRampDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {OnRampDto} onRampDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerOnRamp(
            onRampDto: OnRampDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerOnRamp(onRampDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SaveCardTokenIframeURLRequest} saveCardTokenIframeURLRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerSaveCardTokenIframeURL(
            saveCardTokenIframeURLRequest: SaveCardTokenIframeURLRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentIframeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerSaveCardTokenIframeURL(
                saveCardTokenIframeURLRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SetDefaultPaymentCardRequest} setDefaultPaymentCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerSetDefaultPaymentCard(
            setDefaultPaymentCardRequest: SetDefaultPaymentCardRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetDefaultPaymentCardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerSetDefaultPaymentCard(
                setDefaultPaymentCardRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SetDefaultBankAccountRequest} setDefaultBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerSetPrimaryBankAccount(
            setDefaultBankAccountRequest: SetDefaultBankAccountRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetDefaultBankAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerSetPrimaryBankAccount(
                setDefaultBankAccountRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerShouldGetVerifyBonus(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShouldGetVerifyBonusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerShouldGetVerifyBonus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {TransferBetweenTPAccountsDto} transferBetweenTPAccountsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerTransferBetweenTPAccounts(
            transferBetweenTPAccountsDto: TransferBetweenTPAccountsDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferBetweenTPAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentApiControllerTransferBetweenTPAccounts(
                transferBetweenTPAccountsDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {TransferBetweenTPAccountsValidationDto} transferBetweenTPAccountsValidationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerTransferBetweenTPAccountsValidation(
            transferBetweenTPAccountsValidationDto: TransferBetweenTPAccountsValidationDto,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferBetweenTPAccountsValidationResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.paymentApiControllerTransferBetweenTPAccountsValidation(
                    transferBetweenTPAccountsValidationDto,
                    options
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {MonetaryTransactionsTCBonusApproveRequest} monetaryTransactionsTCBonusApproveRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentApiControllerUpdateMonetaryTransactionsTCBonusApprove(
            monetaryTransactionsTCBonusApproveRequest: MonetaryTransactionsTCBonusApproveRequest,
            options?: any
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonetaryTransactionsTCBonusApproveResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.paymentApiControllerUpdateMonetaryTransactionsTCBonusApprove(
                    monetaryTransactionsTCBonusApproveRequest,
                    options
                );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration);
    return {
        /**
         *
         * @param {CancelWithdrawalRequest} cancelWithdrawalRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerCancelWithdrawal(
            cancelWithdrawalRequest: CancelWithdrawalRequest,
            options?: any
        ): AxiosPromise<CancelWithdrawalResponse> {
            return localVarFp
                .paymentApiControllerCancelWithdrawal(cancelWithdrawalRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateBankAccountRequest} createBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerCreateBankAccount(
            createBankAccountRequest: CreateBankAccountRequest,
            options?: any
        ): AxiosPromise<CreateBankAccountResponse> {
            return localVarFp
                .paymentApiControllerCreateBankAccount(createBankAccountRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CryptoWalletRequest} cryptoWalletRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerCreateCryptoWallet(
            cryptoWalletRequest: CryptoWalletRequest,
            options?: any
        ): AxiosPromise<CryptoWalletResponse> {
            return localVarFp
                .paymentApiControllerCreateCryptoWallet(cryptoWalletRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateCcsWalletTransactionRequest} createCcsWalletTransactionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerCreateWalletTransaction(
            createCcsWalletTransactionRequest: CreateCcsWalletTransactionRequest,
            options?: any
        ): AxiosPromise<CreateCcsAccountProcessResponse> {
            return localVarFp
                .paymentApiControllerCreateWalletTransaction(createCcsWalletTransactionRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateWithdrawalDto} createWithdrawalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerCreateWithdrawal(
            createWithdrawalDto: CreateWithdrawalDto,
            options?: any
        ): AxiosPromise<void> {
            return localVarFp
                .paymentApiControllerCreateWithdrawal(createWithdrawalDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DeleteBankAccountRequest} deleteBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerDeleteBankAccount(
            deleteBankAccountRequest: DeleteBankAccountRequest,
            options?: any
        ): AxiosPromise<DeleteBankAccountResponse> {
            return localVarFp
                .paymentApiControllerDeleteBankAccount(deleteBankAccountRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DeletePaymentCardRequest} deletePaymentCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerDeletePaymentCard(
            deletePaymentCardRequest: DeletePaymentCardRequest,
            options?: any
        ): AxiosPromise<DeletePaymentCardResponse> {
            return localVarFp
                .paymentApiControllerDeletePaymentCard(deletePaymentCardRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PaymentIframeRequest} paymentIframeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerDepositIframe(
            paymentIframeRequest: PaymentIframeRequest,
            options?: any
        ): AxiosPromise<PaymentIframeResponse> {
            return localVarFp
                .paymentApiControllerDepositIframe(paymentIframeRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DepositWireDto} depositWireDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerDepositWire(
            depositWireDto: DepositWireDto,
            options?: any
        ): AxiosPromise<DepositWireResponse> {
            return localVarFp
                .paymentApiControllerDepositWire(depositWireDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DepositWithTokenRequest} depositWithTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerDepositWithToken(
            depositWithTokenRequest: DepositWithTokenRequest,
            options?: any
        ): AxiosPromise<DepositWithTokenResponse> {
            return localVarFp
                .paymentApiControllerDepositWithToken(depositWithTokenRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerGetBankAccounts(options?: any): AxiosPromise<GetBankAccountsResponse> {
            return localVarFp.paymentApiControllerGetBankAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetMonetaryTransactionsByAccountIdRequest} getMonetaryTransactionsByAccountIdRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerGetMonetaryTransactionsPerAccountId(
            getMonetaryTransactionsByAccountIdRequest: GetMonetaryTransactionsByAccountIdRequest,
            options?: any
        ): AxiosPromise<void> {
            return localVarFp
                .paymentApiControllerGetMonetaryTransactionsPerAccountId(
                    getMonetaryTransactionsByAccountIdRequest,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetPaymentTypesDto} getPaymentTypesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerGetPaymentTypes(
            getPaymentTypesDto: GetPaymentTypesDto,
            options?: any
        ): AxiosPromise<Array<PaymentTypesFlagsNewEntity>> {
            return localVarFp
                .paymentApiControllerGetPaymentTypes(getPaymentTypesDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} from
         * @param {string} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerGetRate(from: string, to: string, options?: any): AxiosPromise<GetRateResponse> {
            return localVarFp
                .paymentApiControllerGetRate(from, to, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {OffRampDto} offRampDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerOffRamp(offRampDto: OffRampDto, options?: any): AxiosPromise<void> {
            return localVarFp
                .paymentApiControllerOffRamp(offRampDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {OnRampDto} onRampDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerOnRamp(onRampDto: OnRampDto, options?: any): AxiosPromise<void> {
            return localVarFp
                .paymentApiControllerOnRamp(onRampDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SaveCardTokenIframeURLRequest} saveCardTokenIframeURLRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerSaveCardTokenIframeURL(
            saveCardTokenIframeURLRequest: SaveCardTokenIframeURLRequest,
            options?: any
        ): AxiosPromise<PaymentIframeResponse> {
            return localVarFp
                .paymentApiControllerSaveCardTokenIframeURL(saveCardTokenIframeURLRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SetDefaultPaymentCardRequest} setDefaultPaymentCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerSetDefaultPaymentCard(
            setDefaultPaymentCardRequest: SetDefaultPaymentCardRequest,
            options?: any
        ): AxiosPromise<SetDefaultPaymentCardResponse> {
            return localVarFp
                .paymentApiControllerSetDefaultPaymentCard(setDefaultPaymentCardRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SetDefaultBankAccountRequest} setDefaultBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerSetPrimaryBankAccount(
            setDefaultBankAccountRequest: SetDefaultBankAccountRequest,
            options?: any
        ): AxiosPromise<SetDefaultBankAccountResponse> {
            return localVarFp
                .paymentApiControllerSetPrimaryBankAccount(setDefaultBankAccountRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerShouldGetVerifyBonus(options?: any): AxiosPromise<ShouldGetVerifyBonusResponse> {
            return localVarFp
                .paymentApiControllerShouldGetVerifyBonus(options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {TransferBetweenTPAccountsDto} transferBetweenTPAccountsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerTransferBetweenTPAccounts(
            transferBetweenTPAccountsDto: TransferBetweenTPAccountsDto,
            options?: any
        ): AxiosPromise<TransferBetweenTPAccountsResponse> {
            return localVarFp
                .paymentApiControllerTransferBetweenTPAccounts(transferBetweenTPAccountsDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {TransferBetweenTPAccountsValidationDto} transferBetweenTPAccountsValidationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerTransferBetweenTPAccountsValidation(
            transferBetweenTPAccountsValidationDto: TransferBetweenTPAccountsValidationDto,
            options?: any
        ): AxiosPromise<TransferBetweenTPAccountsValidationResponse> {
            return localVarFp
                .paymentApiControllerTransferBetweenTPAccountsValidation(
                    transferBetweenTPAccountsValidationDto,
                    options
                )
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MonetaryTransactionsTCBonusApproveRequest} monetaryTransactionsTCBonusApproveRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentApiControllerUpdateMonetaryTransactionsTCBonusApprove(
            monetaryTransactionsTCBonusApproveRequest: MonetaryTransactionsTCBonusApproveRequest,
            options?: any
        ): AxiosPromise<MonetaryTransactionsTCBonusApproveResponse> {
            return localVarFp
                .paymentApiControllerUpdateMonetaryTransactionsTCBonusApprove(
                    monetaryTransactionsTCBonusApproveRequest,
                    options
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     *
     * @param {CancelWithdrawalRequest} cancelWithdrawalRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerCancelWithdrawal(cancelWithdrawalRequest: CancelWithdrawalRequest, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerCancelWithdrawal(cancelWithdrawalRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CreateBankAccountRequest} createBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerCreateBankAccount(createBankAccountRequest: CreateBankAccountRequest, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerCreateBankAccount(createBankAccountRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CryptoWalletRequest} cryptoWalletRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerCreateCryptoWallet(cryptoWalletRequest: CryptoWalletRequest, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerCreateCryptoWallet(cryptoWalletRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CreateCcsWalletTransactionRequest} createCcsWalletTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerCreateWalletTransaction(
        createCcsWalletTransactionRequest: CreateCcsWalletTransactionRequest,
        options?: any
    ) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerCreateWalletTransaction(createCcsWalletTransactionRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CreateWithdrawalDto} createWithdrawalDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerCreateWithdrawal(createWithdrawalDto: CreateWithdrawalDto, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerCreateWithdrawal(createWithdrawalDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DeleteBankAccountRequest} deleteBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerDeleteBankAccount(deleteBankAccountRequest: DeleteBankAccountRequest, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerDeleteBankAccount(deleteBankAccountRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DeletePaymentCardRequest} deletePaymentCardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerDeletePaymentCard(deletePaymentCardRequest: DeletePaymentCardRequest, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerDeletePaymentCard(deletePaymentCardRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PaymentIframeRequest} paymentIframeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerDepositIframe(paymentIframeRequest: PaymentIframeRequest, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerDepositIframe(paymentIframeRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DepositWireDto} depositWireDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerDepositWire(depositWireDto: DepositWireDto, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerDepositWire(depositWireDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DepositWithTokenRequest} depositWithTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerDepositWithToken(depositWithTokenRequest: DepositWithTokenRequest, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerDepositWithToken(depositWithTokenRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerGetBankAccounts(options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerGetBankAccounts(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetMonetaryTransactionsByAccountIdRequest} getMonetaryTransactionsByAccountIdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerGetMonetaryTransactionsPerAccountId(
        getMonetaryTransactionsByAccountIdRequest: GetMonetaryTransactionsByAccountIdRequest,
        options?: any
    ) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerGetMonetaryTransactionsPerAccountId(getMonetaryTransactionsByAccountIdRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetPaymentTypesDto} getPaymentTypesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerGetPaymentTypes(getPaymentTypesDto: GetPaymentTypesDto, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerGetPaymentTypes(getPaymentTypesDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerGetRate(from: string, to: string, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerGetRate(from, to, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {OffRampDto} offRampDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerOffRamp(offRampDto: OffRampDto, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerOffRamp(offRampDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {OnRampDto} onRampDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerOnRamp(onRampDto: OnRampDto, options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerOnRamp(onRampDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SaveCardTokenIframeURLRequest} saveCardTokenIframeURLRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerSaveCardTokenIframeURL(
        saveCardTokenIframeURLRequest: SaveCardTokenIframeURLRequest,
        options?: any
    ) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerSaveCardTokenIframeURL(saveCardTokenIframeURLRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SetDefaultPaymentCardRequest} setDefaultPaymentCardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerSetDefaultPaymentCard(
        setDefaultPaymentCardRequest: SetDefaultPaymentCardRequest,
        options?: any
    ) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerSetDefaultPaymentCard(setDefaultPaymentCardRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SetDefaultBankAccountRequest} setDefaultBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerSetPrimaryBankAccount(
        setDefaultBankAccountRequest: SetDefaultBankAccountRequest,
        options?: any
    ) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerSetPrimaryBankAccount(setDefaultBankAccountRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerShouldGetVerifyBonus(options?: any) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerShouldGetVerifyBonus(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {TransferBetweenTPAccountsDto} transferBetweenTPAccountsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerTransferBetweenTPAccounts(
        transferBetweenTPAccountsDto: TransferBetweenTPAccountsDto,
        options?: any
    ) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerTransferBetweenTPAccounts(transferBetweenTPAccountsDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {TransferBetweenTPAccountsValidationDto} transferBetweenTPAccountsValidationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerTransferBetweenTPAccountsValidation(
        transferBetweenTPAccountsValidationDto: TransferBetweenTPAccountsValidationDto,
        options?: any
    ) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerTransferBetweenTPAccountsValidation(transferBetweenTPAccountsValidationDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MonetaryTransactionsTCBonusApproveRequest} monetaryTransactionsTCBonusApproveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentApiControllerUpdateMonetaryTransactionsTCBonusApprove(
        monetaryTransactionsTCBonusApproveRequest: MonetaryTransactionsTCBonusApproveRequest,
        options?: any
    ) {
        return PaymentApiFp(this.configuration)
            .paymentApiControllerUpdateMonetaryTransactionsTCBonusApprove(
                monetaryTransactionsTCBonusApproveRequest,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }
}
