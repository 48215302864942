import { UploadedFile } from 'components/Inputs/DropZone/dropzone.render';
import CheckoutDropZone from 'features/prop/scenes/Checkout/components/bankInformation/components/DropZone/CheckoutDropZone';
import FileDisplay from 'features/prop/scenes/Checkout/components/bankInformation/components/filesDisplay/FileDisplay';
import { uploadD_cleanUploadFile } from 'features/upload-documents/store/actions';
import { FileTypes } from 'features/upload-documents/upload-documents.scheme';
import { connect } from 'react-redux';
import React, { FunctionComponent, memo, useCallback, useRef, useState } from 'react';
import { RootState } from 'store/index';
import { UploadedFileWithType } from 'features/upload-documents/upload-documents.interface';
import { TFunction } from 'i18next';

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
    uploadD_cleanUploadFile,
};
interface CheckoutDropZoneProps {
    files: UploadedFileWithType[];
    maxSize?: number;
    setFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
    uploadD_cleanUploadFile: (fileName: string) => void;
    t: TFunction;
}

const UploadDocumentDropZone: FunctionComponent<CheckoutDropZoneProps> = ({
    files,
    maxSize = 50,
    t,
    setFiles,
    uploadD_cleanUploadFile,
}) => {
    const filesMemo = useRef<Map<string, UploadedFile>>(new Map());
    const onDrop = useCallback(
        (newFiles: UploadedFile[]): void => {
            newFiles.map((file) => {
                filesMemo.current.set(file.name ?? '', file);
                if (file.size && Math.trunc(file.size / 1000 / 1000) >= maxSize) {
                    file.errorMessage = t('investingManualKyc.verifyIdentity.exceededMaxSize', {
                        size: maxSize,
                    });
                }
                return {
                    ...file,
                    fileType: FileTypes.declarationOfDeposit,
                };
            });
            setFiles(() => {
                return [...filesMemo?.current?.values()];
            });
        },
        [maxSize, setFiles, t]
    );

    const handleRemoveFile = useCallback(
        (file: UploadedFile) => {
            uploadD_cleanUploadFile(file.name!);
            setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile.name !== file.name));
        },
        [setFiles, uploadD_cleanUploadFile]
    );
    return (
        <>
            <CheckoutDropZone onDrop={onDrop} />
            {!!files?.length && <FileDisplay handleRemoveFile={handleRemoveFile} files={files} />}
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(UploadDocumentDropZone));
