import { RootState } from 'store';
import { ENotificationsTypes } from './actionsTypes';
import { notificationGetAmount } from './interface';
import axios from 'services/axios';
import { Notification } from 'services/generatedClientFromSwagger';

export const notification_getAllNotifications = () => {
    return async (dispatch, getState) => {
        dispatch({ type: ENotificationsTypes.GET_ALL_NOTIFICATIONS_START });
        try {
            const res = await axios.NotificationsApi.notificationsApiControllerGetNotifications({
                limit: notificationGetAmount,
                offset: 0,
            });
            dispatch({
                type: ENotificationsTypes.GET_ALL_NOTIFICATIONS_SUCCESS,
                payload: res?.data?.notifications || [],
            });
        } catch (error) {
            dispatch({ type: ENotificationsTypes.GET_ALL_NOTIFICATIONS_FAILED });
        }
    };
};

export const notification_getMoreNotifications = () => {
    return async (dispatch, getState) => {
        dispatch({ type: ENotificationsTypes.GET_MORE_NOTIFICATIONS_START });
        try {
            const state: RootState = getState();
            const { offset, hasMoreData } = state.notification;
            if (!hasMoreData) return dispatch({ type: ENotificationsTypes.GET_MORE_NOTIFICATIONS_FAILED });
            const res = await axios.NotificationsApi.notificationsApiControllerGetNotifications({
                limit: notificationGetAmount,
                offset: offset + notificationGetAmount,
            });
            dispatch({
                type: ENotificationsTypes.GET_MORE_NOTIFICATIONS_SUCCESS,
                payload: res.data.notifications,
            });
        } catch (error) {
            dispatch({ type: ENotificationsTypes.GET_MORE_NOTIFICATIONS_FAILED });
        }
    };
};

export const notification_getSpecificNotification = (notificationId: string) => {
    return async (dispatch) => {
        dispatch({ type: ENotificationsTypes.GET_SPECIFIC_NOTIFICATION_START });
        try {
            const res = await axios.NotificationsApi.notificationsApiControllerGetNotification(notificationId);
            dispatch({
                type: ENotificationsTypes.GET_SPECIFIC_NOTIFICATION_SUCCESS,
                payload: res.data.notification,
            });
        } catch (error) {
            dispatch({ type: ENotificationsTypes.GET_SPECIFIC_NOTIFICATION_FAILED, payload: notificationId });
            return false;
        }
    };
};

export const notification_markNotifications = (notifications: Array<string>) => {
    console.log(notifications);

    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const oldNotifications = state.notification.notifications;
            const newNotifications = oldNotifications.map((notification) => {
                const newNotification = { ...notification };
                if (notifications.some((id) => notification._id === id)) {
                    newNotification.markedAt = new Date().toISOString();
                }
                return newNotification;
            });
            axios.NotificationsApi.notificationsApiControllerMarkNotifications(notifications);
            dispatch({
                type: ENotificationsTypes.MARK_NOTIFICATIONS,
                payload: newNotifications,
            });
        } catch (error) {
            return false;
        }
    };
};

export const notification_deleteNotifications = (notifications: Array<string>) => {
    console.log(notifications);
    return async (dispatch, getState) => {
        dispatch({ type: ENotificationsTypes.DELETE_NOTIFICATION_START });
        try {
            const state: RootState = getState();
            const oldNotifications = state.notification.notifications;
            const newNotifications = oldNotifications.reduce<Array<Notification>>((acc, notification) => {
                if (notifications.some((id) => notification._id === id)) {
                    return acc;
                }
                return [...acc, notification];
            }, []);
            axios.NotificationsApi.notificationsApiControllerDeleteNotifications(notifications);
            dispatch({
                type: ENotificationsTypes.DELETE_NOTIFICATION_SUCCESS,
                payload: { notifications: newNotifications, deletedNotifications: notifications },
            });
        } catch (error) {
            dispatch({ type: ENotificationsTypes.DELETE_NOTIFICATION_FAILED });
            return false;
        }
    };
};

export const notification_markNotificationWebPush = (token: string) => {
    return async (dispatch, getState) => {
        try {
            axios.NotificationsApi.notificationsApiControllerMarkPushNotifications({ token });
        } catch (error) {
            return false;
        }
    };
};
