export enum EPropCheckoutTypes {
    SET_CHECKOUT_PLAN_DATA = '[prop-checkout] set plan data',

    SET_SELECTED_PACKAGE = '[prop-checkout] set selected package',

    SET_SELECTED_PAYMENT_METHOD = '[prop-checkout] set selected payment method',

    SET_SELECTED_ADDONS = '[prop-checkout] set selected addons',

    VALIDATE_DISCOUNT_CODE_START = '[prop-checkout] validate discount code start',
    VALIDATE_DISCOUNT_CODE_SUCCESS = '[prop-checkout] validate discount code success',
    VALIDATE_DISCOUNT_CODE_FAIL = '[prop-checkout] validate discount code fail',

    RESET_DISCOUNT_CODE = '[prop-checkout] reset discount code',
    SET_DISCOUNT_CODE = '[prop-checkout] set discount code',

    GET_PROMO_CODE_START = '[prop-checkout] get promo code start',
    GET_PROMO_CODE_SUCCESS = '[prop-checkout] get promo code success',
    GET_PROMO_CODE_FAIL = '[prop-checkout] get promo code fail',
    RESET_CHECKOUT_DATA = '[prop-checkout] reset checkout data',
}
