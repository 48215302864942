import breakpoints from 'muiTheme/config/breakpoints';
import { useState, useEffect } from 'react';

export const enum DeviceType {
    'mobile' = 'mobile',
    'tablet' = 'tablet',
    'desktop' = 'desktop',
}
let debounce;

const useDeviceType = () => {
    const [deviceType, setDeviceType] = useState<DeviceType | ''>('');

    useEffect(() => {
        const handleDeviceTypeChange = () => {
            clearInterval(debounce);
            debounce = setTimeout(() => {
                switch (true) {
                    case window.innerWidth < breakpoints.sm:
                        setDeviceType(DeviceType.mobile);
                        break;
                    case window.innerWidth < breakpoints.md:
                        setDeviceType(DeviceType.tablet);
                        break;
                    default:
                        setDeviceType(DeviceType.desktop);
                }
            }, 1000);
        };

        handleDeviceTypeChange();
        window.addEventListener('resize', handleDeviceTypeChange);

        return () => {
            window.removeEventListener('resize', handleDeviceTypeChange);
        };
    }, []);

    return deviceType;
};

export default useDeviceType;
