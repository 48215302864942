import React from 'react';
import PageLayout from 'components/Layouts/PageLayout';
import imgSrc from 'assets/images/createRealAccount.png';
import mobileImgSrc from 'assets/images/createRealAccount_mobile.png';
import useStyles from './create_real_account.styles';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { Link, useLocation } from 'react-router-dom';
import { Pages } from 'interfaces/main';

export interface CreateRealAccountProps {}

const CreateRealAccount: React.FunctionComponent<CreateRealAccountProps> = () => {
    const classes = useStyles({ imgSrc, mobileImgSrc });
    const { t } = useTranslation('myWallet');

    const { pathname } = useLocation();

    const tPrefix = pathname.includes(Pages.MY_WALLET__DEPOSIT) ? 'deposit' : 'withdraw';

    return (
        <PageLayout fullHeight fullWidth infoData>
            <div className={classes.img} />
            <Typography variant="h1" className={classes.title}>
                {t(`create-real.${tPrefix}.title`)}
            </Typography>
            <Typography variant="body1" color="textSecondary">
                {t(`create-real.${tPrefix}.description`)}
            </Typography>
            <Link to={`/${Pages.TRADING_ACCOUNTS}/${Pages.TRADING_ACCOUNTS__CREATE}`}>
                <Button variant="contained" color="primary" className={classes.btn}>
                    {t(`create-real.${tPrefix}.btn`)}
                </Button>
            </Link>
        </PageLayout>
    );
};

export default CreateRealAccount;
