export default {
    light: {
        common: { black: '#000', white: '#fff' },
        background: { error: '#fad9dc', paper: '#fff', default: '#fff', action: '#F4F5F7' },
        side_menu: { background: '#ffffff', text: '#717284' },
        default: { main: '#ecf0f9', light: '#faf7fb', dark: '#ccd8e5', contrastText: '#000000de' },
        primary: { main: '#1570EF', contrastText: '#fff' },
        secondary: { main: '#7fd7a9', contrastText: '#fff' },
        success: { main: '#00a34e', contrastText: '#fff' },
        error: { main: '#f74633', contrastText: '#fff' },
        props: { default: '#ecf0f9', red: 'red' },
        text: {
            default: '#11142d',
            lightDefault: '#717284',
            primary: '#11142d',
            secondary: '#6585a4',
            action: '#2a52d3',
            disabled: '#000000',
            hint: '#00000061',
            textPrimary: '#0065ff',
        },
        tooltip: { background: '#1b1d21', text: '#ffffff' },
        disabled: { background: '#ecf0f9', backgroundDark: '#6585a4', border: '#d1dce8', contrastText: '#fff' },
    },
    dark: {
        common: { black: '#000', white: '#fff' },
        background: { error: '#fad9dc', paper: '#1f2128', default: '#1f2128', action: '#2F323C' },
        side_menu: { background: '#1f2128', text: '#a5a5a5' },
        default: { main: '#313a48', contrastText: '#fff' },
        primary: { main: '#2a52d3', contrastText: '#fff' },
        secondary: { main: '#7fd7a9', contrastText: '#fff' },
        success: { main: '#00a34e', contrastText: '#fff' },
        error: { main: '#f74633', contrastText: '#fff' },
        props: { default: '#ecf0f9', red: 'red' },
        text: {
            default: '#ffffff',
            lightDefault: '#ffffff',
            primary: '#ffffff',
            secondary: '#a7a8b1',
            action: '#0065ff',
            disabled: '#ffffff',
            hint: '#ffffff61',
            textPrimary: '#0065ff',
        },
        tooltip: { background: '#ffffff', text: '#11142d' },
        disabled: { background: '#242731', backgroundDark: '#6585a4', border: '#35363e', contrastText: '#fff' },
    },
};

export const newColors = {
    gray100: '#F2F4F7',
    blue600: '#1570EF',
    white: '#FFFFFF',
    gray500: '#667085',
    gray50: '#F9FAFB',
    gray700: '#344054',
    green500: '#16b364',
    gray300: '#D0D5DD',
    error500: '#F04438',
    gray200: '#EAECF0',
    gray600: '#475467',
    gray900: '#101828',
};
