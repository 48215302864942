import { isLaptop, isRtl } from './../../helpers/device_helper';
import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    whatsAppWrapper: {
        zIndex: 1010,
        position: 'fixed',
        bottom: '8rem',
        right: '2rem',
        left: 'unset',
        ...isRtl({
            right: 'unset',
            left: '2rem',
        }),
    },
    buttonRoot: {
        maxWidth: '6rem',
        padding: '0.4rem',
        transition: 'max-width 0.3s linear',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        border: `0.1rem solid ${theme.palette.action.disabledBackground}`,
        '&& p': {
            display: 'none',
            whiteSpace: 'nowrap',
        },
        '&:hover,focused': {
            backgroundColor: theme.palette.background.paper,
        },
        ...isLaptop({
            '&:hover': {
                maxWidth: '50rem',
                '&& p': {
                    marginLeft: '0.8rem',
                    marginRight: 'unset',
                    display: 'inline',
                    ...isRtl({
                        marginRight: '0.8rem',
                        marginLeft: 'unset',
                    }),
                },
            },
        }),
    },
    buttonLabel: {
        display: 'flex',
    },
}));
