export const scrollToElementIfNotInView = (el) => {
    try {
        const topOfPage = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        const heightOfPage = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        let elY = 0;
        let elH = 0;
        for (var p = el; p && p.tagName !== 'BODY'; p = p.offsetParent) {
            elY += p.offsetTop;
        }
        elH = el.offsetHeight;

        if (topOfPage + heightOfPage < elY + elH) {
            scrollToElement(el);
        } else if (elY < topOfPage) {
            scrollToElement(el);
        }
    } catch (error) {
        console.log(error);
    }
};

export const scrollToElement = (el) => {
    el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
    });
};
