import { numberReplace } from './numberReplace';

/**
 * By default seperating thousands with commas (',').
 * @param {*} number - the number you want to format.
 * @param {*} maximumFractionDigits - maximum digits after the dot (.).
 * @param {*} style - "decimal" / "currency" / "percent". defualt: "decimal".
 * @param {*} notation - "standard" | "scientific" | "engineering" | "compact". defualt: "standard".
 */

type Notation = 'standard' | 'scientific' | 'engineering' | 'compact' | undefined;
export function numberPipe(
    number,
    maximumFractionDigits = 2,
    minimumFractionDigits = 2,
    style = 'decimal',
    excludeComma: boolean = false,
    notation: Notation = 'standard',
    locales: string = 'he-IL',
    currency?: string
) {
    const originalNumber = number;
    try {
        number = (number || '').toString();
        number = number.replace(new RegExp(',', 'g'), '');
        const splittedDots = number.split('.');
        if (splittedDots.length > 2) {
            number = `${splittedDots[0]}.${splittedDots[1]}`;
        }

        const newNumber = isNaN(+number)
            ? originalNumber
            : new Intl.NumberFormat(locales, {
                  maximumFractionDigits,
                  minimumFractionDigits,
                  style,
                  currency,
                  notation,
              }).format(number);
        return excludeComma ? numberReplace(newNumber) : newNumber;
    } catch (e) {}
    return originalNumber;
}
