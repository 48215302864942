import tradingForProImage from 'assets/images/trading_for_pro.png';
import EconomicCalendarImage from 'assets/images/economic_calendar.png';
import AnalystViewImage from 'assets/images/analyst_view.png';
import FeaturedIdeadImage from 'assets/images/featured_ideas.png';
import EducationOnDemand from 'assets/images/education_on_demand.png';
import WebTvImage from 'assets/images/web_tv.png';
import { Pages } from 'interfaces/main';
import { user_userInfo } from 'store/user/selectors';
import { AccountInfoParsed } from 'services/generatedClientFromSwagger/models';
import { getImages } from 'helpers/getAssets';
const images = getImages();

export interface TradingAnalysisHomeProps {
    userInfo: AccountInfoParsed;
}

export const mapStateToProps = (state) => ({
    userInfo: user_userInfo(state),
});

export enum TradingAnalysisPages {
    'tradingForPro' = 'tradingForPro',
    'webTv' = 'webTv',
    'analystView' = 'analystView',
    'economicCalendar' = 'economicCalendar',
    'educationOnDemand' = 'educationOnDemand',
    'featuredIdeas' = 'featuredIdeas',
}

export enum TradingAnalysisTEPages {
    teCourses = 'courses',
    teEbook = 'ebook',
    teGlossary = 'glossary',
    teCalendar = 'calendar',
    teDailyNews = 'daily_news',
    teNews = 'news',
    teCalculators = 'calculators',
    teMarketWatchCurrencies = 'market_watch_currencies',
    teMarketWatchStocks = 'market_market_stocks',
    teMarketWatchCommodities = 'market_watch_commodities',
}

interface Links {
    imgSrc: string;
    titleT: string;
    descriptionT: string;
    link: string;
    pageName: TradingAnalysisPages;
    guardFeature?: string;
    t4pAnalysisGuard?: boolean;
    hidden?: 'smDown' | 'smUp' | 'xlDown' | 'xlUp' | 'xsDown' | 'xsUp' | 'mdUp' | 'mdDown' | 'lgUp' | 'lgDown';
    status?: 'disabled' | 'enabled';
}

const tradingEducationLinks: Array<Links> = Object.entries(TradingAnalysisTEPages).map(([key, value]) => {
    const image = (): string => {
        const imagePath = key;
        if (images[imagePath]) {
            return images[imagePath].default;
        } else {
            return images['trading_analysis_placeholder'].default;
        }
    };

    return {
        pageName: TradingAnalysisTEPages[key],
        guardFeature: key,
        imgSrc: image(),
        titleT: `trading-analysis:trading_education.${key}.title`,
        descriptionT: `trading-analysis:trading_education.${key}.description`,
        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__TRADING_EDUCATION}/${value}`,
    };
});

export const links: Array<Links> = [
    {
        pageName: TradingAnalysisPages['tradingForPro'],
        imgSrc: tradingForProImage,
        t4pAnalysisGuard: true,
        guardFeature: 't4pTradingAnalysis',
        titleT: 'trading-analysis:trading_for_pro.title',
        descriptionT: 'trading-analysis:trading_for_pro.description',
        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__TRADING_FOR_PRO}`,
    },
    {
        pageName: TradingAnalysisPages['economicCalendar'],
        guardFeature: 'economicCalendar',
        imgSrc: EconomicCalendarImage,
        titleT: 'trading-analysis:economic_calendar.title',
        descriptionT: 'trading-analysis:economic_calendar.description',
        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__ECONOMIN_CALENDAR}`,
    },
    {
        pageName: TradingAnalysisPages['analystView'],
        guardFeature: 'analystView',
        imgSrc: AnalystViewImage,
        titleT: 'trading-analysis:analyst_view.title',
        descriptionT: 'trading-analysis:analyst_view.description',
        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__ANALYST_VIEW}`,
    },
    {
        pageName: TradingAnalysisPages['featuredIdeas'],
        guardFeature: 'featuredIdeas',
        imgSrc: FeaturedIdeadImage,
        titleT: 'trading-analysis:featured_ideas.title',
        descriptionT: 'trading-analysis:featured_ideas.description',
        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__FEATURED_IDEAS}`,
    },
    {
        pageName: TradingAnalysisPages['educationOnDemand'],
        guardFeature: 'educationOnDemand',
        imgSrc: EducationOnDemand,
        titleT: 'trading-analysis:education_on_demand.title',
        descriptionT: 'trading-analysis:education_on_demand.description',
        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__EDUCATION_ON_DEMAND}`,
        hidden: 'smDown',
    },
    {
        pageName: TradingAnalysisPages['webTv'],
        guardFeature: 'webTV',
        imgSrc: WebTvImage,
        titleT: 'trading-analysis:web_tv.title',
        descriptionT: 'trading-analysis:web_tv.description',
        link: `/${Pages.TRADING_ANALYSIS}/${Pages.TRADING_ANALYSIS__WEB_TV}`,
    },
    ...tradingEducationLinks,
];
