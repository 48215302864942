import { makeStyles } from '@material-ui/core';
import { isTablet } from 'helpers/device_helper';

interface Props {
    imgSrc: string;
}

export default makeStyles((theme) => ({
    max_width: {
        maxWidth: '45.6rem',
    },
    subtitle: {
        fontWeight: 600,
    },
    text: {
        color: theme.palette.text.lightDefault,
    },
    img: (props: Props) => ({
        backgroundImage: `url(${props.imgSrc})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '0.8rem',
        height: '12rem',
        ...isTablet({
            height: '18rem',
        }),
    }),
}));
