import ToHomePage from 'components/PrivateRoute/ToHomePage';
import config, { FeatureFlag } from 'config/common';
import PropRouter from './scenes/prop.router';

export const prop_isPropActive = config.featuresFlags[FeatureFlag.PROP];

export const prop_mainPage = () => {
    if (!prop_isPropActive) return ToHomePage;
    return PropRouter;
};

export default prop_mainPage();
