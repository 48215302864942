import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    user_qualifiedToDeposit,
    user_selectedTpAccount,
    user_userInfo,
    user_donePreDepositCondition,
    user_refreshingUser,
    user_notQualifiedToUsResidency,
    user_loader,
} from 'store/user/selectors';
import { Pages } from 'interfaces/main';
import {
    AccountInfoParsed,
    TradingPlatformAccountInfo,
    TradingPlatformInfoTypeEnum,
} from 'services/generatedClientFromSwagger/models';
import SelectRealAccount from './Components/SelectRealAccount';
import CreateRealAccount from './Components/CreateRealaccount';
import NotQualifiedToDeposit from './Components/NotQualifiedToDeposit';
import { kyc_isFailed, kyc_completed, kyc_currentStep, kyc_maxStep } from 'features/kyc/store/selector';
import { failedTerminationActive } from 'features/kyc/kyc-functions';
import { reverseSol_completed } from 'features/ReverseSolicitation/store/selector';
import { payment_getPaymentTypes } from 'store/payment/actions';
import { payment_depositTabsLoading } from 'store/payment/selectors';
import LoaderFullScreen from 'components/LoaderFullScreen';
import NotQualifieldToUsResidency from './Components/NotQualifieldToUsResidency';
import config from 'config/common';
import ClientSupport from 'scenes/ClientSupport';

export interface DepositGuardProps {
    component: any;
    userInfo: AccountInfoParsed;
    user_selectedTpAccount: TradingPlatformAccountInfo;
    user_donePreDepositCondition: boolean;
    user_notQualifiedToUsResidency: boolean;
    qualifiedToDeposit: boolean;
    kyc_isFailed: boolean;
    kyc_completed: boolean;
    kyc_currentStep: number;
    reverseSol_completed: boolean;
    payment_depositTabsLoading: boolean;
    user_refreshingUser: boolean;
    payment_getPaymentTypes: (tpId: string) => void;

    customPreDepositUrl?: string;

    user_loader: boolean;
}

const DepositGuard: React.FunctionComponent<DepositGuardProps> = (props) => {
    const {
        component: Component,
        userInfo,
        user_selectedTpAccount,
        user_notQualifiedToUsResidency,
        qualifiedToDeposit,
        user_donePreDepositCondition,
        kyc_isFailed,
        kyc_completed,
        kyc_currentStep,
        reverseSol_completed,
        payment_getPaymentTypes,
        payment_depositTabsLoading,
        user_refreshingUser,
        customPreDepositUrl,
        user_loader,
        ...restProps
    } = props;

    React.useEffect(() => {
        payment_getPaymentTypes(user_selectedTpAccount.id || '');
    }, [payment_getPaymentTypes, user_selectedTpAccount]);

    if (config.featuresFlags['depositRedirectToSupport']) {
        return <ClientSupport />;
    }

    if (payment_depositTabsLoading || user_refreshingUser || user_loader) {
        return <LoaderFullScreen />;
    }

    if (kyc_isFailed && failedTerminationActive) {
        return <Redirect to={`/${Pages.KYC}/${Pages.KYC__12}`} />;
    }

    if (!kyc_completed) {
        const nextStep = kyc_currentStep === kyc_maxStep() ? kyc_currentStep : kyc_currentStep + 1;
        return <Redirect to={`/${Pages.KYC}/${Pages[`KYC__${nextStep}`]}`} />;
    }

    if (user_notQualifiedToUsResidency) {
        return <NotQualifieldToUsResidency />;
    }

    if (!user_donePreDepositCondition) {
        return <Redirect to={customPreDepositUrl || `/${Pages.MY_WALLET}/${Pages.MY_WALLET__PRE_CONDITION}`} />;
    }

    if (!reverseSol_completed) {
        return <Redirect to={`/${Pages.REVERSE_SOLICITATION}`} />;
    }

    if (
        !userInfo.tradingPlatformAccounts?.some((tp) => tp.tradingPlatform?.type === TradingPlatformInfoTypeEnum.Real)
    ) {
        return <CreateRealAccount />;
    }

    if (user_selectedTpAccount && user_selectedTpAccount.tradingPlatform?.type === TradingPlatformInfoTypeEnum.Demo) {
        return <SelectRealAccount />;
    }

    if (!qualifiedToDeposit) {
        return <NotQualifiedToDeposit />;
    }

    return <Route {...restProps} render={(props) => <Component {...props} />} />;
};

export const mapStateToProps = (state) => ({
    userInfo: user_userInfo(state),
    user_selectedTpAccount: user_selectedTpAccount(state),
    qualifiedToDeposit: user_qualifiedToDeposit(state),
    user_notQualifiedToUsResidency: user_notQualifiedToUsResidency(state),
    kyc_isFailed: kyc_isFailed(state),
    user_donePreDepositCondition: user_donePreDepositCondition(state),
    kyc_completed: kyc_completed(state),
    kyc_currentStep: kyc_currentStep(state),
    reverseSol_completed: reverseSol_completed(state),
    payment_depositTabsLoading: payment_depositTabsLoading(state),
    user_refreshingUser: user_refreshingUser(state),
    user_loader: user_loader(state),
});

export const mapDispatchToProps = {
    payment_getPaymentTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DepositGuard));
