import React from 'react';
import { makeStyles } from '@material-ui/core';
import logoLightMode from 'assets/images/logo_light_mode.png';
import logoDarkMode from 'assets/images/logo_dark_mode.png';
import { Link } from 'react-router-dom';
import { isRtl, isTablet } from 'helpers/device_helper';

export interface LogoProps {
    fromMenu?: boolean;
    alwaysShow?: boolean;
    id?: string;
    width?: string;
    bigLogo?: boolean;
}
interface LogoStyleProps {
    width?: string;
    bigLogo?: boolean;
    fromMenu?: boolean;
    alwaysShow?: boolean;
}
const useStyles = makeStyles((theme) => ({
    login_link: (props: LogoStyleProps) => ({
        display: props.fromMenu ? 'block' : 'none',
        ...isTablet({
            display: props.fromMenu ? 'none' : 'block',
        }),
        ...(props.alwaysShow && {
            display: 'block',
        }),
    }),
    logo_img: (props: LogoStyleProps) => ({
        display: 'block',
        backgroundImage: `url(${theme.palette.type === 'dark' ? logoDarkMode : logoLightMode})`,
        backgroundPosition: isRtl() ? 'right center' : 'left center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: props.bigLogo ? '8.2rem' : '3.6rem',
        width: props.width ? props.width : '12.0rem',
        cursor: 'pointer',
    }),
}));

const Logo: React.FunctionComponent<LogoProps> = ({ fromMenu, alwaysShow, id, width, bigLogo }) => {
    const classes = useStyles({ fromMenu, alwaysShow, width, bigLogo });
    return (
        <Link to="/" replace className={classes.login_link}>
            <span className={classes.logo_img} id={id} />
        </Link>
    );
};

export default Logo;
