export const fileToBase64: (file: any) => Promise<string | undefined> = (file) =>
    new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(undefined);
        } catch (error) {
            reject(undefined);
        }
    });

export function removeBase64DataType(text): string | undefined {
    if (!text) return undefined;
    return text?.split('base64,')?.[1];
}
