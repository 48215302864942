import { EREVERSESOLICITATIONTypes } from 'features/ReverseSolicitation/store/actionsTypes';
import { ESystemTypes } from 'store/system/actionsTypes';
import { EUserTypes } from './actionsTypes';
import { UserReducerState } from './interfaces';

const initialState: UserReducerState = {
    loader: true,
    userInfo: {},
    selectedTPAccount: undefined,
    refreshingUser: false,
    isReachedCreditVolume: undefined,
    isReachedCreditVolumeLoader: false,
    verifyEmailSent: false,

    sendPhoneVerificationLoading: false,
    phoneVerificationSent: false,
    verifyPhoneLoading: false,
    verifyEmailLoading: false,
    permissionStatus: 'default',
    referralCode: '',
    referralCodeLoader: false,
};

const systemReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EUserTypes.START_LOADER:
            return {
                ...state,
                loader: true,
            };
        case EUserTypes.STOP_LOADER:
            return {
                ...state,
                loader: false,
            };
        case EUserTypes.GET_USER_DATA_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case EUserTypes.GET_USER_DATA_FAILED: {
            return {
                ...state,
                loader: false,
            };
        }
        case EUserTypes.GET_USER_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                userInfo: payload,
            };
        }
        case EUserTypes.REFRESH_USER_DATA_START: {
            return {
                ...state,
                refreshingUser: true,
            };
        }
        case EUserTypes.REFRESH_USER_DATA_FAILED: {
            return {
                ...state,
                refreshingUser: false,
            };
        }
        case EUserTypes.REFRESH_USER_DATA_SUCCESS: {
            return {
                ...state,
                refreshingUser: false,
                userInfo: payload,
            };
        }
        case EUserTypes.UPDATE_USER_INFO_START: {
            return {
                ...state,
                loader: true,
                updateUserAddressStatus: undefined,
            };
        }
        case EUserTypes.UPDATE_USER_INFO_SUCCESS: {
            return {
                ...state,
                loader: false,
                userInfo: payload,
                updateUserAddressStatus: 'success',
            };
        }
        case EUserTypes.UPDATE_USER_INFO_FAILED: {
            return {
                ...state,
                loader: false,
                updateUserAddressStatus: 'failed',
            };
        }
        case EUserTypes.UPDATE_USER_INFO_RESET: {
            return {
                ...state,
                loader: false,
                updateUserAddressStatus: undefined,
            };
        }
        case EUserTypes.CREATE_TP_ACCOUNT_SUCCESS: {
            return {
                ...state,
                userInfo: payload,
            };
        }
        case EUserTypes.CREATE_TP_ACCOUNT_FAILED: {
            return {
                ...state,
                loader: false,
            };
        }
        case EUserTypes.CHANGE_SELECTED_TP: {
            return {
                ...state,
                selectedTPAccount: payload,
            };
        }
        case EUserTypes.IS_REACHED_CREDIT_VOLUME_START: {
            return {
                ...state,
                isReachedCreditVolumeLoader: true,
            };
        }
        case EUserTypes.IS_REACHED_CREDIT_VOLUME_SUCCESS: {
            return {
                ...state,
                isReachedCreditVolumeLoader: false,
                isReachedCreditVolume: payload,
            };
        }
        case EUserTypes.UPDATE_CRYPTO_WALLET: {
            return {
                ...state,
                userInfo: { ...state.userInfo, hasCcsWallet: payload },
            };
        }
        case EUserTypes.IS_REACHED_CREDIT_VOLUME_FAILED: {
            return {
                ...state,
                isReachedCreditVolumeLoader: false,
            };
        }
        case EREVERSESOLICITATIONTypes.UPDATE_DATA_SUCCESS: {
            return {
                ...state,
                userInfo: payload,
            };
        }

        case EUserTypes.SEND_EMAIL_VERIFICATION_TO_ACCOUNT_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case EUserTypes.SEND_EMAIL_VERIFICATION_TO_ACCOUNT_SUCCESS: {
            return {
                ...state,
                loader: false,
                verifyEmailSent: true,
            };
        }
        case EUserTypes.SEND_EMAIL_VERIFICATION_TO_ACCOUNT_FAILED: {
            return {
                ...state,
                loader: false,
                verifyEmailSent: false,
            };
        }
        case EUserTypes.SEND_PHONE_VERIFICATION_TO_ACCOUNT_START: {
            return {
                ...state,
                sendPhoneVerificationLoading: true,
            };
        }
        case EUserTypes.SEND_PHONE_VERIFICATION_TO_ACCOUNT_SUCCESS: {
            return {
                ...state,
                sendPhoneVerificationLoading: false,
                phoneVerificationSent: true,
            };
        }
        case EUserTypes.SEND_PHONE_VERIFICATION_TO_ACCOUNT_FAILED: {
            return {
                ...state,
                sendPhoneVerificationLoading: false,
            };
        }
        case EUserTypes.VERIFY_PHONE_START: {
            return {
                ...state,
                verifyPhoneLoading: true,
            };
        }
        case EUserTypes.VERIFY_PHONE_SUCCESS: {
            return {
                ...state,
                verifyPhoneLoading: false,
            };
        }
        case EUserTypes.VERIFY_PHONE_FAILED: {
            return {
                ...state,
                verifyPhoneLoading: false,
            };
        }
        case EUserTypes.VERIFY_EMAIL_START: {
            return {
                ...state,
                verifyEmailLoading: true,
            };
        }
        case EUserTypes.VERIFY_EMAIL_FINISHED: {
            return {
                ...state,
                verifyEmailLoading: false,
            };
        }
        case EUserTypes.VERIFY_EMAIL_FAILED: {
            return {
                ...state,
                verifyEmailLoading: false,
            };
        }
        case EUserTypes.REGISTER_VERIFIED: {
            return {
                ...state,
                user_verified: payload,
            };
        }
        case EUserTypes.UPDATE_ACCOUNT_KYC_DATA_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case EUserTypes.UPDATE_ACCOUNT_KYC_DATA_FAILED: {
            return {
                ...state,
                loader: false,
            };
        }
        case EUserTypes.UPDATE_ACCOUNT_KYC_DATA_SUCCESS: {
            return {
                ...state,
                loader: false,
                userInfo: {
                    ...state.userInfo,
                    regulationKyc: payload,
                },
            };
        }
        case EUserTypes.UPDATE_ACCOUNT_KYC_ACCEPT_RISK_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case EUserTypes.UPDATE_ACCOUNT_KYC_ACCEPT_RISK_FAILED: {
            return {
                ...state,
                loader: false,
            };
        }
        case EUserTypes.UPDATE_ACCOUNT_KYC_ACCEPT_RISK_SUCCESS: {
            return {
                ...state,
                loader: false,
                userInfo: {
                    ...state.userInfo,
                    regulationKyc: payload,
                },
            };
        }

        ////////////////////////////////////
        case EUserTypes.UPDATE_ACCOUNT_PUSH_NOTIFICATION_PERMISSION_START: {
            return {
                ...state,
            };
        }
        case EUserTypes.UPDATE_ACCOUNT_PUSH_NOTIFICATION_PERMISSION_SUCCESS: {
            return {
                ...state,
            };
        }
        case EUserTypes.UPDATE_ACCOUNT_PUSH_NOTIFICATION_PERMISSION_FAILED: {
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    permissionStatus: payload,
                },
            };
        }
        ////////////////////////////////////

        case EUserTypes.GET_REFERRAL_CODE_START:
            return {
                ...state,
                referralCodeLoader: true,
            };
        case EUserTypes.GET_REFERRAL_CODE_SUCCESS:
            return {
                ...state,
                referralCode: payload,
                referralCodeLoader: false,
            };
        case EUserTypes.GET_REFERRAL_CODE_FAILED: {
            return {
                ...state,
                referralCodeLoader: false,
            };
        }

        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                userInfo: [],
                loader: false,
                selectedTPAccount: undefined,
                verifyEmailSent: undefined,
                phoneVerificationSent: undefined,
            };
        }
        default:
            return state;
    }
};

export default systemReducer;
