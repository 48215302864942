import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Root from 'Root';
import LoaderFullScreen from 'components/LoaderFullScreen';
import * as serviceWorker from './serviceWorker';
import './index.css';

ReactDOM.render(
    <Suspense fallback={<LoaderFullScreen />}>
        <Root />
    </Suspense>,
    document.getElementById('root')
);

serviceWorker.unregister();
