import Button from 'components/Button';
import Typography from 'components/Typography';
import { Pages } from 'interfaces/main';
import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './notification-tooltip.styles';
import { TFunction } from 'i18next';
import QuickPreviewMessage from '../QuickPreviewMessage';
import config from 'config/common';
import Icon, { IconsType } from 'components/Icon';
import { Notification } from 'services/generatedClientFromSwagger';
import { Portal } from '@material-ui/core';

export interface NotificationTooltipProps {
    closeMenu: () => void;
    t: TFunction;
    notifications: Array<Notification>;
    markNotifications: (notifications: Array<string>) => void;
}

const NotificationTooltip: React.FunctionComponent<NotificationTooltipProps> = ({
    closeMenu,
    t,
    notifications,
    markNotifications,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>
                <Typography variant="h5" color="textPrimary">
                    {t('title')}
                </Typography>
                {config.featuresFlags['notificationSettings'] && (
                    <Link to={`/${Pages.ACCOUNT_SETTINGS}?tab=emailSettings`}>
                        <Button variant="text">
                            <Icon name={IconsType.account_settings} />
                        </Button>
                    </Link>
                )}
            </div>
            <div className={classes.contentWrapper}>
                <div className={classes.seperator} />
                {notifications.map((message) => (
                    <React.Fragment key={message._id}>
                        <QuickPreviewMessage
                            markNotification={markNotifications}
                            message={message}
                            closeMenu={closeMenu}
                        />
                        <div className={classes.seperator} />
                    </React.Fragment>
                ))}
            </div>
            {notifications.length === 0 && (
                <div className={classes.messageText}>
                    <React.Fragment>
                        <Typography variant="body1" color="textPrimary">
                            {t('no_result')}
                        </Typography>
                    </React.Fragment>
                </div>
            )}

            <Portal>
                <div className={classes.overlay} onClick={closeMenu} />
            </Portal>
        </div>
    );
};

export default React.memo(NotificationTooltip);
