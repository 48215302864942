import { SnackbarProps } from 'components/Snackbar';
import { IFilterModel } from 'components/EnhancedTable';
import { ThemeOptions } from 'interfaces/main';
import { CountryInfo, GetUserCurrenciesResponse } from 'services/generatedClientFromSwagger/models';
import { DeviceType } from 'hooks/useDeviceType';

export interface SystemReducerState {
    loader: boolean;
    theme: ThemeOptions;
    sideMenuState: boolean;
    dataLoader: boolean;
    countries: Array<CountryInfo>;
    currencies: GetUserCurrenciesResponse;
    snackbarIsOpen: boolean;
    snackbarObject: SnackbarProps | null;
    scrollToTable: any;
    filters: IFilterModel;
    filterState: boolean;
    walkThoughStatus: boolean;
    bottomNavBar: boolean;
    deviceType: DeviceType | '';
    educationPopup?: boolean;
}

export enum PopupIframesTypes {
    'calendar' = 'calendar',
    'education' = 'education',
    'deposit' = 'deposit',
    'other' = 'other',
}
export enum TimeFrames {
    '1M' = '1M',
    '5M' = '5M',
    '15M' = '15M',
    '30M' = '30M',
    '1H' = '1H',
    '4H' = '4H',
    '1D' = '1D',
    '1W' = '1W',
}

export enum OHLCRequestTimeFrame {
    _1 = '1',
    _5 = '5',
    _15 = '15',
    _30 = '30',
    _60 = '60',
    _240 = '240',
    _1440 = '1440',
    _10080 = '10080',
    _21600 = '21600',
}

export const timeFrameToNumber = (timeFrame: TimeFrames) => {
    switch (timeFrame) {
        case TimeFrames['1M']:
            return OHLCRequestTimeFrame._1;
        case TimeFrames['5M']:
            return OHLCRequestTimeFrame._5;
        case TimeFrames['15M']:
            return OHLCRequestTimeFrame._15;
        case TimeFrames['30M']:
            return OHLCRequestTimeFrame._30;
        case TimeFrames['1H']:
            return OHLCRequestTimeFrame._60;
        case TimeFrames['4H']:
            return OHLCRequestTimeFrame._240;
        case TimeFrames['1D']:
            return OHLCRequestTimeFrame._1440;
        case TimeFrames['1W']:
            return OHLCRequestTimeFrame._10080;
    }
};
