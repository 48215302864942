import { prop_myChallenges_getFilteredChallenges } from 'features/prop/store/my-challenges/selectors';
import { DeviceType } from 'hooks/useDeviceType';
import { PropChallenge, PickListInfo } from 'services/generatedClientFromSwagger';
import { system_toggleSideMenu } from 'store/system/actions';
import { system_selectorDeviceTypeSize } from 'store/system/selectors';
import { user_accountType, user_fullName } from 'store/user/selectors';

export interface PrivateHeaderProps {
    system_toggleSideMenu: () => void;
    user_accountType: PickListInfo | undefined;
    action_networkConnectionState: (status: boolean) => void;
    networkConnectionStateSelector: boolean;
    user_fullName: string;
    system_selectorDeviceTypeSize: DeviceType | '';
    challenges: PropChallenge[];
}

export const mapStateToProps = (state) => ({
    user_accountType: user_accountType(state),
    user_fullName: user_fullName(state),
    challenges: prop_myChallenges_getFilteredChallenges(state),
    system_selectorDeviceTypeSize: system_selectorDeviceTypeSize(state),
});

export const mapDispatchToProps = {
    system_toggleSideMenu,
};
