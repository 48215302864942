import { EPaymentTypes } from './actionsTypes';
import { batch } from 'react-redux';
import axios from 'services/axios';
import { RootState } from '..';
import i18n from 'i18n';
import { DepositResult, DepositTab, depositTabsMetaData, DepositTypes } from './interfaces';
import config from 'config/common';
import mixPanel, { mixPanelEventsTypes } from 'helpers/mixPanel';
import { accounts_getMonetaryTransactionsHistory } from '../accounts/actions';
import {
    CreateMonetaryTransactionResponse,
    CreateWithdrawalDto,
    DepositWireDto,
    DepositWireResponse,
    PaymentIframeRequest,
    PaymentIframeResponse,
    PropGoalTypeEnum,
    ResultInfoCodeEnum,
    TransferBetweenTPAccountsDto,
    TransferBetweenTPAccountsValidationDto,
} from 'services/generatedClientFromSwagger';
import { AxiosResponse } from 'axios';
import { extractRelevantConditions } from 'helpers/extractConditions';

export const payment_setDepositResult = (depositResult: DepositResult) => {
    return async (dispatch) => {
        dispatch({ type: EPaymentTypes.DEPOSIT_DONE, payload: depositResult });
        mixPanel.track(mixPanelEventsTypes.DEPOSIT_PROCESS_DONE, depositResult);
    };
};

export const payment_resetDepositErrors = () => {
    return async (dispatch) => {
        dispatch({ type: EPaymentTypes.RESET_DEPOSIT_IFRAME_ERROR });
    };
};

export const payment_resetDepositResult = () => ({
    type: EPaymentTypes.RESET_DEPOSIT_RESULT,
});

export const payment_resetState = () => ({
    type: EPaymentTypes.RESET_STATE,
});

export const payment_setDepositType = (depositType: { tabName: string; type: DepositTypes; subType?: string }) => ({
    type: EPaymentTypes.SET_DEPOSIT_TYPE,
    payload: depositType,
});

export const payment_setDepositAmount = (amount: number | undefined, phone: string | undefined) => ({
    type: EPaymentTypes.SET_DEPOSIT_AMOUNT,
    payload: { amount: amount, phone: phone },
});

export interface Payment_getIframeUrlPayload {
    prop: Payment_PropPayload;
}

export interface Payment_PropPayload {
    challengeId: string;
    goalId: string;
    packageId: string;
    addonsIds?: string[];
    discountCode?: string;
}

export const propCreateFullDiscountPlan = () => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const { propPayload, mixPanelPayload } = extractPaymentData(state);
        try {
            dispatch({ type: EPaymentTypes.CREATE_FULL_DISCOUNT_PLAN_START });
            const data = (
                await axios.PropApi.propApiControllerCreateFullDiscountPlan({
                    ...propPayload,
                })
            )?.data;
            const depositResultObj = {
                amount: 0,
                currency: '',
                status: data.code === ResultInfoCodeEnum.Success,
                transactionId: '',
                type: DepositTypes['fullDiscountPlan'],
            };
            dispatch(payment_setDepositResult(depositResultObj));
            mixPanel.track(mixPanelEventsTypes.DEPOSIT_IFRAME_URL, mixPanelPayload);
            mixPanel.track(mixPanelEventsTypes.PLAN_PURCHASE_DEPOSIT, mixPanelPayload);
            dispatch({ type: EPaymentTypes.CREATE_FULL_DISCOUNT_PLAN_SUCCESS });
            return true;
        } catch (error) {
            dispatch({ type: EPaymentTypes.CREATE_FULL_DISCOUNT_PLAN_FAILED });
            return false;
        }
    };
};

export const payment_createFreeTrial = () => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const { propPayload, mixPanelPayload } = extractPaymentData(state);
        try {
            dispatch({ type: EPaymentTypes.CREATE_FREE_TRIAL_PLAN_START });
            const res = (
                await axios.PropApi.propApiControllerFreeTrialPlanPayment({
                    goalId: propPayload.goalId,
                    packageId: propPayload.packageId,
                })
            )?.data;

            if (res.code === ResultInfoCodeEnum.Success) {
                dispatch({
                    type: EPaymentTypes.CREATE_FREE_TRIAL_PLAN_SUCCESS,
                    payload: {
                        ...propPayload,
                        ...res,
                        status: res.code === ResultInfoCodeEnum.Success,
                    },
                });
            } else {
                dispatch({
                    type: EPaymentTypes.CREATE_FREE_TRIAL_PLAN_FAILED,
                    payload: {
                        ...propPayload,
                        ...res,
                        status: false,
                    },
                });
            }
            mixPanel.track(mixPanelEventsTypes.DEPOSIT_IFRAME_URL, mixPanelPayload);
            mixPanel.track(mixPanelEventsTypes.PLAN_PURCHASE_DEPOSIT, mixPanelPayload);
            return true;
        } catch (error) {
            dispatch({
                type: EPaymentTypes.CREATE_FREE_TRIAL_PLAN_FAILED,
                payload: {
                    ...propPayload,
                    status: false,
                },
            });
            return true;
        }
    };
};

export const payment_getIframeUrl = () => {
    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const { propPayload, iframeRequest, mixPanelPayload } = extractPaymentData(state);

            dispatch({ type: EPaymentTypes.GET_DEPOSIT_IFRAME });

            const getIframeRes = await new Promise<AxiosResponse<PaymentIframeResponse>>((resolve, reject) => {
                iframeRequest.tpId = state.prop_myChallenges.mainAccountTp?.id as string;
                iframeRequest.redirect_url = config.payment.depositRedirectUrl;

                return axios.PropApi.propApiControllerPropPayment({
                    ...iframeRequest,
                    ...propPayload,
                })
                    .then(resolve)
                    .catch(reject);
            });

            dispatch({
                type: EPaymentTypes.GET_DEPOSIT_IFRAME_SUCCESS,
                payload: getIframeRes.data.iframeUrl,
            });

            mixPanel.track(mixPanelEventsTypes.DEPOSIT_IFRAME_URL, mixPanelPayload);
            mixPanel.track(mixPanelEventsTypes.PLAN_PURCHASE_DEPOSIT, mixPanelPayload);
        } catch (error: any) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.GET_DEPOSIT_IFRAME_FAILED, payload: error?.toString() || '' });
            });
        }
    };
};

export const payment_internalMonetaryTransaction = () => {};

export const payment_withdraw = (request: CreateWithdrawalDto) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPaymentTypes.WITHDRAW_START });
            await axios.PaymentApi.paymentApiControllerCreateWithdrawal(request);
            dispatch({
                type: EPaymentTypes.WITHDRAW_SUCCESS,
            });

            const mixPanelPayload = {
                tpId: request.tradingPlatformAccountId,
                amount: request.amount,
                type: request.paymentInfo?.$type,
            };

            mixPanel.track(mixPanelEventsTypes.WITHDRAW_REQUEST, mixPanelPayload);
            mixPanel.track(mixPanelEventsTypes.PLAN_PAYOUT_WITHDRAW, mixPanelPayload);
        } catch (error) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.WITHDRAW_FAILED });
            });
        }
    };
};

export const payment_transferBetweenTPAccounts = (request: TransferBetweenTPAccountsDto) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_START });
            await axios.PaymentApi.paymentApiControllerTransferBetweenTPAccounts(request);
            dispatch({ type: EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_SUCCESS });
            dispatch({ type: EPaymentTypes.WITHDRAW_SUCCESS });
            const mixPanelPayload = {
                tpId: request.tradingPlatformAccountId,
                amount: request.amount,
                type: 'InternalPaymentInfo',
            };

            mixPanel.track(mixPanelEventsTypes.TRANSFER_BETWEEN_ACCOUNTS_REQUEST, mixPanelPayload);
        } catch (error) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_FAILED });
            });
        }
    };
};

export const payment_transferBetweenTPAccountsReset = () => ({
    type: EPaymentTypes.TRANSFER_BETWEEN_ACCOUNTS_RESET,
});

export const payment_getRate = (from: string, to: string) => {
    return async (dispatch) => {
        try {
            const res = await axios.PaymentApi.paymentApiControllerGetRate(from, to);
            return Number(res?.data?.rate);
        } catch (error) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.WITHDRAW_FAILED });
            });
        }
    };
};
export const payment_transferBetweenTpAccountsValidation = (request: TransferBetweenTPAccountsValidationDto) => {
    return async (dispatch) => {
        try {
            const res = await axios.PaymentApi.paymentApiControllerTransferBetweenTPAccountsValidation(request);
            return res?.data;
        } catch (error) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.WITHDRAW_FAILED });
            });
        }
    };
};

export const payment_resetWithdrawResult = () => ({
    type: EPaymentTypes.RESET_WITHDRAW_RESULT,
});

export const payment_rejectWithdrawRequest = (monetaryTransactionId: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPaymentTypes.WITHDRAW_CANCEL_START, payload: monetaryTransactionId });
            const res = await axios.PaymentApi.paymentApiControllerCancelWithdrawal({ monetaryTransactionId });
            if (res.data?.status) {
                await dispatch(accounts_getMonetaryTransactionsHistory());
                dispatch({ type: EPaymentTypes.WITHDRAW_CANCEL_SUCCESS, payload: monetaryTransactionId });
                return true;
            }

            dispatch({ type: EPaymentTypes.WITHDRAW_CANCEL_FAILED, payload: monetaryTransactionId });
            return false;
        } catch (error) {
            dispatch({ type: EPaymentTypes.WITHDRAW_CANCEL_FAILED, payload: monetaryTransactionId });
            return false;
        }
    };
};

export const payment_getCryptoWallets = (tpId: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPaymentTypes.GET_CRYPTO_WALLETS_START });
            const res = await axios.PaymentApi.paymentApiControllerCreateCryptoWallet({
                psp: 'coinbase',
                tpId,
            });
            dispatch({ type: EPaymentTypes.GET_CRYPTO_WALLETS_SUCCESS, payload: res.data.addresses });
        } catch (error) {
            dispatch({ type: EPaymentTypes.GET_CRYPTO_WALLETS_FAILED });
        }
    };
};

export const payment_getPaymentTypes = (tpId: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPaymentTypes.GET_PAYMENT_TYPES_START });
            const res = await axios.PaymentApi.paymentApiControllerGetPaymentTypes({ tpId });
            const firstItem = res.data[0];
            dispatch(
                payment_setDepositType({
                    tabName: `${firstItem.name}_*_${firstItem.type || ''}`,
                    type: firstItem.name as any,
                    subType: firstItem.type,
                })
            );
            const mappedResults: { [type: string]: DepositTab } = res.data.reduce<{ [type: string]: DepositTab }>(
                (acc, type) => ({
                    ...acc,
                    [`${type.name}_*_${type.type || ''}`]: {
                        ...depositTabsMetaData[type.name],
                        overrideName: type.overrideName,
                        subType: type.type,
                        iframeNewTab: type.openNewTab,
                        openAtTheSameWindow: type['openAtTheSameWindow'],
                    },
                }),
                {}
            );
            dispatch({ type: EPaymentTypes.GET_PAYMENT_TYPES_SUCCESS, payload: mappedResults });
        } catch (error) {
            dispatch({ type: EPaymentTypes.GET_PAYMENT_TYPES_FAILED });
        }
    };
};

export interface Payment_getReferenceNumberPayload {
    depositWireDto: DepositWireDto;
    prop?: Payment_PropPayload;
}

export const payment_getReferenceNumber = (payload: Payment_getReferenceNumberPayload) => {
    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const { propPayload, mixPanelPayload } = extractPaymentData(state);
            dispatch({ type: EPaymentTypes.GET_REFERENCE_NUMBER_START });

            let res: AxiosResponse<DepositWireResponse> = await axios.PropApi.propApiControllerPropDepositWire({
                ...payload.depositWireDto,
                ...propPayload,
                tpId: state.prop_myChallenges.mainAccountTp?.id as string,
            });
            const depositResultObj = {
                amount: payload.depositWireDto.amount,
                currency: '',
                status: !!res.data.referenceId,
                transactionId: '',
                type: DepositTypes['bank-transfer'],
            };
            dispatch(payment_setDepositResult(depositResultObj));
            dispatch({ type: EPaymentTypes.GET_REFERENCE_NUMBER_SUCCESS, payload: res.data.referenceId });
            mixPanel.track(mixPanelEventsTypes.PLAN_PURCHASE_DEPOSIT, mixPanelPayload);
            return true;
        } catch (error) {
            const depositResultObj = {
                amount: payload.depositWireDto.amount,
                currency: '',
                status: false,
                transactionId: '',
                type: DepositTypes['bank-transfer'],
            };
            dispatch(payment_setDepositResult(depositResultObj));
            dispatch({ type: EPaymentTypes.GET_REFERENCE_NUMBER_FAILED });
            return false;
        }
    };
};

export const challengeUpdated = () => {
    return async (dispatch) => {
        dispatch({ type: EPaymentTypes.CHALLENGE_UPDATED });
    };
};

export const resetChallengeUpdate = () => {
    return async (dispatch) => {
        dispatch({ type: EPaymentTypes.RESET_CHALLENGE_UPDATED });
    };
};

export const extractPaymentData = (state: RootState) => {
    const planObject = state.prop_newChallenge.newGoals[state.prop_checkout.planPaymentData.goalIndex!];

    const propPayload: Payment_PropPayload = {
        challengeId: state.prop_newChallenge.challengePaymentData.challengeId || '',
        goalId: state.prop_checkout.planPaymentData.goalId || '',
        packageId: state.prop_checkout.planPaymentData.packageId || '',
        addonsIds: state.prop_checkout.selectedAddonsIds || [],
        discountCode: state.prop_checkout?.planPaymentData?.discountCode || '',
    };
    const { language } = i18n;
    const { depositAmount, depositType, depositPhone } = state.payment;
    const { selectedTPAccount } = state.user;

    if (
        ((!depositType || !DepositTypes[depositType.type]) && planObject.type !== PropGoalTypeEnum.Free) ||
        !selectedTPAccount
    ) {
        throw new Error('Invalid deposit type, amount, or account');
    }

    const iframeRequest: PaymentIframeRequest = {
        tpId: selectedTPAccount?.id || '',
        amount: depositAmount ?? 0,
        psp: depositType?.type || '',
        paymentType: depositType?.subType ?? '',
        language,
        redirect_url: config.payment.depositRedirectUrl,
        phone: depositPhone,
    };

    const conditions = extractRelevantConditions(
        planObject.challenges[0],
        state.prop_checkout.planPaymentData?.packageIndex || 0
    );

    const mixPanelPayload = {
        ...iframeRequest,
        tpName: selectedTPAccount?.name,
        currency: selectedTPAccount?.baseCurrency?.code,
        conditions,
    };

    return { planObject, propPayload, iframeRequest, mixPanelPayload };
};
export const payment_createPendingDeposit = () => {
    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const propState = state.prop_myChallenges;

            const { propPayload, mixPanelPayload } = extractPaymentData(state);

            dispatch({ type: EPaymentTypes.CREATE_PENDING_PAYMENT_START });

            await new Promise<AxiosResponse<CreateMonetaryTransactionResponse>>((resolve, reject) => {
                return axios.PropApi.propApiControllerPropPendingPayment({
                    tpId: propState.mainAccountTp?.id as string,
                    amount: 0,
                    ...propPayload,
                })
                    .then(resolve)
                    .catch(reject);
            });

            dispatch({
                type: EPaymentTypes.CREATE_PENDING_PAYMENT_SUCCESS,
                payload: 'success',
            });
            mixPanel.track(mixPanelEventsTypes.PLAN_OUTSOURCE_DEPOSIT, mixPanelPayload);
        } catch (error: any) {
            return batch(() => {
                dispatch({ type: EPaymentTypes.CREATE_PENDING_PAYMENT_FAILED, payload: error?.toString() || '' });
            });
        }
    };
};
