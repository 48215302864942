import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const statsPage = `/platforms/plan-statistics`;
const pagesNames = new Set<string>([statsPage]);

export const useDisplayTradeNowButton = () => {
    const { pathname } = useLocation();

    return useMemo(() => {
        return pathname ? pagesNames.has(pathname) : false;
    }, [pathname]);
};
