import { makeStyles } from '@material-ui/core';
import { customGap, isRtl, isTablet } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    seperator: {
        height: '0.1rem',
        width: `calc(100% + ${theme.spacing(8)}rem)`,
        backgroundColor: theme.palette.action.disabledBackground,
        transform: `translateX(-${theme.spacing(4)}rem)`,
    },
    messageWrapper: {
        display: 'flex',
        cursor: 'pointer',
        width: '100%',
        ...customGap(theme.spacing(3), 'horizontal'),
        height: '100%',
    },
    messageWrapper__active: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    smallImage: {
        height: '4.2rem',
        width: '4.2rem',
        borderRadius: '0.8rem',
        backgroundColor: theme.palette.default.main,
    },
    bigImage: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        '& > img': {
            maxHeight: '12rem',
            width: '100%',
            maxWidth: '32rem',
            borderRadius: '0.8rem',
            marginBottom: '0.8rem',
            backgroundColor: theme.palette.default.main,
        },
    },
    dateText: {
        display: 'flex',
        justifyContent: 'end',
        minWidth: '50px',
        color: theme.palette.grayVariants[500],
        fontWeight: 400,
    },
    title: {
        width: '100%',
        display: 'flex',
        gap: '16px',
        alignItems: 'self-start',
        ...isTablet({ padding: '16px' }),
    },
    titleText: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        wordBreak: 'break-all',
        display: '-webkit-box',
        color: theme.palette.grayVariants[900],
        fontSize: '16px',
    },

    buttonDefault: {
        padding: '0',
        '&>span>span': { transform: 'rotate(180deg)' },
        ...isRtl({ '&>span>span': { transform: 'rotate(0deg)' } }),
    },
    textButton: {
        padding: '0',
        lineHeight: 'initial',
    },
    button: {},
    iconContainer: {
        minWidth: '48px',
        minHeight: '48px',
        backgroundColor: theme.palette.grayVariants[100],
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        margin: '2px',
        stroke: theme.palette.grayVariants[500],
    },
    headerContainer: {
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...customGap(theme.spacing(4), 'horizontal'),
    },
    rightContainer: { width: '100%', ...customGap(theme.spacing(1), 'vertical') },
    subText: {
        wordBreak: 'break-word',
        color: theme.palette.grayVariants[500],
    },

    unreadMassage: {
        width: '8px',
        height: '8px',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        position: 'absolute',
        top: '0',
        left: '0',
    },
    showMoreLess: { color: theme.palette.primary.main, fontWeight: 700, margin: '0 4px' },
    kycNotification: {
        color: theme.palette.type === 'dark' ? theme.palette.default.light : theme.palette.grayVariants[600],
    },
}));
