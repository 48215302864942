import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { RadioButtonOption, RadioButtonsProps } from './radio-buttons.scheme';
import useStyles from './radio-buttons.styles';
import classNames from 'classnames';
import Icon, { IconsType } from 'components/Icon';
import IconButton from '@material-ui/core/IconButton';
import HelpOutline from '@material-ui/icons/HelpOutline';
import ToolTip from '../../../components/ToolTip';
import { useTranslation } from 'react-i18next';

const RadioButtons: React.FunctionComponent<RadioButtonsProps> = (props) => {
    const {
        multiple,
        defaultValue,
        options,
        label,
        helperText,
        tooltipText,
        error,
        disabled,
        id,
        labelColor,
        labelVariant = 'body1',
        flexDirection = 'column',
        inputRef,
        onChange,
    } = props;
    const classes = useStyles({ error: Boolean(error), withLabel: Boolean(label), flexDirection });
    const [selected, setSelected] = React.useState<string | number | Array<string> | Array<number>>(() => {
        if (defaultValue) return defaultValue;
        return multiple ? [] : '';
    });

    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        if (Array.isArray(selected)) {
            if (!checked) {
                //eslint-disable-next-line
                const newValues: any = [...selected].filter((item) => item != value);
                onChange && onChange(newValues);
                return setSelected(newValues);
            }
            const newValues: any = [...selected, value];
            onChange && onChange(newValues);
            return setSelected(newValues);
        }
        onChange && onChange(value);
        return setSelected(value);
    };

    const checkIfIsSelected = (val) => {
        if (Array.isArray(selected)) {
            //eslint-disable-next-line
            return selected.some((v) => v == val);
        }
        //eslint-disable-next-line
        return selected == val;
    };

    const getLabelComponents = (option: RadioButtonOption) => {
        if (option.helperText) {
            return (
                <React.Fragment>
                    <Typography variant="body1" classes={{ root: classes.formLabelWithHelperText }}>
                        {option.label}
                    </Typography>
                    <Typography
                        variant="body1"
                        classes={{ root: classes.formLabelHelperText }}
                        color={error ? 'error' : 'inherit'}
                    >
                        {option.helperText}
                    </Typography>
                </React.Fragment>
            );
        }
        return option.label;
    };

    return (
        <FormControl id={id} name={id} error={error} component="fieldset">
            {label && (
                <Typography variant={labelVariant} color={labelColor}>
                    {label}
                    {tooltipText && (
                        <ToolTip
                            display={'inline'} /*placement={isRtl() ? 'right-end' : 'left-end'}*/
                            title={
                                <Typography variant={'body2'}>
                                    <span style={{ whiteSpace: 'pre-line' }}>{t(tooltipText)}</span>
                                </Typography>
                            }
                        >
                            <IconButton style={{ background: 'transparent' }}>
                                <HelpOutline />
                            </IconButton>
                        </ToolTip>
                    )}
                </Typography>
            )}
            <RadioGroup classes={{ root: classes.radioGroupRoot }} aria-label="gender" name="gender1" value={selected}>
                {options.map((option, index) => (
                    <FormControlLabel
                        disabled={disabled || option.disabled || (option.disableIf && option.disableIf(selected))}
                        key={`radioOption-${index}`}
                        value={option.value}
                        control={
                            <Checkbox
                                icon={multiple ? undefined : <Icon name={IconsType.radio_unchecked} />}
                                checkedIcon={multiple ? undefined : <Icon name={IconsType.radio_checked} />}
                                inputRef={inputRef}
                                checked={checkIfIsSelected(option.value)}
                                onChange={handleChange}
                                color="primary"
                                classes={{ root: classes.checkboxRoot }}
                            />
                        }
                        classes={{
                            root: classes.formControlLabelRoot,
                            label: classNames({
                                [classes.formControlRoot]: true,
                                [classes.formControlRoot__active]: checkIfIsSelected(option.value),
                            }),
                        }}
                        label={getLabelComponents(option)}
                    />
                ))}
            </RadioGroup>
            {helperText && (
                <FormHelperText classes={{ error: classes.formHelperTextError }}>{helperText}</FormHelperText>
            )}
            <TextField
                style={{ display: 'none' }}
                type={multiple ? 'radio' : 'text'}
                inputRef={inputRef}
                value={selected}
            />
        </FormControl>
    );
};

export default RadioButtons;
