import { makeStyles } from '@material-ui/core';
import { cssStart, customGap, isLaptop, isRtl } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    wrapper: (props: any) => ({
        borderRadius: '0.8rem',
        padding: `${theme.spacing(1)}rem`,
        border: `0.1rem solid ${theme.palette.action.disabledBackground}`,
        backgroundColor: `${theme.palette.background.default}`,
        width: props.fullWidth ? '100%' : `${props.tabsAmount * 16.4}rem`,
        maxWidth: '100%',
    }),
    indicator: {
        display: 'none',
    },
    tabsRoot: {
        minHeight: 'unset',
    },
    tabWrapper: (props: any) => ({
        display: 'flex',
        flexDirection: props.isRow ? 'row' : 'column',
        alignItems: 'center',
        ...customGap(theme.spacing(1), 'horizontal'),
        height: `${props.heightSize}rem`,
    }),
    tabRoot: (props: any) => ({
        padding: '0.4rem',
        paddingTop: '0.4rem',
        borderRadius: '0.8rem',
        minHeight: 'unset',
        minWidth: props.isScrollable ? '13rem' : 'unset',
        fontSize: '1.3rem',
        fontWeight: 600,
        textTransform: 'unset',
        color: theme.palette.grayVariants[500],
        '&:not(:last-child)': {
            marginRight: `${theme.spacing(1)}rem`,
            marginLeft: '0',
            ...isRtl({
                marginLeft: `${theme.spacing(1)}rem`,
                marginRight: '0',
            }),
        },
        '& svg': {
            marginBottom: 'unset !important',
        },
    }),
    tabSelected: (props: any) => ({
        '&&': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            ...(props.color === 'error' && {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText,
            }),
            ...(props.color === 'success' && {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.success.contrastText,
            }),
        },
    }),
    tabDesktopOnly: {
        display: 'none',
        ...isLaptop({
            display: 'block',
        }),
    },
    withEndNumber: (props: any) => ({
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:after': {
            content: `'${props.endNumber}'`,
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '1.2rem',
            padding: '0 0.4rem',
            ...theme.typography.body2,
            background: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            ...cssStart(theme.direction, 'margin', `${theme.spacing(2)}rem`),
        },
    }),
    flexContainer: (props: any) => ({
        overflow: props.isScrollable ? 'auto' : 'unset',
        // '&::-webkit-scrollbar': {
        //     height: '0rem',
        // },
        ...(props.reverseTabs && {
            flexDirection: 'row-reverse',
        }),
    }),
}));
