import React from 'react';
import PageLayout from 'components/Layouts/PageLayout';
import imgSrc from 'assets/images/selectRealAcount.png';
import mobileImgSrc from 'assets/images/selectRealAccount_mobile.png';
import useStyles from './select_real_account.styles';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Pages } from 'interfaces/main';

export interface SelectRealAccountProps {}

const SelectRealAccount: React.FunctionComponent<SelectRealAccountProps> = () => {
    const classes = useStyles({ imgSrc, mobileImgSrc });
    const { t } = useTranslation('myWallet');

    const { pathname } = useLocation();

    const tPrefix = pathname.includes(Pages.MY_WALLET__DEPOSIT) ? 'deposit' : 'withdraw';

    return (
        <PageLayout fullHeight fullWidth infoData>
            <div className={classes.img} />
            <Typography variant="h1" className={classes.title}>
                {t(`switch-to-real.${tPrefix}.title`)}
            </Typography>
            <Typography variant="body1" color="textSecondary">
                {t(`switch-to-real.${tPrefix}.description`)}
            </Typography>
        </PageLayout>
    );
};

export default SelectRealAccount;
