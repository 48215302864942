import { makeStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

interface Props {
    error: boolean;
    inheritColor?: boolean;
    disableSidesMargin?: boolean;
    alignCenter?: boolean;
}

export default makeStyles((theme) => ({
    formControlRoot: (props: Props) => ({
        alignItems: 'flex-start',
        justifyContent: props.alignCenter ? 'center' : null,
        ...(props.disableSidesMargin
            ? {
                  marginLeft: '-1.1rem',
                  marginRight: 'unset',
                  ...isRtl({
                      marginLeft: 'unset',
                      marginRight: '-1.1rem',
                  }),
              }
            : {}),
    }),
    label: {
        fontSize: '1.4rem',
        lineHeight: '2rem',
        fontWeight: 500,
    },

    checkboxRoot: (props: Props) => ({
        color: props.inheritColor ? 'inherit' : undefined,
        padding: '0 9px',
        '& svg': {
            color: theme.palette.background.default,
            '& rect': {
                stroke: theme.palette.grayVariants[300],
            },
        },
    }),
    formHelperTextError: {
        '&&': {
            color: theme.palette.error.main,
            backgroundColor: 'transparent',
            padding: 'unset',
            margin: '0',
            marginTop: `${theme.spacing(4)}rem`,
            marginLeft: `${theme.spacing(6)}rem`,
        },
    },
}));
