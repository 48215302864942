import { createSelector } from 'reselect';
import { RootState } from 'store';
import { Pages } from 'interfaces/main';
import { IconsType } from 'components/Icon';
import { UploadDButtonProps } from './interface';
import config from 'config/common';

export const sumSub = config.featuresFlags['sumsub'];
export const uploadDocuments = config.featuresFlags['uploadDocuments'];

const rootReducerSelector = (state: RootState) => state;
const uploadDReducerSelector = (state: RootState) => state.uploadD;
const suppliedNecessaryDocumentsReducer = (state: RootState) => state.user.userInfo.suppliedNecessaryDocuments;

export const uploadD_processLoading = createSelector(uploadDReducerSelector, (uploadD) => uploadD.uploadProcessLoader);

export const uploadD_filesUploadingState = createSelector(uploadDReducerSelector, (uploadD) => uploadD.filesUploading);

export const uploadD_isVisible = createSelector(rootReducerSelector, (root) => {
    return !Boolean(root.user.userInfo.suppliedNecessaryDocuments);
});

export const uploadD_completed = createSelector(rootReducerSelector, (root) => {
    return Boolean(root.user.userInfo.suppliedNecessaryDocuments);
});

export const uploadD_status = createSelector(
    suppliedNecessaryDocumentsReducer,
    (suppliedNecessaryDocuments): undefined | UploadDButtonProps => {
        let link = '';

        if (sumSub && !suppliedNecessaryDocuments) {
            link = `/${Pages.SUMSUB_UPLOAD}`;
        } else if (uploadDocuments) {
            link = `/${Pages.UPLOAD_DOCUMENTS}`;
        } else {
            return undefined;
        }

        return {
            possibleLinks: [`/${Pages.UPLOAD_DOCUMENTS}`],
            link,
            buttonType: 'upload_documents',
            icon: IconsType.upload_documents,
        };
    }
);
