interface ConditionalWrapperProps {
    condition: boolean;
    wrapper: (children: JSX.Element) => JSX.Element;
    children: JSX.Element;
}

const ConditionalWrapper: React.FunctionComponent<ConditionalWrapperProps> = (props) => {
    const { condition, wrapper, children } = props;
    return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
