import axios from 'services/axios';
import { EPropMyChallengesTypes } from './actionsTypes';
import { batch } from 'react-redux';
import { RootState } from 'store/index';
import { user_changeSelectedTpByName, user_refreshUserData, user_updateStoreUser } from 'store/user/actions';
import {
    AccountInfoParsed,
    AccountInfoParsedKycVerificationStatusEnum,
    ManualKycRequest,
    PropChallenge,
    PropChallengeChallengeStatusEnum,
    PropChallengeChallengeTypeEnum,
    PropChallengeInternalStatusEnum,
    ResultInfoCodeEnum,
} from 'services/generatedClientFromSwagger';
import { isTablet } from 'helpers/device_helper';
import { KycDialogOpen } from 'features/prop/interfaces';

export const prop__myChallenges_getChallenges = (withLoading: boolean = true, update?: boolean) => {
    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const tpAccounts = state.user.userInfo.tradingPlatformAccounts;
            const accountId = state.user?.userInfo?.id;

            dispatch({ type: EPropMyChallengesTypes.GET_CHALLENGES_START, payload: { withLoading } });
            const plans = (await axios.PropApi.propApiControllerGetPlans(update))?.data?.plans;
            dispatch({ type: EPropMyChallengesTypes.GET_PLANS_SUCCESS, payload: plans });

            if (!plans) {
                return;
            }

            const challenges = plans.reduce((acc: PropChallenge[], plan) => {
                plan?.propChallenges && acc.push(...plan?.propChallenges);
                return acc;
            }, []);
            const fundsFilterOptions: Array<any> = [];
            if (!isTablet()) {
                fundsFilterOptions.push(...(new Set(challenges.map((challenge) => challenge.funds)) ?? []));
            }

            const { filteredChallenges, notStartedfundedPropChallenge } = challenges.reduce(
                (acc, challenge) => {
                    if (challenge.internalStatus === PropChallengeInternalStatusEnum.Success) {
                        acc.filteredChallenges.push(challenge);
                        return acc;
                    }
                    if (
                        challenge.challengeStatus === PropChallengeChallengeStatusEnum.NotStarted &&
                        challenge.challengeType === PropChallengeChallengeTypeEnum.Funded
                    ) {
                        acc.notStartedfundedPropChallenge.push(challenge);
                    }
                    return acc;
                },
                {
                    filteredChallenges: [] as PropChallenge[],
                    notStartedfundedPropChallenge: [] as PropChallenge[],
                }
            );

            if (filteredChallenges.length + 1 !== tpAccounts?.length && Boolean(accountId)) {
                await dispatch(user_refreshUserData());
            }
            dispatch({
                type: EPropMyChallengesTypes.GET_CHALLENGES_SUCCESS,
                payload: {
                    challenges,
                    filteredChallenges,
                    notStartedfundedPropChallenge,
                    fundsFilterOptions,
                },
            });

            const challengeTpName = localStorage.getItem('selectedChallengeTp');

            const selectedChallenge = filteredChallenges.find((c) => {
                return c.tpName === challengeTpName && c?.internalStatus !== PropChallengeInternalStatusEnum.Failure;
            });

            const firstChallenge = filteredChallenges.find((c) => {
                return c.tpName !== challengeTpName && c?.internalStatus !== PropChallengeInternalStatusEnum.Failure;
            });

            const selectedChallengeTpName =
                selectedChallenge?.tpName || firstChallenge?.tpName || filteredChallenges[0]?.tpName || '';

            dispatch(prop__changeSelectedTpChallenge(selectedChallengeTpName));
            return plans;
        } catch (error) {
            dispatch({ type: EPropMyChallengesTypes.GET_CHALLENGES_FAIL });
        }
    };
};

export const prop__myChallenges_validateWithdrawal = (challengeId: string, tpAccount: string) => {
    return async (dispatch) => {
        try {
            const validateWithdrawal = await axios.PropApi.propApiControllerGetChallengeValidateWithdrawal({
                challengeId,
                tpAccount,
            });
            dispatch({
                type: EPropMyChallengesTypes.VALIDATE_WITHDRAWAL_SUCCESS,
                payload: { data: validateWithdrawal.data, tpAccount },
            });
        } catch (error) {
            return false;
        }
    };
};

export const prop__myChallenges_getChallengeIframeUrl = (tpId: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_START, payload: tpId });
            const getIframeRes = await axios.PropApi.propApiControllerGetChallengeIframeUrl(tpId);
            dispatch({
                type: EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_SUCCESS,
                payload: { tpId: tpId, iframeUrl: getIframeRes.data },
            });
        } catch (error) {
            return batch(() => {
                dispatch({ type: EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_FAIL, payload: tpId });
            });
        }
    };
};

export const prop__changeSelectedTpChallenge = (tpName: string) => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        if (state.prop_myChallenges.filteredChallenges.length) {
            const selectedChallenge =
                state.prop_myChallenges.filteredChallenges.find((challenge) => challenge.tpName === tpName) ||
                state.prop_myChallenges.filteredChallenges[0];

            const selectedTpName = selectedChallenge.tpName as string;
            dispatch({ type: EPropMyChallengesTypes.SET_SELECTED_CHALLENGE, payload: selectedChallenge });
            if (selectedTpName !== state.user.selectedTPAccount?.name) {
                dispatch(user_changeSelectedTpByName(selectedTpName));
            }
            localStorage.setItem('selectedChallengeTp', selectedTpName);
        }
    };
};

export const prop__setMainTpAccount = (userInfo: AccountInfoParsed) => {
    return async (dispatch) => {
        const mainAccountTp = userInfo.tradingPlatformAccounts?.reduce((prev, current) => {
            return Number(prev.name) < Number(current.name) ? prev : current;
        });

        dispatch({ type: EPropMyChallengesTypes.SET_MAIN_ACCOUNT_TP, payload: mainAccountTp });
    };
};

export const prop__myChallenges_iframePopup = (isOpen: boolean) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: isOpen
                    ? EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_POPUP_SUCCESS
                    : EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_POPUP_FAIL,
            });
        } catch (error) {
            dispatch({ type: EPropMyChallengesTypes.GET_CHALLENGE_IFRAME_POPUP_FAIL });
            console.error(error);
        }
    };
};
export const prop_handleChallengeStartFundedAlert = (challengeId: string, isOpen: boolean, sent: boolean) => {
    return async (dispatch) => {
        dispatch({
            type: EPropMyChallengesTypes.SET_FUNDED_ALERT_TOGGLE,
            payload: { [challengeId]: { display: isOpen, sent } },
        });
    };
};
export const prop__myChallenges_acceptChallengeTermsAndCondition = (challengeId: string) => {
    return async (dispatch) => {
        try {
            dispatch(prop_handleChallengeStartFundedAlert(challengeId, false, true));
            dispatch({ type: EPropMyChallengesTypes.ACCEPT_CHALLENGE_TERMS_START, payload: challengeId });
            const acceptChallengeTermsAndCondition = await axios.PropApi.propApiControllerAcceptTermsAndConditions({
                challengeId,
            });
            dispatch(user_refreshUserData());
            dispatch({
                type: EPropMyChallengesTypes.ACCEPT_CHALLENGE_TERMS_SUCCESS,
                payload: {
                    challengeId,
                    acceptTermsAndConditions: acceptChallengeTermsAndCondition.data.code === ResultInfoCodeEnum.Success,
                },
            });
        } catch (error) {
            dispatch(prop_handleChallengeStartFundedAlert(challengeId, true, false));
            return batch(() => {
                dispatch({ type: EPropMyChallengesTypes.ACCEPT_CHALLENGE_TERMS_FAIL, payload: challengeId });
            });
        }
    };
};

export const prop_handleKycDialogState = (kycDialogState?: KycDialogOpen) => {
    return async (dispatch) => {
        dispatch({ type: EPropMyChallengesTypes.SET_KYC_DIALOG_STATE, payload: kycDialogState });
    };
};

export const prop_handleKycDialogStateFromNotification = () => {
    return async (dispatch, getState) => {
        const state: RootState = getState();

        let challengeToActivate: PropChallenge | null = null;
        for (let index = 0; index < state.prop_myChallenges.plans.length; index++) {
            const plan = state.prop_myChallenges.plans[index];
            if (plan?.propChallenges?.length) {
                for (let index = 0; index < plan?.propChallenges?.length; index++) {
                    const challenge = plan?.propChallenges?.[index];
                    if (
                        challenge.challengeStatus === PropChallengeChallengeStatusEnum.NotStarted &&
                        challenge.challengeType === PropChallengeChallengeTypeEnum.Funded
                    ) {
                        challengeToActivate = challenge;
                        break;
                    }
                }
            }
            if (challengeToActivate) {
                break;
            }
        }

        if (challengeToActivate) {
            dispatch({
                type: EPropMyChallengesTypes.SET_KYC_DIALOG_STATE,
                payload: { id: challengeToActivate.id, tpName: challengeToActivate.tpName },
            });
        }
    };
};

export const prop__myChallenges_updateStatusManualKyc = (manualKycRequest: ManualKycRequest) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPropMyChallengesTypes.STATUS_MANUAL_KYC_START });
            const statusManualKyc = await axios.PropApi.propApiControllerManualKyc(manualKycRequest);

            if (statusManualKyc.data.updateRes.updateAccountDetailsResult?.accountInfo?.kycVerificationStatus) {
                dispatch(
                    user_updateStoreUser({
                        kycVerificationStatus:
                            AccountInfoParsedKycVerificationStatusEnum[
                                statusManualKyc.data.updateRes.updateAccountDetailsResult?.accountInfo?.kycVerificationStatus?.toString()
                            ],
                    })
                );
                dispatch(user_refreshUserData() as any);
            }
            dispatch({
                type: EPropMyChallengesTypes.STATUS_MANUAL_KYC_SUCCESS,
            });
        } catch (error) {
            return batch(() => {
                dispatch({ type: EPropMyChallengesTypes.STATUS_MANUAL_KYC_FAIL });
            });
        }
    };
};
