import React from 'react';
import { GoogleLocationAutocompletePropsWrapper } from './google-location-autocomplete.scheme';
import GoogleLocation from './GoogleLocation';
import FallBackFields from './FallBackFields';
import Button from 'components/Button';
import useStyles from './google-location-autocomplete.styles';
import Icon, { IconsType } from 'components/Icon';
import { useTranslation } from 'react-i18next';
import config from 'config/common';
const googleFeatureFlag = config.featuresFlags['googleLocationAutocomplete'];
const GoogleLocationAutocompleteWrapper: React.FunctionComponent<GoogleLocationAutocompletePropsWrapper> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('components');

    const [fallBackState, setFallBackState] = React.useState(!Boolean(googleFeatureFlag));

    const toggleFallBackState = () => {
        if (props.error && props.onChange) {
            props.onChange(props.defaultValueObject || {});
        }
        setFallBackState((state) => !state);
    };

    return (
        <div>
            {googleFeatureFlag && (
                <React.Fragment>
                    <GoogleLocation
                        {...props}
                        disabled={props.disabled || fallBackState}
                        error={fallBackState ? false : props.error}
                        helperText={fallBackState ? undefined : props.helperText}
                    />
                    <div className={classes.btnsWrapper}>
                        <Button variant="contained" color="default" size="small" onClick={toggleFallBackState}>
                            {t('googleAutoComplete.didntFoundBtn')}
                        </Button>
                        {fallBackState && (
                            <div className={classes.closeBtn}>
                                <Button variant="text" onClick={toggleFallBackState}>
                                    <Icon name={IconsType.close} />
                                </Button>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )}
            {fallBackState && (
                <FallBackFields
                    disabled={props.disabled}
                    countryOptions={props.countriesList}
                    onChange={props.onChange}
                    defaultValueObject={props.defaultValueObject}
                    error={props.error}
                    helperText={props.helperText}
                />
            )}
        </div>
    );
};

export default GoogleLocationAutocompleteWrapper;
