import { Pages } from 'interfaces/main';
import React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import LoaderFullScreen from 'components/LoaderFullScreen';
import { auth_loader, auth_hasJwtToken } from 'store/auth/selectors';
import { connect } from 'react-redux';
import {
    user_loader,
    user_loggedIn,
    user_phoneVerified,
    user_emailVerified,
    user_verified,
    user_userInfo,
} from 'store/user/selectors';
import { saveLinkToSessionStorage } from 'helpers/linkRemember';
import { deleteTokenFromLocalStorage, getTokenFromLocalStorage } from 'helpers/token';
import { deleteLastSelectedTp } from 'helpers/lastSelectedTP';
import { removeURLParameter } from 'helpers/urlParamaters';
import { AccountInfoParsed } from 'services/generatedClientFromSwagger';
import PropGuard from 'features/prop/scenes/prop.guard';
export interface UserRouteProps {
    component: any;
    hasJwtToken: boolean;
    loginLoading: boolean;
    loggedIn: boolean;
    userLoader: boolean;
    userInfo: AccountInfoParsed;
    phoneVerified: boolean;
    emailVerified: boolean;
    user_verified: boolean;
    isPropRoute?: boolean;
}

const UserRoute: React.FunctionComponent<UserRouteProps> = (props) => {
    const {
        component: Component,
        hasJwtToken,
        loginLoading,
        userLoader,
        loggedIn,
        userInfo,
        phoneVerified,
        emailVerified,
        user_verified,
        isPropRoute,
        ...restProps
    } = props;

    const { pathname, search } = useLocation();
    let history = useHistory();

    const cleanLinkToRemember = React.useMemo(() => {
        let cleanUrl = `${pathname}${search}`;
        cleanUrl = removeURLParameter(cleanUrl, 'ssoToken');
        cleanUrl = removeURLParameter(cleanUrl, 'wtToken');
        cleanUrl = removeURLParameter(cleanUrl, 'crmToken');
        return cleanUrl;
    }, [pathname, search]);

    if (!hasJwtToken && !loginLoading) {
        const token = getTokenFromLocalStorage();
        saveLinkToSessionStorage(cleanLinkToRemember, token);
        deleteTokenFromLocalStorage();
        deleteLastSelectedTp();
        history.replace(`/${Pages.LOGIN}`);
        return null;
    }

    if (loggedIn && !user_verified && !phoneVerified) {
        history.replace(`/${Pages.PHONE_VERIFICATION}`);
        return null;
    }

    if (loggedIn && !user_verified && !emailVerified) {
        history.replace(`/${Pages.EMAIL_VERIFICATION}`);
        return null;
    }

    if (loginLoading || (userLoader && !loggedIn)) {
        return <LoaderFullScreen />;
    }

    if (isPropRoute) {
        return <PropGuard hasChallengesGuard {...props} />;
    }

    return <Route {...restProps} render={(props) => <Component {...props} />} />;
};

export const mapStateToProps = (state) => ({
    hasJwtToken: auth_hasJwtToken(state),
    loginLoading: auth_loader(state),
    loggedIn: user_loggedIn(state),
    userLoader: user_loader(state),
    userInfo: user_userInfo(state),
    emailVerified: user_emailVerified(state),
    phoneVerified: user_phoneVerified(state),
    user_verified: user_verified(state),
});

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UserRoute));
