import { FormControl, InputLabel } from '@material-ui/core';
import config from 'config/common';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../../TextField';
import {
    GoogleResult,
    GoogleComponentsTypes,
    GoogleLocationAutocompleteProps,
    GoogleLocationAutocompletePayload,
} from './google-location-autocomplete.scheme';

let autoComplete;

const loadScript = (url, callback) => {
    let script: any = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
};

const desturctGoogleResult = (result: GoogleResult) => {
    let formattedResult: GoogleLocationAutocompletePayload = {};

    if (result && result?.address_components) {
        result.address_components.forEach((res) => {
            res.types.forEach((type) => {
                switch (type) {
                    case GoogleComponentsTypes.country:
                        formattedResult.country = res.long_name;
                        formattedResult.country_short = res.short_name;
                        break;
                    case GoogleComponentsTypes.locality:
                    case GoogleComponentsTypes.postal_town:
                    case GoogleComponentsTypes.administrative_area_level_2:
                    case GoogleComponentsTypes.administrative_area_level_3:
                    case GoogleComponentsTypes.administrative_area_level_4:
                    case GoogleComponentsTypes.administrative_area_level_5:
                        formattedResult.city = res.long_name;
                        break;
                    case GoogleComponentsTypes.premise:
                    case GoogleComponentsTypes.route:
                        formattedResult.address = res.long_name;
                        break;
                    case GoogleComponentsTypes.street_number:
                        formattedResult.addressNumber = res.long_name;
                        break;
                    case GoogleComponentsTypes.postal_code:
                        formattedResult.postalCode = res.long_name;
                        break;
                    case GoogleComponentsTypes.administrative_area_level_1:
                        formattedResult.state = res.long_name;
                        break;
                }
            });
        });
    }

    return formattedResult;
};

const GoogleLocationAutocomplete: React.FunctionComponent<GoogleLocationAutocompleteProps> = (props) => {
    const {
        variant,
        label,
        id,
        disabled,
        loading,
        startIcon,
        endIcon,
        defaultValue,
        placeholder,
        error,
        helperText,
        countryRestrictions,
        onChange,
    } = props;
    const [query, setQuery] = useState(defaultValue);
    const [innerError, setError] = useState<boolean>(Boolean(error));
    const autoCompleteRef = useRef<any>(null);

    const {
        i18n: { language },
    } = useTranslation();

    React.useEffect(() => {
        setError(Boolean(error));
        //eslint-disable-next-line
    }, [error]);

    const handlePlaceSelect = async (updateQuery) => {
        const addressObject = autoComplete.getPlace();
        const query = addressObject?.formatted_address || '';
        updateQuery(query);
        setError(false);
        onChange && onChange(desturctGoogleResult(addressObject));
    };

    const handleScriptLoad = async (updateQuery, autoCompleteRef) => {
        const windowAsAny: any = window;
        autoComplete = new windowAsAny.google.maps.places.Autocomplete(autoCompleteRef.current, { types: ['address'] });
        autoComplete.setComponentRestrictions({
            country: countryRestrictions || [],
        });
        autoComplete.setFields(['address_components', 'formatted_address']);
        autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery));
    };

    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&libraries=places&language=${language}`,
            () => {
                handleScriptLoad(setQuery, autoCompleteRef);
            }
        );
        //eslint-disable-next-line
    }, []);

    return (
        <FormControl>
            {label && (
                <InputLabel
                    shrink
                    error={false}
                    htmlFor={`${id}-google-autocomplete-input` || 'textfield-google-autocomplete-input'}
                >
                    {label}
                </InputLabel>
            )}
            <TextField
                inputRef={autoCompleteRef}
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                variant={variant}
                placeholder={placeholder}
                id={`${id}-google-autocomplete-input` || 'textfield-google-autocomplete-input'}
                disabled={disabled || loading}
                startIcon={startIcon}
                endIcon={endIcon}
                error={innerError}
                helperText={innerError && helperText}
            />
        </FormControl>
    );
};

export default GoogleLocationAutocomplete;
