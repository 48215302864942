import { makeStyles } from '@material-ui/core';
import { customGap } from 'helpers/device_helper';
import { isRtl } from 'helpers/device_helper';
export default makeStyles((theme) => ({
    wrapper: {
        ...customGap(theme.spacing(5), 'vertical'),
        color: theme.palette.text.default,
    },
    text_Capitalize: {
        textTransform: 'capitalize',
    },
    page_link: {
        '&:link,&:visited': {
            color: theme.palette.primary.main,
        },
    },
    challengesTitle: {
        marginBottom: '1.6rem',
        ...isRtl({
            textAlign: 'right',
        }),
    },
    accept_terms_checkbox: {
        '& label': {
            '& span': {
                fontSize: '1.6rem',
                fontWeight: 600,
            },
        },
    },
}));
