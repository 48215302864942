import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    user_selectedTpAccount,
    user_userInfo,
    user_isReachedCreditVolume,
    user_isReachedCreditVolumeLoader,
    user_denyWithdrawal,
    user_disableWithdrawalOpenPositions,
} from 'store/user/selectors';
import {
    AccountInfoParsed,
    PropChallenge,
    TradingPlatformAccountBalance,
    TradingPlatformAccountInfo,
    TradingPlatformInfoTypeEnum,
} from 'services/generatedClientFromSwagger/models';
import SelectRealAccount from './Components/SelectRealAccount';
import CreateRealAccount from './Components/CreateRealaccount';
import { accounts_balanceDataLoading, accounts_balanceDataOne } from 'store/accounts/selectors';
import NotQualifiedToWithdrawal from './Components/NotQualifiedToWithdrawal';
import { user_getReachedCreditVolume } from 'store/user/actions';
import NotQualifiedToWithdrawalVolume from './Components/NotQualifiedToWithdrawalVolume';
import LoaderFullScreen from 'components/LoaderFullScreen';
import { accounts_getAccountsBalance } from 'store/accounts/actions';
import DenyWithdrawal from './Components/DenyWithdrawal';
import {
    prop_myChallenges_getFundedChallenges,
    prop_myChallenges_getSelectedChallenge,
} from 'features/prop/store/my-challenges/selectors';
import { Pages } from 'interfaces/main';

export interface WithdrawGuardProps {
    component: any;
    userInfo: AccountInfoParsed;
    user_selectedTpAccount: TradingPlatformAccountInfo;
    accounts_balanceDataOne: (accountName: string) => TradingPlatformAccountBalance;
    user_getReachedCreditVolume: (tpAccountId: string) => void;
    accounts_balanceDataLoading: boolean;
    accounts_getAccountsBalance: (accountsNames: Array<string>) => void;
    user_enabledReachedVolumeFeature: boolean;
    user_disableWithdrawalOpenPositionsFeature: boolean;
    user_isReachedCreditVolume?: { status: boolean };
    user_isReachedCreditVolumeLoader: boolean;
    user_denyWithdrawal: boolean;
    fundedChallenges?: Array<PropChallenge>;
    selectedChallenge?: PropChallenge;
}

const WithdrawGuard: React.FunctionComponent<WithdrawGuardProps> = (props) => {
    const {
        component: Component,
        userInfo,
        user_selectedTpAccount,
        accounts_balanceDataOne,
        user_getReachedCreditVolume,
        user_isReachedCreditVolume,
        user_isReachedCreditVolumeLoader,
        user_enabledReachedVolumeFeature,
        user_disableWithdrawalOpenPositionsFeature,
        accounts_getAccountsBalance,
        accounts_balanceDataLoading,
        user_denyWithdrawal,
        selectedChallenge,
        ...restProps
    } = props;

    React.useEffect(() => {
        if (
            user_selectedTpAccount?.id &&
            user_selectedTpAccount.tradingPlatform?.type === TradingPlatformInfoTypeEnum.Real &&
            !user_isReachedCreditVolume &&
            !user_isReachedCreditVolumeLoader
        ) {
            user_getReachedCreditVolume(user_selectedTpAccount.id);
        }
    }, [
        user_selectedTpAccount,
        user_isReachedCreditVolume,
        user_getReachedCreditVolume,
        user_isReachedCreditVolumeLoader,
    ]);

    React.useEffect(() => {
        if (user_selectedTpAccount && user_selectedTpAccount.name) {
            accounts_getAccountsBalance([user_selectedTpAccount.name]);
        }
    }, [user_selectedTpAccount, accounts_getAccountsBalance]);

    if (user_isReachedCreditVolumeLoader || accounts_balanceDataLoading) {
        return <LoaderFullScreen />;
    }

    if (
        !userInfo.tradingPlatformAccounts?.some((tp) => tp.tradingPlatform?.type === TradingPlatformInfoTypeEnum.Real)
    ) {
        return <CreateRealAccount />;
    }

    if (user_selectedTpAccount.tradingPlatform?.type === TradingPlatformInfoTypeEnum.Demo) {
        return <SelectRealAccount />;
    }

    if (user_denyWithdrawal) {
        return <DenyWithdrawal />;
    }

    if (user_isReachedCreditVolume && !user_isReachedCreditVolume?.status && !user_isReachedCreditVolumeLoader) {
        return <NotQualifiedToWithdrawalVolume fullName={`${userInfo.firstName} ${userInfo.lastName}`} />;
    }

    const balance = user_selectedTpAccount.name && accounts_balanceDataOne(user_selectedTpAccount.name);

    if (user_disableWithdrawalOpenPositionsFeature && balance && balance.margin !== 0) {
        return <NotQualifiedToWithdrawal fullName={`${userInfo.firstName} ${userInfo.lastName}`} />;
    }

    return <Route {...restProps} render={(props) => <Component {...props} />} />;
};

export const mapStateToProps = (state) => ({
    userInfo: user_userInfo(state),
    user_selectedTpAccount: user_selectedTpAccount(state),
    accounts_balanceDataOne: (accountName: string) => accounts_balanceDataOne(state, accountName),
    user_isReachedCreditVolume: user_isReachedCreditVolume(state),
    user_isReachedCreditVolumeLoader: user_isReachedCreditVolumeLoader(state),
    accounts_balanceDataLoading: accounts_balanceDataLoading(state),
    user_denyWithdrawal: user_denyWithdrawal(state),
    user_disableWithdrawalOpenPositionsFeature: user_disableWithdrawalOpenPositions(state),
    selectedChallenge: prop_myChallenges_getSelectedChallenge(state),
});

export const mapDispatchToProps = {
    user_getReachedCreditVolume,
    accounts_getAccountsBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(WithdrawGuard));
