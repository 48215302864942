import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import Button from 'components/Button';
import useStyles from './version-popup.styles';
import { useTranslation } from 'react-i18next';
import RocketLight from 'assets/images/rocket_light.png';
import RocketDark from 'assets/images/rocket_dark.png';
import { Hidden, useTheme } from '@material-ui/core';

const VersionPopup: React.FunctionComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation('common');
    const [open, setOpen] = useState<boolean>(false);
    const [animate, setAnimate] = useState<boolean>(false);

    useEffect(() => {
        const updateVersion = () => {
            setOpen(true);
        };
        const anyWindow = window as any;
        window.addEventListener('newVersion', updateVersion);
        anyWindow.versionUpdateListenerActive = true;

        return () => {
            window.removeEventListener('newVersion', updateVersion);
        };
    }, []);

    const updateVersion = () => {
        window.location.reload();
    };

    const handleDialogEntered = () => {
        setAnimate(true);
    };

    const handleDialogExited = () => {
        setAnimate(false);
    };

    const theme = useTheme();
    return (
        <Dialog
            open={open}
            classes={{ paper: `${classes.dialogPaper}` }}
            onEntered={handleDialogEntered}
            onExited={handleDialogExited}
        >
            <img
                src={theme.palette.type === 'dark' ? RocketDark : RocketLight}
                alt="Rocket"
                className={classes.imgRocket}
            />
            <DialogContent>
                <DialogContentText className={classes.dialogTitle}>{t('version_popup.title')}</DialogContentText>
                <DialogContentText className={classes.dialogSubTitle}>
                    {t('version_popup.description')}
                </DialogContentText>
            </DialogContent>
            <Button variant="contained" onClick={updateVersion} className={classes.closeButton}>
                {t('version_popup.update')}
            </Button>
        </Dialog>
    );
};

export default VersionPopup;
