import { PropPackage, ValidateDiscountCodeResponseDiscountCodeStatusEnum } from 'services/generatedClientFromSwagger';
import { EPropCheckoutTypes } from './actionsTypes';
import { RootState } from 'store';
import axios from 'services/axios';

export const prop_checkout_savePlanData = (goalId: string, packageId: string) => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const newGoals = state.prop_newChallenge.newGoals;
        let goalIndex = -1;
        let packageIndex = -1;
        const selectedPackages: PropPackage[] = [];
        for (let xIndex = 0; xIndex < newGoals.length; xIndex++) {
            const newGoal = newGoals[xIndex];
            if (newGoal._id === goalId) {
                goalIndex = xIndex;
                for (let yIndex = 0; yIndex < newGoal.challenges[0].packages.length; yIndex++) {
                    const challengePackage = newGoal.challenges[0].packages[yIndex];
                    if (challengePackage._id === packageId) {
                        selectedPackages.push(...newGoal.challenges[0].packages.sort((a, b) => a.amount - b.amount));
                        packageIndex = selectedPackages.findIndex((p) => p._id === packageId);
                        break;
                    }
                }
            }
            if (goalIndex > -1 && packageIndex > -1) {
                break;
            }
        }

        dispatch({
            type: EPropCheckoutTypes.SET_CHECKOUT_PLAN_DATA,
            payload: {
                goalId,
                goalIndex,
                packageId,
                packageIndex,
                selectedPackages,
                selectedGoal: newGoals[goalIndex],
            },
        });
    };
};

export const prop_checkout_saveSelectedPackage = (packageId: string) => {
    return async (dispatch) => {
        dispatch({
            type: EPropCheckoutTypes.SET_SELECTED_PACKAGE,
            payload: packageId,
        });
    };
};

export const prop_checkout_saveSelectedPaymentMethod = (paymentMethod: string) => {
    return async (dispatch) => {
        dispatch({
            type: EPropCheckoutTypes.SET_SELECTED_PAYMENT_METHOD,
            payload: paymentMethod,
        });
    };
};

export const prop_checkout_saveSelectedAddons = (addonsIds: string[]) => {
    return async (dispatch) => {
        dispatch({
            type: EPropCheckoutTypes.SET_SELECTED_ADDONS,
            payload: addonsIds,
        });
    };
};

export const prop_checkout_validateDiscountCode = (discountCode: string, subTotalAmount: number) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPropCheckoutTypes.VALIDATE_DISCOUNT_CODE_START });
            const validDiscountCode = (
                await axios.PropApi.propApiControllerValidateDiscountCode({ discountCode, orderAmount: subTotalAmount })
            ).data;

            const isDiscountCodeValid =
                validDiscountCode.discountCodeStatus === ValidateDiscountCodeResponseDiscountCodeStatusEnum.Valid;

            dispatch({
                type: EPropCheckoutTypes.VALIDATE_DISCOUNT_CODE_SUCCESS,
                payload: {
                    discountCodeValidation: validDiscountCode,
                    isDiscountCodeValid,
                    discountCode,
                    newOrderAmount: isDiscountCodeValid ? validDiscountCode?.newOrderAmount : undefined,
                    discountCodeStatus: validDiscountCode.discountCodeStatus,
                },
            });
        } catch (error) {
            dispatch({ type: EPropCheckoutTypes.VALIDATE_DISCOUNT_CODE_FAIL });
        }
    };
};

export const prop_checkout_resetDiscountCode = () => ({
    type: EPropCheckoutTypes.RESET_DISCOUNT_CODE,
});

export const prop_checkout_setDiscountCode = (discountCode: string) => ({
    type: EPropCheckoutTypes.SET_DISCOUNT_CODE,
    payload: discountCode,
});

export const prop_checkout_resetCheckoutData = () => ({
    type: EPropCheckoutTypes.RESET_CHECKOUT_DATA,
});

export const prop_checkout_getPromoDiscountCodes = (accountId: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EPropCheckoutTypes.GET_PROMO_CODE_START });
            const getPromoDiscountCodes = (await axios.PropApi.propApiControllerGetPromoDiscountCodes(accountId)).data;
            dispatch({
                type: EPropCheckoutTypes.GET_PROMO_CODE_SUCCESS,
                payload: getPromoDiscountCodes?.promotions,
            });
        } catch (error) {
            dispatch({ type: EPropCheckoutTypes.GET_PROMO_CODE_FAIL });
        }
    };
};
