import { createStyles } from '@material-ui/core';
import palette from 'muiTheme/config/palette';

export default createStyles({
    switchHeader: {
        margin: '0.8rem',
        padding: '0.8rem',
        borderRadius: '0.8rem',
        backgroundColor: palette().default.main,
        color: palette().default.contrastText,
    },
    iconButton: {
        padding: 0,
        backgroundColor: 'transparent',
        color: palette().default.contrastText,
    },
    transitionContainer: {
        height: '2rem',
    },
});
