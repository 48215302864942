import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => {
    return {
        form_wrapper: {
            display: 'flex',
            flexDirection: 'column',
        },
        form_item: {
            '& > *:first-child': {
                width: '100%',
            },
        },
        formRow: {
            '&&': {
                maxWidth: '68.4rem',
            },
        },
        with_max_width: {
            '&&': {
                maxWidth: `calc(45.6rem + ${theme.spacing(6)}rem)`,
            },
        },
        spacing5: {
            '&&': {
                width: `calc(100% + ${theme.spacing(5)}rem)`,
                margin: `-${theme.spacing(5) / 2}rem`,
                '& .MuiGrid-item': {
                    padding: `${theme.spacing(5) / 2}rem`,
                },
            },
        },
        spacing6: {
            '&&': {
                width: `calc(100% + ${theme.spacing(2)}rem)`,
                margin: `-${theme.spacing(4) / 2}rem -${theme.spacing(2) / 2}rem`,
                '& .MuiGrid-item': {
                    padding: `${theme.spacing(4) / 2}rem ${theme.spacing(2) / 2}rem`,
                },
            },
        },
        buttonsSection: {
            paddingTop: '8px',
        },
        reverseSubmitCancelButtons: {
            display: 'flex',
            flexDirection: 'column-reverse',
        },
        passwordError: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: '8px',
            color: theme.palette.grayVariants[500],
            padding: '0.8rem 0 0 0',
        },
        passwordIcons: {
            lineHeight: 0,
        },
    };
});
