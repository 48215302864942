import { AccountInfoParsed } from 'services/generatedClientFromSwagger/models';
import { FormComponents, FormInput } from 'components/FormGenerator';
import { user_userInfo, user_fullAddress } from 'store/user/selectors';
import { auth_logout } from 'store/auth/actions';

export const mapStateToProps = (state) => ({
    userInfo: user_userInfo(state),
    userFullAddress: user_fullAddress(state),
});

export const mapDispatchToProps = {
    logOut: auth_logout,
};

export interface UsResidencyProps {
    logOut: () => void;
    userInfo?: AccountInfoParsed;
}

export const UsResidencyFailedInputsScheme = (isUs: boolean): Array<FormInput> => [
    {
        id: 'dearClient',
        type: FormComponents.typography,
        typographyProps: {
            variant: 'body1',
        },
        typographyTextT: 'myWallet:not-qualified-us-resident.dearClient',
    },
    {
        id: 'mainText',
        type: FormComponents.typography,
        typographyProps: {
            variant: 'body1',
        },
        typographyTextT: 'myWallet:not-qualified-us-resident.mainText',
    },
];
