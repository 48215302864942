import { IconsType } from 'components/Icon';
import config from 'config/common';
import { prop_isPropActive } from 'features/prop';
import { prop_myChallenges_getFilteredChallenges } from 'features/prop/store/my-challenges/selectors';
import { Pages } from 'interfaces/main';
import { PropChallenge } from 'services/generatedClientFromSwagger';
import { system_bottom_nav_bar } from 'store/system/selectors';

export enum BottomNavigationIds {
    HOME = 'home',
    MARKETS = 'markets',
    MY_CHALLENGES = 'my-challenges',
    POSITIONS = 'positions',
    SOCIAL = 'social',
    FUNDS = 'funds',
    PLAN_STATISTICS = 'plan-statistics',
    PROP__NEW_CHALLENGES = 'new-challenges',
}

export const navigationTabsScheme = () => [
    {
        id: BottomNavigationIds.HOME,
        labelT: 'trade',
        icon: IconsType.nav_bar_line_chart,
        selectedIcon: IconsType.nav_bar_line_chart_filled,
        link: `/${Pages.PLATFORMS}/home`,
        hasChallenges: true,
    },
    {
        id: BottomNavigationIds.PLAN_STATISTICS,
        labelT: 'stats',
        icon: IconsType.nav_bar_chart_square,
        selectedIcon: IconsType.nav_bar_chart_square_filled,
        link: `/${Pages.PLATFORMS}/plan-statistics`,
        hasChallenges: true,
    },
    {
        id: BottomNavigationIds.PROP__NEW_CHALLENGES,
        labelT: 'new',
        icon: IconsType.nav_bar_plus,
        selectedIcon: IconsType.nav_bar_plus,
        link: `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`,
        isRelevant: prop_isPropActive,
        hidden: false,
        czLink: true,
    },
    {
        id: BottomNavigationIds.MY_CHALLENGES,
        labelT: 'challenges',
        icon: IconsType.nav_bar_trophy,
        selectedIcon: IconsType.nav_bar_trophy_filled,
        link: `/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`,
        isRelevant: prop_isPropActive,
    },
    {
        id: BottomNavigationIds.POSITIONS,
        labelT: 'positions',
        icon: IconsType.nav_bar_settings,
        selectedIcon: IconsType.nav_bar_settings_filled,
        link: `/${Pages.PLATFORMS}/positions`,
        hasChallenges: true,
    },
];

export interface MobileBottomNavigationProps {
    auth_isSocialEnabled: boolean;
    system_bottom_nav_bar: boolean;
    filteredChallenges: Array<PropChallenge>;
}

export const mapStateToProps = (state) => ({
    system_bottom_nav_bar: system_bottom_nav_bar(state),
    filteredChallenges: prop_myChallenges_getFilteredChallenges(state),
});
