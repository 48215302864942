import config from 'config/common';
class Smartico {
    private hasSmartico: boolean = false;

    constructor() {
        if (!config.featuresFlags['SmarticoNotifications'] || !config.smartico.guid || !config.smartico.brandKey)
            return;
        this.hasSmartico = true;
        this.initScript();
    }

    private initScript() {
        const anyWindow = window as any;
        anyWindow._smartico = {};

        (function (d: any, r: any, b: any, h?: any, s?: any) {
            h = d.getElementsByTagName('head')[0];
            s = d.createElement('script');
            s.onload = b;
            s.src = r;
            h.appendChild(s);
        })(document, 'https://libs.smartico.ai/smartico.js', function () {
            anyWindow._smartico.on('init', (errorCode) => {
                console.log('Smartico init', { errorCode });
            });
            anyWindow._smartico.init(config.smartico.guid, { brand_key: config.smartico.brandKey });
        });
    }

    public async login(userId: string) {
        if (!this.hasSmartico) {
            return;
        }
        try {
            await this.waitForSmarticoInit();
            const anyWindow = window as any;
            anyWindow._smartico.identify(userId);
            anyWindow._smartico.online(userId);
        } catch (e) {
            console.error(e);
        }
    }

    private async waitForSmarticoInit() {
        return await new Promise((resolve, reject) => {
            const anyWindow = window as any;
            if (anyWindow?._smartico?.tracker?.initStatus) {
                return resolve('Smartico already init');
            }
            anyWindow._smartico.on('init', (errorCode) => {
                if (errorCode > 0) {
                    return reject('Smartico error');
                }
                return resolve('Smartico init');
            });
        });
    }
}

export default new Smartico();
