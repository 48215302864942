import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    BottomNavigation_root: {
        '& .MuiBottomNavigationAction-root': { minWidth: 'unset' },
        '&.MuiBottomNavigation-root': {
            height: '68px',
            boxShadow: '0px -2px 20px rgba(0, 0, 0, 0.05), 0px -12px 16px rgba(156, 163, 175, 0.04)',
            '&&': {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.default.main,
            },
            direction: 'ltr',
        },
    },
    mui_wrapper: {
        '&&': {
            paddingTop: '0rem',
            '& .MuiBottomNavigationAction-label': {
                fontSize: '1rem',
                marginTop: '0.4rem',
                '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    '& svg, & svg path': {
                        color: theme.palette.primary.main,
                    },
                },
            },
        },
    },
    label: {
        '&&&': {
            fontSize: '1rem',
            marginTop: '0.4rem',
        },
    },
    MuiSvgIcon_root: {
        '& .MuiBottomNavigationAction-wrapper': { gap: '5px' },
        '&&': {
            minWidth: 'unset',
            padding: '0.4rem',
            '&.Mui-selected': {
                paddingTop: '0.4rem',
            },
        },
    },
    propNewChallengesTab: {
        '&.Mui-selected': {
            color: theme.palette.secondary.main,
        },
        color: theme.palette.secondary.main,
        '&.MuiBottomNavigationAction-root': {
            color: theme.palette.secondary.main,
        },
        '&&': {
            marginTop: '-26px',
            '& .MuiBottomNavigationAction-label': {
                fontSize: '1rem',
                marginTop: '0.4rem',
                '&.Mui-selected': {
                    color: theme.palette.secondary.main,
                },
            },
        },
    },
}));
