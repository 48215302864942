export default {
    root: {
        padding: '1.6rem',
    },
    head: {
        padding: '1.2rem 1.6rem',
        height: '4.4rem',
        border: 'unset',
    },
    body: {
        height: '7.2rem',
        position: 'relative' as 'relative',
        textTransform: 'capitalize' as 'capitalize',
        '& > div > p': {
            whiteSpace: 'nowrap' as 'nowrap',
            // textOverflow: 'ellipsis',
            // overflow: 'hidden',
        },
        border: 'unset',
    },
    paddingCheckbox: {
        width: 'unset',
    },
    footer: {},
};
