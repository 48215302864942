import config from 'config/common';
import { getTokenFromLocalStorage } from './token';
import { jwtDecode as decode } from 'jwt-decode';
import { TokenPayload } from './jwtParser';

interface LinkObj {
    link: string;
    user_id: string;
}

const getUserIdFromToken = (token: string | undefined): string => {
    if (!token) return 'null';
    const parsedToken = decode<TokenPayload>(token);
    if (!parsedToken) return 'null';
    return parsedToken?.user_id || 'null';
};

export const saveLinkToSessionStorage = (link: string, token?: string) => {
    const user_id = getUserIdFromToken(token);
    const linkObj: LinkObj = {
        link,
        user_id,
    };
    sessionStorage.setItem(config.linkRememberKey, JSON.stringify(linkObj));
};

export const getLinkFromSessionStorage = () => {
    try {
        const token = getTokenFromLocalStorage();
        const user_id = getUserIdFromToken(token);
        const json = sessionStorage.getItem(config.linkRememberKey);
        if (!json) return null;
        const linkObj: LinkObj = JSON.parse(json);
        if (linkObj.user_id === user_id || linkObj.user_id === 'null') return linkObj.link;
        return null;
    } catch (error) {
        return null;
    }
};

export const getAndRemoveLinkFromSessionStorage = () => {
    try {
        const token = getTokenFromLocalStorage();
        const user_id = getUserIdFromToken(token);
        const json = sessionStorage.getItem(config.linkRememberKey);
        sessionStorage.removeItem(config.linkRememberKey);
        if (!json) return null;
        const linkObj: LinkObj = JSON.parse(json);
        if (linkObj.user_id === user_id || linkObj.user_id === 'null') return linkObj.link;
        return null;
    } catch (error) {
        return null;
    }
};
