import { RootState } from 'store/index';
import { AccountInfoParsedIsUsResidentEnum } from '../../../../services/generatedClientFromSwagger';
import config from 'config/common';

export const kyc_by_regulation_currentStep = (root: RootState) => {
    const { user } = root;

    const { country, city, custom_field_dateOfBirth, isUsResident, regulationKyc } = user.userInfo;

    const steps = [{ step: 0, questions: [1, 2, 3, 4, 5, 6] }];

    const kyc = regulationKyc?.kycs[config.kycType || ''];
    const kycFragments = kyc?.kycFragments || {};
    let userQuestions = Object.keys(kycFragments);

    const currentStep =
        steps.find((step) => {
            let questions = step.questions;
            let stepCompleted = questions.every((r) => userQuestions?.includes(r.toString()));
            return !stepCompleted;
        }) || null;

    switch (true) {
        case Boolean(kyc?.completed):
        case isUsResident === AccountInfoParsedIsUsResidentEnum.Yes:
            return 2;
        case !country || !city || !custom_field_dateOfBirth:
            return -1;
        case currentStep !== null && Boolean(currentStep):
            return Number(currentStep?.step);
        default:
            return 2;
    }
};
