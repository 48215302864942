import React from 'react';
import Loader from '../Loader';

interface Props {
    withoutLoader?: boolean;
}

const LoaderFullScreen = (props: Props) => {
    const { withoutLoader = false } = props;
    return (
        <div
            style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                top: 0,
                right: 0,
                backgroundColor: 'rgba(0,0,0,0.3)',
                zIndex: 10000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {!withoutLoader && <Loader />}
        </div>
    );
};

export default LoaderFullScreen;
