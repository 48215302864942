import { EConfigTypes } from './actionsTypes';
import { ConfigReducerState } from './interfaces';

const initialState: ConfigReducerState = {
    loader: false,
    env: undefined,
};

const configReducer = (state = initialState, { type, payload }: { type: EConfigTypes; payload: any }) => {
    switch (type) {
        case EConfigTypes.GET_ENV_START:
            return {
                ...state,
                loader: true,
            };
        case EConfigTypes.GET_ENV_SUCCESS:
            return {
                ...state,
                loader: false,
                env: payload,
            };
        case EConfigTypes.GET_ENV_FAIL:
            return {
                ...state,
                loader: false,
            };

        default:
            return state;
    }
};

export default configReducer;
