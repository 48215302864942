import { createSelector } from 'reselect';
import { RootState } from '../index';
import countryCodeToDialCode from 'helpers/countryCodeToDialCode';

const systemReducerSelector = (state: RootState) => state.system;

export const system_loader = createSelector(systemReducerSelector, (system) => system.loader);

export const system_theme = createSelector(systemReducerSelector, (system) => system.theme);

export const system_side_menu_state = createSelector(systemReducerSelector, (system) => system.sideMenuState);

export const system_dataLoader = createSelector(systemReducerSelector, (system) => system.dataLoader);

export const system_currenciesList = createSelector(systemReducerSelector, (system) => system.currencies);

export const system_educationModalState = createSelector(systemReducerSelector, (system) => system.educationPopup);

export const system_countriesList = createSelector(systemReducerSelector, (system) =>
    system.countries
        .filter((country) => country.id && country.name)
        .map((country) => ({ value: country.id, label: country.name, shortName: country.shortName }))
);

export const system_countriesListDialCodes = createSelector(systemReducerSelector, (system) =>
    system.countries
        .filter((country) => country.id && country.name)
        .map((country) => ({
            value: countryCodeToDialCode(country.shortName),
            label: country.name,
            selectedLabel: countryCodeToDialCode(country.shortName),
        }))
        .filter((country) => country.value)
);

export const system_snackbarState = createSelector(systemReducerSelector, (system) => system.snackbarIsOpen);

export const system_snackbarObject = createSelector(systemReducerSelector, (system) => system.snackbarObject);

export const getFiltersSelector = createSelector(systemReducerSelector, (system) => system.filters);

export const getFilterStateSelector = createSelector(systemReducerSelector, (system) => system.filterState);

export const getScrollToTableState = createSelector(systemReducerSelector, (system) => system.scrollToTable);

export const system_walkThroughAction = createSelector(systemReducerSelector, (system) => system.walkThoughStatus);

export const system_bottom_nav_bar = createSelector(systemReducerSelector, (system) => system.bottomNavBar);

export const system_selectorDeviceTypeSize = createSelector(systemReducerSelector, (system) => system.deviceType);
