import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { Pages } from 'interfaces/main';

const pagesNames = new Set<Pages>([
    Pages.PROP__MY_PURCHASES,
    Pages.PROP__PAYOUT,
    Pages.PROP__PAYOUT__WITHDRAW,
    Pages.MY_WALLET__DEPOSIT__THANKYOU,
]);

const useDisableHeaderOnMobile = () => {
    const location = useLocation();
    const isMobileView = useMediaQuery('(max-width:600px)');

    return useMemo(() => {
        const { pathname } = location;
        const lastPathname = pathname.replace(/\/$/, '').split('/').pop();
        return isMobileView && pagesNames.has(lastPathname as Pages);
    }, [location, isMobileView]);
};

export default useDisableHeaderOnMobile;
