import { createSelector } from 'reselect';
import { RootState } from '../index';
import { MonetaryTransactionTypeEnum, TradingPlatformInfoTypeEnum } from 'services/generatedClientFromSwagger/models';
import { InternalTransactionStatusEnum } from './interfaces';
import config from '../../config/common';
const ccsOnly = config.featuresFlags['ccsOnly'];

const userReducerSelector = (state: RootState) => state.user;
const accountsReducerSelector = (state: RootState) => state.accounts;
const rootReducerSelector = (state: RootState) => state;

export const accounts_realTPAccounts = createSelector(userReducerSelector, (user) => {
    return (
        user.userInfo.tradingPlatformAccounts?.filter(
            (account) => account.tradingPlatform?.type === TradingPlatformInfoTypeEnum.Real
        ) || []
    );
});

export const accounts_demoTPAccounts = createSelector(userReducerSelector, (user) => {
    return (
        user.userInfo.tradingPlatformAccounts?.filter(
            (account) => account.tradingPlatform?.type === TradingPlatformInfoTypeEnum.Demo
        ) || []
    );
});

export const accounts_balanceDataLoading = createSelector(
    accountsReducerSelector,
    (accounts) => accounts.getAccountsLoader
);

export const accounts_balanceData = createSelector(accountsReducerSelector, (accounts) => accounts.accountsData);

export const accounts_balanceDataOne = (state, accountName: string) =>
    createSelector(accountsReducerSelector, (accounts) => accounts.accountsData[accountName])(state);

export const accounts_getAccountBalance = (state) => (accountName: string) =>
    createSelector(accountsReducerSelector, (accounts) => accounts.accountsData[accountName])(state);

export const accounts_tpTradingHistoryLoading = createSelector(
    accountsReducerSelector,
    (accounts) => accounts.tpHistoryLoader
);

export const accounts_tpTradingHistory = createSelector(accountsReducerSelector, (accounts) => accounts.tpHistory);

export const accounts_tpTradingHistoryOne = (state, tpId: string) =>
    createSelector(accountsReducerSelector, (accounts) => accounts.tpHistory[tpId] || [])(state);

export const accounts_monetaryTransactionsDataLoading = createSelector(
    accountsReducerSelector,
    (accounts) => accounts.getMonetaryTransactionsLoader
);

export const accounts_monetaryTransactionsData = createSelector(
    accountsReducerSelector,
    (accounts) => accounts.monetaryTransactionsHistory
);

export const accounts_monetaryTransactionsDataOne = (state, accountName: string) =>
    createSelector(accountsReducerSelector, userReducerSelector, (accounts, user) =>
        accounts.monetaryTransactionsHistory
            .filter((transaction) => {
                if (
                    transaction.type === MonetaryTransactionTypeEnum.TransferBetweenTradingPlatformAccounts &&
                    transaction?.['oppositeAccountId'] === user?.selectedTPAccount?.id
                ) {
                    return true;
                }

                if (transaction.tradingPlatformAccountName !== accountName) return false;
                if (transaction.type === MonetaryTransactionTypeEnum.Withdrawal) {
                    const isFee =
                        transaction.internalTransactionStatus?.value === InternalTransactionStatusEnum.WithdrawalFee;
                    if (isFee && !transaction.approved) {
                        return false;
                    }
                    return true;
                }
                return true;
            })
            .map((transaction) => {
                if (
                    transaction.type === MonetaryTransactionTypeEnum.TransferBetweenTradingPlatformAccounts &&
                    transaction?.['oppositeAccountId'] === user?.selectedTPAccount?.id
                ) {
                    transaction.amount = transaction?.['convertedAmount'];
                    transaction.currency = user?.selectedTPAccount?.baseCurrency || undefined;
                }
                return transaction;
            })
    )(state);

export const accounts_withdrawTransactionsDataOne = (state, accountName: string) =>
    createSelector(accountsReducerSelector, (accounts) =>
        accounts.monetaryTransactionsHistory.filter((transaction) => {
            const isFee = transaction.internalTransactionStatus?.value === InternalTransactionStatusEnum.WithdrawalFee;
            const isWithdrawal = transaction.type === MonetaryTransactionTypeEnum.Withdrawal;
            if (transaction.tradingPlatformAccountName === accountName && isWithdrawal) {
                if (isFee && !transaction.approved) {
                    return false;
                }
                return true;
            }
            return false;
        })
    )(state);

export const accounts_maxWithdrawAvailableOne = (state, accountName: string) =>
    createSelector(rootReducerSelector, (root) => {
        let czFeatureMaxWithdrawal: undefined | number = undefined;

        if (config.featuresFlags['czFeatures']) {
            czFeatureMaxWithdrawal = root.user.userInfo.accountFeatures?.maxWithdrawal?.amountTreshold;
        }

        if (ccsOnly) {
            const ccsMaxWith = state.assets.walletBalance['USD'];
            if (czFeatureMaxWithdrawal !== undefined) {
                return Math.min(ccsMaxWith, czFeatureMaxWithdrawal);
            } else {
                return ccsMaxWith;
            }
        }

        const balance = accounts_balanceDataOne(root, accountName);
        const transactionData = accounts_monetaryTransactionsDataOne(root, accountName);
        const pendingTransactions = transactionData.filter(
            (transaction) =>
                transaction.type === MonetaryTransactionTypeEnum.Withdrawal &&
                transaction.internalTransactionStatus?.value === InternalTransactionStatusEnum.Pending
        );
        const withdrawableAmount = balance?.withdrawableAmount || 0;
        const pendingRequests = pendingTransactions.reduce((acc, transaction) => acc + (transaction.amount || 0), 0);

        const totalMaxWithdrawal = Math.max(withdrawableAmount - Math.max(pendingRequests, 0), 0);

        if (czFeatureMaxWithdrawal !== undefined) {
            return Math.min(totalMaxWithdrawal, czFeatureMaxWithdrawal);
        } else {
            return totalMaxWithdrawal;
        }
    })(state);

export const accounts_minWithdrawal = createSelector(userReducerSelector, (user) => {
    if (config.featuresFlags['manualMinWithdrawal']) {
        return config.minWithdrawal;
    }

    if (Boolean(config.featuresFlags['czFeatures'])) {
        return Number(user.userInfo.accountFeatures?.minWithdrawal?.amountTreshold) || 0;
    }

    return 0;
});

export const accounts_userHasPendingWithdraw = createSelector(accountsReducerSelector, (accounts) =>
    accounts.monetaryTransactionsHistory.some(
        (transaction) =>
            transaction.type === MonetaryTransactionTypeEnum.Withdrawal &&
            transaction.internalTransactionStatus?.value === InternalTransactionStatusEnum.Pending
    )
);

export const accounts_changeTpPasswordLoading = createSelector(
    accountsReducerSelector,
    (accounts) => accounts.changePasswordLoading
);

export const accounts_changeTpPasswordStatus = createSelector(
    accountsReducerSelector,
    (accounts) => accounts.changePasswordLastStatus
);

export const accounts_updateEmailNotificationsSettingsLoading = createSelector(
    accountsReducerSelector,
    (accounts) => accounts.updateEmailNotificationsSettingsLoading
);
