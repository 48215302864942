import config from 'config/common';
import { lightenDarkenColor } from 'helpers/lighten-darken-color';
import queryString from 'query-string';

interface Params {
    muiThemeJson?: string;
}

const muiThemeJsonToMuiTheme = (muiThemeJson?: string) => {
    try {
        const fromSession = sessionStorage.getItem('muiThemeJson') || undefined;
        muiThemeJson = muiThemeJson || fromSession;
        if (!muiThemeJson) return false;
        const parsedTheme = JSON.parse(muiThemeJson) as any;
        //eslint-disable-next-line
        const test = parsedTheme.dark.background.default;
        sessionStorage.setItem('muiThemeJson', muiThemeJson);
        return parsedTheme;
    } catch (error) {
        return false;
    }
};

const defaultParams: Params = queryString.parse(window.location.search) as any;

let palette = config.pallete;

const PalleteFromSearch = muiThemeJsonToMuiTheme(defaultParams?.muiThemeJson);
if (PalleteFromSearch) {
    palette = PalleteFromSearch;
}

export default (theme: 'dark' | 'light' = 'light') => palette[theme];

export const newColors = {
    gray100: '#F2F4F7',
    blue600: '#1570EF',
    white: '#FFFFFF',
    gray500: '#667085',
    gray50: '#F9FAFB',
    gray700: '#344054',
    green500: '#16b364',
    gray300: '#D0D5DD',
    error500: '#F04438',
    gray200: '#EAECF0',
    gray600: '#475467',
    gray900: '#101828',
    gray400: '#98A2B3',
    greyScale500: '#6B7280',
};

export const newShadows = {
    xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
    lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
    xl2: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    xl3: '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
    button: '12px 12px 36px 6px rgba(2, 2, 70, 0.12)',
};

export interface CreateColorVariantsValue {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
}

export const createColorVariants = (color: string): CreateColorVariantsValue => {
    const levels = [150, 120, 90, 60, 30, 0, -30, -60, -90];
    const variants = {} as CreateColorVariantsValue;

    for (let i = 0; i < levels.length; i++) {
        const level = levels[i];
        const key = (100 + i * 100).toString();
        variants[key] = lightenDarkenColor(color, level);
    }

    return variants;
};
