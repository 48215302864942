import { makeStyles } from '@material-ui/core';
import { isTablet } from 'helpers/device_helper';

interface Props {
    error: boolean;
    withLabel: boolean;
    flexDirection: 'row' | 'column';
}

export default makeStyles((theme) => ({
    formControlRoot: {
        color: theme.palette.text.lightDefault,
        fontSize: '1.6rem',
        fontWeight: 'normal',
        lineHeight: '1.3',
    },
    formControlRoot__active: {
        color: theme.palette.text.default,
        fontWeight: 600,
    },
    label: {
        fontSize: '1.3rem',
        fontWeight: 'normal',
        lineHeight: '2rem',
    },
    checkboxRoot: (props: Props) => ({
        padding: '0 0.8rem',
        '& svg': {
            fill: props.error ? theme.palette.error.main : 'currentColor',
        },
    }),
    radioGroupRoot: (props: Props) => ({
        flexDirection: 'column',
        ...isTablet({
            flexDirection: props.flexDirection,
        }),
    }),
    formControlLabelRoot: (props: Props) => ({
        alignItems: 'flex-start',
        marginTop: `${theme.spacing(4)}rem`,
    }),
    formHelperTextError: {
        '&&': {
            color: theme.palette.error.main,
            backgroundColor: 'transparent',
            padding: 'unset',
            margin: '0',
            marginTop: `${theme.spacing(4)}rem`,
            marginLeft: `${theme.spacing(6)}rem`,
        },
    },
    formLabelHelperText: {
        fontWeight: 'normal',
    },
    formLabelWithHelperText: {
        color: theme.palette.text.default,
        marginBottom: `${theme.spacing(3)}rem`,
        lineHeight: '1.3',
    },
}));
