import config from 'config/common';

export const saveLastSelectedTp = (tp_id: string | undefined) => {
    if (!tp_id) return;
    localStorage.setItem(config.lastSelectedTpKey, tp_id);
};

export const getLastSelectedTpFromLocalStorage = () => {
    try {
        const fromStorage = localStorage.getItem(config.lastSelectedTpKey) || '';
        return fromStorage;
    } catch (error) {
        return '';
    }
};

export const deleteLastSelectedTp = () => {
    localStorage.removeItem(config.lastSelectedTpKey);
};
