import { Hidden } from '@material-ui/core';
import classnames from 'classnames';
import config from 'config/common';
import React from 'react';
import LanguageSelect from '../components/LanguageSelect';
import Logo from '../components/Logo';
import ThemeSwitch from '../components/ThemeSwitch';
import useStyles from '../header.styles';

export interface PublicHeaderProps {}

const PublicHeader: React.FunctionComponent<PublicHeaderProps> = () => {
    const classes = useStyles();
    return (
        <header className={classnames(classes.header_container, classes.header_without_border)}>
            <div className={classes.header_left_section}>
                <Logo alwaysShow />
            </div>
            <div className={classes.header_right_section}>
                {!config.featuresFlags['disableDarkMode'] && (
                    <Hidden xsDown>
                        <ThemeSwitch />
                    </Hidden>
                )}
                <LanguageSelect />
            </div>
        </header>
    );
};

export default PublicHeader;
