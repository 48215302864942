export enum EAuthTypes {
    REGISTER_START = '[auth] register start',
    REGISTER_SUCCESS = '[auth] register success',
    REGISTER_FAILED = '[auth] register failed',

    LOGIN_START = '[auth] login start',
    LOGIN_SUCCESS = '[auth] login success',
    LOGIN_FAILED = '[auth] login failed',

    SOCIAL_REQUEST = '[auth] social request',
    LOGOUT = '[auth] logout',
    RESET_FORM_ERROR = '[auth] reset form error',
    SET_FORM_ERROR = '[auth] set form error',

    START_LOADER = '[auth] start loader',
    STOP_LOADER = '[auth] stop loader',

    IP_DATA_START = '[auth] ip data start',
    IP_DATA_SUCCESS = '[auth] ip data success',
    IP_DATA_FAILED = '[auth] ip data failed',
}
