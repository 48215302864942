import { EUPLOADDTypes } from './actionsTypes';
import axios from 'services/axios';
import { batch } from 'react-redux';
import { removeBase64DataType } from 'helpers/fileToBase64';
import { FileUploadStatus } from './interface';
import { UploadedFileWithType } from '../upload-documents.interface';

export const uploadD_uploadFiles = (files: Array<UploadedFileWithType>) => {
    return async (dispatch) => {
        const filesUploadingState = {};
        const filesPromise = new Promise((resolve, reject) => {
            try {
                dispatch({ type: EUPLOADDTypes.UPLOAD_FILES_PROCESS_START });
                let counter = files.length;
                files.forEach(async (file) => {
                    const { fileType, base64, name } = file;
                    dispatch({ type: EUPLOADDTypes.UPLOAD_FILE_START, payload: { fileName: name } });
                    try {
                        const fileBase64Clean = removeBase64DataType(base64);
                        if (!fileBase64Clean) throw Error('');
                        const uploadFileResponse = await axios.UserApi.userControllerUploadDocs({
                            fileName: `${fileType}_${name}`,
                            fileContent: fileBase64Clean,
                        });
                        if (!uploadFileResponse.data.status) throw Error('');
                        filesUploadingState[name ?? ''] = {
                            loading: false,
                            status: FileUploadStatus.SUCCESS,
                        };
                        dispatch({ type: EUPLOADDTypes.UPLOAD_FILE_SUCCESS, payload: { fileName: name } });
                        if (counter - 1 === 0) {
                            resolve(filesUploadingState);
                            return dispatch({ type: EUPLOADDTypes.UPLOAD_FILES_PROCESS_FINISH });
                        }
                        counter--;
                    } catch (e) {
                        filesUploadingState[name ?? ''] = {
                            loading: false,
                            status: FileUploadStatus.FAILED,
                        };
                        dispatch({ type: EUPLOADDTypes.UPLOAD_FILE_FAILED, payload: { fileName: name } });
                        if (counter - 1 === 0) {
                            return dispatch({ type: EUPLOADDTypes.UPLOAD_FILES_PROCESS_FINISH });
                        }
                        counter--;
                    }
                });
            } catch (e) {
                reject();
                return batch(() => {
                    dispatch({ type: EUPLOADDTypes.UPLOAD_FILES_PROCESS_FINISH });
                });
            }
        });
        return await filesPromise;
    };
};

export const uploadD_cleanUploadFiles = () => ({
    type: EUPLOADDTypes.CLEAN_UPLOAD_FILES,
});

export const uploadD_cleanUploadFile = (fileName: string) => ({
    type: EUPLOADDTypes.CLEAN_UPLOAD_FILE,
    payload: { fileName },
});
