import FormGenerator from 'components/FormGenerator';
import Typography from 'components/Typography';
import { deleteTokenFromLocalStorage } from 'helpers/token';
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UsResidencyFailedInputsScheme, UsResidencyProps } from './not_qualified_to_us_residency.scheme';
import { mapDispatchToProps, mapStateToProps } from './not_qualified_to_us_residency.scheme';
import { AccountInfoParsedIsUsResidentEnum } from 'services/generatedClientFromSwagger';
import PageLayout from 'components/Layouts/PageLayout';
import imgSrc from 'assets/images/contactUs.png';
import useStyles from './not_qualified_to_us_residency.styles';

const UsResidencyFailed: React.FunctionComponent<UsResidencyProps> = (props) => {
    const { logOut, userInfo } = props;
    const history = useHistory();
    const { t } = useTranslation('myWallet');

    const classes = useStyles({ imgSrc });

    const isUs: any = React.useMemo(() => {
        return userInfo?.isUsResident === AccountInfoParsedIsUsResidentEnum.Yes;
    }, [userInfo]);

    React.useEffect(() => {
        setTimeout(() => {
            deleteTokenFromLocalStorage();
            logOut();
        }, 20000);
        //eslint-disable-next-line
    }, []);

    const handleOnSubmit = async () => {
        history.replace('');
    };

    return (
        <PageLayout fullHeight fullWidth infoData>
            <div className={classes.img} />
            <React.Fragment>
                <Typography variant="h1">{t('not-qualified-us-resident.title')}</Typography>
                <FormGenerator
                    onSubmitClick={handleOnSubmit}
                    submitBtnT={'myWallet:not-qualified-us-resident.buttonsText.continue'}
                    inputs={UsResidencyFailedInputsScheme(isUs)}
                    submitBtnProps={{
                        status: 'redirect',
                        statusDuration: 20,
                        statusDurationOverCallback: handleOnSubmit,
                    }}
                />
            </React.Fragment>
        </PageLayout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UsResidencyFailed));
