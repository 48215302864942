import { ErrorsScheme, LoginTypes } from 'interfaces/main';
import { GetIpDataResponse } from 'services/generatedClientFromSwagger/models';

export enum AuthFormFields {
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    password = 'password',
    phoneCountryCode = 'phoneCountryCode',
    phoneNumber = 'phoneNumber',
    accountType = 'accountType',
    general = 'general',
    usCheckbox = 'usCheckbox',
    mainLogin_local = 'mainLogin_local',
    mainLogin_facebook = 'mainLogin_facebook',
    mainLogin_google = 'mainLogin_google',
    mainRegister_local = 'mainRegister_local',
    mainRegister_facebook = 'mainRegister_facebook',
    mainRegister_google = 'mainRegister_google',
    acceptTermOfServiceInformation = 'acceptTermOfServiceInformation',
    acceptCookiesInformation = 'acceptCookiesInformation',
}

export interface AuthLocalRequest {
    [AuthFormFields.firstName]?: string;
    [AuthFormFields.lastName]?: string;
    [AuthFormFields.email]: string;
    [AuthFormFields.password]: string;
    [AuthFormFields.phoneCountryCode]?: string;
    [AuthFormFields.phoneNumber]?: string;
    [AuthFormFields.accountType]?: boolean;
    [AuthFormFields.acceptTermOfServiceInformation]?: boolean;
    [AuthFormFields.acceptCookiesInformation]?: boolean;
}

export interface AuthReducerState {
    loader: boolean;
    loginType: LoginTypes | null;
    errors: ErrorsScheme;
    hasJwtToken: boolean;
    ipData: GetIpDataResponse | undefined;
    emailVerified: boolean | null;
}
