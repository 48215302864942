import Button from 'components/Button';
import React from 'react';
import useStyles from './whatsAppButton.styles';
import whatsAppButton from 'assets/images/whatsAppButton.png';
import Typography from 'components/Typography';
import { Hidden } from '@material-ui/core';
import config from 'config/common';
import { useTranslation } from 'react-i18next';
import mixPanel, { mixPanelEventsTypes } from 'helpers/mixPanel';

const WhatsAppButton: React.FunctionComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation('common');

    if (!config.whatsAppContactNumber || !config.featuresFlags['whatsappButton']) return null;

    return (
        <div className={classes.whatsAppWrapper}>
            <a
                rel="noopener noreferrer"
                target="_blank"
                onClick={() => {
                    mixPanel.track(mixPanelEventsTypes.WHATS_APP_OPEN, {});
                }}
                href={`https://api.whatsapp.com/send?phone=${config.whatsAppContactNumber}&text=Client`}
            >
                <Button classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}>
                    <img alt="whatsapp" src={whatsAppButton} />
                    <Hidden smDown>
                        <Typography variant="body1">{t('breadcrump.WhatsApp_Chat')}</Typography>
                    </Hidden>
                </Button>
            </a>
        </div>
    );
};

export default WhatsAppButton;
