import React from 'react';
import { LanguageSelectProps } from './language_select.scheme';
import { locales } from 'config/i18n';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import Select from 'components/Inputs/Select';
import useStyles from './language_select.styles';

const LanguageSelect: React.FunctionComponent<LanguageSelectProps> = (props) => {
    const { toggleMenuState } = props;
    const classes = useStyles();
    const {
        i18n: { language },
    } = useTranslation();

    const changeLanaguage = (lng: string) => {
        if (lng !== language) {
            i18n.changeLanguage(lng);
            toggleMenuState && toggleMenuState();
        }
    };

    return (
        <div className={classes.select_wrapper}>
            <Select
                value={language}
                options={Object.keys(locales).map((lngKey) => ({
                    label: locales[lngKey].display_name.toUpperCase(),
                    value: locales[lngKey].code,
                    tooltipText: locales[lngKey].full_name,
                }))}
                onChange={changeLanaguage}
                id={'language_select'}
            />
        </div>
    );
};

export default LanguageSelect;
