import { RootState } from 'store/index';
import { AccountInfoParsedIsUsResidentEnum } from '../../../../services/generatedClientFromSwagger';
import config from 'config/common';

export const kyc_as_regulation_currentStep = (root: RootState) => {
    const { user } = root;

    const { country, city, custom_field_dateOfBirth, isUsResident, regulationKyc } = user.userInfo;

    const steps = [
        { step: 0, questions: [1, 2, 3] },
        { step: 1, questions: [4, 5, 6, 7] },
        { step: 2, questions: [8] },
        { step: 3, questions: [20, 21, 22, 23, 24] },
        { step: 4, questions: [25, 27] },
        { step: 5, questions: [28, 29] },
        { step: 6, questions: [30, 31] },
        { step: 7, questions: [32, 33] },
        { step: 8, questions: [34, 36, 39] },
    ];

    const kyc = regulationKyc?.kycs[config.kycType || ''];
    const kycFragments = kyc?.kycFragments || {};
    let userQuestions = Object.keys(kycFragments);

    const currentStep =
        steps.find((step) => {
            let questions = step.questions;
            let stepCompleted = questions.every((r) => userQuestions?.includes(r.toString()));
            return !stepCompleted;
        }) || null;

    switch (true) {
        case Boolean(kyc?.completed):
        case isUsResident === AccountInfoParsedIsUsResidentEnum.Yes:
            return 10;
        case !country || !city || !custom_field_dateOfBirth:
            return -1;
        case currentStep !== null && Boolean(currentStep):
            return Number(currentStep?.step);
        default:
            return 10;
    }
};
