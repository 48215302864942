export enum EAccountsTypes {
    GET_ACCOUNTS_DATA = '[accounts] get accounts data',
    GET_ACCOUNTS_DATA_SUCCESS = '[accounts] get accounts data success',
    GET_ACCOUNTS_DATA_FAILED = '[accounts] get accounts data failed',

    GET_ACCOUNTS_DATA_WITH_NO_LOADER = '[accounts] get accounts data with no loader',
    GET_ACCOUNTS_DATA_SUCCESS_WITH_NO_LOADER = '[accounts] get accounts data success with no loader',
    GET_ACCOUNTS_DATA_FAILED_WITH_NO_LOADER = '[accounts] get accounts data failed with no loader',

    GET_MONETARY_TRANSACTIONS_DATA = '[accounts] get monetary transactions data',
    GET_MONETARY_TRANSACTIONS_DATA_SUCCESS = '[accounts] get monetary transactions data success',
    GET_MONETARY_TRANSACTIONS_DATA_FAILED = '[accounts] get monetary transactions data failed',

    CHANGE_TP_PASSWORD_START = '[accounts] change tp password start',
    CHANGE_TP_PASSWORD_SUCCESS = '[accounts] change tp password success',
    CHANGE_TP_PASSWORD_FAILED = '[accounts] change tp password failed',
    RESET_CHANGE_TP_PASSWORD_STATUS = '[accounts] reset change tp password status',

    GET_TP_TRADING_HISTORY = '[accounts] get tp trading history',
    GET_TP_TRADING_HISTORY_SUCCESS = '[accounts] get tp trading history success',
    GET_TP_TRADING_HISTORY_FAILED = '[accounts] get tp trading history failed',

    // Notification settings
    GET_EMAIL_NOTIFICATIONS_SETTINGS_DATA_SUCCESS = '[accounts] get email notifications settings success',

    UPDATE_EMAIL_NOTIFICATIONS_SETTINGS_START = '[accounts] update email notifications settings',
    UPDATE_EMAIL_NOTIFICATIONS_FAILED = '[accounts] update email notifications failed',
    UPDATE_EMAIL_NOTIFICATIONS_SETTINGS_SUCCESS = '[accounts] update email notifications success',
}
