import { defaultI18Lng, isRtlLanguage } from 'config/i18n';
import moment from 'moment';

export function gmt0ToLocaleTime(date: Date) {
    try {
        return moment.utc(date).local().format();
    } catch (e) {}
    return date;
}

export function dateWithTimeFilter(date, language = defaultI18Lng, withHours = true) {
    try {
        return moment(date).format(getFormat(language, withHours));
    } catch (e) {}
    return '';
}

export function dateFilter(date, language = defaultI18Lng) {
    try {
        return moment(date).format(getFormat(language));
    } catch (e) {}
    return '';
}

export function timeFilter(date, language = defaultI18Lng) {
    try {
        return moment(date).format(isRtlLanguage(language) ? 'HH:mm' : 'HH:mm');
    } catch (e) {}
    return '';
}

export function timeWithSecondsFilter(date, language = defaultI18Lng) {
    try {
        return moment(date).format(isRtlLanguage(language) ? 'HH:mm:ss' : 'HH:mm:ss');
    } catch (e) {}
    return '';
}

export function getFormat(language = defaultI18Lng, withHour = false) {
    const hour = !withHour ? '' : isRtlLanguage(language) ? ', HH:mm' : ', HH:mm';
    const date = isRtlLanguage(language) ? 'MMM DD, YYYY' : 'MMM DD, YYYY';
    return date + hour;
}
