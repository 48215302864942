import React from 'react';
import Typography from 'components/Typography';
import useStyles from '../fundedInvestingManualKycDialog.styles';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { Pages } from 'interfaces/main';
import { Link } from 'react-router-dom';

interface DocumentResubmissionProps {
    handleNextStep: () => void;
}

const VerificationAssistanceRequiredStep: React.FunctionComponent<DocumentResubmissionProps> = ({ handleNextStep }) => {
    const classes = useStyles();
    const { t } = useTranslation('prop');

    return (
        <>
            <Typography className={classes.header} variant="tLgSemibold">
                {t('investingManualKyc.verificationAssistanceRequired.header')}
            </Typography>
            <Typography className={classes.subTxt} variant="tSmallRegular">
                {t('investingManualKyc.verificationAssistanceRequired.subTxt')}
            </Typography>
            <Link to={`/${Pages.CLIENT_SUPPORT}`} replace>
                <Button className={classes.btn} variant="contained" color="primary" onClick={handleNextStep}>
                    {t(`investingManualKyc.verificationAssistanceRequired.btn`)}
                </Button>
            </Link>
        </>
    );
};

export default VerificationAssistanceRequiredStep;
