import React from 'react';
import PageLayout from 'components/Layouts/PageLayout';
import imgSrc from 'assets/images/contactUs.png';
import useStyles from './deny_withdrawal.styles';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { user_fullName } from 'store/user/selectors';

export interface DenyWithdrawalProps {
    fullName: string;
}

const DenyWithdrawal: React.FunctionComponent<DenyWithdrawalProps> = (props) => {
    const classes = useStyles({ imgSrc });
    const { t } = useTranslation('myWallet');

    const { fullName } = props;

    return (
        <PageLayout fullHeight fullWidth infoData>
            <div className={classes.img} />
            <Typography variant="h1" className={classes.title}>
                {t('deny-withdrawal.title', { fullName: fullName })}
            </Typography>
            <Typography variant="body1" color="textSecondary">
                {t('deny-withdrawal.description')}
            </Typography>
        </PageLayout>
    );
};

export const mapDispatchToProps = {};

export const mapStateToProps = (state) => ({
    fullName: user_fullName(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DenyWithdrawal));
