import { makeStyles } from '@material-ui/core';
import { customGap, isLaptop, isRtl } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    wrapper: {
        textAlign: 'start',
        ...customGap(theme.spacing(3), 'vertical'),
    },
    itemsWrapper: {
        maxHeight: '50.0rem',
        overflow: 'auto',
        ...customGap(theme.spacing(3), 'vertical'),
    },
    itemsWrapper__withScroll: {
        ...isLaptop({
            marginRight: '-1.2rem',
            marginLeft: 'unset',
            ...isRtl({
                marginLeft: '-1.2rem',
                marginRight: 'unset',
            }),
        }),
    },
    item: {
        border: `0.1rem solid ${theme.palette.action.disabledBackground}`,
        borderRadius: '0.8rem',
        padding: `${theme.spacing(3)}rem`,
        display: 'flex',
        ...customGap(theme.spacing(3), 'horizontal'),
    },
    itemImage: {
        height: '4.2rem',
        width: '4.2rem',
        minHeight: '4.2rem',
        minWidth: '4.2rem',
        borderRadius: '0.8rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.common.white,
    },
    itemImage__buy: {
        background: theme.palette.success.main,
    },
    itemImage__sell: {
        background: theme.palette.error.main,
    },
    content: {
        color: theme.palette.text.default,
        textAlign: 'start',
        width: '100%',
        ...customGap(theme.spacing(1), 'vertical'),
    },
    spaceBetweenTexts: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    stars_wrapper: {
        display: 'flex',
        ...customGap(theme.spacing(1), 'horizontal'),
    },
    star: {
        color: theme.palette.default.main,
        '& path': {
            stroke: theme.palette.default.dark,
        },
    },
    star__buy: {
        color: theme.palette.success.main,
        '& path': {
            stroke: theme.palette.success.main,
        },
    },
    star__sell: {
        color: theme.palette.error.main,
        '& path': {
            stroke: theme.palette.error.main,
        },
    },
}));
