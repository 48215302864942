import { makeStyles } from '@material-ui/core';
import { isMobileDevice } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    iconBtn: {
        height: '4rem',
        width: '4rem',
        borderRadius: '50%',
        border: `0.1rem solid ${theme.palette.action.disabledBackground}`,
        color: theme.palette.grayVariants[500],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        ...isMobileDevice({
            border: 'none',
        }),
        '&:hover': {
            color: theme.palette.grayVariants[700],
            borderColor: theme.palette.grayVariants[700],
        },
        '& svg': {
            stroke: 'currentColor',
        },
    },
    iconBtn__active: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderColor: 'transparent',
        '&:hover': {
            color: theme.palette.primary.contrastText,
            borderColor: 'transparent',
        },
    },
    toolTipPopper: { pointerEvents: 'initial' },
    toolTipPaper: {
        maxHeight: 'calc(100vh - 10rem)',
        overflow: 'auto',
        borderRadius: '0.8rem',
        padding: `${theme.spacing(6)}rem 0 ${theme.spacing(4)}rem`,
        backgroundColor: theme.palette.background.paper,
        boxShadow:
            '0 0.5rem 0.5rem -0.3rem rgba(0,0,0,0.2), 0 0.8rem 1.0rem 0.1rem rgba(0,0,0,0.14), 0 0.3rem 1.4rem 0.2rem rgba(0,0,0,0.12)',
        minWidth: '32rem',
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(3)}rem`,
    },
    toolTipArrow: {
        color: theme.palette.background.paper,
        height: '1rem',
        width: '1.5rem',
    },
    toolTipTop: {
        '&& .MuiTooltip-arrow': {
            marginTop: '-1rem',
        },
    },
    btn: {
        color: theme.palette.side_menu.text,
        padding: '0.8rem',
        width: '100%',
        alignItems: 'center',
        lineHeight: 1,
        justifyContent: 'flex-start',
        '&:hover,&:focus': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
    notificationIcon: {
        color: theme.palette.grayVariants[600],
    },
}));
