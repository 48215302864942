import React from 'react';
import { ShareButtonProps } from './shareButton.scheme';
import useStyles from './shareButton.styles';
import Icon, { IconsType } from 'components/Icon';

const ShareButton: React.FunctionComponent<ShareButtonProps> = (props) => {
    const { shareButtons, fixedGap } = props;
    const classes = useStyles();

    return (
        <div className={classes.iconsContainer} style={{ gap: `${fixedGap}px` }}>
            {shareButtons.map((button, index) => (
                <a
                    href={button.link}
                    key={`shareButton_${index}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.shareButtonStyle}
                >
                    <Icon key={`share_button_${button.name}`} name={IconsType[button.icon]} />
                </a>
            ))}
        </div>
    );
};
export default ShareButton;
