/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SendAccountVerificationRequestDto } from '../models';
// @ts-ignore
import { SendDeviceAuthenticationRequestDto } from '../models';
// @ts-ignore
import { SendVerificationResponse } from '../models';
// @ts-ignore
import { VerifyAccountResponseParsed } from '../models';
// @ts-ignore
import { VerifyDeviceRequestParsed } from '../models';
// @ts-ignore
import { VerifyDeviceResponseParsed } from '../models';
// @ts-ignore
import { VerifyEmailDto } from '../models';
// @ts-ignore
import { VerifyPhoneDto } from '../models';
/**
 * VerifyAccountApi - axios parameter creator
 * @export
 */
export const VerifyAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {SendDeviceAuthenticationRequestDto} sendDeviceAuthenticationRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyControllerSendDeviceAuthentication: async (
            sendDeviceAuthenticationRequestDto: SendDeviceAuthenticationRequestDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'sendDeviceAuthenticationRequestDto' is not null or undefined
            assertParamExists(
                'verifyControllerSendDeviceAuthentication',
                'sendDeviceAuthenticationRequestDto',
                sendDeviceAuthenticationRequestDto
            );
            const localVarPath = `/api/verify/sendDeviceAuthentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                sendDeviceAuthenticationRequestDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SendAccountVerificationRequestDto} sendAccountVerificationRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyControllerSendVerificationToAccount: async (
            sendAccountVerificationRequestDto: SendAccountVerificationRequestDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'sendAccountVerificationRequestDto' is not null or undefined
            assertParamExists(
                'verifyControllerSendVerificationToAccount',
                'sendAccountVerificationRequestDto',
                sendAccountVerificationRequestDto
            );
            const localVarPath = `/api/verify/sendVerificationToAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                sendAccountVerificationRequestDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {VerifyDeviceRequestParsed} verifyDeviceRequestParsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyControllerVerifyDevice: async (
            verifyDeviceRequestParsed: VerifyDeviceRequestParsed,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'verifyDeviceRequestParsed' is not null or undefined
            assertParamExists('verifyControllerVerifyDevice', 'verifyDeviceRequestParsed', verifyDeviceRequestParsed);
            const localVarPath = `/api/verify/verifyDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                verifyDeviceRequestParsed,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {VerifyEmailDto} verifyEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyControllerVerifyEmail: async (
            verifyEmailDto: VerifyEmailDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmailDto' is not null or undefined
            assertParamExists('verifyControllerVerifyEmail', 'verifyEmailDto', verifyEmailDto);
            const localVarPath = `/api/verify/verifyEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {VerifyPhoneDto} verifyPhoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyControllerVerifyPhone: async (
            verifyPhoneDto: VerifyPhoneDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'verifyPhoneDto' is not null or undefined
            assertParamExists('verifyControllerVerifyPhone', 'verifyPhoneDto', verifyPhoneDto);
            const localVarPath = `/api/verify/verifyPhone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(verifyPhoneDto, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * VerifyAccountApi - functional programming interface
 * @export
 */
export const VerifyAccountApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = VerifyAccountApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {SendDeviceAuthenticationRequestDto} sendDeviceAuthenticationRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyControllerSendDeviceAuthentication(
            sendDeviceAuthenticationRequestDto: SendDeviceAuthenticationRequestDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendVerificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyControllerSendDeviceAuthentication(
                sendDeviceAuthenticationRequestDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SendAccountVerificationRequestDto} sendAccountVerificationRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyControllerSendVerificationToAccount(
            sendAccountVerificationRequestDto: SendAccountVerificationRequestDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendVerificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyControllerSendVerificationToAccount(
                sendAccountVerificationRequestDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {VerifyDeviceRequestParsed} verifyDeviceRequestParsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyControllerVerifyDevice(
            verifyDeviceRequestParsed: VerifyDeviceRequestParsed,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyDeviceResponseParsed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyControllerVerifyDevice(
                verifyDeviceRequestParsed,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {VerifyEmailDto} verifyEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyControllerVerifyEmail(
            verifyEmailDto: VerifyEmailDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyAccountResponseParsed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyControllerVerifyEmail(
                verifyEmailDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {VerifyPhoneDto} verifyPhoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyControllerVerifyPhone(
            verifyPhoneDto: VerifyPhoneDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyAccountResponseParsed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyControllerVerifyPhone(
                verifyPhoneDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * VerifyAccountApi - factory interface
 * @export
 */
export const VerifyAccountApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = VerifyAccountApiFp(configuration);
    return {
        /**
         *
         * @param {SendDeviceAuthenticationRequestDto} sendDeviceAuthenticationRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyControllerSendDeviceAuthentication(
            sendDeviceAuthenticationRequestDto: SendDeviceAuthenticationRequestDto,
            options?: any
        ): AxiosPromise<SendVerificationResponse> {
            return localVarFp
                .verifyControllerSendDeviceAuthentication(sendDeviceAuthenticationRequestDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SendAccountVerificationRequestDto} sendAccountVerificationRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyControllerSendVerificationToAccount(
            sendAccountVerificationRequestDto: SendAccountVerificationRequestDto,
            options?: any
        ): AxiosPromise<SendVerificationResponse> {
            return localVarFp
                .verifyControllerSendVerificationToAccount(sendAccountVerificationRequestDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {VerifyDeviceRequestParsed} verifyDeviceRequestParsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyControllerVerifyDevice(
            verifyDeviceRequestParsed: VerifyDeviceRequestParsed,
            options?: any
        ): AxiosPromise<VerifyDeviceResponseParsed> {
            return localVarFp
                .verifyControllerVerifyDevice(verifyDeviceRequestParsed, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {VerifyEmailDto} verifyEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyControllerVerifyEmail(
            verifyEmailDto: VerifyEmailDto,
            options?: any
        ): AxiosPromise<VerifyAccountResponseParsed> {
            return localVarFp
                .verifyControllerVerifyEmail(verifyEmailDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {VerifyPhoneDto} verifyPhoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyControllerVerifyPhone(
            verifyPhoneDto: VerifyPhoneDto,
            options?: any
        ): AxiosPromise<VerifyAccountResponseParsed> {
            return localVarFp
                .verifyControllerVerifyPhone(verifyPhoneDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerifyAccountApi - object-oriented interface
 * @export
 * @class VerifyAccountApi
 * @extends {BaseAPI}
 */
export class VerifyAccountApi extends BaseAPI {
    /**
     *
     * @param {SendDeviceAuthenticationRequestDto} sendDeviceAuthenticationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifyAccountApi
     */
    public verifyControllerSendDeviceAuthentication(
        sendDeviceAuthenticationRequestDto: SendDeviceAuthenticationRequestDto,
        options?: any
    ) {
        return VerifyAccountApiFp(this.configuration)
            .verifyControllerSendDeviceAuthentication(sendDeviceAuthenticationRequestDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SendAccountVerificationRequestDto} sendAccountVerificationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifyAccountApi
     */
    public verifyControllerSendVerificationToAccount(
        sendAccountVerificationRequestDto: SendAccountVerificationRequestDto,
        options?: any
    ) {
        return VerifyAccountApiFp(this.configuration)
            .verifyControllerSendVerificationToAccount(sendAccountVerificationRequestDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {VerifyDeviceRequestParsed} verifyDeviceRequestParsed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifyAccountApi
     */
    public verifyControllerVerifyDevice(verifyDeviceRequestParsed: VerifyDeviceRequestParsed, options?: any) {
        return VerifyAccountApiFp(this.configuration)
            .verifyControllerVerifyDevice(verifyDeviceRequestParsed, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {VerifyEmailDto} verifyEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifyAccountApi
     */
    public verifyControllerVerifyEmail(verifyEmailDto: VerifyEmailDto, options?: any) {
        return VerifyAccountApiFp(this.configuration)
            .verifyControllerVerifyEmail(verifyEmailDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {VerifyPhoneDto} verifyPhoneDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifyAccountApi
     */
    public verifyControllerVerifyPhone(verifyPhoneDto: VerifyPhoneDto, options?: any) {
        return VerifyAccountApiFp(this.configuration)
            .verifyControllerVerifyPhone(verifyPhoneDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
