import Icon from 'components/Icon';
import React from 'react';
import { useStyles } from './iconBox.styles';
import { IconBoxProps } from './iconBox.scheme';

const IconBox: React.FunctionComponent<IconBoxProps> = ({ size, iconType, color = 'success', variant = 'none' }) => {
    const classes = useStyles({ variant, size, color });
    return (
        <div className={classes.iconWrapper}>
            <Icon className={classes.icon} name={iconType} />
        </div>
    );
};

export default IconBox;
