import React, { useCallback, useEffect } from 'react';
import { DepositResult, DepositTypes } from 'store/payment/interfaces';
import qs from 'qs';
import { Pages } from 'interfaces/main';
import { useHistory } from 'react-router-dom';

export const useSetDepositResults = ({
    depositType,
    payment_resetState,
    payment_setDepositType,
    payment_setDepositResult,
    depositSuccessRedirectUrl,
}) => {
    const history = useHistory();
    useEffect(() => {
        window.addEventListener('message', checkIframeEvent, false);
        window.addEventListener('storage', checkIframeEvent, false);
        return () => {
            window.removeEventListener('message', checkIframeEvent);
            window.removeEventListener('storage', checkIframeEvent);
        };
        //eslint-disable-next-line
    }, [depositType]);

    const checkIframeEvent = useCallback(
        (event) => {
            const paymentType = { ...depositType };

            let eventData = event.data;
            if (event.type === 'storage') {
                if (event?.storageArea?.message) {
                    eventData = JSON.parse(event.storageArea.message);
                }
                if (event?.newValue) {
                    try {
                        eventData = JSON.parse(event?.newValue ?? {});
                    } catch (e) {}
                }
            }

            if (event.type === 'manual') {
                eventData = { queryString: event.data };
            }

            let { queryString, paymentCanceled } = eventData ?? {};
            if (paymentCanceled) {
                payment_resetState();
                return payment_setDepositType(paymentType);
            }

            if (paymentType?.type === DepositTypes.bridgerpay) {
                if (event.data.event === '[bp]:redirect') {
                    const bridgerUrl = new URL(event.data.url);
                    queryString = bridgerUrl.search;
                }
            }

            if (!queryString) return;
            try {
                const parsedQueryString = qs.parse(queryString, { ignoreQueryPrefix: true }) as any;
                let depositResultObj: DepositResult | undefined;

                paymentType.type = paymentType.type ?? parsedQueryString.psp ?? DepositTypes.unknown;

                switch (paymentType.type) {
                    case DepositTypes.payotop: {
                        if (parsedQueryString?.replyCode === '600') {
                            payment_resetState();
                            return payment_setDepositType(depositType);
                        }
                        depositResultObj = {
                            amount: parsedQueryString.trans_amount,
                            currency: parsedQueryString.trans_currency,
                            status: parsedQueryString.replyCode === '000',
                            transactionId: parsedQueryString.trans_id,
                        };
                        break;
                    }
                    case DepositTypes.safecharge:
                        depositResultObj = {
                            amount: parsedQueryString.totalAmount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.ppp_status === 'OK',
                            transactionId: parsedQueryString.TransactionID,
                        };
                        break;
                    case DepositTypes.praxis:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'approved',
                            transactionId: parsedQueryString.order_id,
                        };
                        break;
                    case DepositTypes.bridgerpay:
                        depositResultObj = {
                            amount: 0,
                            currency: 'unknown',
                            status: parsedQueryString.status === 'approved',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.payretailers:
                        depositResultObj = {
                            amount: 0,
                            currency: 'unknown',
                            status: parsedQueryString.status === 'approved',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.neobanq:
                        depositResultObj = {
                            amount: 0,
                            currency: 'unknown',
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.order_id,
                        };
                        break;
                    case DepositTypes.bpwallet:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'approved',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.gr8pay: {
                        if (parsedQueryString?.replyCode === '600') {
                            payment_resetState();
                            return payment_setDepositType(paymentType);
                        }
                        depositResultObj = {
                            amount: parsedQueryString.trans_amount,
                            currency: parsedQueryString.trans_currency,
                            status: parsedQueryString.replyCode === '000',
                            transactionId: parsedQueryString.trans_id,
                        };
                        break;
                    }
                    case DepositTypes.maldopay:
                        depositResultObj = {
                            amount: 0,
                            currency: 'unknown',
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.pagsmile:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: false,
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.paymentechnologies:
                        depositResultObj = {
                            amount: 0,
                            currency: 'unknown',
                            status: parsedQueryString.status === 'approved',
                            transactionId: '',
                        };
                        break;
                    case DepositTypes.ngpayment:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.knoxsecure:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.quikipay:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.rafinita:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.gcg:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.neonpay:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.dikeshield:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.nonstopay:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.korapay:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.kasha:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.pelecard:
                        if (parsedQueryString?.PelecardStatusCode === '555') {
                            payment_resetState();
                            return payment_setDepositType(paymentType);
                        }
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.payport:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.bitolo:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.payneteasy:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.vepara:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.nowpayments:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.match2pay:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.paytechno:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.StatusCode === '1',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.paragon:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: 'unknown',
                            status:
                                parsedQueryString.status.toLowerCase() === 'approved' ||
                                parsedQueryString['status-code'] === 20000 ||
                                parsedQueryString['status-code'] === '20000',
                            transactionId: parsedQueryString.client_orderid,
                        };
                        break;
                    case DepositTypes.epsilon:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.responseCode === '1',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.payku:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'success',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.toptechpay:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: parsedQueryString.status === 'approved',
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.cricpayz:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: true,
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.payok:
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: true,
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    case DepositTypes.unknown: {
                        depositResultObj = {
                            amount: parsedQueryString.amount,
                            currency: parsedQueryString.currency,
                            status: true,
                            transactionId: parsedQueryString.orderId,
                        };
                        break;
                    }
                }
                if (!depositResultObj) return;
                payment_resetState();
                payment_setDepositResult(depositResultObj);
                history.replace(
                    depositSuccessRedirectUrl || `/${Pages.MY_WALLET}/${Pages.MY_WALLET__DEPOSIT__THANKYOU}`
                );
            } catch (error) {
                console.log(error);
            }
        },
        [
            depositType,
            history,
            depositSuccessRedirectUrl,
            payment_resetState,
            payment_setDepositResult,
            payment_setDepositType,
        ]
    );

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('rd') === 'cz') {
            console.log('checkIframeEvent');

            checkIframeEvent({ data: window.location.search, type: 'manual' });
        }
    }, [payment_setDepositType, checkIframeEvent]);
};
