import { createStyles, makeStyles } from '@material-ui/core';
import Icon, { IconsType } from 'components/Icon';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) =>
    createStyles({
        check_iconWrapper: {
            borderRadius: '50%',
            background: theme.palette.blueVariants[600],
            height: 16,
            width: 16,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        trash_iconWrapper: (prop?: { variant?: 'error' | 'info' }) => ({
            color: prop?.variant === 'info' ? theme.palette.blueVariants[600] : theme.palette.errorVariants[700],
        }),
    })
);

export const CheckFilled: FunctionComponent = () => {
    const classes = useStyles();
    return (
        <div className={classes.check_iconWrapper}>
            <Icon name={IconsType.check_filled} />
        </div>
    );
};

interface TrashIconProps {
    variant?: 'error' | 'info';
}

export const Trash: FunctionComponent<TrashIconProps> = ({ variant }) => {
    const classes = useStyles({ variant });
    return (
        <div className={classes.trash_iconWrapper}>
            <Icon name={IconsType.trash} />
        </div>
    );
};
