import * as React from 'react';
import { Snackbar as SnackbarMat, makeStyles, Grow } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Button, { ButtonProps } from 'components/Button';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Icon, { IconsType } from 'components/Icon';
import Typography from 'components/Typography';
import { customGap, isRtl, oppositeCss } from 'helpers/device_helper';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="standard" {...props} />;
}

function GrowTransition(props: TransitionProps) {
    return <Grow {...props} />;
}

interface StyleProps {
    withCustomIcon?: boolean;
    withCloseIcon?: boolean;
}

const useStyles = makeStyles((theme) => ({
    alertIcon: (props: StyleProps) => ({
        display: props.withCustomIcon ? 'none' : 'inherit',
        ...oppositeCss(theme.direction, 'padding', props.withCloseIcon ? `0 0 0 2.0rem` : '0'),
    }),
    alertRoot: (props: StyleProps) => ({
        alignItems: 'center',
        position: 'relative',
        padding: props.withCloseIcon ? '2.0rem 2.0rem 1.0rem 2.0rem' : '1.0rem 2.0rem',
    }),
    message: {
        display: 'flex',
    },
    closeBtn: {
        position: 'absolute',
        left: '1.0rem',
        top: '1.0rem',
        right: 'unset',
        ...isRtl({
            left: 'unset',
            right: '1.0rem',
            top: '1.0rem',
        }),
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2.0rem',
    },
    contectWrapper: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...customGap(theme.spacing(5), 'vertical'),
        maxWidth: '50.0rem',
    },
    actionBtnRoot: {
        borderWidth: '0.1rem',
        borderStyle: 'solid',
        borderColor: 'inherit',
        padding: '0.5rem 1.5rem',
        color: 'inherit',
    },
}));

export interface SnackbarProps {
    action?: () => void;
    onClose: () => void;
    snackbarState?: boolean;
    actionBtnText?: string;
    actionBtnProps?: ButtonProps;
    message: string;
    severty?: 'success' | 'info' | 'warning' | 'error';
    icon?: IconsType;
    duration?: number;
    closeBtn?: boolean;
}

const Snackbar: React.FunctionComponent<SnackbarProps> = (props) => {
    const {
        message,
        actionBtnText,
        actionBtnProps = {},
        severty,
        snackbarState,
        duration = 5000,
        icon,
        closeBtn,
        action,
        onClose,
    } = props;
    const classes = useStyles({ withCustomIcon: Boolean(icon), withCloseIcon: closeBtn });

    const actionButton = () => {
        if (!action) {
            return undefined;
        }

        return (
            <Button
                variant="text"
                {...actionBtnProps}
                classes={{ root: classes.actionBtnRoot }}
                onClick={() => {
                    action();
                    onClose();
                }}
            >
                {actionBtnText || 'Retry'}
            </Button>
        );
    };

    return (
        <SnackbarMat
            open={snackbarState}
            TransitionComponent={GrowTransition}
            autoHideDuration={duration}
            anchorOrigin={{ vertical: 'bottom', horizontal: isRtl() ? 'left' : 'right' }}
            onClose={onClose}
            ClickAwayListenerProps={{ onClickAway: () => null }}
        >
            <Alert
                // action={actionButton()}
                severity={severty}
                classes={{ root: classes.alertRoot, message: classes.message, icon: classes.alertIcon }}
            >
                <React.Fragment>
                    {closeBtn && (
                        <Button variant="text" color="inherit" classes={{ root: classes.closeBtn }} onClick={onClose}>
                            <Icon name={IconsType.close} />
                        </Button>
                    )}
                    {icon && (
                        <div className={classes.iconWrapper}>
                            <Icon name={icon} />
                        </div>
                    )}
                    <div className={classes.contectWrapper}>
                        <Typography variant="body1" color="inherit">
                            {message}
                        </Typography>
                        {actionButton()}
                    </div>
                </React.Fragment>
            </Alert>
        </SnackbarMat>
    );
};

export default Snackbar;
