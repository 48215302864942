import React from 'react';
import PageLayout from 'components/Layouts/PageLayout';
import imgSrc from 'assets/images/contactUs.png';
import useStyles from './not_qualified_to_deposit.styles';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';
import config from 'config/common';

export interface NotQualifiedToDepositProps {}

const NotQualifiedToDeposit: React.FunctionComponent<NotQualifiedToDepositProps> = () => {
    const classes = useStyles({ imgSrc });
    const { t } = useTranslation('myWallet');

    return (
        <PageLayout fullHeight fullWidth infoData>
            <div className={classes.img} />
            <Typography variant="h1" className={classes.title}>
                {t('not-qualified-to-deposit.title', { siteName: config.siteName })}
            </Typography>
            <Typography variant="body1" color="textSecondary">
                {t('not-qualified-to-deposit.description')}
            </Typography>
        </PageLayout>
    );
};

export default NotQualifiedToDeposit;
