import { makeStyles } from '@material-ui/core';
import { newShadows } from 'muiTheme/config/palette';

export default makeStyles((theme) => ({
    dialogPaper: {
        width: '448px',
        maxWidth: '448px',
        borderRadius: '12px',
        boxShadow: newShadows.xl,
        padding: '24px',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.background.default,
    },
    backDrop: { backdropFilter: 'blur(8px)' },
    iconsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1.6rem !important',
    },
    closeButton: {
        color: theme.palette.grayVariants[500],
        width: '4rem',
        height: '4rem',
        padding: '1rem',
    },
    header: {
        marginBottom: '0px !important',
        marginTop: '16px !important',
        color: theme.palette.grayVariants[900],
    },
    subTxtFundedInvestingManualKycDialog: {
        marginTop: '16px !important',
        marginBottom: '16px !important',
        color: theme.palette.grayVariants[600],
    },

    checkBoxContainer: {
        marginBottom: '32px !important',
    },
    FundedInvestingManualKycDialog: {
        width: '100%',
        marginTop: '24px',
    },
    checkbox: {
        color: theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.default.light,
        padding: '0 9px',

        '& svg': {
            color: theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.default.light,
            '& rect': {
                stroke:
                    theme.palette.type === 'dark' ? theme.palette.grayVariants[500] : theme.palette.grayVariants[300],
            },
        },

        '&:last-child': {
            margin: '0',
        },
    },
    id: {
        marginTop: '4px !important',
        marginBottom: '0px !important',
        color: theme.palette.grayVariants[700],
        fontWeight: 400,
    },
    subTxtNotPass: {
        marginTop: '16px !important',
        marginBottom: '8px !important',
    },
    btn: {
        width: '100%',
        marginTop: '24px !important',
        padding: '10px 16px',
        lineHeight: '2.4rem',
    },
    accept: {
        fontWeight: 500,
    },
    traderAgreement: {
        color: theme.palette.primary.main,
        fontWeight: 500,
    },
    subTxt: {
        marginTop: '16px !important',
        marginBottom: '8px !important',
    },
    subTxt2: {
        marginTop: '16px !important',
        marginBottom: '8px !important',
        fontWeight: 400,
    },
    trader_agreement_link: {
        color: `${theme.palette.primary.main} !important`,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
    },
}));
