/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetLeadDetailsResponse1 } from '../models';
// @ts-ignore
import { GetPickListInfoItemsRequest } from '../models';
// @ts-ignore
import { GetPickListInfoItemsResponse } from '../models';
// @ts-ignore
import { GetPromotionCodeGroupsRequest } from '../models';
// @ts-ignore
import { GetPromotionCodeGroupsResponse } from '../models';
// @ts-ignore
import { PublicGetCountriesRequest } from '../models';
// @ts-ignore
import { PublicGetCountriesResponse } from '../models';
// @ts-ignore
import { PublicGetLeadDetailsRequest } from '../models';
// @ts-ignore
import { ValidatePromoCodeDto } from '../models';
/**
 * CrmPublicApiApi - axios parameter creator
 * @export
 */
export const CrmPublicApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {PublicGetCountriesRequest} publicGetCountriesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerGetCountries: async (
            publicGetCountriesRequest: PublicGetCountriesRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'publicGetCountriesRequest' is not null or undefined
            assertParamExists(
                'crmPublicControllerGetCountries',
                'publicGetCountriesRequest',
                publicGetCountriesRequest
            );
            const localVarPath = `/api/crmPublic/GetCountries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                publicGetCountriesRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PublicGetLeadDetailsRequest} publicGetLeadDetailsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerGetLeadDetails: async (
            publicGetLeadDetailsRequest: PublicGetLeadDetailsRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'publicGetLeadDetailsRequest' is not null or undefined
            assertParamExists(
                'crmPublicControllerGetLeadDetails',
                'publicGetLeadDetailsRequest',
                publicGetLeadDetailsRequest
            );
            const localVarPath = `/api/crmPublic/GetLeadDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                publicGetLeadDetailsRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetPickListInfoItemsRequest} getPickListInfoItemsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerGetPickListInfoItems: async (
            getPickListInfoItemsRequest: GetPickListInfoItemsRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getPickListInfoItemsRequest' is not null or undefined
            assertParamExists(
                'crmPublicControllerGetPickListInfoItems',
                'getPickListInfoItemsRequest',
                getPickListInfoItemsRequest
            );
            const localVarPath = `/api/crmPublic/GetPickListInfoItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getPickListInfoItemsRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetPickListInfoItemsRequest} getPickListInfoItemsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerGetPickListInfoItemsAsEnum: async (
            getPickListInfoItemsRequest: GetPickListInfoItemsRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getPickListInfoItemsRequest' is not null or undefined
            assertParamExists(
                'crmPublicControllerGetPickListInfoItemsAsEnum',
                'getPickListInfoItemsRequest',
                getPickListInfoItemsRequest
            );
            const localVarPath = `/api/crmPublic/GetPickListInfoItemsAsEnum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getPickListInfoItemsRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetPromotionCodeGroupsRequest} getPromotionCodeGroupsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerGetPromotionCodeGroups: async (
            getPromotionCodeGroupsRequest: GetPromotionCodeGroupsRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getPromotionCodeGroupsRequest' is not null or undefined
            assertParamExists(
                'crmPublicControllerGetPromotionCodeGroups',
                'getPromotionCodeGroupsRequest',
                getPromotionCodeGroupsRequest
            );
            const localVarPath = `/api/crmPublic/GetPromotionCodeGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getPromotionCodeGroupsRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ValidatePromoCodeDto} validatePromoCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerValidatePromoCode: async (
            validatePromoCodeDto: ValidatePromoCodeDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'validatePromoCodeDto' is not null or undefined
            assertParamExists('crmPublicControllerValidatePromoCode', 'validatePromoCodeDto', validatePromoCodeDto);
            const localVarPath = `/api/crmPublic/ValidatePromoCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                validatePromoCodeDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * CrmPublicApiApi - functional programming interface
 * @export
 */
export const CrmPublicApiApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = CrmPublicApiApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {PublicGetCountriesRequest} publicGetCountriesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmPublicControllerGetCountries(
            publicGetCountriesRequest: PublicGetCountriesRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicGetCountriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmPublicControllerGetCountries(
                publicGetCountriesRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PublicGetLeadDetailsRequest} publicGetLeadDetailsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmPublicControllerGetLeadDetails(
            publicGetLeadDetailsRequest: PublicGetLeadDetailsRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLeadDetailsResponse1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmPublicControllerGetLeadDetails(
                publicGetLeadDetailsRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetPickListInfoItemsRequest} getPickListInfoItemsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmPublicControllerGetPickListInfoItems(
            getPickListInfoItemsRequest: GetPickListInfoItemsRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPickListInfoItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmPublicControllerGetPickListInfoItems(
                getPickListInfoItemsRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetPickListInfoItemsRequest} getPickListInfoItemsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmPublicControllerGetPickListInfoItemsAsEnum(
            getPickListInfoItemsRequest: GetPickListInfoItemsRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmPublicControllerGetPickListInfoItemsAsEnum(
                getPickListInfoItemsRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetPromotionCodeGroupsRequest} getPromotionCodeGroupsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmPublicControllerGetPromotionCodeGroups(
            getPromotionCodeGroupsRequest: GetPromotionCodeGroupsRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPromotionCodeGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmPublicControllerGetPromotionCodeGroups(
                getPromotionCodeGroupsRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ValidatePromoCodeDto} validatePromoCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmPublicControllerValidatePromoCode(
            validatePromoCodeDto: ValidatePromoCodeDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmPublicControllerValidatePromoCode(
                validatePromoCodeDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * CrmPublicApiApi - factory interface
 * @export
 */
export const CrmPublicApiApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = CrmPublicApiApiFp(configuration);
    return {
        /**
         *
         * @param {PublicGetCountriesRequest} publicGetCountriesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerGetCountries(
            publicGetCountriesRequest: PublicGetCountriesRequest,
            options?: any
        ): AxiosPromise<PublicGetCountriesResponse> {
            return localVarFp
                .crmPublicControllerGetCountries(publicGetCountriesRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PublicGetLeadDetailsRequest} publicGetLeadDetailsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerGetLeadDetails(
            publicGetLeadDetailsRequest: PublicGetLeadDetailsRequest,
            options?: any
        ): AxiosPromise<GetLeadDetailsResponse1> {
            return localVarFp
                .crmPublicControllerGetLeadDetails(publicGetLeadDetailsRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetPickListInfoItemsRequest} getPickListInfoItemsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerGetPickListInfoItems(
            getPickListInfoItemsRequest: GetPickListInfoItemsRequest,
            options?: any
        ): AxiosPromise<GetPickListInfoItemsResponse> {
            return localVarFp
                .crmPublicControllerGetPickListInfoItems(getPickListInfoItemsRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetPickListInfoItemsRequest} getPickListInfoItemsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerGetPickListInfoItemsAsEnum(
            getPickListInfoItemsRequest: GetPickListInfoItemsRequest,
            options?: any
        ): AxiosPromise<object> {
            return localVarFp
                .crmPublicControllerGetPickListInfoItemsAsEnum(getPickListInfoItemsRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetPromotionCodeGroupsRequest} getPromotionCodeGroupsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerGetPromotionCodeGroups(
            getPromotionCodeGroupsRequest: GetPromotionCodeGroupsRequest,
            options?: any
        ): AxiosPromise<GetPromotionCodeGroupsResponse> {
            return localVarFp
                .crmPublicControllerGetPromotionCodeGroups(getPromotionCodeGroupsRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ValidatePromoCodeDto} validatePromoCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmPublicControllerValidatePromoCode(
            validatePromoCodeDto: ValidatePromoCodeDto,
            options?: any
        ): AxiosPromise<boolean> {
            return localVarFp
                .crmPublicControllerValidatePromoCode(validatePromoCodeDto, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * CrmPublicApiApi - object-oriented interface
 * @export
 * @class CrmPublicApiApi
 * @extends {BaseAPI}
 */
export class CrmPublicApiApi extends BaseAPI {
    /**
     *
     * @param {PublicGetCountriesRequest} publicGetCountriesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmPublicApiApi
     */
    public crmPublicControllerGetCountries(publicGetCountriesRequest: PublicGetCountriesRequest, options?: any) {
        return CrmPublicApiApiFp(this.configuration)
            .crmPublicControllerGetCountries(publicGetCountriesRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PublicGetLeadDetailsRequest} publicGetLeadDetailsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmPublicApiApi
     */
    public crmPublicControllerGetLeadDetails(publicGetLeadDetailsRequest: PublicGetLeadDetailsRequest, options?: any) {
        return CrmPublicApiApiFp(this.configuration)
            .crmPublicControllerGetLeadDetails(publicGetLeadDetailsRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetPickListInfoItemsRequest} getPickListInfoItemsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmPublicApiApi
     */
    public crmPublicControllerGetPickListInfoItems(
        getPickListInfoItemsRequest: GetPickListInfoItemsRequest,
        options?: any
    ) {
        return CrmPublicApiApiFp(this.configuration)
            .crmPublicControllerGetPickListInfoItems(getPickListInfoItemsRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetPickListInfoItemsRequest} getPickListInfoItemsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmPublicApiApi
     */
    public crmPublicControllerGetPickListInfoItemsAsEnum(
        getPickListInfoItemsRequest: GetPickListInfoItemsRequest,
        options?: any
    ) {
        return CrmPublicApiApiFp(this.configuration)
            .crmPublicControllerGetPickListInfoItemsAsEnum(getPickListInfoItemsRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetPromotionCodeGroupsRequest} getPromotionCodeGroupsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmPublicApiApi
     */
    public crmPublicControllerGetPromotionCodeGroups(
        getPromotionCodeGroupsRequest: GetPromotionCodeGroupsRequest,
        options?: any
    ) {
        return CrmPublicApiApiFp(this.configuration)
            .crmPublicControllerGetPromotionCodeGroups(getPromotionCodeGroupsRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ValidatePromoCodeDto} validatePromoCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmPublicApiApi
     */
    public crmPublicControllerValidatePromoCode(validatePromoCodeDto: ValidatePromoCodeDto, options?: any) {
        return CrmPublicApiApiFp(this.configuration)
            .crmPublicControllerValidatePromoCode(validatePromoCodeDto, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
