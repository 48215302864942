import React from 'react';
import { Select as SelectMat, MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { isMobileDevice, isRtl } from 'helpers/device_helper';
import Loader from 'components/Loader';
import useStyles from './styles';
import Icon, { IconsType } from 'components/Icon';
import Checkbox from '../Checkbox';
import Tooltip from 'components/ToolTip';
import { useTranslation } from 'react-i18next';
import { useClientRect } from 'hooks/useClientRect';
import classNames from 'classnames';

export interface OptionItem {
    value: string | number;
    label:
        | string
        | number
        | React.ReactText
        | { iconType: IconsType; label?: string | number; iconAfterText?: boolean };
    selectedLabel?: string | { iconType: IconsType };
    tooltipText?: string;
    endIcon?: IconsType;
}
export interface SelectProps {
    variant?: 'standard' | 'outlined' | 'filled';
    id?: string;
    label?: string;
    formControlRootClassName?: string;
    selectClassName?: string;
    value?: string | number | Array<string> | Array<number>;
    options: Array<OptionItem>;
    startIcon?: IconsType;
    endIcon?: IconsType;
    loading?: boolean;
    placeholder?: string;
    color?: 'primary' | 'secondary';
    multiple?: boolean;
    multipleLabel?: string | { iconType: IconsType };
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    fullWidth?: boolean;
    disableMarginTop?: boolean;
    defaultValue?: string | number | Array<string> | Array<number>;
    slim?: boolean;
    removeArrow?: boolean;
    disableNativeSelect?: boolean;
    disableBorder?: boolean;
    disablePadding?: boolean;
    extraMenuItemClassNames?: { [className: string]: boolean };
    staticRenderValue?: string | { iconType: IconsType; tooltip?: string };
    inputRef?: (el: any) => void;
    onChange?: (value: any) => void;
    onOptionClick?: (e, value) => void;
    isRtlReverseDirection?: boolean;
    selectFirstOption?: boolean;
}

const Select: React.FunctionComponent<SelectProps> = (props) => {
    const {
        id,
        label,
        value,
        options,
        variant = 'outlined',
        startIcon,
        endIcon,
        color,
        loading,
        multiple,
        multipleLabel,
        placeholder,
        disabled,
        error,
        helperText,
        fullWidth,
        disableMarginTop,
        defaultValue,
        slim,
        removeArrow,
        disableNativeSelect,
        disableBorder,
        disablePadding,
        extraMenuItemClassNames,
        staticRenderValue,
        inputRef,
        onChange,
        onOptionClick,
        isRtlReverseDirection,
        selectFirstOption = false,
        formControlRootClassName,
        selectClassName,
    } = props;
    const [{ height: refHeight }, myRef] = useClientRect();

    const inputGap = React.useMemo(() => {
        const remRefHeight = refHeight / 10;
        if (remRefHeight >= 1.2) {
            return Math.abs(remRefHeight + 1.2);
        } else {
            return 2.4;
        }
    }, [refHeight]);

    const classes = useStyles({
        fullWidth,
        disableMarginTop,
        slim,
        disabled: disabled || loading,
        inputGap,
        disableBorder,
        removeArrow,
        disablePadding,
        isRtlReverseDirection,
    });
    const { t } = useTranslation();

    const [selectValue, setSelectValue] = React.useState<string | number | Array<string> | Array<number>>(() => {
        if (value) return value;
        if (multiple) return [];
        if (selectFirstOption && options.length > 0) return options[0].value;
        return '';
    });

    const handleSelectChange = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        let newValue: any = e.target.value;
        setSelectValue(newValue);
        onChange && onChange(newValue);
    };

    const handleChangeMultipleNative = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { options } = event.target as HTMLSelectElement;
        const value: string[] = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setSelectValue(value);
        onChange && onChange(value);
    };

    React.useEffect(() => {
        if (defaultValue) {
            setSelectValue(defaultValue);
        }
        //eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (value) {
            setSelectValue(value);
        }
    }, [value]);

    const getEndAdorment = () => {
        if (loading) return <Loader size={15} />;
        return endIcon ? <Icon name={endIcon} /> : null;
    };

    const getStartAdorment = () => (startIcon ? <Icon name={startIcon} /> : null);

    const Placeholder = () => (
        <span id="placeholder" className={classes.placeholder}>
            {placeholder}
        </span>
    );

    const showNativeSelect = () => {
        return isMobileDevice() && !disableNativeSelect;
    };

    const getHelperText = () => {
        if (helperText) return helperText;
        if (multiple && showNativeSelect()) {
            return t('helperText.multiSelect');
        }
    };

    const getSelectedItemLabel = (item?: OptionItem) => {
        if (!item) return '';
        if (typeof item.selectedLabel === 'string') {
            return item.selectedLabel;
        }
        if (item.selectedLabel?.iconType) {
            return (
                <div className={classes.selectedIcon}>
                    <Icon name={item.selectedLabel?.iconType} />
                    <span>{item.label}</span>
                </div>
            );
        }
        if (typeof item.label === 'string') return item.label;
        if (typeof item.label === 'object' && item.label?.iconType) {
            return (
                <div className={classes.selectedIcon}>
                    <Icon name={item.label.iconType} />
                    <span>{item.label.label}</span>
                </div>
            );
        }
    };

    return (
        <FormControl
            classes={{ root: `${classes.formControlRoot} ${formControlRootClassName}` }}
            variant={variant}
            error={error}
        >
            {label && (
                <div>
                    <InputLabel ref={myRef} error={false} shrink={true} id={`select-${id}-label`}>
                        {label}
                    </InputLabel>
                </div>
            )}
            <SelectMat
                color={color}
                inputRef={inputRef}
                disabled={disabled || loading}
                multiple={multiple}
                native={showNativeSelect()}
                classes={{ select: `${classes.select} ${selectClassName}`, disabled: classes.selectDisabled }}
                MenuProps={{
                    classes: { list: classes.menuList, paper: classes.menuPaper },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: isRtl() ? 'right' : 'left',
                    },
                }}
                IconComponent={
                    loading || removeArrow
                        ? () => null
                        : (args) => (
                              <Icon
                                  {...args}
                                  color={color}
                                  name={IconsType.chevron_down_piqk}
                                  className={classNames(args.className, classes.selectIcon)}
                              />
                          )
                }
                labelId={`select-${id}-label`}
                id={`select-${id}`}
                value={selectValue}
                onChange={showNativeSelect() && multiple ? handleChangeMultipleNative : handleSelectChange}
                startAdornment={getStartAdorment()}
                endAdornment={getEndAdorment()}
                displayEmpty
                renderValue={(selected) => {
                    if (multiple && Array.isArray(selected)) {
                        const selectedObject = selected
                            .map((item) => {
                                const itemFound = options.find((option) => option.value === item);
                                return itemFound?.selectedLabel || itemFound?.label || '';
                            })
                            .join(', ');
                        return selected.length === 0 ? <Placeholder /> : selectedObject;
                    }
                    if (staticRenderValue) {
                        if (typeof staticRenderValue === 'object') {
                            if (staticRenderValue.tooltip) {
                                return (
                                    <Tooltip title={staticRenderValue.tooltip}>
                                        <div>
                                            <Icon name={staticRenderValue.iconType} />
                                        </div>
                                    </Tooltip>
                                );
                            }
                            return <Icon name={staticRenderValue.iconType} />;
                        }
                        return staticRenderValue;
                    }
                    if (multiple && multipleLabel) {
                        return typeof multipleLabel === 'object' && multipleLabel?.iconType ? (
                            <Icon name={multipleLabel.iconType} />
                        ) : (
                            multipleLabel
                        );
                    }
                    if (multiple && Array.isArray(selected)) {
                        const selectedObject = selected
                            .map((item) => {
                                const itemFound = options.find((option) => option.value === item);
                                return getSelectedItemLabel(itemFound);
                            })
                            .join(', ');
                        return selected.length === 0 ? <Placeholder /> : selectedObject;
                    }
                    const selectedItem = options.find((option) => option.value === selected);
                    const label =
                        typeof selectedItem?.label === 'object' ? selectedItem?.label?.label : selectedItem?.label;

                    return selected === '' ? <Placeholder /> : getSelectedItemLabel(selectedItem) || label;
                }}
            >
                {placeholder ? (
                    showNativeSelect() ? (
                        <option
                            style={{ display: 'none' }}
                            disabled
                            value={''}
                            className={classNames(extraMenuItemClassNames)}
                        >
                            {placeholder}
                        </option>
                    ) : (
                        <MenuItem
                            style={{ display: 'none' }}
                            disabled
                            value={''}
                            className={classNames(extraMenuItemClassNames)}
                        >
                            <Placeholder />
                        </MenuItem>
                    )
                ) : showNativeSelect() ? (
                    <option disabled hidden></option>
                ) : null}

                {options.map((option, index) =>
                    showNativeSelect() ? (
                        <option
                            key={`select-option-${id}-${option.value}`}
                            id={`select-option-${id}-${option.value}`.split(' ').join('')}
                            value={option.value}
                            className={classNames(extraMenuItemClassNames)}
                            onClick={(e) => onOptionClick && onOptionClick(e, option.value)}
                        >
                            {typeof option.label === 'object' ? (
                                <React.Fragment>
                                    {option.label.label && <span>{option.label.label}</span>}
                                    {option.label.iconType && (
                                        <div className={classes.selectItemIcon}>
                                            <Icon name={option.label.iconType} />
                                        </div>
                                    )}
                                </React.Fragment>
                            ) : (
                                option.label
                            )}
                        </option>
                    ) : (
                        <MenuItem
                            key={`select-option-${id}-${option.value}`}
                            id={`select-option-${id}-${option.value}`.split(' ').join('')}
                            value={option.value}
                            className={classNames(extraMenuItemClassNames)}
                            onClick={(e) => onOptionClick && onOptionClick(e, option.value)}
                        >
                            {multiple && (
                                <Checkbox
                                    id={`select-option-checkbox-${id}-${option.value}`.split(' ').join('')}
                                    color="default"
                                    inheritColor
                                    disableSidesMargin
                                    checked={
                                        Array.isArray(selectValue) &&
                                        selectValue.some((select) => select === option.value)
                                    }
                                />
                            )}

                            {typeof option.label === 'object' ? (
                                <div className={classes.selectItemIconLabelWrapper}>
                                    {!option.label.iconAfterText && option.label.iconType && (
                                        <div className={classes.selectItemIcon}>
                                            <Icon name={option.label.iconType} />
                                        </div>
                                    )}
                                    {option.label.label && <span>{option.label.label}</span>}
                                    {option.label.iconAfterText && option.label.iconType && (
                                        <div className={classes.selectItemIcon}>
                                            <Icon name={option.label.iconType} />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                option.label
                            )}
                            {option.tooltipText && (
                                <Tooltip title={option.tooltipText} placement={isRtl() ? 'right' : 'left'}>
                                    <div className={classes.tooltip} />
                                </Tooltip>
                            )}
                            {option.endIcon && (
                                <div>
                                    <Icon name={IconsType.CheckSvg} />
                                </div>
                            )}
                        </MenuItem>
                    )
                )}
            </SelectMat>
            {getHelperText() && <FormHelperText>{getHelperText()}</FormHelperText>}
        </FormControl>
    );
};

export default Select;
