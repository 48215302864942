import { Notification } from 'services/generatedClientFromSwagger';
import React from 'react';
import {
    prop__myChallenges_acceptChallengeTermsAndCondition,
    prop_handleKycDialogStateFromNotification,
} from 'features/prop/store/my-challenges/actions';

export interface QuickPreviewMessageProps {
    closeMenu?: () => void;
    message: Notification;
    isActive?: boolean;
    markNotification?: (messageId: Array<string>) => void;
    handleKycDialogStateFromNotification: () => void;
    acceptChallengeTermsAndCondition: (challengeId: string) => void;
}

export const mapStateToProps = (state) => ({});

export const mapDispatchToProps = {
    handleKycDialogStateFromNotification: prop_handleKycDialogStateFromNotification,
    acceptChallengeTermsAndCondition: prop__myChallenges_acceptChallengeTermsAndCondition,
};

export const countWords = (text) => {
    const words = text.split(' ');
    return words.length;
};

export const textLines = (text, showMoreState, limitWord) => {
    if (showMoreState) return text;
    const words = text.split(' ');
    const dynamicWordsLines = words.slice(0, limitWord);
    return dynamicWordsLines.join(' ') + ' ';
};

export const lineButton = (text, showMoreState, t, classes, showMore, showLess, limitWord) => {
    const wordsLength = countWords(text);
    if (wordsLength < limitWord) return;

    return !showMoreState ? (
        <>
            ...{' '}
            <span onClick={showMore} className={classes.showMoreLess}>
                {t('showMoreButton')}
            </span>
        </>
    ) : (
        <span onClick={showLess} className={classes.showMoreLess}>
            {t('showLessButton')}
        </span>
    );
};
