import { TFunction } from 'i18next';
import moment from 'moment';
import { Moment } from 'moment';

export const getDiffInDDHHMM = (from: Moment, to: Moment, t: TFunction, createdAt) => {
    let days = to.diff(from, 'days');
    from.days(from.days() + days);
    let hours = to.diff(from, 'hours');
    from.hours(from.hours() + hours);
    let minutes = to.diff(from, 'minutes');
    switch (true) {
        case days > 0:
            return moment(createdAt).format('MMM DD');
        case hours > 0:
            return t('trading-analysis:suggestions.timeDiff.hours', { hours, minutes });
        default:
            return t('trading-analysis:suggestions.timeDiff.minutes', { minutes });
    }
};
