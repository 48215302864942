import { createStyles } from '@material-ui/core';

export default createStyles({
    root: {
        textAlign: 'start',
        fontSize: '1.2rem',
        letterSpacing: '0.026rem',
        lineHeight: 1.19,
        whiteSpace: 'pre-line',
        '&$error': {
            margin: '-0.6rem 0 0 0',
            padding: '1.0rem 0 0.4rem',
            borderRadius: '0 0 0.8rem 0.8rem',
            fontWeight: 400,
            fontSize: '1.4rem',
            lineHeight: '2.0rem',
        },
    },
});
