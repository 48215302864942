import { DeviceType } from 'hooks/useDeviceType';
import React from 'react';
import MenuButton from './components/menuButton.render';

export interface BackOrMenuBtnProps {
    toggleSideMenu: () => void;
    accountName?: string;
    deviceSize?: DeviceType | '';
}

const BackOrMenuBtn: React.FunctionComponent<BackOrMenuBtnProps> = (props) => {
    const { toggleSideMenu, accountName } = props;

    return (
        <React.Fragment>
            <MenuButton accountName={accountName} toggleSideMenu={toggleSideMenu} />
        </React.Fragment>
    );
};

export default BackOrMenuBtn;
