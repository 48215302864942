import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    SideMenuProps,
    mapDispatchToProps,
    mapStateToProps,
    menuLinks,
    menuLinksApp,
    shareButtons,
} from './side-menu.scheme';
import useStyles from './side-menu.styles';
import MenuItem from './components/MenuItem';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import MenuTopSection from './components/MenuTopSection';
import { Pages, ThemeOptions } from 'interfaces/main';
import Icon, { IconsType } from 'components/Icon';
import classNames from 'classnames';
import { isDesktop, isLaptop, isMobileDevice } from 'helpers/device_helper';
import { Hidden, useMediaQuery } from '@material-ui/core';
import config from 'config/common';
import Typography from 'components/Typography';
import Button from 'components/Button';
import ShareButton from 'components/ShareButtons';
import Tooltip from 'components/ToolTip';
import { PropChallengeChallengeStatusEnum } from 'services/generatedClientFromSwagger';

const isWtDesktopSize = '1088';

const SideMenu: React.FunctionComponent<SideMenuProps> = (props) => {
    const {
        sideMenuState,
        uploadD_menuBannerProps,
        userInfo,
        toggleTheme,
        logOut,
        system_toggleSideMenu,
        user_tradingAnalysisActive,
        userFlowGuard,
        theme,
        challenges,
        filteredChallenges,
        notStartedFundedChallenges,
        system_toggleEducationModal,
    } = props;
    const classes = useStyles({ sideMenuState });
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const matches = useMediaQuery(`(min-width:${isWtDesktopSize}px)`);

    const handleOnOverlayClick = (e) => {
        if (e?.target?.id === 'overlay') {
            system_toggleSideMenu();
        }
    };
    const handleOnMenuItemClick = (obj?: {
        logoutClick?: boolean;
        toogleTheme?: boolean;
        tourGuide?: boolean;
        tradersEducationClick?: boolean;
    }) => {
        const { logoutClick, toogleTheme, tourGuide, tradersEducationClick } = obj || {};

        if (logoutClick) {
            return logOut();
        }
        if (toogleTheme) {
            toggleTheme();
        }
        if (tourGuide && config.wtFeaturesFlags['tourGuide'] && Boolean(validChallenges?.length)) {
            history.push(`/${Pages.PLATFORMS}?startTourGuide=true`);
        }
        if (sideMenuState && !isDesktop()) {
            system_toggleSideMenu();
        }
        if (tradersEducationClick) {
            system_toggleEducationModal();
        }
    };

    const handleCloseButton = () => {
        if (sideMenuState && !isDesktop()) {
            system_toggleSideMenu();
        }
    };

    const checkIfIsActive = (link: string | Array<string> | undefined) => {
        if (!link) return false;
        if (Array.isArray(link)) {
            return link.some((url) => location.pathname.includes(url));
        }
        return location.pathname.includes(link);
    };
    const validChallenges = useMemo(
        () =>
            filteredChallenges.filter(
                (challenge) =>
                    challenge?.challengeStatus !== PropChallengeChallengeStatusEnum.NotStarted &&
                    challenge?.challengeStatus !== PropChallengeChallengeStatusEnum.Draft
            ),
        [filteredChallenges]
    );
    return (
        <div
            className={classNames({
                [classes.overlay]: true,
                [classes.overlay__open]: sideMenuState,
            })}
            id="overlay"
            onClick={handleOnOverlayClick}
        >
            <div
                className={classNames({
                    [classes.menu_container]: true,
                    [classes.menu_container__open]: sideMenuState,
                })}
            >
                {sideMenuState && (
                    <Button onClick={handleCloseButton} className={classes.closeButton}>
                        <Icon name={IconsType.close} />
                    </Button>
                )}
                <div className={classes.menu_list_wrapper}>
                    <MenuTopSection
                        userFlowGuard={userFlowGuard}
                        notificationsAmount={3}
                        menuState={sideMenuState}
                        toggleMenuState={system_toggleSideMenu}
                    />
                </div>

                <div className={classes.menu_list_wrapper}>
                    {menuLinks
                        .filter((linkObject) => {
                            if (linkObject.tradingAnalysisLogic) {
                                return user_tradingAnalysisActive;
                            }
                            if (!linkObject.link) return false;
                            if (linkObject.hideLink) return false;
                            if (linkObject.hasChallenges && challenges.length === 0) return false;
                            if (
                                linkObject.hasFilteredChallenges &&
                                filteredChallenges.length === 0 &&
                                notStartedFundedChallenges?.length === 0
                            )
                                return false;
                            if (linkObject.hidden === 'forced' && !userInfo.hasCcsWallet) return false;
                            if (linkObject.hasChallengeStarted) {
                                return filteredChallenges.find((challenge) => challenge.tpName);
                            }

                            return true;
                        })
                        .map((linkObject, linkIndex) => {
                            return (
                                <Hidden
                                    key={`menuItem_${linkIndex}`}
                                    {...(linkObject.hidden ? { [linkObject.hidden]: true } : {})}
                                >
                                    <MenuItem
                                        withTooltip={isLaptop()}
                                        isCollapsible={linkObject.collapsible}
                                        collapsible={linkObject.collapsible}
                                        onClick={handleOnMenuItemClick}
                                        menuState={sideMenuState}
                                        t={t}
                                        {...linkObject}
                                        active={checkIfIsActive([
                                            linkObject.link || '',
                                            ...(linkObject.possibleLinks ? linkObject.possibleLinks : []),
                                        ])}
                                        subMenuLinks={linkObject?.subMenuLinks?.filter((subLink) => {
                                            if (subLink.hasChallenges) {
                                                return challenges.length > 0;
                                            }
                                            if (subLink.hasFilteredChallenges) {
                                                return (
                                                    filteredChallenges.length > 0 ||
                                                    (notStartedFundedChallenges?.length ?? 0) > 0
                                                );
                                            }
                                            return true;
                                        })}
                                        id={`menu_button_${linkObject.t_name}`.split('.').join('_')}
                                    />
                                </Hidden>
                            );
                        })}

                    {config.featuresFlags['tradersEducation'] && config.tradersEducation?.url && (
                        <MenuItem
                            withTooltip={isLaptop()}
                            menuState={sideMenuState}
                            t={t}
                            t_name={'side_menu.item_names.traders__education'}
                            onClick={() => handleOnMenuItemClick({ tradersEducationClick: true })}
                            icon={IconsType.lightbulbProp}
                            id={`menu_button_traders_education`}
                        />
                    )}

                    {uploadD_menuBannerProps && (
                        <MenuItem
                            withTooltip={isLaptop()}
                            onClick={handleOnMenuItemClick}
                            menuState={sideMenuState}
                            t={t}
                            active={checkIfIsActive(uploadD_menuBannerProps.possibleLinks)}
                            link={uploadD_menuBannerProps.link}
                            t_name={`side_menu.item_names.${uploadD_menuBannerProps.buttonType}`}
                            icon={uploadD_menuBannerProps.icon}
                            percent={uploadD_menuBannerProps.stepsPercent}
                            id={`menu_item_uploadDocuments`}
                        />
                    )}
                </div>

                <div className={classNames(classes.menu_list_wrapper, classes.menu_list_wrapper_last)}>
                    <div>
                        {!config.featuresFlags['disableDarkMode'] && (
                            <MenuItem
                                withTooltip={isLaptop()}
                                menuState={sideMenuState}
                                t={t}
                                t_name={`side_menu.item_names.${theme}_mode`}
                                onClick={() => handleOnMenuItemClick({ toogleTheme: true })}
                                icon={theme === ThemeOptions.DARK ? IconsType.lightModeIcon : IconsType.darkModeIcon}
                                id={`menu_item_light_dark_mode`}
                            />
                        )}
                        {matches && config.wtFeaturesFlags['tourGuide'] && Boolean(validChallenges?.length) && (
                            <MenuItem
                                withTooltip={isLaptop()}
                                menuState={sideMenuState}
                                t={t}
                                t_name={'side_menu.item_names.tour_guide'}
                                onClick={() => handleOnMenuItemClick({ tourGuide: true })}
                                icon={IconsType.tour_guide_book_piqk}
                            />
                        )}

                        {menuLinksApp
                            .filter((linkObject) => linkObject.link)
                            .map((linkObject, linkIndex) => (
                                <React.Fragment key={`sidemenu-link-${linkIndex}`}>
                                    {sideMenuState && linkObject.appCodeText && linkObject.appCode ? (
                                        <div className={classes.menuItemWrapper}>
                                            <Tooltip
                                                title={t(linkObject.appCodeText, { subVar: linkObject.appCode })}
                                                placement="right"
                                            >
                                                <div className={classes.menuItemWrapper}>
                                                    <MenuItem
                                                        withTooltip={isLaptop()}
                                                        menuState={sideMenuState}
                                                        t={t}
                                                        {...linkObject}
                                                        active={checkIfIsActive([
                                                            linkObject.link || '',
                                                            ...(linkObject.possibleLinks
                                                                ? linkObject.possibleLinks
                                                                : []),
                                                        ])}
                                                        id={`menu_button_${linkObject.t_name}`.split('.').join('_')}
                                                        onClick={(e) => {
                                                            if (isMobileDevice()) {
                                                                e.preventDefault();
                                                                if (linkObject.link) {
                                                                    const delay = 2000;
                                                                    setTimeout(() => {
                                                                        window.location.href = linkObject.link!;
                                                                    }, delay);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <MenuItem
                                            key={`menuItem_${linkIndex}`}
                                            withTooltip={isLaptop()}
                                            additionalTooltipContent={
                                                linkObject.appCodeText && linkObject.appCode
                                                    ? t(linkObject.appCodeText, { subVar: linkObject.appCode })
                                                    : undefined
                                            }
                                            menuState={sideMenuState}
                                            t={t}
                                            {...linkObject}
                                            active={checkIfIsActive([
                                                linkObject.link || '',
                                                ...(linkObject.possibleLinks ? linkObject.possibleLinks : []),
                                            ])}
                                            id={`menu_button_${linkObject.t_name}`.split('.').join('_')}
                                        />
                                    )}
                                </React.Fragment>
                            ))}

                        <MenuItem
                            withTooltip={isLaptop()}
                            menuState={sideMenuState}
                            t={t}
                            t_name={'side_menu.item_names.logout'}
                            onClick={() => handleOnMenuItemClick({ logoutClick: true })}
                            icon={IconsType.logOut_piqk}
                            id={`menu_item_logout`}
                        />
                    </div>
                </div>

                <div
                    className={classNames(classes.shareIconsContainer, {
                        [classes.shareIconsContainer__openMenu]: sideMenuState,
                        [classes.shareIconsContainer__closedMenu]: !sideMenuState,
                    })}
                >
                    <Typography variant="tMdMedium">{t('side_menu.share')}</Typography>
                    <ShareButton shareButtons={shareButtons} key={'menu-share-links'} />
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SideMenu));
