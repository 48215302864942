import { ESystemTypes } from 'store/system/actionsTypes';
import { EPlatformTypes } from './actionsTypes';
import { PlatformReducerState } from './interfaces';

const initialState: PlatformReducerState = {
    platformIframeUrl: undefined,
    platformIframeUrlLoading: false,
};

const AccountsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EPlatformTypes.GET_IFRAME_URL: {
            return {
                ...state,
                platformIframeUrl: undefined,
                platformIframeUrlLoading: true,
            };
        }
        case EPlatformTypes.GET_IFRAME_URL_SUCCESS: {
            return {
                ...state,
                platformIframeUrl: payload,
                platformIframeUrlLoading: false,
            };
        }
        case EPlatformTypes.GET_IFRAME_URL_FAILED: {
            return {
                ...state,
                platformIframeUrl: undefined,
                platformIframeUrlLoading: false,
            };
        }
        case EPlatformTypes.RESET_STATE:
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                platformIframeUrl: undefined,
                platformIframeUrlLoading: false,
            };
        }
        default:
            return state;
    }
};

export default AccountsReducer;
