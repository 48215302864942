/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccountInfoParsed } from '../models';
// @ts-ignore
import { ContactUsRequest } from '../models';
// @ts-ignore
import { ContactUsResponse } from '../models';
// @ts-ignore
import { CreateClientZoneEventRequest } from '../models';
// @ts-ignore
import { GetAccountLoginDevicesResponse } from '../models';
// @ts-ignore
import { GetCZReferralCodeResponse } from '../models';
// @ts-ignore
import { GetClientZoneFeaturesResponse } from '../models';
// @ts-ignore
import { GetNotificationSettingsResponse } from '../models';
// @ts-ignore
import { GetUserCurrenciesResponse } from '../models';
// @ts-ignore
import { GetUserDataResponse } from '../models';
// @ts-ignore
import { RefreshUserRequest } from '../models';
// @ts-ignore
import { ResultInfo } from '../models';
// @ts-ignore
import { UpdateNotificationSettingsRequestDto } from '../models';
// @ts-ignore
import { UpdateNotificationSettingsResponse } from '../models';
// @ts-ignore
import { UpdateReverseSolicitationRequest } from '../models';
// @ts-ignore
import { UploadDocsRequest } from '../models';
// @ts-ignore
import { UploadDocsResponse } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {AccountInfoParsed} accountInfoParsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCompleteYourProfileDone: async (
            accountInfoParsed: AccountInfoParsed,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'accountInfoParsed' is not null or undefined
            assertParamExists('userControllerCompleteYourProfileDone', 'accountInfoParsed', accountInfoParsed);
            const localVarPath = `/api/user/completeYourProfileDone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                accountInfoParsed,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ContactUsRequest} contactUsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerContactUs: async (
            contactUsRequest: ContactUsRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'contactUsRequest' is not null or undefined
            assertParamExists('userControllerContactUs', 'contactUsRequest', contactUsRequest);
            const localVarPath = `/api/user/contactUs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                contactUsRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateClientZoneEventRequest} createClientZoneEventRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateClientZoneEvent: async (
            createClientZoneEventRequest: CreateClientZoneEventRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'createClientZoneEventRequest' is not null or undefined
            assertParamExists(
                'userControllerCreateClientZoneEvent',
                'createClientZoneEventRequest',
                createClientZoneEventRequest
            );
            const localVarPath = `/api/user/createClientZoneEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createClientZoneEventRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAccountLoginDevices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getAccountLoginDevices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetClientZoneFeatures: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getClientZoneFeatures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetNotificationSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getNotificationSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetReferralCode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getReferralCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [promo]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserCurrencies: async (promo?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/userCurrencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (promo !== undefined) {
                localVarQueryParameter['promo'] = promo;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/userData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RefreshUserRequest} refreshUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRefreshUser: async (
            refreshUserRequest: RefreshUserRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'refreshUserRequest' is not null or undefined
            assertParamExists('userControllerRefreshUser', 'refreshUserRequest', refreshUserRequest);
            const localVarPath = `/api/user/refreshUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                refreshUserRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AccountInfoParsed} accountInfoParsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateAccountInfo: async (
            accountInfoParsed: AccountInfoParsed,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'accountInfoParsed' is not null or undefined
            assertParamExists('userControllerUpdateAccountInfo', 'accountInfoParsed', accountInfoParsed);
            const localVarPath = `/api/user/updateAccountInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                accountInfoParsed,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateNotificationSettingsRequestDto} updateNotificationSettingsRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateNotificationSettings: async (
            updateNotificationSettingsRequestDto: UpdateNotificationSettingsRequestDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateNotificationSettingsRequestDto' is not null or undefined
            assertParamExists(
                'userControllerUpdateNotificationSettings',
                'updateNotificationSettingsRequestDto',
                updateNotificationSettingsRequestDto
            );
            const localVarPath = `/api/user/updateNotificationSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateNotificationSettingsRequestDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateReverseSolicitationRequest} updateReverseSolicitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateReverseSolicitation: async (
            updateReverseSolicitationRequest: UpdateReverseSolicitationRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'updateReverseSolicitationRequest' is not null or undefined
            assertParamExists(
                'userControllerUpdateReverseSolicitation',
                'updateReverseSolicitationRequest',
                updateReverseSolicitationRequest
            );
            const localVarPath = `/api/user/updateReverseSolicitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateReverseSolicitationRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UploadDocsRequest} uploadDocsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUploadDocs: async (
            uploadDocsRequest: UploadDocsRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'uploadDocsRequest' is not null or undefined
            assertParamExists('userControllerUploadDocs', 'uploadDocsRequest', uploadDocsRequest);
            const localVarPath = `/api/user/uploadDocs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                uploadDocsRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {AccountInfoParsed} accountInfoParsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCompleteYourProfileDone(
            accountInfoParsed: AccountInfoParsed,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoParsed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCompleteYourProfileDone(
                accountInfoParsed,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ContactUsRequest} contactUsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerContactUs(
            contactUsRequest: ContactUsRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactUsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerContactUs(
                contactUsRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {CreateClientZoneEventRequest} createClientZoneEventRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateClientZoneEvent(
            createClientZoneEventRequest: CreateClientZoneEventRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateClientZoneEvent(
                createClientZoneEventRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetAccountLoginDevices(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountLoginDevicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetAccountLoginDevices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetClientZoneFeatures(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientZoneFeaturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetClientZoneFeatures(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetNotificationSettings(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNotificationSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetNotificationSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetReferralCode(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCZReferralCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetReferralCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [promo]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetUserCurrencies(
            promo?: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserCurrenciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUserCurrencies(promo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetUserData(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUserData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {RefreshUserRequest} refreshUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerRefreshUser(
            refreshUserRequest: RefreshUserRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoParsed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerRefreshUser(
                refreshUserRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {AccountInfoParsed} accountInfoParsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateAccountInfo(
            accountInfoParsed: AccountInfoParsed,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoParsed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateAccountInfo(
                accountInfoParsed,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateNotificationSettingsRequestDto} updateNotificationSettingsRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateNotificationSettings(
            updateNotificationSettingsRequestDto: UpdateNotificationSettingsRequestDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateNotificationSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateNotificationSettings(
                updateNotificationSettingsRequestDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UpdateReverseSolicitationRequest} updateReverseSolicitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateReverseSolicitation(
            updateReverseSolicitationRequest: UpdateReverseSolicitationRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoParsed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateReverseSolicitation(
                updateReverseSolicitationRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UploadDocsRequest} uploadDocsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUploadDocs(
            uploadDocsRequest: UploadDocsRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadDocsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUploadDocs(
                uploadDocsRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration);
    return {
        /**
         *
         * @param {AccountInfoParsed} accountInfoParsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCompleteYourProfileDone(
            accountInfoParsed: AccountInfoParsed,
            options?: any
        ): AxiosPromise<AccountInfoParsed> {
            return localVarFp
                .userControllerCompleteYourProfileDone(accountInfoParsed, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ContactUsRequest} contactUsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerContactUs(contactUsRequest: ContactUsRequest, options?: any): AxiosPromise<ContactUsResponse> {
            return localVarFp
                .userControllerContactUs(contactUsRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateClientZoneEventRequest} createClientZoneEventRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateClientZoneEvent(
            createClientZoneEventRequest: CreateClientZoneEventRequest,
            options?: any
        ): AxiosPromise<ResultInfo> {
            return localVarFp
                .userControllerCreateClientZoneEvent(createClientZoneEventRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAccountLoginDevices(options?: any): AxiosPromise<GetAccountLoginDevicesResponse> {
            return localVarFp.userControllerGetAccountLoginDevices(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetClientZoneFeatures(options?: any): AxiosPromise<GetClientZoneFeaturesResponse> {
            return localVarFp.userControllerGetClientZoneFeatures(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetNotificationSettings(options?: any): AxiosPromise<GetNotificationSettingsResponse> {
            return localVarFp
                .userControllerGetNotificationSettings(options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetReferralCode(options?: any): AxiosPromise<GetCZReferralCodeResponse> {
            return localVarFp.userControllerGetReferralCode(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [promo]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserCurrencies(promo?: string, options?: any): AxiosPromise<GetUserCurrenciesResponse> {
            return localVarFp
                .userControllerGetUserCurrencies(promo, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserData(options?: any): AxiosPromise<GetUserDataResponse> {
            return localVarFp.userControllerGetUserData(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {RefreshUserRequest} refreshUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRefreshUser(
            refreshUserRequest: RefreshUserRequest,
            options?: any
        ): AxiosPromise<AccountInfoParsed> {
            return localVarFp
                .userControllerRefreshUser(refreshUserRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {AccountInfoParsed} accountInfoParsed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateAccountInfo(
            accountInfoParsed: AccountInfoParsed,
            options?: any
        ): AxiosPromise<AccountInfoParsed> {
            return localVarFp
                .userControllerUpdateAccountInfo(accountInfoParsed, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateNotificationSettingsRequestDto} updateNotificationSettingsRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateNotificationSettings(
            updateNotificationSettingsRequestDto: UpdateNotificationSettingsRequestDto,
            options?: any
        ): AxiosPromise<UpdateNotificationSettingsResponse> {
            return localVarFp
                .userControllerUpdateNotificationSettings(updateNotificationSettingsRequestDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateReverseSolicitationRequest} updateReverseSolicitationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateReverseSolicitation(
            updateReverseSolicitationRequest: UpdateReverseSolicitationRequest,
            options?: any
        ): AxiosPromise<AccountInfoParsed> {
            return localVarFp
                .userControllerUpdateReverseSolicitation(updateReverseSolicitationRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UploadDocsRequest} uploadDocsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUploadDocs(
            uploadDocsRequest: UploadDocsRequest,
            options?: any
        ): AxiosPromise<UploadDocsResponse> {
            return localVarFp
                .userControllerUploadDocs(uploadDocsRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     *
     * @param {AccountInfoParsed} accountInfoParsed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCompleteYourProfileDone(accountInfoParsed: AccountInfoParsed, options?: any) {
        return UserApiFp(this.configuration)
            .userControllerCompleteYourProfileDone(accountInfoParsed, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ContactUsRequest} contactUsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerContactUs(contactUsRequest: ContactUsRequest, options?: any) {
        return UserApiFp(this.configuration)
            .userControllerContactUs(contactUsRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {CreateClientZoneEventRequest} createClientZoneEventRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCreateClientZoneEvent(
        createClientZoneEventRequest: CreateClientZoneEventRequest,
        options?: any
    ) {
        return UserApiFp(this.configuration)
            .userControllerCreateClientZoneEvent(createClientZoneEventRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetAccountLoginDevices(options?: any) {
        return UserApiFp(this.configuration)
            .userControllerGetAccountLoginDevices(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetClientZoneFeatures(options?: any) {
        return UserApiFp(this.configuration)
            .userControllerGetClientZoneFeatures(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetNotificationSettings(options?: any) {
        return UserApiFp(this.configuration)
            .userControllerGetNotificationSettings(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetReferralCode(options?: any) {
        return UserApiFp(this.configuration)
            .userControllerGetReferralCode(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} [promo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetUserCurrencies(promo?: string, options?: any) {
        return UserApiFp(this.configuration)
            .userControllerGetUserCurrencies(promo, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetUserData(options?: any) {
        return UserApiFp(this.configuration)
            .userControllerGetUserData(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {RefreshUserRequest} refreshUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerRefreshUser(refreshUserRequest: RefreshUserRequest, options?: any) {
        return UserApiFp(this.configuration)
            .userControllerRefreshUser(refreshUserRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {AccountInfoParsed} accountInfoParsed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateAccountInfo(accountInfoParsed: AccountInfoParsed, options?: any) {
        return UserApiFp(this.configuration)
            .userControllerUpdateAccountInfo(accountInfoParsed, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateNotificationSettingsRequestDto} updateNotificationSettingsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateNotificationSettings(
        updateNotificationSettingsRequestDto: UpdateNotificationSettingsRequestDto,
        options?: any
    ) {
        return UserApiFp(this.configuration)
            .userControllerUpdateNotificationSettings(updateNotificationSettingsRequestDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UpdateReverseSolicitationRequest} updateReverseSolicitationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateReverseSolicitation(
        updateReverseSolicitationRequest: UpdateReverseSolicitationRequest,
        options?: any
    ) {
        return UserApiFp(this.configuration)
            .userControllerUpdateReverseSolicitation(updateReverseSolicitationRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {UploadDocsRequest} uploadDocsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUploadDocs(uploadDocsRequest: UploadDocsRequest, options?: any) {
        return UserApiFp(this.configuration)
            .userControllerUploadDocs(uploadDocsRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
