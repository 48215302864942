import { ESystemTypes } from 'store/system/actionsTypes';
import { EAnalyticsTypes } from './actionsTypes';
import { AnalyticsReducerState } from './interfaces';

const initialState: AnalyticsReducerState = {
    pages: {},
    webTVLinks: [],
    webTVLoading: false,
    tradingSuggesionsLoading: false,
    tradingSuggesions: [],
};

const AccountsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EAnalyticsTypes.GET_IFRAME_URL: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [payload]: {
                        loading: true,
                    },
                },
            };
        }
        case EAnalyticsTypes.GET_IFRAME_URL_SUCCESS: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [payload.pageId]: {
                        loading: false,
                        url: payload.iframeUrl,
                    },
                },
            };
        }
        case EAnalyticsTypes.GET_IFRAME_URL_FAILED: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [payload]: {
                        loading: false,
                    },
                },
            };
        }
        case EAnalyticsTypes.GET_WEB_TV_LINKS: {
            return {
                ...state,
                webTVLoading: true,
            };
        }
        case EAnalyticsTypes.GET_WEB_TV_LINKS_SUCCESS: {
            return {
                ...state,
                webTVLoading: false,
                webTVLinks: payload,
            };
        }
        case EAnalyticsTypes.GET_WEB_TV_LINKS_FAILED: {
            return {
                ...state,
                webTVLoading: false,
            };
        }
        case EAnalyticsTypes.GET_TRADING_SUGGESTIONS: {
            return {
                ...state,
                tradingSuggesionsLoading: true,
            };
        }
        case EAnalyticsTypes.GET_TRADING_SUGGESTIONS_SUCCESS: {
            return {
                ...state,
                tradingSuggesionsLoading: false,
                tradingSuggesions: payload,
            };
        }
        case EAnalyticsTypes.GET_TRADING_SUGGESTIONS_FAILED: {
            return {
                ...state,
                tradingSuggesionsLoading: false,
            };
        }
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                pages: {},
            };
        }
        default:
            return state;
    }
};

export default AccountsReducer;
