/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateSubscriptionResponse } from '../models';
// @ts-ignore
import { DeleteNotificationsResponse } from '../models';
// @ts-ignore
import { GetNotificaionResponse } from '../models';
// @ts-ignore
import { GetNotificaionsResponse } from '../models';
// @ts-ignore
import { GetNotificationsDto } from '../models';
// @ts-ignore
import { MarkNotificationsResponse } from '../models';
// @ts-ignore
import { MarkPushNotificationsRequest } from '../models';
// @ts-ignore
import { PushSubscriptionSchema } from '../models';
/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {PushSubscriptionSchema} pushSubscriptionSchema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerCreateSusbscription: async (
            pushSubscriptionSchema: PushSubscriptionSchema,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'pushSubscriptionSchema' is not null or undefined
            assertParamExists(
                'notificationsApiControllerCreateSusbscription',
                'pushSubscriptionSchema',
                pushSubscriptionSchema
            );
            const localVarPath = `/api/notifications/createSusbscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                pushSubscriptionSchema,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerDeleteNotifications: async (
            requestBody: Array<string>,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('notificationsApiControllerDeleteNotifications', 'requestBody', requestBody);
            const localVarPath = `/api/notifications/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerGetNotification: async (
            notificationId: string,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('notificationsApiControllerGetNotification', 'notificationId', notificationId);
            const localVarPath = `/api/notifications/{notificationId}`.replace(
                `{${'notificationId'}}`,
                encodeURIComponent(String(notificationId))
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetNotificationsDto} getNotificationsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerGetNotifications: async (
            getNotificationsDto: GetNotificationsDto,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getNotificationsDto' is not null or undefined
            assertParamExists('notificationsApiControllerGetNotifications', 'getNotificationsDto', getNotificationsDto);
            const localVarPath = `/api/notifications/getNotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getNotificationsDto,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerMarkNotifications: async (
            requestBody: Array<string>,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('notificationsApiControllerMarkNotifications', 'requestBody', requestBody);
            const localVarPath = `/api/notifications/mark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MarkPushNotificationsRequest} markPushNotificationsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerMarkPushNotifications: async (
            markPushNotificationsRequest: MarkPushNotificationsRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'markPushNotificationsRequest' is not null or undefined
            assertParamExists(
                'notificationsApiControllerMarkPushNotifications',
                'markPushNotificationsRequest',
                markPushNotificationsRequest
            );
            const localVarPath = `/api/notifications/markPush`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                markPushNotificationsRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {PushSubscriptionSchema} pushSubscriptionSchema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsApiControllerCreateSusbscription(
            pushSubscriptionSchema: PushSubscriptionSchema,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSubscriptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsApiControllerCreateSusbscription(
                pushSubscriptionSchema,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsApiControllerDeleteNotifications(
            requestBody: Array<string>,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsApiControllerDeleteNotifications(
                requestBody,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsApiControllerGetNotification(
            notificationId: string,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNotificaionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsApiControllerGetNotification(
                notificationId,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetNotificationsDto} getNotificationsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsApiControllerGetNotifications(
            getNotificationsDto: GetNotificationsDto,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNotificaionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsApiControllerGetNotifications(
                getNotificationsDto,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsApiControllerMarkNotifications(
            requestBody: Array<string>,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsApiControllerMarkNotifications(
                requestBody,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {MarkPushNotificationsRequest} markPushNotificationsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationsApiControllerMarkPushNotifications(
            markPushNotificationsRequest: MarkPushNotificationsRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationsApiControllerMarkPushNotifications(
                markPushNotificationsRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = NotificationsApiFp(configuration);
    return {
        /**
         *
         * @param {PushSubscriptionSchema} pushSubscriptionSchema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerCreateSusbscription(
            pushSubscriptionSchema: PushSubscriptionSchema,
            options?: any
        ): AxiosPromise<CreateSubscriptionResponse> {
            return localVarFp
                .notificationsApiControllerCreateSusbscription(pushSubscriptionSchema, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerDeleteNotifications(
            requestBody: Array<string>,
            options?: any
        ): AxiosPromise<DeleteNotificationsResponse> {
            return localVarFp
                .notificationsApiControllerDeleteNotifications(requestBody, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerGetNotification(
            notificationId: string,
            options?: any
        ): AxiosPromise<GetNotificaionResponse> {
            return localVarFp
                .notificationsApiControllerGetNotification(notificationId, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetNotificationsDto} getNotificationsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerGetNotifications(
            getNotificationsDto: GetNotificationsDto,
            options?: any
        ): AxiosPromise<GetNotificaionsResponse> {
            return localVarFp
                .notificationsApiControllerGetNotifications(getNotificationsDto, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerMarkNotifications(
            requestBody: Array<string>,
            options?: any
        ): AxiosPromise<MarkNotificationsResponse> {
            return localVarFp
                .notificationsApiControllerMarkNotifications(requestBody, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MarkPushNotificationsRequest} markPushNotificationsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsApiControllerMarkPushNotifications(
            markPushNotificationsRequest: MarkPushNotificationsRequest,
            options?: any
        ): AxiosPromise<void> {
            return localVarFp
                .notificationsApiControllerMarkPushNotifications(markPushNotificationsRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     *
     * @param {PushSubscriptionSchema} pushSubscriptionSchema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsApiControllerCreateSusbscription(
        pushSubscriptionSchema: PushSubscriptionSchema,
        options?: any
    ) {
        return NotificationsApiFp(this.configuration)
            .notificationsApiControllerCreateSusbscription(pushSubscriptionSchema, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsApiControllerDeleteNotifications(requestBody: Array<string>, options?: any) {
        return NotificationsApiFp(this.configuration)
            .notificationsApiControllerDeleteNotifications(requestBody, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} notificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsApiControllerGetNotification(notificationId: string, options?: any) {
        return NotificationsApiFp(this.configuration)
            .notificationsApiControllerGetNotification(notificationId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetNotificationsDto} getNotificationsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsApiControllerGetNotifications(getNotificationsDto: GetNotificationsDto, options?: any) {
        return NotificationsApiFp(this.configuration)
            .notificationsApiControllerGetNotifications(getNotificationsDto, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsApiControllerMarkNotifications(requestBody: Array<string>, options?: any) {
        return NotificationsApiFp(this.configuration)
            .notificationsApiControllerMarkNotifications(requestBody, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MarkPushNotificationsRequest} markPushNotificationsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsApiControllerMarkPushNotifications(
        markPushNotificationsRequest: MarkPushNotificationsRequest,
        options?: any
    ) {
        return NotificationsApiFp(this.configuration)
            .notificationsApiControllerMarkPushNotifications(markPushNotificationsRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
