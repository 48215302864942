import { makeStyles } from '@material-ui/core';
import { customGap } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    wrapper: {
        ...customGap(theme.spacing(4), 'vertical'),
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    seperator: {
        height: '0.1rem',
        width: '100%',
        backgroundColor: theme.palette.action.disabledBackground,
    },
    contentWrapper: {
        flexGrow: 1,
        overflow: 'auto',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: `0 ${theme.spacing(3)}rem`,
    },
    seeAllWrapper: {
        textAlign: 'center',
    },
    overlay: {
        height: '100vh',
        width: '100vw',
        zIndex: theme.zIndex.drawer,
        overflow: 'hidden',
        background: 'rgba(0, 0, 0, 0.4)',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    messageText: {
        padding: `0 ${theme.spacing(3)}rem`,
    },
}));
