import { makeStyles } from '@material-ui/core';
import { newShadows } from 'muiTheme/config/palette';

export default makeStyles((theme) => ({
    btn_wrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '32px',
    },
    propTradeNowBtn: {
        padding: '10px 16px',
        boxShadow: newShadows.xs,
        borderRadius: '8px',
        border: `1px solid ${theme.palette.blueVariants[600]}`,
        height: '44px',
        gap: '8px',
        width: 'max-content',
        '&>.MuiButton-label': {
            gap: '8px',
            lineHeight: 2.4,
        },
    },
    trade_now: {
        color: theme.palette.blueVariants[600],
    },
    line_chart_icon: {
        color: theme.palette.blueVariants[600],
    },
}));
