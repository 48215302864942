import { ESystemTypes } from 'store/system/actionsTypes';
import { EAccountsTypes } from './actionsTypes';
import { AccountsReducerState } from './interfaces';

const initialState: AccountsReducerState = {
    getAccountsLoader: false,
    accountsData: {},

    getMonetaryTransactionsLoader: false,
    monetaryTransactionsHistory: [],

    changePasswordLoading: false,
    changePasswordLastStatus: undefined,

    tpHistoryLoader: false,
    tpHistory: {},

    updateEmailNotificationsSettingsStatus: undefined,
    updateEmailNotificationsSettingsLoading: false,
};

const AccountsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case EAccountsTypes.GET_ACCOUNTS_DATA: {
            return {
                ...state,
                getAccountsLoader: true,
            };
        }
        case EAccountsTypes.GET_ACCOUNTS_DATA_SUCCESS: {
            return {
                ...state,
                getAccountsLoader: false,
                accountsData: {
                    ...state.accountsData,
                    ...payload,
                },
            };
        }
        case EAccountsTypes.GET_ACCOUNTS_DATA_FAILED: {
            return {
                ...state,
                getAccountsLoader: false,
            };
        }
        case EAccountsTypes.GET_ACCOUNTS_DATA_WITH_NO_LOADER: {
            return {
                ...state,
            };
        }
        case EAccountsTypes.GET_ACCOUNTS_DATA_SUCCESS_WITH_NO_LOADER: {
            return {
                ...state,
                accountsData: {
                    ...state.accountsData,
                    ...payload,
                },
            };
        }
        case EAccountsTypes.GET_ACCOUNTS_DATA_FAILED_WITH_NO_LOADER: {
            return {
                ...state,
            };
        }
        case EAccountsTypes.GET_TP_TRADING_HISTORY: {
            return {
                ...state,
                tpHistoryLoader: true,
            };
        }
        case EAccountsTypes.GET_TP_TRADING_HISTORY_SUCCESS: {
            return {
                ...state,
                tpHistoryLoader: false,
                tpHistory: {
                    ...state.tpHistory,
                    ...payload,
                },
            };
        }
        case EAccountsTypes.GET_TP_TRADING_HISTORY_FAILED: {
            return {
                ...state,
                tpHistoryLoader: false,
            };
        }
        case EAccountsTypes.GET_MONETARY_TRANSACTIONS_DATA: {
            return {
                ...state,
                getMonetaryTransactionsLoader: true,
            };
        }
        case EAccountsTypes.GET_MONETARY_TRANSACTIONS_DATA_SUCCESS: {
            return {
                ...state,
                getMonetaryTransactionsLoader: false,
                monetaryTransactionsHistory: payload,
            };
        }
        case EAccountsTypes.GET_MONETARY_TRANSACTIONS_DATA_FAILED: {
            return {
                ...state,
                getMonetaryTransactionsLoader: false,
            };
        }
        case EAccountsTypes.CHANGE_TP_PASSWORD_START: {
            return {
                ...state,
                changePasswordLoading: true,
                changePasswordLastStatus: undefined,
            };
        }
        case EAccountsTypes.CHANGE_TP_PASSWORD_SUCCESS: {
            return {
                ...state,
                changePasswordLoading: false,
                changePasswordLastStatus: 'success',
            };
        }
        case EAccountsTypes.CHANGE_TP_PASSWORD_FAILED: {
            return {
                ...state,
                changePasswordLoading: false,
                changePasswordLastStatus: 'failed',
            };
        }
        case EAccountsTypes.RESET_CHANGE_TP_PASSWORD_STATUS: {
            return {
                ...state,
                changePasswordLastStatus: undefined,
            };
        }
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                accountsData: {},
                getAccountsLoader: false,
                changePasswordLoading: false,
                changePasswordLastStatus: undefined,
            };
        }
        case EAccountsTypes.GET_EMAIL_NOTIFICATIONS_SETTINGS_DATA_SUCCESS: {
            return {
                ...state,
                notificationsSettings: payload,
            };
        }
        case EAccountsTypes.UPDATE_EMAIL_NOTIFICATIONS_SETTINGS_START: {
            return {
                ...state,
                updateEmailNotificationsSettingsLoading: true,
            };
        }
        case EAccountsTypes.UPDATE_EMAIL_NOTIFICATIONS_SETTINGS_SUCCESS: {
            return {
                ...state,
                notificationsSettings: payload,
                updateEmailNotificationsSettingsLoading: false,
            };
        }
        default:
            return state;
    }
};

export default AccountsReducer;
