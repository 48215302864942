export enum EPaymentTypes {
    SET_DEPOSIT_TYPE = '[payment] set deposit type',
    SET_DEPOSIT_AMOUNT = '[payment] set deposit amount',
    GET_DEPOSIT_IFRAME = '[payment] get deposit iframe',
    GET_DEPOSIT_IFRAME_SUCCESS = '[payment] get deposit iframe success',
    GET_DEPOSIT_IFRAME_FAILED = '[payment] get deposit iframe failed',

    DEPOSIT_DONE = '[payment] deposit done',
    RESET_DEPOSIT_RESULT = '[payment] reset deposit result',

    WITHDRAW_START = '[payment] withdraw start',
    WITHDRAW_SUCCESS = '[payment] withdraw on success',
    WITHDRAW_FAILED = '[payment] withdraw on failed',
    RESET_WITHDRAW_RESULT = '[payment] reset withdraw result',

    RESET_STATE = '[payment] reset state',

    WITHDRAW_CANCEL_START = '[payment] cancel withdraw start',
    WITHDRAW_CANCEL_SUCCESS = '[payment] cancel withdraw success',
    WITHDRAW_CANCEL_FAILED = '[payment] cancel withdraw failed',

    GET_CRYPTO_WALLETS_START = '[payment] get crypto wallets start',
    GET_CRYPTO_WALLETS_SUCCESS = '[payment] get crypto wallets success',
    GET_CRYPTO_WALLETS_FAILED = '[payment] get crypto wallets failed',

    GET_PAYMENT_TYPES_START = '[payment] get payment types start',
    GET_PAYMENT_TYPES_SUCCESS = '[payment] get payment types success',
    GET_PAYMENT_TYPES_FAILED = '[payment] get payment types failed',

    TRANSFER_BETWEEN_ACCOUNTS_START = '[payment] transfer between accounts start',
    TRANSFER_BETWEEN_ACCOUNTS_SUCCESS = '[payment] transfer between accounts success',
    TRANSFER_BETWEEN_ACCOUNTS_FAILED = '[payment] transfer between accounts failed',
    TRANSFER_BETWEEN_ACCOUNTS_RESET = '[payment] transfer between accounts reset',

    GET_REFERENCE_NUMBER_START = '[payment] get reference number start',
    GET_REFERENCE_NUMBER_SUCCESS = '[payment] get reference number success',
    GET_REFERENCE_NUMBER_FAILED = '[payment] get reference number failed',

    CHALLENGE_UPDATED = '[payment] challenge updated',
    RESET_CHALLENGE_UPDATED = '[payment] reset challenge updated',
    RESET_DEPOSIT_IFRAME_ERROR = '[payment] reset deposit iframe errors',

    CREATE_PENDING_PAYMENT_START = '[payment] create pending payment start',
    CREATE_PENDING_PAYMENT_SUCCESS = '[payment] create pending payment success',
    CREATE_PENDING_PAYMENT_FAILED = '[payment] create pending payment failed',

    CREATE_FREE_TRIAL_PLAN_START = '[payment] create free trial plan start',
    CREATE_FREE_TRIAL_PLAN_SUCCESS = '[payment] create free trial plan success',
    CREATE_FREE_TRIAL_PLAN_FAILED = '[payment] create free trial plan failed',

    //CreateFullDiscountPlan
    CREATE_FULL_DISCOUNT_PLAN_START = '[payment] create full discount plan start',
    CREATE_FULL_DISCOUNT_PLAN_SUCCESS = '[payment] create full discount plan success',
    CREATE_FULL_DISCOUNT_PLAN_FAILED = '[payment] create full discount plan failed',
}
