import { createStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default createStyles({
    root: {
        borderRadius: '0.8rem',
        zIndex: 1,
    },
    input: {
        padding: '1.25rem 1.2rem',
    },
    notchedOutline: {
        borderWidth: '0.1rem !important',
    },
    adornedEnd: {
        paddingInlineEnd: '1.2rem',
    },
    adornedStart: {
        paddingInlineStart: '1.2rem',
    },
    inputAdornedStart: {
        paddingInlineStart: '1.2rem',
        ...isRtl({
            paddingLeft: '1.2rem',
        }),
    },
    inputAdornedEnd: {
        paddingInlineEnd: '1.2rem',
        ...isRtl({
            paddingRight: '1.2rem',
        }),
    },
});
