import { prop__changeSelectedTpChallenge } from 'features/prop/store/my-challenges/actions';
import { prop_myChallenges_getSelectedChallenge } from 'features/prop/store/my-challenges/selectors';
import { PropChallenge } from 'services/generatedClientFromSwagger';

export interface WtTradeBtnProps {
    selectedChallenge: PropChallenge;
    setSelectedChallenge: (tpName: string) => void;
}

export const mapStateToProps = (state) => ({
    selectedChallenge: prop_myChallenges_getSelectedChallenge(state),
});

export const mapDispatchToProps = {
    setSelectedChallenge: prop__changeSelectedTpChallenge,
};
