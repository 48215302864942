import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    redText: {
        color: theme.palette.error.main,
    },
    primaryText: {
        color: theme.palette.primary.main,
    },
    secondaryText: {
        color: theme.palette.secondary.main,
    },
    underLine: {
        textDecoration: 'underline',
    },
    innerLine: {
        textDecoration: 'line-through',
    },
    link: {
        '&:link,&:visited': {
            color: theme.palette.primary.main,
        },
    },
    seperator: {
        width: '100%',
        height: '0.1rem',
        background: theme.palette.action.disabledBackground,
    },
}));
