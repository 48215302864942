export enum EAnalyticsTypes {
    GET_IFRAME_URL = '[analytics] get platform iframe',
    GET_IFRAME_URL_SUCCESS = '[analytics] get platform iframe success',
    GET_IFRAME_URL_FAILED = '[analytics] get platform iframe failed',

    GET_WEB_TV_LINKS = '[analytics] get webtv links',
    GET_WEB_TV_LINKS_SUCCESS = '[analytics] get webtv links success',
    GET_WEB_TV_LINKS_FAILED = '[analytics] get webtv links failed',

    GET_TRADING_SUGGESTIONS = '[analytics] get trading suggestions',
    GET_TRADING_SUGGESTIONS_SUCCESS = '[analytics] get trading suggestions success',
    GET_TRADING_SUGGESTIONS_FAILED = '[analytics] get trading suggestions failed',
}
