import { makeStyles } from '@material-ui/core';
import { customGap, isTablet } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        ...customGap(theme.spacing(5), 'vertical'),
    },
    btnsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        ...isTablet({
            flexDirection: 'row',
        }),
        '& > button': {
            border: `1px solid ${theme.palette.default.dark}`,
            color: theme.palette.default.contrastText,
        },
    },
    closeBtn: {
        marginTop: '1.2rem',
        marginBottom: '-0.8rem',
        display: 'flex',
        justifyContent: 'flex-end',
        ...isTablet({
            marginTop: 'unset',
            display: 'block',
        }),
    },
}));
