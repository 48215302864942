import {
    ClosedPositionInfo,
    GetNotificationSettingsResponse,
    MonetaryTransaction,
    TradingPlatformAccountBalance,
} from 'services/generatedClientFromSwagger/models';

export interface AccountsReducerState {
    getAccountsLoader: boolean;
    accountsData: {
        [accountName: string]: TradingPlatformAccountBalance;
    };
    getMonetaryTransactionsLoader: boolean;
    monetaryTransactionsHistory: Array<MonetaryTransaction>;

    changePasswordLoading: boolean;
    changePasswordLastStatus?: 'success' | 'failed';

    tpHistoryLoader: boolean;
    tpHistory: {
        [tpId: string]: Array<ClosedPositionInfo>;
    };

    updateEmailNotificationsSettingsLoading: boolean;
    updateEmailNotificationsSettingsStatus?: 'success' | 'failed';

    notificationsSettings?: GetNotificationSettingsResponse;
}

export enum InternalTransactionStatusEnum {
    Pending = 100000000,
    Cancelled = 100000001,
    Rejected = 100000002,
    Approved = 100000003,
    WithdrawalFee = 100000004,
}
