import { makeStyles } from '@material-ui/core';
import { isRtl } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    backBtn: {
        ...isRtl({
            transform: 'rotate(180deg)',
        }),
    },
    menuBtn: {
        color: theme.palette.primary.main,
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        cursor: 'pointer',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.blueVariants[50],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border:
            theme.palette.type === 'dark'
                ? `1.33333px solid ${theme.palette.grayVariants[300]}`
                : `1.33333px solid ${theme.palette.background.default}`,
        boxShadow:
            '0px 1.778px 3.556px -0.889px rgba(16, 24, 40, 0.10), 0px 0.889px 1.778px -0.889px rgba(16, 24, 40, 0.06)',
    },
}));
