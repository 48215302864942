import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { ButtonRadioGroupProps } from './button-radio-group.scheme';
import useStyles from './button-radio-group.styles';
import classNames from 'classnames';

const ButtonRadioGroup: React.FunctionComponent<ButtonRadioGroupProps> = (props) => {
    const {
        multiple,
        defaultValue,
        options,
        label,
        helperText,
        error,
        disabled,
        id,
        labelColor,
        labelVariant = 'body1',
        inputRef,
        onChange,
    } = props;
    const classes = useStyles({ error: false, withLabel: true });
    const [selected, setSelected] = React.useState<string | number | Array<string> | Array<number>>(() => {
        if (defaultValue) return defaultValue;
        return multiple ? [] : '';
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        if (Array.isArray(selected)) {
            if (!checked) {
                //eslint-disable-next-line
                const newValues: any = [...selected].filter((item) => item != value);
                onChange && onChange(newValues);
                return setSelected(newValues);
            }
            const newValues: any = [...selected, value];
            onChange && onChange(newValues);
            return setSelected(newValues);
        }
        onChange && onChange(value);
        return setSelected(value);
    };

    const checkIfIsSelected = (val) => {
        if (Array.isArray(selected)) {
            //eslint-disable-next-line
            return selected.some((v) => v == val);
        }
        //eslint-disable-next-line
        return selected == val;
    };

    return (
        <FormControl id={id} name={id} error={error} component="fieldset">
            {label && (
                <Typography variant={labelVariant} color={error ? 'error' : labelColor}>
                    {label}
                </Typography>
            )}
            <RadioGroup classes={{ root: classes.radioGroupRoot }} aria-label="gender" name="gender1" value={selected}>
                {options.map((option, index) => (
                    <FormControlLabel
                        disabled={disabled || option.disabled || (option.disableIf && option.disableIf(selected))}
                        key={`radioOption-${index}`}
                        value={option.value}
                        control={
                            <Checkbox
                                inputRef={inputRef}
                                checked={checkIfIsSelected(option.value)}
                                onChange={handleChange}
                                color="primary"
                                classes={{ root: classes.checkboxRoot }}
                            />
                        }
                        classes={{
                            root: classNames({
                                [classes.formControlLabelRoot]: true,
                                [classes.formControlLabelRoot__active]: checkIfIsSelected(option.value),
                            }),
                            label: classNames({
                                [classes.formControlRoot]: true,
                                [classes.formControlRoot__active]: checkIfIsSelected(option.value),
                            }),
                        }}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
            {helperText && (
                <FormHelperText classes={{ error: classes.formHelperTextError }}>{helperText}</FormHelperText>
            )}
            <TextField
                style={{ display: 'none' }}
                type={multiple ? 'radio' : 'text'}
                inputRef={inputRef}
                value={selected}
            />
        </FormControl>
    );
};

export default ButtonRadioGroup;
