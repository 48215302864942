import { IconsType } from 'components/Icon';
import config, { FeatureFlag } from 'config/common';
import { KycButtonProps } from 'features/kyc/store/interface';
import { kyc_status } from 'features/kyc/store/selector';
import { uploadD_status } from 'features/upload-documents/store/selector';
import { UploadDButtonProps } from 'features/upload-documents/store/interface';
import { sumsub_status } from 'features/sumsub/store/selector';
import { Pages, SideMenuList, ThemeOptions, WithdrawalTypes } from 'interfaces/main';
import { auth_logout } from 'store/auth/actions';
import { system_toggleEducationModal, system_toggleSideMenu, system_toggleTheme } from 'store/system/actions';
import { system_side_menu_state, system_theme } from 'store/system/selectors';
import { userFlowGuard, user_tcPermission, user_tradingAnalysisActive, user_userInfo } from 'store/user/selectors';
import { AccountInfoParsed, PropChallenge } from 'services/generatedClientFromSwagger';
import {
    prop_myChallenges_getAllChallenges,
    prop_myChallenges_getFilteredChallenges,
    prop_myChallenges_getNotStartedFundedChallenges,
} from 'features/prop/store/my-challenges/selectors';

export interface SideMenuProps {
    sideMenuState: boolean;
    theme: ThemeOptions;
    kyc_menuBannerProps?: KycButtonProps;
    uploadD_menuBannerProps?: UploadDButtonProps;
    user_tcPermission: boolean;
    userInfo: AccountInfoParsed;
    user_tradingAnalysisActive: boolean;
    toggleTheme: () => void;
    logOut: () => void;
    system_toggleSideMenu: () => void;
    system_toggleEducationModal: () => void;
    userFlowGuard: { possibleLinks: Array<string>; link: string; buttonType: string; icon: IconsType };
    challenges: Array<PropChallenge>;
    filteredChallenges: Array<PropChallenge>;
    notStartedFundedChallenges?: Array<PropChallenge>;
}

export const mapStateToProps = (state) => ({
    sideMenuState: system_side_menu_state(state),
    theme: system_theme(state),
    kyc_menuBannerProps: kyc_status(state),
    sumsub_menuBannerProps: sumsub_status(state),
    uploadD_menuBannerProps: uploadD_status(state),
    user_tcPermission: user_tcPermission(state),
    userInfo: user_userInfo(state),
    user_tradingAnalysisActive: user_tradingAnalysisActive(state),
    userFlowGuard: userFlowGuard(state),
    challenges: prop_myChallenges_getAllChallenges(state),
    filteredChallenges: prop_myChallenges_getFilteredChallenges(state),
    notStartedFundedChallenges: prop_myChallenges_getNotStartedFundedChallenges(state),
});

export const mapDispatchToProps = {
    logOut: auth_logout,
    system_toggleSideMenu,
    toggleTheme: system_toggleTheme,
    system_toggleEducationModal,
};

export const menuLinks: SideMenuList = [
    {
        t_name: 'side_menu.item_names.home',
        icon: IconsType.home_empty_piqk,
        link: `/${Pages.PLATFORMS}/home`,
        hideLink: config.featuresFlags[FeatureFlag.PROP],
    },
    {
        t_name: 'side_menu.item_names.dashboard',
        icon: IconsType.pieChart,
        link: `/${Pages.PLATFORMS}/plan-statistics`,
    },
    {
        t_name: 'side_menu.item_names.prop',
        icon: IconsType.new_trophy_prop,
        collapsible: true,
        link: `/${Pages.PROP}`,
        possibleLinks: [
            `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`,
            `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}/${Pages.PROP__NEW_CHALLENGES__DEPOSIT}`,
            `/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`,
            `/${Pages.PROP}/${Pages.PROP__MY_PURCHASES}`,
        ],
        subMenuLinks: [
            {
                icon: IconsType.annotationQuestion,
                t_name: 'side_menu.item_names.prop__new_challenge',
                link: `/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}`,
            },
            {
                icon: IconsType.client_support,
                t_name: 'side_menu.item_names.prop__my_challenges',
                link: `/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`,
                hasFilteredChallenges: true,
            },
            {
                icon: IconsType.shopping_bag,
                t_name: 'side_menu.item_names.prop__my_purchases',
                link: `/${Pages.PROP}/${Pages.PROP__MY_PURCHASES}`,
                hasChallenges: true,
            },
            {
                icon: IconsType.currencyDollarCircle,
                t_name: 'side_menu.item_names.prop__payout',
                link: `/${Pages.PROP}/${Pages.PROP__PAYOUT}`,
            },
        ],
        hideLink: !config.featuresFlags[FeatureFlag.PROP],
    },
    {
        t_name: 'side_menu.item_names.trading',
        icon: IconsType.tradingProp,
        link: `/${Pages.PLATFORMS}/home`,
        hideLink: !config.featuresFlags[FeatureFlag.PROP],
        hasChallengeStarted: true,
    },
    {
        t_name: 'side_menu.item_names.funds',
        icon: IconsType.funds_empty_piqk,
        link: `/${Pages.PLATFORMS}/funds`,
        hideLink: config.featuresFlags[FeatureFlag.PROP],
    },

    {
        t_name: 'side_menu.item_names.deposit',
        icon: IconsType.my_wallet,
        link: `/${Pages.MY_WALLET}/${Pages.MY_WALLET__DEPOSIT}`,
        possibleLinks: [`/${Pages.MY_WALLET}/${Pages.MY_WALLET__PRE_CONDITION}`, `/${Pages.REVERSE_SOLICITATION}`],
        hideLink: config.featuresFlags[FeatureFlag.PROP],
    },
    {
        t_name: 'side_menu.item_names.withdraw',
        icon: IconsType.withdraw,
        link:
            config.withdrawalTypes.includes(WithdrawalTypes.Outsource) && config.withdrawalTypes.length === 1
                ? config.outsourceWithdrawal
                : `/${Pages.MY_WALLET}/${Pages.MY_WALLET__WITHDRAW}`,
        outsideLink: config.withdrawalTypes.includes(WithdrawalTypes.Outsource) && config.withdrawalTypes.length === 1,
        hideLink: config.featuresFlags[FeatureFlag.PROP],
    },
    {
        t_name: 'side_menu.item_names.refer_earn',
        icon: IconsType.user_right,
        link: `/${Pages.REFER_EARN}`,
        hideLink: !Boolean(config.featuresFlags?.referAndEarn),
    },
    {
        t_name: 'side_menu.item_names.trading_analysis',
        icon: IconsType.trading_analysis,
        link: `/${Pages.TRADING_ANALYSIS}`,
        tradingAnalysisLogic: true,
    },
    {
        t_name: 'side_menu.item_names.help_center',
        icon: IconsType.help_center_piqk,
        collapsible: true,
        link: `/${Pages.CLIENT_SUPPORT}`,

        subMenuLinks: [
            {
                icon: IconsType.support_piqk,
                t_name: 'side_menu.item_names.support',
                link: `/${Pages.CLIENT_SUPPORT}`,
            },
        ],
    },
    {
        t_name: 'side_menu.item_names.account_settings',
        icon: IconsType.account_settings,
        link: `/${Pages.ACCOUNT_SETTINGS}`,
    },
    {
        t_name: 'side_menu.item_names.regulation',
        icon: IconsType.regulation,
        link: config.links.legal_information,
        outsideLink: true,
    },
];

export const menuLinksApp: SideMenuList = [
    {
        t_name: 'side_menu.item_names.download_iphone',
        icon: IconsType.apple_app_piqk,
        link: config.downloadLinks.ios,
        outsideLink: true,
        appCode: config.downloadLinks.iosAppCode,
        appCodeText: 'links.platform.subApple',
    },
    {
        t_name: 'side_menu.item_names.download_android',
        icon: IconsType.android_app_piqk,
        link: config.downloadLinks.android,
        outsideLink: true,
        appCode: config.downloadLinks.androidAppCode,
        appCodeText: 'links.platform.subAndroid',
    },
    {
        t_name: 'side_menu.item_names.download_sirix',
        icon: IconsType.station_app_piqk,
        link: config.downloadLinks.sirix,
        outsideLink: true,
    },
];

export const shareButtons = [
    {
        name: 'twitter',
        icon: 'twitter_share_piqk',
        link: `https://twitter.com/intent/tweet?text=${encodeURIComponent(config.api.URL)}`,
    },
    {
        name: 'linkDin',
        icon: 'linkDin_share_piqk',
        link: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(config.api.URL)}`,
    },
    {
        name: 'faceBook',
        icon: 'faceBook_share_piqk',
        link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(config.api.URL)}`,
    },
];
//
