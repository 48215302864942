import { createSelector } from 'reselect';
import { RootState } from '../index';

const analyticsReducerSelector = (state: RootState) => state.analytics;

export const analytics_pageIdIsLoading = (state, siteId: string) =>
    createSelector(analyticsReducerSelector, (analytics) => analytics.pages[siteId]?.loading)(state);

export const analytics_pageIdIframeUrl = (state, siteId: string) =>
    createSelector(analyticsReducerSelector, (analytics) => analytics.pages[siteId]?.url)(state);

export const analytics_webTVLinks = createSelector(analyticsReducerSelector, (analytics) => analytics.webTVLinks);

export const analytics_webTVLoading = createSelector(analyticsReducerSelector, (analytics) => analytics.webTVLoading);

export const analytics_tradingSuggetions = createSelector(
    analyticsReducerSelector,
    (analytics) => analytics.tradingSuggesions
);

export const analytics_tradingSuggetionsLoading = createSelector(
    analyticsReducerSelector,
    (analytics) => analytics.tradingSuggesionsLoading
);
