/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GenerateTradingAnalyticsIframeRequest } from '../models';
// @ts-ignore
import { GenerateTradingAnalyticsIframeResponse } from '../models';
// @ts-ignore
import { GetT4PSignalsRequest } from '../models';
// @ts-ignore
import { GetT4PSignalsResponse } from '../models';
// @ts-ignore
import { GetWebTVLinksResponse } from '../models';
/**
 * TradingAnalyticsApi - axios parameter creator
 * @export
 */
export const TradingAnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {GenerateTradingAnalyticsIframeRequest} generateTradingAnalyticsIframeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingAnalysisControllerGenerateWebTraderIframe: async (
            generateTradingAnalyticsIframeRequest: GenerateTradingAnalyticsIframeRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'generateTradingAnalyticsIframeRequest' is not null or undefined
            assertParamExists(
                'tradingAnalysisControllerGenerateWebTraderIframe',
                'generateTradingAnalyticsIframeRequest',
                generateTradingAnalyticsIframeRequest
            );
            const localVarPath = `/api/tradingAnalytics/generateWebTraderIframe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                generateTradingAnalyticsIframeRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {GetT4PSignalsRequest} getT4PSignalsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingAnalysisControllerGetT4PSignals: async (
            getT4PSignalsRequest: GetT4PSignalsRequest,
            options: any = {}
        ): Promise<RequestArgs> => {
            // verify required parameter 'getT4PSignalsRequest' is not null or undefined
            assertParamExists('tradingAnalysisControllerGetT4PSignals', 'getT4PSignalsRequest', getT4PSignalsRequest);
            const localVarPath = `/api/tradingAnalytics/getT4PSignals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                getT4PSignalsRequest,
                localVarRequestOptions,
                configuration
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingAnalysisControllerWebTVLinks: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tradingAnalytics/webTvLinks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * TradingAnalyticsApi - functional programming interface
 * @export
 */
export const TradingAnalyticsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TradingAnalyticsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {GenerateTradingAnalyticsIframeRequest} generateTradingAnalyticsIframeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingAnalysisControllerGenerateWebTraderIframe(
            generateTradingAnalyticsIframeRequest: GenerateTradingAnalyticsIframeRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTradingAnalyticsIframeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingAnalysisControllerGenerateWebTraderIframe(
                generateTradingAnalyticsIframeRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {GetT4PSignalsRequest} getT4PSignalsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingAnalysisControllerGetT4PSignals(
            getT4PSignalsRequest: GetT4PSignalsRequest,
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetT4PSignalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingAnalysisControllerGetT4PSignals(
                getT4PSignalsRequest,
                options
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradingAnalysisControllerWebTVLinks(
            options?: any
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWebTVLinksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradingAnalysisControllerWebTVLinks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * TradingAnalyticsApi - factory interface
 * @export
 */
export const TradingAnalyticsApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance
) {
    const localVarFp = TradingAnalyticsApiFp(configuration);
    return {
        /**
         *
         * @param {GenerateTradingAnalyticsIframeRequest} generateTradingAnalyticsIframeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingAnalysisControllerGenerateWebTraderIframe(
            generateTradingAnalyticsIframeRequest: GenerateTradingAnalyticsIframeRequest,
            options?: any
        ): AxiosPromise<GenerateTradingAnalyticsIframeResponse> {
            return localVarFp
                .tradingAnalysisControllerGenerateWebTraderIframe(generateTradingAnalyticsIframeRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GetT4PSignalsRequest} getT4PSignalsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingAnalysisControllerGetT4PSignals(
            getT4PSignalsRequest: GetT4PSignalsRequest,
            options?: any
        ): AxiosPromise<GetT4PSignalsResponse> {
            return localVarFp
                .tradingAnalysisControllerGetT4PSignals(getT4PSignalsRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradingAnalysisControllerWebTVLinks(options?: any): AxiosPromise<GetWebTVLinksResponse> {
            return localVarFp.tradingAnalysisControllerWebTVLinks(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TradingAnalyticsApi - object-oriented interface
 * @export
 * @class TradingAnalyticsApi
 * @extends {BaseAPI}
 */
export class TradingAnalyticsApi extends BaseAPI {
    /**
     *
     * @param {GenerateTradingAnalyticsIframeRequest} generateTradingAnalyticsIframeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradingAnalyticsApi
     */
    public tradingAnalysisControllerGenerateWebTraderIframe(
        generateTradingAnalyticsIframeRequest: GenerateTradingAnalyticsIframeRequest,
        options?: any
    ) {
        return TradingAnalyticsApiFp(this.configuration)
            .tradingAnalysisControllerGenerateWebTraderIframe(generateTradingAnalyticsIframeRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {GetT4PSignalsRequest} getT4PSignalsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradingAnalyticsApi
     */
    public tradingAnalysisControllerGetT4PSignals(getT4PSignalsRequest: GetT4PSignalsRequest, options?: any) {
        return TradingAnalyticsApiFp(this.configuration)
            .tradingAnalysisControllerGetT4PSignals(getT4PSignalsRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradingAnalyticsApi
     */
    public tradingAnalysisControllerWebTVLinks(options?: any) {
        return TradingAnalyticsApiFp(this.configuration)
            .tradingAnalysisControllerWebTVLinks(options)
            .then((request) => request(this.axios, this.basePath));
    }
}
