import React from 'react';
import { FormControlLabel, Switch as SwitchMat, SwitchProps as SwitchPropsMat } from '@material-ui/core';

export interface SwitchProps extends SwitchPropsMat {
    label?: React.ReactNode | string;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

const Switch: React.FunctionComponent<SwitchProps> = (props) => {
    const { label, labelPlacement, ...rest } = props;
    return <FormControlLabel labelPlacement={labelPlacement} control={<SwitchMat {...rest} />} label={label} />;
};

export default Switch;
