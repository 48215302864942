import { auth_hasJwtToken } from 'store/auth/selectors';
import { user_verified } from 'store/user/selectors';

export interface HeaderProps {
    hasJwtToken: boolean;
    user_verified: boolean;
}

export const mapStateToProps = (state) => ({
    hasJwtToken: auth_hasJwtToken(state),
    user_verified: user_verified(state),
});

export const mapDispatchToProps = {};
