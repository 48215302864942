import { makeStyles } from '@material-ui/core';
import { customGap } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    breadcrump_wrapper: {
        padding: `${theme.spacing(2)}rem ${theme.spacing(5)}rem 0`,
        display: 'flex',
        alignItems: 'baseline',
        ...customGap(theme.spacing(2), 'horizontal'),
    },
    item: {
        color: theme.palette.action.disabled,
    },
    link_item: {
        '&:link,&:visited': {
            color: theme.palette.primary.main,
        },
    },
}));
