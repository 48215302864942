import { Notification } from 'services/generatedClientFromSwagger';

export const notificationGetAmount = 100;

export interface NotificationReducerState {
    loading: boolean;
    notifications: Array<Notification>;
    deleteNotificationLoading: boolean;
    notFoundNotifications: Array<string>;

    offset: number;
    moreLoading: boolean;
    hasMoreData: boolean;
}
