import React from 'react';
import { Hidden, makeStyles } from '@material-ui/core';
import { customGap, isRtl, isTablet } from 'helpers/device_helper';
import BackOrMenuBtn from 'components/BackOrMenuBtn';
// import MainNotificationBtn from 'features/Notifications/components/MainNotificationBtn';
import Logo from 'components/Header/components/Logo';
import Icon, { IconsType } from 'components/Icon';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ConditionalWrapper from 'components/ConditionalWrapper';
import config from 'config/common';
import LanguageSelect from 'components/Header/components/LanguageSelect';
const kycActive = config.featuresFlags['kyc'];

export enum UserDepositStatus {
    'KYC' = 'complete_kyc',
    'VERIFIED' = 'verified',
}
export interface MenuTopSectionProps {
    notificationsAmount: number;
    menuState: boolean;
    toggleMenuState: () => void;
    userFlowGuard: { possibleLinks: Array<string>; link: string; buttonType: string; icon: IconsType };
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: `0 ${theme.spacing(3)}rem`,
        height: '4rem',
    },
    section_one: {
        color: theme.palette.grayVariants[500],
        display: 'flex',
        ...customGap(theme.spacing(2), 'horizontal'),
    },
    section_two: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        ...customGap(theme.spacing(5), 'horizontal'),
        '& .MuiOutlinedInput-root': { borderRadius: '20px', height: '36px', fontSize: '10px' },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': { fontSize: '1.6rem' },
    },
    wrapper__nonVerified: {
        backgroundColor: '#FEF0C7',
        color: '#DC6803',
        width: 'fit-content',
        height: '26px',
        borderRadius: '100px',
        display: 'flex',
        alignItems: 'center',
    },
    wrapper__verified: {
        backgroundColor: '#D3F8DF',
        color: '#099250',
    },
    icon: {
        margin: '0px 5.33px',
        stroke: '#DC6803',
    },
    icon__verified: {
        stroke: '#099250',
    },
    statusButton: { paddingRight: '5.33px', ...isRtl({ paddingLeft: '5.33px' }) },
}));

const MenuTopSection: React.FunctionComponent<MenuTopSectionProps> = (props) => {
    const { menuState, toggleMenuState, userFlowGuard } = props;
    const classes = useStyles({ menuState });
    const { t } = useTranslation('dashboard');

    //for now langue in mobile will work only when kyc is OFF **!temporary!**

    return (
        <div className={classes.wrapper}>
            <div className={classes.section_one}>
                {isTablet() && <BackOrMenuBtn toggleSideMenu={toggleMenuState} />}
                <Logo fromMenu={true} />
            </div>

            {menuState && userFlowGuard && kycActive ? (
                <ConditionalWrapper
                    condition={userFlowGuard.buttonType !== UserDepositStatus.VERIFIED}
                    wrapper={(wrapperChildren) => (
                        <Link to={`${userFlowGuard.link}`} onClick={() => toggleMenuState()}>
                            {wrapperChildren}
                        </Link>
                    )}
                >
                    <div
                        className={classNames({
                            [classes.wrapper__nonVerified]: true,
                            [classes.wrapper__verified]: userFlowGuard.buttonType === UserDepositStatus.VERIFIED,
                        })}
                    >
                        <Icon
                            className={classNames({
                                [classes.icon]: true,
                                [classes.icon__verified]: userFlowGuard.buttonType === UserDepositStatus.VERIFIED,
                            })}
                            name={userFlowGuard.icon}
                        />
                        <Typography className={classes.statusButton} variant="tXsMedium">
                            {t(userFlowGuard.buttonType)}
                        </Typography>
                    </div>
                </ConditionalWrapper>
            ) : (
                menuState && (
                    <div className={classes.section_two}>
                        <Hidden smUp>
                            <LanguageSelect toggleMenuState={toggleMenuState} />
                        </Hidden>
                    </div>
                )
            )}
        </div>
    );
};

export default MenuTopSection;
