import { ESystemTypes } from 'store/system/actionsTypes';
import { ESUMSUBTypes } from './actionsTypes';
import { SumsubReducerState } from './interface';

const initialState: SumsubReducerState = {
    sumsubData: undefined,
    sumsubLoading: false,
    requestApproved: undefined,
};

const SumsubReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ESUMSUBTypes.GET_SUMSUB_DATA: {
            return {
                ...state,
                sumsubData: undefined,
                sumsubLoading: true,
            };
        }
        case ESUMSUBTypes.GET_SUMSUB_DATA_SUCCESS: {
            return {
                ...state,
                sumsubData: payload,
                sumsubLoading: false,
            };
        }
        case ESUMSUBTypes.GET_SUMSUB_DATA_FAILED: {
            return {
                ...state,
                sumsubData: undefined,
                sumsubLoading: false,
            };
        }
        case ESUMSUBTypes.REQUEST_APPROVED: {
            return {
                ...state,
                requestApproved: true,
            };
        }
        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                sumsubData: undefined,
                sumsubLoading: false,
                requestApproved: undefined,
            };
        }
        default:
            return state;
    }
};

export default SumsubReducer;
