import React, { ChangeEvent } from 'react';
import {
    BaseTextFieldProps,
    FormControl,
    InputBaseComponentProps,
    InputLabel,
    TextField as TextFieldMat,
} from '@material-ui/core';
import Icon, { IconsType } from 'components/Icon';
import Loader from 'components/Loader';
import useStyles from './textfield.styles';
import Button from 'components/Button';
import InstrumentImage from 'assets/Instrument';

export interface TextFieldProps extends BaseTextFieldProps {
    onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onFocus?: (event: any) => void;
    onBlur?: (event: any) => void;
    startIcon?: IconsType | { label: string; style?: any };
    startIconAsFlag?: { label: string; style?: any };
    startIconAsButton?: () => void;
    endIconAsButton?: () => void;
    endIcon?: IconsType | { label: string; style?: any };
    endIconClassName?: string;
    loading?: boolean;
    disableMarginTop?: boolean;
    alignCenter?: boolean;
    neverDisabled?: boolean;
    readOnly?: boolean;
    inputProps?: InputBaseComponentProps;
}

const TextField: React.FunctionComponent<TextFieldProps> = (props) => {
    const {
        endIconClassName,
        startIcon,
        startIconAsFlag,
        startIconAsButton,
        endIcon,
        endIconAsButton,
        loading,
        type,
        disabled,
        label,
        id,
        variant = 'outlined',
        disableMarginTop,
        alignCenter,
        onFocus,
        onBlur,
        neverDisabled,
        readOnly,
        inputProps,
        ...rest
    } = props;
    const classes = useStyles({
        disableMarginTop: !!disableMarginTop,
        disabled: disabled || loading,
        alignCenter,
    });

    const isPasswordField = props.type === 'password';
    const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);

    const handlePasswordVisibleClick = () => setPasswordVisible(!passwordVisible);

    const getStartAdormentIcon = () => {
        if (startIconAsFlag?.label) {
            return <InstrumentImage instrument={startIconAsFlag.label} iconSize="0.7" />;
        }

        if (!startIcon) return null;
        let icon;

        if (typeof startIcon === 'string') {
            icon = <Icon name={startIcon} />;
        } else {
            icon = <Icon name={IconsType.custom}>{startIcon}</Icon>;
        }
        if (startIconAsButton) {
            return (
                <Button variant="text" onClick={startIconAsButton} color="inherit">
                    {icon}
                </Button>
            );
        }
        return icon;
    };

    const getEndAdormentIcon = () => {
        if (loading) return <Loader size={15} />;
        if (isPasswordField) {
            return (
                <Icon
                    onClick={handlePasswordVisibleClick}
                    style={{ cursor: 'pointer' }}
                    name={passwordVisible ? IconsType.visible : IconsType.un_visible}
                />
            );
        }
        let icon;
        if (typeof endIcon === 'string') {
            icon = <Icon className={endIconClassName} name={endIcon} />;
        } else {
            icon = <Icon name={IconsType.custom}>{endIcon}</Icon>;
        }
        if (endIconAsButton) {
            return (
                <Button variant="text" onClick={endIconAsButton} color="inherit">
                    {icon}
                </Button>
            );
        }
        return icon;
    };

    const getInputType = () => {
        if (isPasswordField) {
            if (passwordVisible) return 'text';
            return 'password';
        }
        return type;
    };

    return (
        <FormControl fullWidth={props.fullWidth}>
            {label && (
                <InputLabel shrink error={false} htmlFor={id || 'textfield-input'}>
                    {label}
                </InputLabel>
            )}
            <TextFieldMat
                type={getInputType()}
                classes={{ root: classes.root }}
                {...rest}
                variant={variant}
                id={id || 'textfield-input'}
                disabled={!neverDisabled && (disabled || loading)}
                InputProps={{
                    classes: {
                        input: classes.input,
                    },
                    onFocus,
                    onBlur,
                    startAdornment: getStartAdormentIcon(),
                    endAdornment: getEndAdormentIcon(),
                    readOnly: readOnly || false,
                    inputProps: inputProps,
                }}
            />
        </FormControl>
    );
};

export default TextField;
