import React from 'react';
import PageLayout from 'components/Layouts/PageLayout';
import imgSrc from 'assets/images/contactUs.png';
import useStyles from './not_qualified_to_withdrawal_volume.styles';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';

export interface NotQualifiedToWithdrawalProps {
    fullName: string;
}

const NotQualifiedToWithdrawalVolume: React.FunctionComponent<NotQualifiedToWithdrawalProps> = (props) => {
    const classes = useStyles({ imgSrc });
    const { t } = useTranslation('myWallet');

    const { fullName } = props;

    return (
        <PageLayout fullHeight fullWidth infoData>
            <div className={classes.img} />
            <Typography variant="h1" className={classes.title}>
                {t('not-qualified-to-withdrawal-volume.title', { fullName: fullName })}
            </Typography>
            <Typography variant="body1" color="textSecondary">
                {t('not-qualified-to-withdrawal-volume.description')}
            </Typography>
        </PageLayout>
    );
};

export default NotQualifiedToWithdrawalVolume;
