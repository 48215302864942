import { EPlatformTypes } from './actionsTypes';
import { batch } from 'react-redux';
import axios from 'services/axios';
// import { changePlatformSrc } from 'helpers/changePlatformSrc';
// import { RootState } from '..';
// import { user_selectedTpAccount } from 'store/user/selectors';
// import i18 from 'i18n';

export const platform_getIframeUrl = (accountName: string, tradingId: string) => {
    return async (dispatch, getState) => {
        try {
            // const state: RootState = getState();
            // const selectedTp = user_selectedTpAccount(state);
            dispatch({ type: EPlatformTypes.GET_IFRAME_URL });
            const getIframeRes = await axios.WebTraderApi.webTraderControllerGenerateWebTraderIframe({
                accountName,
                tradingId,
            });
            dispatch({ type: EPlatformTypes.GET_IFRAME_URL_SUCCESS, payload: getIframeRes.data });
            // if (selectedTp) {
            //     changePlatformSrc(selectedTp, i18.language , getIframeRes.data.iframeUrl);
            // }
        } catch (error) {
            return batch(() => {
                dispatch({ type: EPlatformTypes.GET_IFRAME_URL_FAILED });
            });
        }
    };
};

export const platform_resetState = () => ({ type: EPlatformTypes.RESET_STATE });
