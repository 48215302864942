import React from 'react';
import { Tabs, Tab, Hidden } from '@material-ui/core';
import useStyles from './tab-panel.styles';
import Icon, { IconsType } from 'components/Icon';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import MuiLink from '@material-ui/core/Link';

export interface TabProps {
    icon?: IconsType;
    label: string;
    subLabel?: any;
    labelMobile?: string;
    link?: string;
    desktopOnly?: boolean;
    endNumber?: number;
    id?: string;
    outsourceLink?: string;
}

export interface TabPanelProps {
    tabs: Array<TabProps>;
    selected: number;
    fullWidth?: boolean;
    reverseTabs?: boolean;
    color?: 'default' | 'primary' | 'secondary' | 'error' | 'success';
    id?: string;
    onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
    isRow?: boolean;
    heightSize?: number;
    isScrollable?: boolean;
}

const TabPanel: React.FunctionComponent<TabPanelProps> = (props) => {
    const {
        tabs,
        selected,
        color,
        reverseTabs,
        id = 'tabPanel',
        onChange,
        fullWidth,
        isRow,
        heightSize,
        isScrollable,
    } = props;

    const [endNumber, setEndNumber] = React.useState<number | undefined>(undefined);

    const classes = useStyles({
        tabsAmount: tabs.length,
        endNumber,
        color,
        reverseTabs,
        fullWidth,
        isRow,
        heightSize,
        isScrollable,
    });

    React.useEffect(() => {
        const selectedTab = tabs[selected];
        if (selectedTab && selectedTab.endNumber) {
            setEndNumber(selectedTab.endNumber);
        }
    }, [selected, tabs]);

    const getTabIcon = (icon?: IconsType) => {
        if (icon) {
            return (
                <React.Fragment>
                    <Hidden smDown>
                        <Icon name={icon} />
                    </Hidden>
                    <Hidden mdUp>
                        <Icon name={icon} style={{ height: '1.8rem' }} />
                    </Hidden>
                </React.Fragment>
            );
        }
        return undefined;
    };

    return (
        <div className={classes.wrapper}>
            <Tabs
                id={id}
                value={selected}
                onChange={onChange}
                classes={{ root: classes.tabsRoot, indicator: classes.indicator, flexContainer: classes.flexContainer }}
                variant="fullWidth"
            >
                {tabs.map((tab, tabIndex) => (
                    <Tab
                        id={`${id}_${tab.id || tabIndex}`}
                        component={tab?.outsourceLink ? (MuiLink as any) : tab.link ? (Link as any) : 'div'}
                        to={tab.link}
                        href={tab?.outsourceLink && tab.outsourceLink}
                        target={tab?.outsourceLink && '_blank'}
                        underline={'none'}
                        key={`tab-${tabIndex}`}
                        label={
                            <div>
                                <span
                                    id={`${id}_${tab.id || tabIndex}_endNumber`}
                                    className={classNames({
                                        [classes.withEndNumber]: tab.endNumber && selected === tabIndex,
                                    })}
                                >
                                    <Hidden smDown>{tab.label}</Hidden>
                                    <Hidden mdUp>{tab.labelMobile || tab.label}</Hidden>
                                </span>
                                {tab.subLabel && <div>{tab.subLabel}</div>}
                            </div>
                        }
                        icon={getTabIcon(tab.icon)}
                        classes={{
                            wrapper: classes.tabWrapper,
                            root: classNames({ [classes.tabRoot]: true, [classes.tabDesktopOnly]: tab.desktopOnly }),
                            selected: classes.tabSelected,
                        }}
                    />
                ))}
            </Tabs>
        </div>
    );
};

export default TabPanel;
