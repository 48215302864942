import { makeStyles } from '@material-ui/core';

export const typographyVariantObj = {
    dXl2Regular: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '7.2rem',
        lineHeight: '9.0rem',
    },
    dXl2Medium: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '7.2rem',
        lineHeight: '9.0rem',
    },
    dXl2Semibold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '7.2rem',
        lineHeight: '9.0rem',
    },
    dXl2Bold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '7.2rem',
        lineHeight: '9.0rem',
    },
    dXlRegular: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '6.0rem',
        lineHeight: '7.2rem',
    },
    dXlMedium: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '6.0rem',
        lineHeight: '7.2rem',
    },
    dDxlSemibold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '6.0rem',
        lineHeight: '7.2rem',
    },
    xlBold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '6.0rem',
        lineHeight: '7.2rem',
    },
    dLgRegular: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '4.8rem',
        lineHeight: '6.0rem',
    },
    dLgMedium: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '4.8rem',
        lineHeight: '6.0rem',
    },
    dLgSemibold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '4.8rem',
        lineHeight: '6.0rem',
    },
    dLgBold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '4.8rem',
        lineHeight: '6.0rem',
    },
    dMdRegular: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '3.6rem',
        lineHeight: '4.4rem',
    },
    dMdMedium: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '3.6rem',
        lineHeight: '4.4rem',
    },
    dMdSemibold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '3.6rem',
        lineHeight: '4.4rem',
    },
    dMdBold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '3.6rem',
        lineHeight: '4.4rem',
    },
    dSmRegular: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '3.0rem',
        lineHeight: '3.8rem',
    },
    dSmMedium: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '3.0rem',
        lineHeight: '3.8rem',
    },
    dSmSemibold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '3.0rem',
        lineHeight: '3.8rem',
    },
    dSmBold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '3.0rem',
        lineHeight: '3.8rem',
    },
    dXsRegular: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '2.4rem',
        lineHeight: '3.2rem',
    },
    dXsMedium: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '2.4rem',
        lineHeight: '3.2rem',
    },
    dXsSemibold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '2.4rem',
        lineHeight: '3.2rem',
    },
    dXsBold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '2.4rem',
        lineHeight: '3.2rem',
    },
    tXlRegular: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '2.0rem',
        lineHeight: '3.0rem',
    },
    tXlMedium: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '2.0rem',
        lineHeight: '3.0rem',
    },
    tXlSemibold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '2.0rem',
        lineHeight: '3.0rem',
    },
    tXlBold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '2.0rem',
        lineHeight: '3.0rem',
    },
    tLgRegular: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1.8rem',
        lineHeight: '2.8rem',
    },
    tLgMedium: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.8rem',
        lineHeight: '2.8rem',
    },
    tLgSemibold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.8rem',
        lineHeight: '2.8rem',
    },
    tLgBold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1.8rem',
        lineHeight: '2.8rem',
    },
    tMdRegular: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
    },
    tMdMedium: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
    },
    tMdSemibold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
    },
    tMdBold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
    },
    tSmallRegular: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1.4rem',
        lineHeight: '2.0rem',
    },
    tSmallMedium: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.4rem',
        lineHeight: '2.0rem',
    },
    tSmallSemibold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.4rem',
        lineHeight: '2.0rem',
    },
    tSmallBold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1.4rem',
        lineHeight: '2.0rem',
    },
    tXsRegular: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
    },
    tXsMedium: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
    },
    tXsSemibold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
    },
    tXsBold: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
    },
};

export default makeStyles((theme) => typographyVariantObj);
