import { Pages } from 'interfaces/main';
import { user_notQualifiedToUsResidency } from 'store/user/selectors';
import { kyc_completed, kyc_currentStep } from '../../store/selector';

export interface CompleteYourProfileProps {
    completedStep: number;
    kycCompleted: boolean;
    user_notQualifiedToUsResidency: boolean;
}

export const mapStateToProps = (state) => ({
    completedStep: kyc_currentStep(state),
    kycCompleted: kyc_completed(state),
    user_notQualifiedToUsResidency: user_notQualifiedToUsResidency(state),
});

export const mapDispatchToProps = {};

const kycCreateSteps = (count): Array<{ link: string }> => {
    return Array.from({ length: count }, (_, index) => null).map((step, index) => {
        return {
            link: '/' + Pages.KYC + '/' + Pages[`KYC__${index}`],
        };
    });
};

export const kycSteps = kycCreateSteps(6);
