import React from 'react';
import ToHomePage from 'components/PrivateRoute/ToHomePage';
import config from 'config/common';
import MainNotificationBtn from './components/MainNotificationBtn';
import NotificationsRouter from './scenes/NotificationsRouter';

export const notificationsActive = config.featuresFlags['notifications'];

export const headerComponentBtn = () => {
    if (!notificationsActive) return null;
    return <MainNotificationBtn />;
};

export const notificationsMainPage = () => {
    if (!notificationsActive) return ToHomePage;
    return NotificationsRouter;
};

export default notificationsMainPage();
